import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const getFilledAges = (subDomains) => {
  // check which columns are used
  return subDomains.reduce((ages, subdomain) => {
    ages = Array.from(
      new Set([
        ...ages,
        ...Object.keys(subdomain).filter((key) => {
          if (key !== 'name' && subdomain[key].length > 0) return true
          return false
        }),
      ])
    )
    return ages
  }, [])
}

const ShowObservationForm = ({
  children,
  domain,
  domainsProccessed,
  index,
  onlyActivityDone,
}) => {
  const nextDomain = domainsProccessed[index + 1]
  if (
    (nextDomain &&
      (domain.id !== nextDomain.id ||
        (domain.id === nextDomain.id &&
          getFilledAges(nextDomain.subDomains).length === 0))) ||
    !nextDomain
  ) {
    return children
  }

  return null
}

const ClassHeaders = ({ subDomains, onlyActivityDone }) => {
  const ages = getFilledAges(subDomains)
  let classes = 'text-center bg-white rounded-bl rounded-br m-1 p-1'
  if (onlyActivityDone) {
    classes += ' text-white mt-0 h-0 overflow-hidden'
  } else {
    classes += 'mb-2'
  }

  if (onlyActivityDone) {
    return <div className={classes}></div>
  }

  return (
    <>
      {ages.map((age) => {
        const label = age === 2 ? 'À partir de 2 ans ½' : `${age} ans`
        return <div className={classes}>{label}</div>
      })}
    </>
  )
}

const ActivitesGrid = ({
  children,
  subDomains,
  background,
  onlyActivityDone,
}) => {
  const ages = getFilledAges(subDomains)
  let gridTemplateColumns = `20px${ages.map((a) => ' auto').join('')}`
  if (onlyActivityDone) {
    gridTemplateColumns = `20px auto`
  }

  return (
    <div
      className="p-1"
      style={{
        display: 'grid',
        gridTemplateColumns,
        gridTemplateRows: 'auto',
        height: '100%',
        minHeight: '100%',
      }}
    >
      {children}
    </div>
  )
}

const Activities = ({
  subdomain,
  columns = 1,
  onClickActivity,
  background,
}) => {
  const activities = Object.keys(subdomain)
    .filter((k) => k !== 'name')
    .map((k) => subdomain[k])
    .flat()
    .filter((a) => a.done)
  // add a dotted border for the first activity updated_at coming after july or december
  // as the teacher makes 2 meeting per year, they need to know when activies have been made.
  let lastYearMonthKey = null
  return activities.map((a, idx) => {
    const details = a.detail.ChildrenActivities
    let drawBorder = false

    if (details && details.length > 0) {
      const firstActivity = details[0]
      const updatedAt = new Date(firstActivity.updatedAt)
      // get year of updatedAt variable
      const year = new Date(updatedAt).getFullYear()
      let currentMonthKey = `${year}-${updatedAt.getMonth()}`

      // each time year month change add the border

      if (lastYearMonthKey && lastYearMonthKey !== currentMonthKey) {
        drawBorder = true

        if (idx === 0) {
          drawBorder = false
        }
      }
      lastYearMonthKey = currentMonthKey
    }

    let style =
      columns === 1
        ? { width: '110px', marginRight: '10px' }
        : { width: '100%' }
    if (drawBorder) {
      style = {
        ...style,
        borderLeft: `5px dotted ${background}`,
        paddingLeft: '20px',
        boxSizing: 'content-box',
      }
    }

    return (
      <div
        className={`flex flex-col justify-start items-center cursor-pointer ${
          a.done ? 'opacity-100 font-bold	' : 'opacity-50'
        }`}
        data-activity="activity"
        style={style}
        key={`activity-${a.activity}`}
        onClick={() => onClickActivity(a.detail)}
      >
        <img
          alt="a"
          src={a.image || 'https://via.placeholder.com/100'}
          height="30px"
          width={a.done ? '125px' : '30px'}
          style={
            a.done
              ? { borderRadius: '0.75rem 0.75rem', marginRight: '6px' }
              : {
                  borderRadius: '0.75rem 0.75rem',
                  margin: '3px 6px',
                  maxHeight: '30px',
                }
          }
        />
        <div
          className="text-xs text-center align-middle"
          style={{ fontSize: '8px' }}
        >
          {a.activity}
        </div>
      </div>
    )
  })
}

export default function LogBookPdf({
  domainsProccessed = [],
  classToUse,
  onClickActivity,
  isEditing,
  setIsEditing,
  saveObservation,
  form,
  child,
  onlyActivityDone,
}) {
  const { setValue } = form
  const [observations, setObservation] = useState({})

  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16)

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    }
  }

  let modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline'],
      [{ color: [] }, { align: [] }],
    ],
  }

  return (
    <>
      {domainsProccessed &&
        domainsProccessed.map((domain, index) => (
          <>
            {getFilledAges(domain.subDomains).length > 0 && (
              <React.Fragment key={`fragement-${domain.id}-${index}`}>
                <div
                  className="mt-8 print:m-0 overflow-scroll md:overflow-hidden h-full"
                  data-domain="domain"
                  style={{
                    border: `1px dashed ${domain.color}`,
                    borderRadius: '25px 25px',
                    padding: '25px',
                    margin: '25px',
                  }}
                  key={`domain-${domain.id}-${index}`}
                >
                  <div className="flex items-center justify-center pb-2">
                    <div className="flex items-center justify-center text-xl font-medium text-gray-800 text-center mb-4 align-middle">
                      <span
                        style={{ color: domain.color }}
                        className="text-sm mr-4"
                      >
                        ◍
                      </span>
                      <span>{domain.name}</span>
                      <span
                        style={{ color: domain.color }}
                        className="text-sm ml-4"
                      >
                        ◍
                      </span>
                    </div>
                  </div>
                  <ActivitesGrid
                    subDomains={domain.subDomains}
                    background={domain.color}
                    onlyActivityDone={onlyActivityDone}
                  >
                    {domain.subDomains.map((subDomain, index) => (
                      <React.Fragment
                        key={`subDomain-${subDomain.id}-${index}`}
                      >
                        <div
                          className={'text-center pr-2 pl-6 pb-4 text-white'}
                          style={{
                            writingMode: 'vertical-lr',
                            transform: 'scale(-1,-1)',
                            opacity: 0.8,
                            color: domain.color,
                          }}
                        >
                          {subDomain.name}
                        </div>
                        {onlyActivityDone && (
                          <>
                            <div
                              className={`flex flex-wrap flex-auto items-start content-start justify-start gap-1 bg-white mx-1 p-2 ${
                                index === 0 ? 'rounded-tl rounded-tr' : ''
                              }`}
                              data-activitycontainer="container"
                              key={`subDomain-${subDomain.id}-pdf`}
                            >
                              <Activities
                                subdomain={subDomain}
                                background={domain.color}
                                onClickActivity={onClickActivity}
                              />
                            </div>
                          </>
                        )}
                        {!onlyActivityDone &&
                          Object.keys(subDomain).map((age) => {
                            const ages = getFilledAges(domain.subDomains)
                            if (age === 'name' || !ages.includes(age)) {
                              return null
                            } else {
                              return (
                                <div
                                  className={`flex flex-wrap flex-auto items-start content-start justify-start gap-1 bg-white mx-1 p-2 ${
                                    index === 0 ? 'rounded-tl rounded-tr' : ''
                                  }`}
                                  data-activitycontainer="container"
                                  key={`subDomain-${subDomain.id}-age-${age}`}
                                >
                                  {subDomain[age].map((t) => (
                                    <div
                                      className={`flex flex-row justify-start items-center cursor-pointer ${
                                        t.done
                                          ? 'opacity-100 font-bold	'
                                          : 'opacity-50'
                                      }`}
                                      data-activity="activity"
                                      style={
                                        ages.length < 3
                                          ? {
                                              width: '40%',
                                              marginRight: '10px',
                                            }
                                          : { width: '100%' }
                                      }
                                      key={`activity-${t.activity}`}
                                      onClick={() => onClickActivity(t.detail)}
                                    >
                                      <img
                                        alt="a"
                                        src={
                                          t.image ||
                                          'https://via.placeholder.com/100'
                                        }
                                        height="30px"
                                        width={t.done ? '60px' : '30px'}
                                        style={
                                          t.done
                                            ? {
                                                borderRadius: '0.75rem 0.75rem',
                                                marginRight: '6px',
                                              }
                                            : {
                                                borderRadius: '0.75rem 0.75rem',
                                                margin: '3px 6px',
                                              }
                                        }
                                      />
                                      <div
                                        className="text-xs text-left align-middle"
                                        style={{ fontSize: '8px' }}
                                      >
                                        {t.activity}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )
                            }
                          })}
                      </React.Fragment>
                    ))}
                    <>
                      <div></div>
                      <ClassHeaders
                        subDomains={domain.subDomains}
                        onlyActivityDone={onlyActivityDone}
                      />
                    </>
                  </ActivitesGrid>
                </div>

                <ShowObservationForm
                  domain={domain}
                  domainsProccessed={domainsProccessed}
                  index={index}
                >
                  <div
                    className="p-2 py-4 mt-8 print:m-0 print:h-screen flex flex-col"
                    data-observation="observation"
                    style={{
                      margin: '25px',
                      borderRadius: '25px 25px',
                      background: domain.color,
                    }}
                  >
                    <div className="bg-white rounded-lg py-2 flex-1">
                      <div
                        className="text-white flex justify-between items-center text-2xl px-8 my-4"
                        style={{
                          backgroundImage: `linear-gradient(to right, ${
                            domain.color
                          }, ${hexToRGB(domain.color, '0.6')}, ${
                            domain.color
                          })`,
                        }}
                      >
                        <div>Observation</div>

                        {isEditing &&
                        isEditing.name === domain.name &&
                        isEditing.index === index ? (
                          <div className="text-xs flex gap-2 m-1 print:hidden">
                            <div
                              className="cursor-pointer bg-green-500 p-2 rounded"
                              onClick={() => {
                                saveObservation({
                                  commentJSON:
                                    observations?.[domain.id]?.[index],
                                  DomainId: domain.id,
                                  ChildId: child.id,
                                })
                                setIsEditing(false)
                              }}
                            >
                              Sauvegarder
                            </div>

                            <div
                              className="cursor-pointer bg-red-500 p-2 rounded"
                              onClick={() => {
                                setValue(
                                  `observation-${domain.id}-${index}`,
                                  domain?.comment
                                )
                                setIsEditing(false)
                              }}
                            >
                              Annuler
                            </div>
                          </div>
                        ) : (
                          <div
                            className="text-xs cursor-pointer print:hidden"
                            onClick={() =>
                              setIsEditing({ name: domain.name, index })
                            }
                          >
                            Modifier
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          height: '85%',
                          minHeight: '240px',
                          padding: '10px',
                        }}
                      >
                        <ReactQuill
                          modules={
                            !isEditing || isEditing?.index !== index
                              ? { toolbar: false }
                              : modules
                          }
                          defaultValue={domain.commentJSON}
                          readOnly={!isEditing || isEditing?.index !== index}
                          onChange={(content, delta, source, editor) => {
                            setObservation({
                              [domain.id]: {
                                [index]: editor.getContents(),
                              },
                            })
                            return editor.getContents()
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-center py-2">
                      <div className="text-xl font-medium text-white text-center">
                        {domain.name}
                      </div>
                    </div>
                  </div>
                </ShowObservationForm>
              </React.Fragment>
            )}
          </>
        ))}
    </>
  )
}

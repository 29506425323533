import React, { useRef, useState, useEffect } from 'react'
import ReactToPrint from 'react-to-print'
import { useForm } from 'react-hook-form'
import Input from 'components/form/Input'
import Button from 'components/form/Button'
import montessori from 'assets/images/logo_ecole_montessori_pau.jpg'
import {
  dateFormat,
  timeToNumber,
  twoDecimalPlace,
  getDateWithoutTime,
} from '../lib/utils'
import { BILL_TYPE, BILL_STATUS, USER_ROLE } from 'lib/constants'
import { useTranslation } from 'react-i18next'

export default function Bill({
  bill,
  creditNote,
  onCreateCreditNote,
  role,
  onSubmitEditing,
  onPayBill,
  isLoading,
  onCancelBill,
  recreate,
  onRecreate,
  noPrintable = false,
}) {
  const componentToPrint = useRef()
  const [schoolingYear, setSchoolingYear] = useState()
  const [billingYear, setBillingYear] = useState()
  const [quantity, setQuantity] = useState()
  const [isEditing, setIsEditing] = useState()
  const [isEditingPaymentTerms, setIsEditingPaymentTerms] = useState()
  const [totalHt, setTotalHt] = useState(0)

  const [firstname, setFirstname] = useState()
  const [lastname, setLastname] = useState()
  const form = useForm()
  const { register, handleSubmit, watch, getValues } = form

  const { t } = useTranslation()

  const amount = watch('amount')
  const qte = watch('qte')

  const toggleEditing = (kind) => {
    let data = getValues(kind)
    switch (kind) {
      case 'info':
        isEditing && onSubmitEditing({ info: data })
        setIsEditing(!isEditing)
        break
      case 'paymentTerms':
        isEditingPaymentTerms && onSubmitEditing({ paymentTerms: data })
        setIsEditingPaymentTerms(!isEditingPaymentTerms)
        break
      default:
        break
    }
  }

  const MonthlyBankDebit = ({ year, amount, dateStart, numberPayment }) => {
    let monthStart
    let incre
    if (dateStart === 'Septembre') {
      monthStart = new Date(`${billingYear}-08-06`)
      incre = [...Array(numberPayment || 10).keys()]
    } else {
      monthStart = new Date(`${billingYear}-01-06`)
      incre = [...Array(numberPayment || 6).keys()]
    }

    const months = incre.map((i) => {
      let month = monthStart.getMonth() + 1

      monthStart.setMonth(month)
      if (month === 12 && monthStart.getMonth() + 1 === 1) {
        monthStart.setFullYear(monthStart.getFullYear() + 1)
      }

      return {
        month: month >= 10 ? month.toString() : `0${month}`,
        year: month >= 8 ? billingYear - 1 : billingYear,
      }
    })

    //Payment in 10 month for a scholarship
    const monthlyAmount = parseInt(amount, 10) / incre.length
    return (
      <div className="ml-8">
        {months.map((m) => (
          <li key={m.month}>
            {' '}
            {t('{{monthlyAmount}}€ le 06/{{mois}}/{{annee}}', {
              monthlyAmount: monthlyAmount,
              mois: m.month,
              annee: m.year,
            })}
          </li>
        ))}
      </div>
    )
  }

  useEffect(() => {
    if (bill && bill.BillChildren) {
      let year = Number(bill.name.split('-')[1])
      setSchoolingYear(`${year - 1}-${year}`)
      setBillingYear(year)
      let qte = 0
      bill.BillItems.forEach((billItem) => {
        if (billItem.quantity) {
          qte += billItem.quantity
        } else {
          qte += timeToNumber(billItem.quantity_time)
        }
      })
      setQuantity(qte)
      setFirstname(bill?.BillChildren[0].Child.firstname)
      setLastname(bill?.BillChildren[0].Child.lastname)
    }
  }, [bill])

  // calcule totalHt
  useEffect(() => {
    if (!isNaN(amount) && !isNaN(qte)) {
      setTotalHt(twoDecimalPlace(Number(amount) * Number(qte)))
    } else if (bill && bill?.BillItems[0]?.amount && quantity) {
      setTotalHt(twoDecimalPlace(bill.BillItems[0].amount * quantity))
    }
  }, [
    onCreateCreditNote,
    amount,
    qte,
    creditNote,
    bill,
    quantity,
    getValues,
    watch,
  ])

  return (
    <>
      <div className="text-blue-600 text-center flex justify-center print:hidden">
        <div className="flex justify-end sm:w-1/2 xl:w-1/2 gap-2 pr-4 my-4">
          {bill &&
            bill.status === BILL_STATUS.pending &&
            !bill?.BillUsers[0]?.Company?.id &&
            bill.type === BILL_TYPE.childcare &&
            role &&
            role !== USER_ROLE.ADMIN && (
              <button
                className="bg-green-500 text-white py-4 px-4 rounded-lg"
                type="submit"
                onClick={onPayBill}
              >
                {t('Payer la facture')}
              </button>
            )}
          {bill &&
            bill?.status === BILL_STATUS.pending &&
            role &&
            role === USER_ROLE.ADMIN &&
            !recreate && (
              <>
                <button
                  className="text-red-500 p-4 rounded-lg"
                  onClick={onCancelBill}
                >
                  {t('Annuler la facture')}
                </button>
              </>
            )}
          {bill &&
            bill?.status === BILL_STATUS.cancel &&
            role === USER_ROLE.ADMIN &&
            bill?.type !== BILL_TYPE.creditnote && (
              <>
                {!recreate ? (
                  <button
                    className="text-yellow-500 p-4 rounded-lg"
                    onClick={onRecreate}
                  >
                    {t('Mode création de facture')}
                  </button>
                ) : (
                  <button
                    className="text-yellow-500 p-4 rounded-lg"
                    onClick={onRecreate}
                  >
                    {t("Mode création d'avoir")}
                  </button>
                )}
              </>
            )}
          <ReactToPrint
            trigger={() => (
              <button className="bg-transparent text-blue-600 py-3 px-4 rounded-lg hover:bg-blue-500 hover:text-white">
                {!noPrintable && t('Imprimer')}
              </button>
            )}
            content={() => componentToPrint.current}
            bodyClass={''}
            documentTitle={
              bill && `${bill.BillUsers[0].User.lastname} ${bill.name}`
            }
            pageStyle={
              !noPrintable &&
              `" 
          @media all {
            .page-break {
              display: none;
            }
          }
          
          @media print {
            html, body {
              height: initial !important;
              overflow: initial !important;
              -webkit-print-colorimport LoginComponent from './Login';
-adjust: exact;import { useLocation } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useRegistration } from 'hooks/useRegistration';

            }
          }
          
          @media print {
            .page-break {
              margin-top: 1rem;
              display: block;
              page-break-before: auto;
            }
          }
          
          @page {
            size: auto;
            margin: 20mm;
          }
          "`
            }
          />
        </div>
      </div>
      <div className="flex items-center print:items-start justify-center mt-2 print:mt-0">
        <form
          className="w-full form-container sm:w-full xl:w-1/2"
          onSubmit={(e) => {
            e.preventDefault()
            if ((isEditing || isEditingPaymentTerms) && !creditNote) {
              toggleEditing(isEditing ? 'info' : 'paymentTerms')
              return
            }
          }}
        >
          <div ref={componentToPrint}>
            <div className="flex w-full justify-between">
              <div>
                <div className="text-3xl">
                  {creditNote ? 'FA-XXXX-XXXX' : bill && bill.name}
                </div>
                <div className="text-xl">
                  {t('Année scolaire')} {schoolingYear}
                </div>
              </div>
              <div>
                <img
                  src={montessori}
                  alt="Montessori"
                  className="logo"
                  style={{ width: '200px' }}
                />
              </div>
            </div>
            <div className="w-full my-2 border-2 border-black"></div>

            <div className="flex w-full justify-between items-center text-sm">
              <div>
                <div className="font-black">École Montessori de Pau</div>
                <div>4 bis rue Maryse Bastié</div>
                <div>64000 Pau</div>
                <div>contact@ecole-montessori-pau.fr</div>
              </div>
              <div className="text-base">
                {bill?.type !== BILL_TYPE.childcare &&
                bill?.BillUsers?.[0]?.Company ? (
                  <span>{bill.BillUsers[0].Company.name}</span>
                ) : (
                  <span>
                    {t('M / Mme')} {bill && bill.BillUsers[0].User.lastname}
                  </span>
                )}
              </div>
              <div className="self-start font-black">
                {creditNote
                  ? dateFormat(new Date())
                  : bill &&
                    bill?.createdAt &&
                    t('{{jour}}/{{mois}}/{{annee}}', {
                      jour: getDateWithoutTime(bill.createdAt).split('/')[2],
                      mois: getDateWithoutTime(bill.createdAt).split('/')[1],
                      annee: getDateWithoutTime(bill.createdAt).split('/')[0],
                    })}
              </div>
            </div>

            <div className="w-full my-2 border-2 border-black"></div>

            <div className="flex w-full justify-around items-center text-sm px-4">
              <div className="w-full text-center">
                <div className="font-black">{t('Désignation')}</div>
                {!creditNote ? (
                  <div>
                    {bill && bill?.BillItems && bill?.BillChildren && (
                      <>
                        {t(
                          bill.BillItems[0]?.designation
                            .replace(firstname, '')
                            .replace(lastname, '') +
                            '{{firstname}} {{lastname}}',
                          {
                            firstname,
                            lastname,
                          }
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="flex w-full justify-center">
                    <div className="w-full">
                      <Input
                        required
                        key={'designation'}
                        ref={register({ required: 'Champ obligatoire' })}
                        type="text"
                        name="designation"
                        label=""
                        placeholder="Frais de ..."
                        value={
                          watch('designation') ||
                          bill?.BillItems[0]?.designation
                        }
                        inputClassName="bg-gray-200 rounded-lg"
                        autofocus={true}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full text-center">
                <div className="font-black">{t('Prix')} HT</div>
                {!creditNote ? (
                  <>
                    <div>
                      {bill &&
                        bill.BillItems &&
                        bill.BillItems[0] &&
                        twoDecimalPlace(
                          bill?.BillItems[0].amount && bill.BillItems[0].amount
                        )}{' '}
                      €
                    </div>
                  </>
                ) : (
                  <div className="flex w-full justify-center">
                    <div className="w-2/5">
                      <Input
                        required
                        key={'amount'}
                        ref={register({ required: 'Champ obligatoire' })}
                        type="number"
                        name="amount"
                        label=""
                        placeholder="100"
                        value={watch('amount') || bill?.BillItems[0]?.amount}
                        inputClassName="bg-gray-200 rounded-lg"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full text-center">
                <div className="font-black">{t('Quantité')}</div>
                {!creditNote ? (
                  <div>
                    {quantity && quantity.toFixed(2)}{' '}
                    {bill && bill.type === BILL_TYPE.childcare && 'h'}
                  </div>
                ) : (
                  <div className="flex w-full justify-center">
                    <div className="w-2/5">
                      <Input
                        required
                        key={'qte'}
                        ref={register({ required: 'Champ obligatoire' })}
                        type="number"
                        name="qte"
                        label=""
                        placeholder="1"
                        step={1}
                        value={watch('qte') || quantity}
                        inputClassName="bg-gray-200 rounded-lg"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full text-center">
                <div className="font-black">{t('Total')} HT</div>
                <div>{totalHt} €</div>
              </div>
            </div>

            <div className="w-full mt-4 mb-4 border border-black"></div>

            <div className="flex w-full justify-between items-center text-sm">
              <div></div>
              <div></div>
              <div>{t('Total')} HT</div>
              <div>{totalHt} €</div>
            </div>
            <div className="flex w-full justify-between items-center text-sm mt-2">
              <div></div>
              <div></div>
              <div className="text-xl font-black">
                {t('Total à payer en euros')}
              </div>
              <div className="text-xl">
                <>{totalHt} €</>
              </div>
            </div>

            <div className="w-full mt-4 mb-4 border-2 border-black"></div>

            <div className="flex w-full justify-between items-center text-sm mt-2">
              <div>
                <div className="font-black text-xs">
                  {t('Modalités de paiements')}&nbsp;{' '}
                  {role === USER_ROLE.ADMIN && (
                    <span className="text-blue-500 print:hidden">
                      {isEditingPaymentTerms ? (
                        <>
                          <span
                            className="hover:text-blue-600 hover:cursor-pointer"
                            onClick={() => toggleEditing('paymentTerms')}
                          >
                            Enregistrer
                          </span>{' '}
                          &nbsp; | &nbsp;
                          <span
                            className="text-orange-600 hover:text-orange-500 hover:cursor-pointer"
                            onClick={() => setIsEditingPaymentTerms(false)}
                          >
                            Annuler
                          </span>
                        </>
                      ) : (
                        <span
                          className="hover:text-blue-600 hover:cursor-pointer"
                          onClick={() => toggleEditing('paymentTerms')}
                        >
                          Modifier
                        </span>
                      )}
                    </span>
                  )}
                </div>
                <div>
                  {isEditingPaymentTerms ? (
                    <>
                      <Input
                        key={'paymentTerms'}
                        ref={register()}
                        name="paymentTerms"
                        value={watch('paymentTerms') || bill?.paymentTerms}
                        placeholder="A propos.."
                        inputClassName="leading-10"
                      />
                    </>
                  ) : (
                    <>
                      {bill && !bill.paymentTerms && bill.type && (
                        <span>
                          {bill.type === BILL_TYPE.registrationFees
                            ? t(
                                `Règlement par chèque à l'ordre de l'école Montessori de Pau`
                              )
                            : bill.type === BILL_TYPE.registration
                            ? t(`Règlement par prélèvement`)
                            : t('Règlement par carte bancaire')}
                        </span>
                      )}
                      {bill &&
                        bill?.BillItems[0]?.Registration &&
                        !bill.paymentTerms &&
                        bill.type === BILL_TYPE.registration && (
                          <MonthlyBankDebit
                            year={schoolingYear}
                            amount={bill.amount}
                            dateStart={bill.BillItems[0].Registration.month}
                            numberPayment={
                              bill.BillItems[0].Registration.numberPayment
                            }
                          />
                        )}

                      {bill && bill.paymentTerms && (
                        <span>{bill.paymentTerms}</span>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="flex w-full flex-col justify-between text-sm mt-2">
              <div className="font-black text-xs">
                {t('Informations complémentaires')}&nbsp;{' '}
                {role === USER_ROLE.ADMIN && (
                  <span className="text-blue-500 print:hidden">
                    {isEditing ? (
                      <>
                        <span
                          className="hover:text-blue-600 hover:cursor-pointer"
                          onClick={() => toggleEditing('info')}
                        >
                          Enregistrer
                        </span>{' '}
                        &nbsp; | &nbsp;
                        <span
                          className="text-orange-600 hover:text-orange-500 hover:cursor-pointer"
                          onClick={() => setIsEditing(false)}
                        >
                          Annuler
                        </span>
                      </>
                    ) : (
                      <span
                        className="hover:text-blue-600 hover:cursor-pointer"
                        onClick={() => toggleEditing('info')}
                      >
                        Modifier
                      </span>
                    )}
                  </span>
                )}
              </div>
              <div className="flex flex-col bg-gray-100 h-20">
                {isEditing && (
                  <Input
                    key={'info'}
                    ref={register()}
                    name="info"
                    value={watch('info') || bill?.info}
                    placeholder="A propos.."
                    inputClassName="leading-10"
                  />
                )}
                {!isEditing && bill && bill.info}
                {!isEditing && bill?.status === BILL_STATUS.paid && (
                  <p>
                    {t('Facture réglée le {{day}}/{{month}}/{{year}}', {
                      day: getDateWithoutTime(bill.datePayment).split('/')[2],
                      month: getDateWithoutTime(bill.datePayment).split('/')[1],
                      year: getDateWithoutTime(bill.datePayment).split('/')[0],
                    })}
                  </p>
                )}
              </div>
            </div>

            <div className="flex w-full justify-end items-center text-xs mt-2">
              <div>
                <div>{t("Établissement d'enseignement primaire")}</div>
                <div>{t('TVA non applicable, art. 261-4-4°-a des CGI')}</div>
              </div>
            </div>

            <div className="w-full mt-4 mb-4 border border-black"></div>

            <div className="flex w-full justify-between items-center text-2xs mt-2 text-left">
              <div>
                <div>
                  {t(
                    "En cas de non règlement à l'échéance prévue, il sera dû, conformément aux articles L441-3 et L441-6 du code du commerce"
                  )}{' '}
                  :
                </div>
                <div>
                  -{' '}
                  {t(
                    "des pénalités de retard sur la base des honoraires restant dus, au taux de trois fois le taux d'intérêt en vigueur"
                  )}
                </div>
                <div>
                  -{' '}
                  {t(
                    'une indemnité forfaitaire pour frais de recouvrement de 40€'
                  )}
                </div>
              </div>
            </div>

            <div className="w-full mt-4 mb-4 border border-black"></div>

            <div className="flex w-full justify-between items-center text-xs mt-2">
              <div className="w-full text-center">
                ÉCOLE MONTESSORI DE PAU - SIRET: 82082595800011 / APE: 8520Z
              </div>
            </div>
          </div>
          {creditNote && (
            <div className="mt-4">
              <Button
                onClick={handleSubmit((e) => {
                  if (!isEditing && !isEditingPaymentTerms) {
                    if (bill?.info) {
                      e.info = bill?.info
                    }
                    if (bill?.paymentTerms) {
                      e.paymentTerms = bill?.paymentTerms
                    }
                    onCreateCreditNote(e)
                  }
                })}
                disabled={isLoading}
                submits
              >
                {!recreate
                  ? 'Créer un avoir pour cette facture'
                  : 'Recréer la facture'}{' '}
              </Button>
            </div>
          )}
        </form>
      </div>
    </>
  )
}

/* eslint-disable react/display-name */
import React from 'react'

export default React.forwardRef(
  (
    {
      type,
      label,
      labelPosition,
      name,
      placeholder,
      required,
      // onChange,
      className,
      helpText,
      error,
      value,
      prefix,
      suffix,
      defaultValue,
      disabled = undefined,
      autofocus = false,
      hide = false,
      inputClassName,
      inline,
      checked = false,
      step,
    },
    ref
  ) => {
    let classes = className

    // if (required) {
    //   classes += ' required'
    // }

    if (!labelPosition) {
      labelPosition = 'before'
    }

    if (disabled) {
      classes += ' cursor-not-allowed'
    }

    const display = hide ? 'none' : ''

    return (
      <label className={classes} htmlFor={name} style={{ display: display }}>
        <div
          className={required ? 'required' : ''}
          style={{ display: inline ? 'flex' : 'block' }}
        >
          {labelPosition === 'before' && (
            <span
              dangerouslySetInnerHTML={{ __html: label }}
              className={`whitespace-no-wrap ${disabled ? 'opacity-50' : ''} ${
                inline ? 'mr-1' : ''
              }`}
            />
          )}
          <div className="flex items-center">
            {prefix && <span>{prefix}</span>}
            <input
              ref={ref}
              type={type}
              id={name}
              name={name}
              step={step || 0.01}
              placeholder={
                typeof placeholder !== 'undefined' ? placeholder : label
              }
              defaultValue={value || defaultValue}
              disabled={disabled}
              autoFocus={autofocus}
              defaultChecked={checked}
              className={(inputClassName || '') + ' bg-gray-200 rounded print:bg-white'}
            />
            {suffix && <span>{suffix}</span>}
          </div>
          {labelPosition === 'after' && (
            <span
              dangerouslySetInnerHTML={{ __html: label }}
              className={`whitespace-no-wrap ${disabled ? 'opacity-50' : ''} ${
                inline ? 'ml-1' : ''
              }`}
            />
          )}
        </div>
        {error ? (
          <span className="help-text error">{error}</span>
        ) : (
          <span className="help-text">{helpText}</span>
        )}
      </label>
    )
  }
)

import React, { useState, useEffect } from 'react'

export default function YesNoButton({
  yes = 'Yes',
  no = 'No',
  defaultValue,
  name,
  onChange,
  form,
}) {
  const [val, setVal] = useState(defaultValue)
  const { setValue } = form

  useEffect(() => {
    setValue(name, defaultValue)
    // eslint-disable-next-line
  }, [])

  const changeValue = (bool) => {
    setVal(bool)
    onChange({ name, value: bool })
  }

  return (
    <>
      <div className="flex w-full text-white select-none cursor-pointer">
        <div
          onClick={() => changeValue(true)}
          className={`${
            val === true ? 'bg-green-600' : 'bg-gray-300 text-gray-500'
          } w-1/2 duration-100 rounded-l-sm `}
        >
          {yes}
        </div>
        <div
          onClick={() => changeValue(false)}
          className={`${
            val === false ? 'bg-red-600' : 'bg-gray-300 text-gray-500'
          } w-1/2 duration-100 rounded-r-sm`}
        >
          {no}
        </div>
      </div>
    </>
  )
}

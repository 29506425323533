import { userApi } from './user'
import { notifyApi } from './notify'
import { formsApi } from './form'
import { redirectApi } from './redirect'
import { registrationsApi } from './registrations'
import { billsApi } from './bills'
import { languageApi } from './language'

export default {
  user: userApi,
  notify: notifyApi,
  forms: formsApi,
  redirect: redirectApi,
  registrations: registrationsApi,
  bills: billsApi,
  languageApi: languageApi
}

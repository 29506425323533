import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useUser } from '../stores/user'
import { useHistory } from 'react-router-dom'
import useRegistration from '../hooks/useRegistration'
import useBill from '../hooks/useBill'
import useChildCare from '../hooks/useChildcare'
import Dashboard from '../components/Dashboard'
import Layout from '../components/Layout'
import { useNotify } from 'stores/notify'
import { useRegistrations } from 'stores/registrations'
import { useTranslation } from 'react-i18next'
import Spinner from '../components/common/Spinner'
import { USER_ROLE } from 'lib/constants'
import DashboardEmployee from '../components/DashboardEmployee'

const DashboardPage = (props) => {
  const registrations = useRegistrations(({ registrations }) => registrations)
  const [shouldDisplayDate, setShouldDisplayDate] = useState(false)
  const [dates, setDates] = useState()
  // const user = userApi()
  const history = useHistory()
  const { clearRegistration } = useUser(({ clearRegistration }) => ({
    clearRegistration,
  }))
  const { getAllRegistrations, hasValidRegistration } = useRegistration()
  const { getBillsAndStatus } = useBill()
  const { getChildcaresMonth } = useChildCare()
  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))
  const { t } = useTranslation()
  const [bill, setBill] = useState()
  const [registrationsLoad, setRegistrationsLoad] = useState(false)

  useEffect(() => {
    async function loadRegistrations() {
      const registrations = await getAllRegistrations()
      if (registrations.error) {
        setErrorMessage(t(registrations.error.message))
        return
      }
      setRegistrationsLoad(true)
    }
    if(props.role === USER_ROLE.PARENT){
      loadRegistrations()
    }
    //eslint-disable-next-line
  }, [])

  // We need to do this to reset the navigatin
  // while creating/updating a registration.
  // Otherwise we end up stuck on the last registration
  // the user created/modified
  useEffect(() => clearRegistration(), [clearRegistration])

  useEffect(() => {
    async function getDates() {
      try {
        const dates = await getChildcaresMonth()
        if (dates.error) {
          setErrorMessage(t(dates.error.message))
          return
        }
        setDates(dates.results)
      } catch (err) {
        setErrorMessage(t(err.message))
      }
    }
    if (shouldDisplayDate && !dates && props.role === USER_ROLE.PARENT) {
      getDates()
    }
  }, [dates, getChildcaresMonth, setErrorMessage, shouldDisplayDate, t, props.role])

  useEffect(() => {
    async function loadBill() {
      try {
        const bill = await getBillsAndStatus()
        setBill(bill.results)
      } catch (err) {
        setErrorMessage(t(err.message))
      }
    }
    if(props.role === USER_ROLE.PARENT){
      loadBill()
    }
    //eslint-disable-next-line
  }, [setErrorMessage])

  const onCreateRegistration = useCallback(() => {
    history.push('/schoolingyear/', { creating: true })
  }, [history])

  const onEditRegistration = useCallback(() => {
    if (registrations?.length) {
      history.push('/chooseregistration')
    }
  }, [history, registrations])

  const onManageNursery = useCallback(() => {
    if (hasValidRegistration()) {
      history.push('/nursery')
    }
  }, [hasValidRegistration, history])

  const onToggleDate = useCallback(() => {
    if (hasValidRegistration()) {
      setShouldDisplayDate(!shouldDisplayDate)
    }
  }, [hasValidRegistration, shouldDisplayDate])

  const onViewSummaryNursery = useCallback(
    (year, month) => {
      if (hasValidRegistration()) {
        history.push('/summarynursery', { year, month })
      }
    },
    [hasValidRegistration, history]
  )

  const disabledNavigation = useMemo(() => {
    const forbiddenRoutes = ['/about']

    if (!hasValidRegistration()) {
      forbiddenRoutes.push('/nursery')
      forbiddenRoutes.push('/nurseryhistory')
    }

    if (!Array.isArray(registrations) || registrations?.length === 0) {
      forbiddenRoutes.push('/chooseregistration')
    }

    return forbiddenRoutes
  }, [hasValidRegistration, registrations])

  const onViewBill = useCallback(
    (_billData) => {
      if (hasValidRegistration()) {
        history.push('/billsview')
      }
    },
    [hasValidRegistration, history]
  )

  const onPayBill = () => {
    history.push('/billsview')
  }

  const onViewCertificate = () => {
    history.push('/viewcertificate')
  }
  return (
    <Layout
      title={`École Montessori`}
      isHome
      disableNavigation={disabledNavigation}
    >
      {registrationsLoad && (props.role === USER_ROLE.ADMIN || props.role === USER_ROLE.PARENT) ? (
        <Dashboard
          onCreateRegistration={onCreateRegistration}
          onEditRegistration={onEditRegistration}
          onManageNursery={onManageNursery}
          onToggleDate={onToggleDate}
          onViewSummaryNursery={onViewSummaryNursery}
          onViewBill={onViewBill}
          onPayBill={onPayBill}
          onViewCertificate={onViewCertificate}
          shouldDisplayDate={shouldDisplayDate}
          hasValidRegistration={hasValidRegistration()}
          dates={dates}
          registrations={registrations}
          bill={bill}
        />
      ) : props.role === USER_ROLE.EMPLOYEE ?
        (
          <DashboardEmployee
            onClickNurseryViewer={() => history.push('/nurseryviewer')}
            onClickClassPresence={() => history.push('/classpresence')}
            onClickAcquiredSkills={() => history.push('/acquiredskills')}
            onClickCategoriesList={() => history.push('/category')}
            onClickActivitiesList={() => history.push('/activity')}
            onClickProgressionList={() => history.push('/progressionlist')}
          />
        )
        : (
          <div className="flex justify-center">
            <Spinner />
          </div>
        )}

    </Layout>
  )
}

export default DashboardPage

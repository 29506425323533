import React, { useRef, useState, useEffect } from 'react'
import StepButtons from './common/StepButtons'
import { useHistory } from 'react-router-dom'
import Spinner from './common/Spinner'
import ReactToPrint from 'react-to-print'
import { useForm } from 'react-hook-form'
import CheckBox from './form/CheckBox'
import LogBookPdf from './LogBookPDF'
import montessori from 'assets/images/logo_ecole_montessori_pau.jpg'
import { OLD_CLASSES, YOUNG_CLASSES } from 'lib/constants'

export default function LogBook({
  child,
  domains,
  isLoading,
  saveObservation,
  year,
  schoolClass,
  onClickActivity,
  profilePic,
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [classToUse, setClassToUse] = useState()

  const [domainsProccessed, setDomainsProcessed] = useState([])

  const form = useForm()
  const { control, watch } = form

  const componentToPrint = useRef()
  const history = useHistory()

  const watchActivityDone = watch('onlyActivityDone')

  const dataProcessing = (domains, filter) => {
    let domainsP = []
    domains.forEach((domain) => {
      if (domain.subDomains.length === 0) {
        return
      }

      domain.subDomains.forEach((subDomain) => {
        let findDomain = domainsP.filter(
          (d) => d.name === domain.name && d.subDomains.length < 2
        )[0]
        let previous = domainsP.filter(
          (d) => d.name === domain.name && d.subDomains.length === 2
        )[0]
        if (previous) {
          // set this props if domain already exists, so it will not display duplicate observation
          previous.disableObservation = true
        }

        let tmpDomain
        if (findDomain) {
          tmpDomain = findDomain
        } else {
          tmpDomain = {
            name: domain.name,
            comment: domain?.Observation?.comment,
            color: domain?.color || '#1EAFDC',
            subDomains: [],
            id: domain.id,
            commentJSON: domain?.Observation?.commentJSON,
          }
        }
        let classAge
        let young = ['2', '3', '4', '5', '6']
        let old = ['7', '8', '9']

        if (YOUNG_CLASSES.includes(schoolClass)) {
          classAge = young
          setClassToUse('young')
        } else if (OLD_CLASSES.includes(schoolClass)) {
          classAge = old
          setClassToUse('old')
        }

        let tmpSubDomain = {
          name: subDomain.name,
        }

        classAge.forEach((y) => {
          tmpSubDomain[y] = []
        })

        subDomain.Activities.forEach((ca) => {
          if (filter) {
            tmpSubDomain['3'].push({
              activity: ca.name,
              image: ca.File?.url,
              done: ca.ChildrenActivities.length > 0 ? true : false,
              detail: ca,
            })
          } else {
            if (ca.age && classAge.includes(ca.age.toString())) {
              if (tmpSubDomain[ca.age] && tmpSubDomain[ca.age].length > 0) {
                if (filter && ca.ChildrenActivities.length === 0) {
                } else {
                  tmpSubDomain[ca.age].push({
                    activity: ca.name,
                    image: ca.File?.url,
                    done: ca.ChildrenActivities.length > 0 ? true : false,
                    detail: ca,
                  })
                }
              } else {
                if (filter && ca.ChildrenActivities.length === 0) {
                } else {
                  tmpSubDomain[ca.age] = [
                    {
                      activity: ca.name,
                      image: ca.File?.url,
                      done: ca.ChildrenActivities.length > 0 ? true : false,
                      detail: ca,
                    },
                  ]
                }
              }
            }
          }
        })
        if (findDomain) {
          tmpDomain.subDomains.push(tmpSubDomain)
        } else {
          tmpDomain.subDomains.push(tmpSubDomain)
          domainsP.push(tmpDomain)
        }
      })
    })
    setDomainsProcessed(domainsP)
  }

  useEffect(() => {
    if (domains) {
      // should not display domains without subDomains, and activity witth domains
      let filteredDomains = domains.filter((domain) => {
        if (domain.subDomains.length > 0 && domain.id !== 'noDomain') {
          return true
        } else {
          return false
        }
      })

      dataProcessing(filteredDomains, watchActivityDone)
      // dataProcessing(domains, watchActivityDone)
    }
    //eslint-disable-next-line
  }, [watchActivityDone, domains])

  return (
    <>
      <div className="flex items-center justify-center flex-col w-full mt-8 ">
        <div className="flex flex-col w-full md:w-3/4">
          <div className="px-8">
            <StepButtons goBack={() => history.goBack()} hideNext />
          </div>

          {isLoading && <Spinner />}
          {domains && domains.length === 0 && !isLoading ? (
            <div className="p-2 bg-orange-100 text-orange-700 w-full px-8">
              Aucune activité n'a été trouvée
            </div>
          ) : (
            <>
              <div className="px-8 py-2 my-4 bg-gray-200">
                <div className="text-lg">Options:</div>
                <div>
                  <CheckBox
                    key="onlyActivityDone"
                    form={form}
                    control={control}
                    name={'onlyActivityDone'}
                    defaultChecked={false}
                    label="Mode carnet parent"
                  />
                </div>
              </div>

              <ReactToPrint
                trigger={() => (
                  <button className="bg-transparent text-blue-600 py-3 px-4 my-4 rounded-lg border-none">
                    Imprimer
                  </button>
                )}
                content={() => componentToPrint.current}
                bodyClass=""
                documentTitle={
                  child &&
                  `Carnet de suivi - ${child.firstname} ${child.lastname} `
                }
                pageStyle=" 

                html, body {
                  background: white !important;
                }
                @media all {
                  .page-break {
                    display: none;
                  }
                }
                
                @media print {
                  html, body {
                    // height: initial !important;
                    height: 100% !important;
                    overflow: initial !important;
                    background: white !important;
                    -webkit-print-color-adjust: exact;
                  }
                }
                
                @media print {
                  .page-break {
                    margin-top: 1rem;
                    display: block;
                  }
                }
                
                @page {
                  size: landscape;
                  margin: 10mm;
                  
                }

                [data-domain]{
                  page-break-before: always;
                  break-inside: auto;
                  // max-height: 100vh !important;
                  // height: 100vh !important;
                  position: relative;
                  height: 100%;
                  
                }

                [data-activity]{
                  // max-width: 50px;
                  // width: 30px;
                  font-size: 0.10rem; 
                }
                
                [data-activitycontainer]{
                  //   display: grid;
                  // grid-template-columns: 1fr 1fr;  
                  // page-break-after:auto;
                  break-inside: auto;
                }

                [data-observation]{
                  display: block;
                  page-break-before: always;
                  break-inside: avoid;
                  overflow: hidden;
                }

                textarea{
                  background: white !important;
                }

                .ql-toolbar.ql-snow{
                  display: none !important;
                }

                .ql-container.ql-snow{
                  border-style:none !important;
                }
                
                  "
              />
              <div ref={componentToPrint} className="w-full bg-white">
                <img
                  src={montessori}
                  alt="Montessori"
                  className="logo ml-auto mr-auto my-10"
                  style={{ width: '400px' }}
                />

                {child && (
                  <div className="w-full flex flex-col p-4 bg-white pb-12 mt-4">
                    <div className="text-center rounded-full  border-gray-200 border-8 overflow-hidden w-48 h-48 mr-auto ml-auto bg-white">
                      {profilePic && (
                        <img
                          src={`data:${profilePic.type};base64, ${profilePic.path}`}
                          alt={`${child.firstname}`}
                          className=""
                        />
                      )}
                    </div>
                    <div className="w-full text-center mt-4 text-2xl font-bold">
                      {child.firstname} {child.lastname}
                    </div>
                    <div className="text-center text-xl text-gray-600">
                      Mon carnet de suivi 2022-2023 (
                      {child.Registrations &&
                        child?.Registrations[0]?.SchoolClass.name}
                      )
                    </div>
                    <div className="w-full text-center text-sm mt-32 text-gray-600">
                      Enseignantes: Maria SEROURIAN et Laetitia OLIVIER
                    </div>
                  </div>
                )}
                <LogBookPdf
                  domainsProccessed={domainsProccessed}
                  classToUse={classToUse}
                  onlyActivityDone={watchActivityDone}
                  onClickActivity={onClickActivity}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  saveObservation={saveObservation}
                  form={form}
                  child={child}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

import React from 'react'

export default React.forwardRef(
  (
    {
      type,
      label,
      name,
      placeholder,
      required,
      onChange,
      className,
      helpText,
      error,
      value,
      defaultValue,
      rows,
      style = {},
      disabled = false,
    },
    ref
  ) => {
    let classes = className

    if (required) {
      classes += ' required'
    }

    return (
      <label className={classes} htmlFor={name}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
        <textarea
          disabled={disabled}
          ref={ref}
          id={name}
          name={name}
          placeholder={typeof placeholder !== 'undefined' ? placeholder : label}
          defaultValue={value ?? defaultValue}
          onChange={onChange}
          rows={rows ? rows : 2}
          className="bg-gray-200 rounded"
          style={style}
        ></textarea>
        {error ? (
          <span className="help-text error">{error}</span>
        ) : (
          <span className="help-text">{helpText}</span>
        )}
      </label>
    )
  }
)

import React from 'react'

import Navigation from 'components/Navigation'

const LayoutAdmin = ({
  children,
  location,
  title,
  hasBack = false,
  isHome = false,
  className = '',
}) => {
  return (
    <>
      <Navigation
        location={location}
        title={title}
        hasBack={hasBack}
        isHome={isHome}
      />
      <div className={`layout m-auto mt-8 ${className}`}>{children}</div>
    </>
  )
}

export default LayoutAdmin

import React, { useMemo, useState, useCallback } from 'react'
import Table from 'components/common/table'
import useActivity from 'hooks/useActivity'
import { useHistory } from 'react-router-dom'
import { IDefaultColumnFilter } from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'

import {
  Actions,
  EditAction,
  RemoveAction,
} from 'components/common/table/actions'

import { useNotify } from 'stores/notify'

const initialPageSize = 10
const initialSortBy = [
  {
    id: 'name',
    desc: false,
  },
]

const MyModelsList = () => {
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const [forceRefresh] = useState(1)

  const {
    getDomains,
    removeDomain,
  } = useActivity()

  const onError = useCallback(
    (err) => {
      if (err && err.statusCode && err.statusCode === 401) {
        setErrorMessage(err.message)
        history.replace('/')
      } else {
        setErrorMessage('Une erreur est survenue')
      }
    },
    [history, setErrorMessage]
  )

  const fetchData = useApiFetch(getDomains, onError)

  const [removed, setRemoved] = useState(null)

  const onRemove = useCallback(
    async (id) => {
      const req = await removeDomain(id)
      if (req.error) {
        setErrorMessage(req.error.message)
        return
      }

      if (req.results && req.results.success) {
        setSuccessMessage(`Elément supprimé avec succès`)
        setRemoved(id)
      } else {
        setErrorMessage(`Impossible de supprimer: ${req.error.message}`)
      }
    },
    [removeDomain, setErrorMessage, setSuccessMessage]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'name', // accessor is the "key" in the data
        Filter: IDefaultColumnFilter,
        className: 'font-bold',
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableFilters: true,
        Cell: (props) => (
          <Actions>
            <EditAction
              entity={props.value}
              message={'Voulez-vous modifier ce domaine ?'}
              to={`/setdomain/${props.value}`}
            />
            <RemoveAction
              onRemove={onRemove}
              {...props}
              title={`Êtes-vous sûr de vouloir supprimer le domaine ${props.row.original.name}&nbsp;?`}
            />
          </Actions>
        ),
      },
    // eslint-disable-next-line
    ], [onRemove] 
  )

  const addDomain = useCallback(() => {
    history.push('/setdomain/new')
  }, [history])

  return (
    <div className="flex justify-center w-full pt-12">
      <Table
        fetchData={fetchData}
        name="Liste des domaines"
        initialPageSize={initialPageSize}
        columns={columns}
        rowRemoved={removed}  
        initialSortBy={initialSortBy}
        addEntity={addDomain}
        forceRefresh={forceRefresh}
      />
    </div>
  )
}

export default MyModelsList

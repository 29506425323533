import { useMemo } from 'react'
import { useUser } from 'stores/user'
import { useNotify } from 'stores/notify'
import Api from 'lib/api'
import { SCHOOLINGYEAR_STATUS } from 'lib/constants'

export default () => {
  const { token, registrationId } = useUser(({ token, registrationId }) => ({
    token,
    registrationId,
  }))
  const { setSuccessMessage } = useNotify(({ setSuccessMessage }) => ({
    setSuccessMessage,
  }))

  return useMemo(
    () => ({
      async getSchoolingdate(offset = 0, limit = 100, filters, order) {
        try {
          return Api.getSchoolingdate(token, {
            query: { offset, limit, filters, order },
          })
        } catch (err) {
          return { error: err }
        }
      },
      async getOneSchoolingDate(id) {
        try {
          const date = await Api.getOneSchoolingDate(token, id, {
            query: { populate: true },
          })
          return date
        } catch (err) {
          return { error: err }
        }
      },
      async setSchoolingDate({ registrationId, ...params }) {
        try {
          const date = await Api.setSchoolingDate({
            token,
            params,
            id: registrationId,
          })
          return date
        } catch (err) {
          return { error: err }
        }
      },
      async getPrice(familyIndex) {
        try {
          const price = await Api.getPrice({
            token,
            id: registrationId,
            familyIndex,
          })
          return price
        } catch (err) {
          return { error: err }
        }
      },
      async draftSchoolingDate(id) {
        try {
          const date = await Api.updateSchoolingDate(token, id, {
            status: SCHOOLINGYEAR_STATUS.draft,
          })
          setSuccessMessage('La date est maintenant en brouillon')
          return date
        } catch (err) {
          return { error: err }
        }
      },
      async updateSchoolingDate(params) {
        const id = params.id
        delete params.id
        try {
          const date = await Api.updateSchoolingDate(token, id, params)
          return date
        } catch (err) {
          return { error: err }
        }
      },
      async deleteSchoolingDate(id) {
        try {
          const date = await Api.deleteSchoolingDate(token, id)
          return date
        } catch (err) {
          return { error: err }
        }
      },
      async createSchoolingDate(params) {
        try {
          const date = await Api.createSchoolingDate(token, params)
          return date
        } catch (err) {
          return { error: err }
        }
      },
      async createPricingsGrid(params) {
        try {
          const pricingGrid = await Api.createPricingsGrid(token, params)
          return pricingGrid
        } catch (err) {
          return { error: err }
        }
      },
      async updatePricingsGridsLists(id, params) {
        try {
          const pricingsGridsLists = await Api.updatePricingsGridsLists({
            token,
            id,
            params,
          })
          return pricingsGridsLists
        } catch (err) {
          return { error: err }
        }
      },
      async getSchoolVacation(year) {
        try {
          const vacation = await Api.getSchoolVacation(year)
          return vacation
        } catch (err) {
          return { error: err }
        }
      },
      async getChildcareHours() {
        try {
          const hours = await Api.getChildcareHours({ token, query: { limit: 100, filters:[['custom', '=', 'null']] } })
          return hours
        } catch (err) {
          return { error: err }
        }
      },
      async removePricingGridChildcareHours({ datas }) {
        try {
          const hours = await Api.removePricingGridChildcareHours({ datas, token })
          return hours
        } catch (err) {
          return { error: err }
        }
      },
      async createPricingGridChildcareHours({ add }) {
        try {
          const hours = await Api.createPricingGridChildcareHours({ add, token })
          return hours
        } catch (err) {
          return { error: err }
        }
      },
      async getChildrenBySchoolClass({ year }) {
        // add schoolClass in props 
        try {
          const children = await Api.getChildrenBySchoolClass({ token, year })
          return children
        } catch (err) {
          return { error: err }
        }
      },
      async setChildClassPresence({ date, morning, afternoon, schoolClass }) {
        try {
          const presence = await Api.setChildClassPresence({ token, date, morning, afternoon, schoolClass })
          return presence
        } catch (err) {
          return { error: err }
        }
      },
      async getChildrenPresence({ date}) {
        try {
          const presences = await Api.getChildrenPresence({ token, date })
          return presences
        } catch (err) {
          return { error: err }
        }
      },
      async classPresenceYearExport({ year }) {
        try{
          const presences = await Api.classPresenceYearExport({ token, year })
          return presences
        }catch(err){
          return { error: err}
        }
      },
      async classPresenceMonthExport({ year, filter}){
        try{
          const presences = await Api.classPresenceMonthExport({ token, query: { ...filter, year}})
          return presences
        }catch(err){
          return { error: err}
        }
      },
      async classPresenceMonthDetailedExport({ year, filter}){
        try{
          const presences = await Api.classPresenceMonthDetailedExport({ token, query: { ...filter, year }})
          return presences
        }catch(err){
          return { error: err}
        }
      }
    }),
    [registrationId, setSuccessMessage, token]
  )
}

import React from 'react'

export default function DashboardCard({
  title,
  children,
  onClick = () => { }
}) {
  return (
    <div
      className="w-full md:w-1/2 xl:w-1/3 md:odd:pr-6 xl:odd:pr-0 xl:third:pl-6 xl:three:pr-6 mb-6"
      onClick={onClick}
    >
      <div className="bg-white border-transparent rounded shadow-lg">
        <div className="bg-gray-300 uppercase text-gray-800 border-b border-gray-500 rounded-tl rounded-tr py-2 px-6 ">
          <h5 className="uppercase text-gray-600 truncate">{title}</h5>
        </div>
        <div className="p-6 py-4 text-blue-900">{children}</div>
      </div>
    </div>
  )
}

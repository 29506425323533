import { useUser } from 'stores/user'
import Api from 'lib/api'

export default () => {
  const { token } = useUser(({ token }) => ({ token }))

  return {
    async getCategories(offset = 0, limit = 100, filters, order) {
      try {
        const categories = await Api.getCategories(token, {
          query: { offset, limit, filters, order },
        })
        return categories
      } catch (err) {
        return { error: err }
      }
    },
    async removeCategory(id) {
      try {
        const category = await Api.removeCategory(token, id)
        return category
      } catch (err) {
        return { error: err }
      }
    },
    async getCategory(id, populate = true) {
      let query = { populate }
      try {
        const category = await Api.getCategory(id, token, query)
        return category
      } catch (err) {
        return { error: err }
      }
    },
    async updateCategory(id, datas) {
      try {
        const category = await Api.updateCategory({ id, datas, token })
        return category
      } catch (err) {
        return { error: err }
      }
    },
    async createCategory(datas) {
      try {
        const category = await Api.createCategory({ datas, token })
        return category
      } catch (err) {
        return { error: err }
      }
    },
    async getActivities(
      offset = 0,
      limit = 100,
      filters,
      order,
      populate = false
    ) {
      try {
        const activites = await Api.getActivities(token, {
          query: { offset, limit, filters, order, populate },
        })
        return activites
      } catch (err) {
        return { error: err }
      }
    },
    async removeActivity(id) {
      try {
        const activity = await Api.removeActivity(token, id)
        return activity
      } catch (err) {
        return { error: err }
      }
    },
    async getActivity(id, populate = true) {
      const query = { populate }
      try {
        const activity = await Api.getActivity(id, token, query)
        return activity
      } catch (err) {
        return { error: err }
      }
    },
    async updateActivity(id, datas) {
      try {
        const activity = await Api.updateActivity({ id, datas, token })
        return activity
      } catch (err) {
        return { error: err }
      }
    },
    async createActivity(datas) {
      try {
        const activity = await Api.createActivity({ datas, token })
        return activity
      } catch (err) {
        return { error: err }
      }
    },
    async getCategoryActivities({
      categoryId,
      childId,
      schoolClassId,
      searchFilter,
      search,
      schoolingYearId,
    }) {
      let query = {
        schoolClassId,
        childId,
        searchFilter,
        search,
        schoolingYearId,
      }

      if (categoryId) {
        query.categoryId = categoryId
      }

      try {
        const activities = await Api.getCategoryActivities(token, query)
        return activities
      } catch (err) {
        return { error: err }
      }
    },
    async getCategoriesHavingActivities({ id, schoolingyear }) {
      let query = {
        // schoolingyear
      }
      try {
        const categories = await Api.getCategoriesHavingActivities({
          id,
          query,
          token,
        })
        return categories
      } catch (err) {
        return { error: err }
      }
    },
    async getActivityLevels() {
      try {
        const levels = await Api.getActivityLevels(token)
        return levels
      } catch (err) {
        return { error: err }
      }
    },
    async validateChildSkill(datas) {
      try {
        const skill = await Api.validateChildSkill({ token, datas })
        return skill
      } catch (err) {
        return { error: err }
      }
    },
    async getActivitiesCategoriesPDF(childId, SchoolClassId, schoolingyearid) {
      try {
        const activities = await Api.getActivitiesCategoriesPDF({
          token,
          query: { childId, SchoolClassId, schoolingyearid },
        })
        return activities
      } catch (err) {
        return { error: err }
      }
    },
    async getActivitiesViews(offset = 0, limit = 100, filters, order) {
      try {
        const activities = await Api.getActivitiesViews({
          token,
          query: { offset, limit, filters, order },
        })
        return activities
      } catch (err) {
        return { error: err }
      }
    },
    async getProgressions(
      offset = 0,
      limit = 100,
      filters,
      order,
      populate = false
    ) {
      try {
        const progressions = await Api.getProgressions({
          token,
          query: { offset, limit, filters, order, populate },
        })
        return progressions
      } catch (err) {
        return { error: err }
      }
    },
    async createProgression(datas) {
      try {
        const progression = await Api.createProgression({ token, datas })
        return progression
      } catch (err) {
        return { error: err }
      }
    },
    async removeProgression(id) {
      try {
        const progression = await Api.removeProgression({ token, id })
        return progression
      } catch (err) {
        return { error: err }
      }
    },
    async getProgression(id, populate = false) {
      try {
        const progression = await Api.getProgression({
          token,
          id,
          query: { populate },
        })
        return progression
      } catch (err) {
        return { error: err }
      }
    },
    async updateProgression(id, datas) {
      try {
        const progression = await Api.updateProgression({ token, id, datas })
        return progression
      } catch (err) {
        return { error: err }
      }
    },
    async getActivitiesRecommanded({ childId, search, schoolingYearId }) {
      let query = {
        childId,
        schoolingYearId,
      }

      if (search) {
        query.search = search
      }

      try {
        const activities = await Api.getActivitiesRecommanded({ query, token })
        return activities
      } catch (err) {
        return { error: err }
      }
    },
    async getActivityImage(fileId) {
      try {
        const image = await Api.getActivityImage({ token, fileId })
        return image
      } catch (err) {
        return { errorr: err }
      }
    },
    async deleteActivityImage(id) {
      try {
        const image = await Api.deleteActivityImage({ token, id })
        return image
      } catch (err) {
        return { error: err }
      }
    },
    async deleteChildrenActivityImage(id) {
      try {
        const image = await Api.deleteChildrenActivityImage({ token, id })
        return image
      } catch (err) {
        return { error: err }
      }
    },
    async downloadActivityImage(id) {
      try {
        const image = await Api.downloadActivityImage({ token, id })
        return image
      } catch (err) {
        return { error: err }
      }
    },
    async searchNationalLabel(text) {
      try {
        const nationalLabel = await Api.searchNationalLabel({
          token,
          query: { text },
        })
        return nationalLabel
      } catch (err) {
        return { error: err }
      }
    },
    async getCategoriesCurrent() {
      try {
        const categories = await Api.getCategoriesCurrent({ token })
        return categories
      } catch (err) {
        return { error: err }
      }
    },
    async getAllRecommandedActivities({ schoolingYear, schoolClass }) {
      try {
        const categories = await Api.getAllRecommandedActivities({
          token,
          schoolingYear,
          schoolClass,
        })
        return categories
      } catch (err) {
        return { error: err }
      }
    },
    async getChildrenHavingActivityUnDone({ schoolingYear, activityId }) {
      try {
        const query = {
          schoolingYearId: schoolingYear,
          activityId: activityId,
        }
        const children = await Api.getChildrenHavingActivityUnDone({
          token,
          query,
        })
        return children
      } catch (err) {
        return { error: err }
      }
    },

    async getSubDomains(
      offset = 0,
      limit = 100,
      filters,
      order,
      populate = false
    ) {
      try {
        const subDomains = await Api.getSubDomains({
          token,
          query: { offset, limit, filters, order, populate },
        })
        return subDomains
      } catch (err) {
        return { error: err }
      }
    },
    async getSubDomain(id, populate = false) {
      try {
        const subDomain = await Api.getSubDomain({
          token,
          id,
          query: { populate },
        })
        return subDomain
      } catch (err) {
        return { error: err }
      }
    },
    async createSubDomain(datas) {
      try {
        const subDomain = await Api.createSubDomain({ token, datas })
        return subDomain
      } catch (err) {
        return { error: err }
      }
    },
    async removeSubDomain(id) {
      try {
        const subDomain = await Api.removeSubDomain({ token, id })
        return subDomain
      } catch (err) {
        return { error: err }
      }
    },
    async updateSubDomain(id, datas) {
      try {
        const subDomain = await Api.updateSubDomain({ token, id, datas })
        return subDomain
      } catch (err) {
        return { error: err }
      }
    },

    async getDomains(
      offset = 0,
      limit = 100,
      filters,
      order,
      populate = false
    ) {
      try {
        const domains = await Api.getDomains({
          token,
          query: { offset, limit, filters, order, populate },
        })
        return domains
      } catch (err) {
        return { error: err }
      }
    },
    async getDomain(id, populate = false) {
      try {
        const domain = await Api.getDomain({ token, id, query: { populate } })
        return domain
      } catch (err) {
        return { error: err }
      }
    },
    async createDomain(datas) {
      try {
        const domain = await Api.createDomain({ token, datas })
        return domain
      } catch (err) {
        return { error: err }
      }
    },
    async removeDomain(id) {
      try {
        const domain = await Api.removeDomain({ token, id })
        return domain
      } catch (err) {
        return { error: err }
      }
    },
    async updateDomain(id, datas) {
      try {
        const domain = await Api.updateDomain({ token, id, datas })
        return domain
      } catch (err) {
        return { error: err }
      }
    },
    async saveObservation(params) {
      try {
        const observation = await Api.saveObservation(token, params)
        return observation
      } catch (err) {
        return { error: err }
      }
    },
    async updateObservation(params) {
      try {
        let id = params.ObservationId
        delete params.ObservationId
        const observation = await Api.updateObservation(token, params, id)
        return observation
      } catch (err) {
        return { error: err }
      }
    },
  }
}

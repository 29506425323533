import React, { useState, useEffect } from 'react'
import SetCompany from 'components/SetCompany'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import LayoutAdmin from 'components/LayoutAdmin'
import useRegistration from 'hooks/useRegistration'
import { isFormChanged } from 'lib/utils'

export default function SetCompanyPage() {
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()
  const { getCompany, updateCompany, createCompany } = useRegistration()
  const [company, setCompany] = useState()
  const [loading, setLoading] = useState({ prevState: null, current: false })
  const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
      setWarningMessage,
    })
  )

  useEffect(() => {
    async function loadDatas(newElt = null) {
      try {
        if (!newElt) {
          const company = await getCompany(id)
          if (company.error) {
            setErrorMessage(company.error.message)
            return
          }
          setCompany(company.results)
        }
      } catch (err) {
        setErrorMessage('Service indisponible')
      }
    }
    if (!isNaN(Number(id))) {
      loadDatas()
    } else if (id === 'new') {
      loadDatas('new')
    } else {
      setErrorMessage('Impossible de trouver cette société')
    }
    // eslint-disable-next-line
  }, [])

  const goBack = () => {
    history.goBack()
  }
  const onSubmit = async (datas) => {
    setLoading({ prevState: loading.current, current: true })
    if (!isNaN(Number(id))) {
      if (isFormChanged({
        address: datas.address,
        financialPartner: datas.financialPartner === 1 ? true : false,
        name: datas.name
      }, {
        address: company.address,
        financialPartner: company.financialPartner,
        name: company.name
      })) {
        let newCompany = await updateCompany(id, datas)
        if (newCompany.error) {
          setErrorMessage(newCompany.error.message)
          setLoading({ prevState: loading.current, current: false })
          return
        }
        setCompany(newCompany.results)
        setSuccessMessage('Société mis à jour')
      } else {
        setWarningMessage("Rien n'a été modifié")
      }
    } else {
      let company = await createCompany(datas)
      if (company.error) {
        setErrorMessage(company.error.message)
        setLoading({ prevState: loading.current, current: false })
        return
      }
      setSuccessMessage('Société créée')
    }
    setLoading({ prevState: loading.current, current: false })
  }

  return (
    <LayoutAdmin location={location} title="Société" hasBack>
      <SetCompany
        onSubmit={onSubmit}
        goBack={goBack}
        company={company}
        id={id}
        loading={loading}
      />
    </LayoutAdmin>
  )
}

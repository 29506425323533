import React, { useEffect, useState, useCallback } from 'react'
import ChooseRegistration from 'components/ChooseRegistration'
import useRegistration from '../hooks/useRegistration'
import { useHistory } from 'react-router-dom'
import { useUser, userApi } from '../stores/user'
import { useNotify } from '../stores/notify'
import { REGISTRATION_STATUS } from 'lib/constants'
import { useTranslation } from 'react-i18next'
import Layout from 'components/Layout'

export default function ChooseRegistrationPage(props) {
  const history = useHistory()
  const { getAllRegistrations, reregister } = useRegistration()
  const [registrations, setRegistrations] = useState()
  const { setUser, token } = useUser(({ setUser, token }) => ({
    setUser,
    token,
  }))
  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))
  const user = userApi()
  const { t } = useTranslation()
  const getRegistration = useCallback(async () => {
    const registrations = await getAllRegistrations()
    if (registrations.error) {
      setErrorMessage(t(registrations.error.message))
      return
    }
    if (registrations.results) {
      setRegistrations(registrations.results.registration)
    }
  // eslint-disable-next-line
  }, [setErrorMessage])

  const onSelectRegistration = useCallback(
    ({ id, status }) => {
      setUser({ ...user, registrationId: id, status })
      if (status !== REGISTRATION_STATUS.open) {
        history.push('/summary')
      } else {
        history.push(`/schoolingyear/${id}`)
      }
    },
    [history, setUser, user]
  )

  const onReregister = useCallback(
    async (id) => {
      const registration = await reregister(id)
      if (registration.error) {
        setErrorMessage(t(registration.error.message))
        return
      }
      // delete user.status
      // delete user.registrationId
      await setUser({
        ...user,
        registrationId: registration.results.registration.id,
        status: registration.results.registration.status,
      })
      history.push(`/schoolingyear/${registration.results.registration.id}`)
    },
    [history, reregister, setErrorMessage, setUser, user, t]
  )

  useEffect(() => {
    getRegistration()
  }, [getRegistration, token])

  return (
    <Layout
    buttonGoHome={{
      to: props.role === 'admin' ? 'admin' : 'dashboard',
      history,
    }}
    title={t("Liste des inscriptions")}>

    <ChooseRegistration
      registrations={registrations}
      onSelectRegistration={onSelectRegistration}
      onReregister={onReregister}
      />
      </Layout>
  )
}

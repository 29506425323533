import { useMemo } from 'react'
import { useUser } from 'stores/user'
import Api from 'lib/api'

function findFiles(obj, prefix = '', files = []) {
  if (Array.isArray(obj)) {
    let i = 0
    for (const o of obj) {
      findFiles(o, `${prefix}.${i}`, files)
      i++
    }
  } else {
    // Object.keys(obj).forEach((key) => {
    if (
      typeof obj === 'object' &&
      obj.hasOwnProperty('typee') &&
      obj.typee === 'file'
    ) {
      files.push({ name: prefix, file: obj })
    } else {
      Object.keys(obj).forEach((key) => {
        if (obj[key] && typeof obj[key] === 'object') {
          const newPrefix = `${prefix ? prefix + '.' : ''}${key}`
          findFiles(obj[key], newPrefix, files)
        }
      })
    }
    // })
  }
  return files
}

async function createFormData(payload) {
  // Check if files exist in payload
  const files = findFiles(payload)
  let formData = payload
  // If there are files,
  // - construct formdata,
  // - remove all files id if it isn't an integer,
  // - json parse all fields (because content-type is multipart/formdata, and must contains only string and files),
  // - add files to formdata.
  if (files.length) {
    formData = new FormData()
    files.forEach(({ file }) => {
      // Remove non integer id, that means it's new file
      // (id generated by react-files)
      if (!Number.isInteger(file.id)) {
        delete file.id
      }
    })

    Object.keys(payload).forEach((key) => {
      if (payload[key] === undefined) {
        formData.append(key, null)
      } else {
        formData.append(key, JSON.stringify(payload[key]))
      }
    })
    files.forEach(({ name, file }) => {
      formData.append(
        name,
        new Blob([file], { type: file.type }),
        file.name || 'file'
      )
    })
  }
  // const { id } = payload
  let headers = { Accept: 'application/json' }
  // If there are files, add content-type header to multipart.
  if (files.length) {
    headers['Content-Type'] = 'multipart/form-data'
  } else {
    headers['Content-Type'] = 'application/json'
  }

  // return Api.sendFiles(token, id, {
  //   body: formData,
  //   headers,
  // })
  return { formData, headers }
  // return Api.sendFiles({ token, id, registrationId, formData, headers })
}

export default () => {
  const { token, registrationId, id } = useUser(
    ({ token, registrationId, id }) => ({
      token,
      registrationId,
      id,
    })
  )

  return useMemo(
    () => ({
      // downloadFile(path) {
      //   return Api.downloadFile(token, {
      //     query: {
      //       filters: [['path', path]],
      //     },
      //   })
      // },

      async downloadFile(fileId) {
        try {
          const file = await Api.downloadFile({
            token,
            id: registrationId,
            fileId,
          })
          return file
        } catch (err) {
          return { error: err }
        }
      },
      async setChildDetails(params) {
        try {
          const test = await createFormData(params)
          const { formData, headers } = test
          const child = await Api.setChildDetails({
            token,
            id,
            registrationId,
            formData,
            headers,
          })
          return child
        } catch (err) {
          return { error: err }
        }
      },
      async sendFiles(datas) {
        try {
          const test = await createFormData(datas)
          const { formData, headers } = test
          const files = await Api.sendFiles({
            token,
            id,
            registrationId,
            formData,
            headers,
          })
          return files
        } catch (err) {
          return { error: err }
        }
      },
      async getFiles(kind, registrationIdOverride) {
        try {
          const files = await Api.getFiles({
            token,
            id: registrationIdOverride || registrationId,
            kind,
          })
          return files
        } catch (err) {
          return { error: err }
        }
      },
      async removeFile(fileId) {
        try {
          const del = await Api.deleteFile({
            token,
            id: registrationId,
            fileId,
          })
          return del
        } catch (err) {
          return { error: err }
        }
      },
      async validateChildSkill(params) {
        try {
          const test = await createFormData(params)
          const { formData, headers } = test

          const skill = await Api.validateChildSkill({
            token,
            formData,
            headers,
          })

          return skill
        } catch (err) {
          return { error: err }
        }
      },
      async importActivities(params, id) {
        try {
          const test = await createFormData(params)
          const { formData, headers } = test

          const activities = await Api.importActivities({
            token,
            formData,
            headers,
            id,
          })

          return activities
        } catch (err) {
          return { error: err }
        }
      },
      async updateActivityClasses(datas) {
        try {
          const data = await createFormData(datas)
          const { formData, headers } = data

          const activity = await Api.updateActivityClasses({
            formData,
            headers,
            token,
          })
          return activity
        } catch (err) {
          return { error: err }
        }
      },
    }),
    [id, registrationId, token]
  )
}

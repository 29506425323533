import React from 'react'
import { useLocation } from 'react-router-dom'
import PriceList from 'components/registrations/PriceList'
import LayoutAdmin from '../components/LayoutAdmin'

export default function PriceListPage() {
  const location = useLocation()

  return (
    <div>
      <LayoutAdmin location={location} title="Tarifications" hasBack={false} >
        <PriceList />
      </LayoutAdmin>
    </div>
  )
}

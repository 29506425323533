import React, { useState, useEffect } from 'react'
import AcquiredSkills from 'components/AcquiredSkills'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import LayoutAdmin from 'components/LayoutAdmin'
import useSchoolingYear from 'hooks/useSchoolingYear'
import useRegistration from 'hooks/useRegistration'
import { ALLOWED_CLASSES, OLD_CLASSES, YOUNG_CLASSES } from 'lib/constants'

export default function AcquiredSkillsPage(props) {
  const location = useLocation()
  const history = useHistory()
  const { getSchoolingdate } = useSchoolingYear()
  const { getChildrenbySchoolingyear, getSchoolClasses } = useRegistration()
  const { id } = useParams()

  const [originalChildrenList, setOriginalChildrenlist] = useState([])
  const [loading, setLoading] = useState(null)
  const [childrenList, setChildrenList] = useState([])
  const [schoolingyears, setSchoolingyears] = useState([])
  const [defaultYear, setDefaultYear] = useState(0)
  const [schoolClasses, setSchoolClasses] = useState([])
  const [allSchoolClasses, setAllSchoolClasses] = useState([])

  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))

  const loadChildrenList = async (year, abortController) => {
    setLoading(true)
    try {
      const children = await getChildrenbySchoolingyear(year, abortController)
      const schoolClass = {}

      children.results.children.forEach((child) => {
        let childClass = child.Registrations[0].SchoolClass.name
        if (schoolClass.hasOwnProperty(childClass)) {
          schoolClass[childClass].push({
            label: `${child.firstname} ${child.lastname}`,
            value: child.id,
          })
        } else {
          schoolClass[childClass] = [
            {
              label: `${child.firstname} ${child.lastname}`,
              value: child.id,
            },
          ]
        }
      })

      // set photo
      children.results.files.forEach((file) => {
        Object.keys(schoolClass).forEach((a) => {
          let child = schoolClass[a].find(
            (child) => child.value === file.ChildId
          )
          if (child) {
            child.file = file
          }
        })
      })

      // Sort classes
      const classOrder = ALLOWED_CLASSES
      const orderedClasses = {}
      classOrder.forEach((c) => {
        if (schoolClass[c]) {
          orderedClasses[c] = schoolClass[c]
        }
      })

      setChildrenList(orderedClasses)
      setOriginalChildrenlist(children.results)
      history.replace(`/acquiredskills/${year}`)
      setLoading(false)
    } catch (err) {
      if (!abortController.signal.aborted) {
        setErrorMessage(
          'Impossible de charger les enfants de cette année scolaire'
        )
        setLoading(false)
      }
    }
  }

  const onChildView = (childId, schoolClass) => {
    if (childId === 'all' && schoolClass) {
      let classToUse = originalChildrenList.children.find(
        (child) => child?.Registrations[0].SchoolClass.name === schoolClass
      )
      // Total of children, all classes
      let allChildren = originalChildrenList.children
        // .filter(child => child?.Registrations[0]?.SchoolClass.name === schoolClass)
        .map((child) => child.id)

      history.push('/childvalidation', {
        child: {
          entities: 'all',
          id: classToUse.id,
          SchoolClassId: classToUse?.Registrations[0]?.SchoolClassId,
          Registrations: classToUse?.Registrations,
          schoolClass,
          all: allChildren,
        },
        SchoolingYearId: id,
      })
      return
    } else {
      const child = originalChildrenList.children.find(
        (child) => child.id === childId
      )
      history.push('/childvalidation', {
        child,
        SchoolingYearId: id,
        schoolClass,
      })
      return
    }
  }

  // load schoolingyear
  useEffect(() => {
    async function loadSchoolingYear() {
      const schoolingyears = await getSchoolingdate(0, 100, [
        ['status', 'publish'],
      ])
      const formattedSchoolingyears = schoolingyears.results.map(
        (schoolingyear) => {
          return {
            label: `${schoolingyear.year}-${Number(schoolingyear.year) + 1}`,
            value: schoolingyear.id,
          }
        }
      )
      setSchoolingyears(formattedSchoolingyears)
    }
    loadSchoolingYear()
  }, [getSchoolingdate])

  useEffect(() => {
    if (schoolingyears && schoolingyears.length > 0) {
      const date = new Date()
      let currentYear
      if (date.getMonth() + 1 >= 8) {
        currentYear = date.getFullYear()
      } else {
        currentYear = date.getFullYear() + 1
      }

      let year
      schoolingyears.forEach((s) => {
        let y = s.label.split('-')
        if (Number(y[0]) === currentYear) {
          year = s.value
        }
      })
      setDefaultYear(year)
    }
  }, [schoolingyears])

  useEffect(() => {
    // having defaultYear === 0 means component is still loading
    const abortController = new AbortController()
    loadChildrenList(id, abortController)
    // if (id && defaultYear !== 0) {
    //   loadChildrenList(id, abortController)
    // } else if (defaultYear) {
    //   loadChildrenList(defaultYear, abortController)
    // }

    // return () => {
    //   abortController.abort()
    // }

    //eslint-disable-next-line
  }, [defaultYear])

  // load schoolClasses
  useEffect(() => {
    async function loadClasses() {
      const schoolClasses = await getSchoolClasses()
      let temp = schoolClasses.results
        .filter((t) => t.name === '3-6' || t.name === '6-9')
        .map((t) => ({ label: t.name, value: `class-${t.id}` }))

      setAllSchoolClasses(
        schoolClasses.results.map((t) => ({
          label: t.name,
          value: `class-${t.id}`,
        }))
      )

      setSchoolClasses(temp)
    }

    loadClasses()
  }, [getSchoolClasses])

  const onViewRecommandedActivities = (schoolClass) => {
    history.push(`/recommandedactivities/${id || defaultYear}/${schoolClass}`)
  }

  const whichClassIs = (schoolClassId) => {
    let young = YOUNG_CLASSES
    let old = OLD_CLASSES

    const test = allSchoolClasses.find(
      (a) => a.value === `class-${schoolClassId}`
    )
    if (young.includes(test?.label)) {
      return allSchoolClasses.find((a) => a.label === '3-6').value
    }
    if (old.includes(test?.label)) {
      return allSchoolClasses.find((a) => a.label === '6-9').value
    }
  }

  return (
    <LayoutAdmin location={location} title="Suivi des enfants" hasBack>
      <AcquiredSkills
        loading={loading}
        schoolingyears={schoolingyears}
        onSchoolingyearChange={loadChildrenList}
        childrenList={childrenList}
        onChildClick={onChildView}
        defaultSchoolingyear={id || defaultYear}
        onViewRecommandedActivities={onViewRecommandedActivities}
        schoolClasses={schoolClasses}
        defaultSchoolClass={
          whichClassIs(props.SchoolClassId) ||
          allSchoolClasses.find((a) => a.label === '3-6')?.value
        }
      />
    </LayoutAdmin>
  )
}

import React from 'react'
import ReactDOM from 'react-dom'
import Modal from './Modal'
import Radio from 'components/form/Radio'
import { Controller } from 'react-hook-form'
import File from 'components/form/File'

const confirmRoot = document.createElement('div')

export default ({
  activity,
  levels,
  form,
  file,
  deleteImage,
  cancel,
  canEdit,
  history,
  children,
  childId,

}) => {
  let img = []

  if (file) {
    img = [{ path: file.path, type: file.type, name: file.file.name, id: file.file.id }]
    form.setValue("activityvalidation", img)
  }

  return new Promise((resolve) => {
    const confirm = (answer) => {
      ReactDOM.unmountComponentAtNode(confirmRoot)
      resolve(answer)
    }

    let canPresent = [
      { label: "Oui", value: true },
      { label: "Non", value: false },
    ]

    const onDeleteImage = async (id) => {
      await deleteImage(activity.ChildrenActivities[0].ActivityId)
      confirm(false)
    }

    // Update form value, and toggle bg/text-color
    const selectChildren = (e, childId, childrenSelected) => {
      let currentChildrenSelected = childrenSelected || []
      let childrenList = []
      if (currentChildrenSelected.length > 0) {
        childrenList = currentChildrenSelected.split(',')
      }
      if (childrenList.find(id => id === childId.toString())) {
        childrenList = childrenList.filter(id => id !== childId)
        e.currentTarget.classList.remove('bg-blue-500')
        e.currentTarget.classList.remove('text-white')
        e.currentTarget.classList.add('bg-gray-300')
        e.currentTarget.classList.add('text-gray-600')
      } else {
        childrenList.push(childId.toString())
        e.currentTarget.classList.remove('bg-gray-300')
        e.currentTarget.classList.remove('text-gray-600')
        e.currentTarget.classList.add('bg-blue-500')
        e.currentTarget.classList.add('text-white')
      }
      form.setValue('childrenId', childrenList.join(','))
    }


    ReactDOM.render(
      <Modal
        title={activity?.name}
        responsive
        large
        isOpen={true}
        onClose={() => confirm(false)}
        allowScroll={true}
      >
        {canEdit && (
          <div
            className="flex w-full justify-center"
          >
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => {
                confirm(false)
                history.push(`/setactivity/${activity.id}`)
              }}
            >
              Modifier
            </span>
          </div>
        )}
        <div className="flex w-full justify-center">
          <input
            className="hidden"
            name="level"
            type="number"
            ref={form.register()}
          />
          <input
            className="hidden"
            name="childrenId"
            type='string'
            ref={form.register()}
          />
          <div className="flex justify-center mt-8 flex-col gap-2">

            {children && children.length > 0 && (
              <div className="mb-4">
                <p className="p-1">
                  Enfants n'ayant pas réalisé l'activité
                </p>
                <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 ">
                  {children.map(child => (
                    <div
                      className="bg-gray-300 text-gray-600 p-2 cursor-pointer"
                      key={child.id}
                      onClick={(e) => {
                        if (canEdit) {
                          selectChildren(
                            e,
                            child.id.toString(),
                            form.watch('childrenId')
                          )
                        }
                      }}
                    >
                      {child.firstname} {child.lastname} ({child.schoolClass})
                    </div>
                  ))}

                </div>
                <div className="p-1 text-sm text-gray-600">
                  Si aucun enfant n'est sélectionné, cela validera l'activité pour l'ensemble des enfants de la liste
                </div>
              </div>
            )}
            <div className="flex flex-col justify-center gap-2">
              {levels && levels.map(level => (
                <div
                  key={`level-${level.name}`}
                  className={`
                ${activity?.ChildrenActivities && activity?.ChildrenActivities[0]?.ActivityLevelId === level.id ?
                      'bg-blue-500 text-white' :
                      'bg-gray-300 text-gray-600'}
                   py-2 px-4 rounded-sm text-white cursor-pointer flex gap-1 items-center text-nowrap`
                  }
                  onClick={e => {
                    form.setValue(`level`, level.id)
                    e.currentTarget.parentNode.childNodes.forEach(element => {
                      element.classList.remove('bg-blue-500')
                      element.classList.remove('text-white')
                      element.classList.remove('bg-gray-300')
                      element.classList.remove('text-gray-600')

                      if (element === e.currentTarget) {
                        e.currentTarget.classList.add('bg-blue-500')
                        e.currentTarget.classList.add('text-white')
                      } else {
                        element.classList.add('bg-gray-300')
                        element.classList.add('text-gray-600')
                      }
                    })
                  }}
                >
                  <div
                    style={{ background: `#${level.color}`, height: '25px', width: "25px" }}
                    className="border border-black mr-2 flex-0 "
                  >
                  </div>
                  <div className="flex-1">
                    {level.name}
                  </div>
                </div>
              ))}
            </div>
            <Radio
              input={{ label: "Peut-il présenter l'activité à d'autres enfants ?" }}
              inputs={canPresent}
              form={form}
              defaultValue={activity?.ChildrenActivities && activity?.ChildrenActivities[0]?.canPresent ? true : false}
              name="canPresent"
              ref={form.register()}
              label="Peut-il présenter l'activité à d'autres enfants ?"
            />
            {file && file.path ? (
              <img
                alt="Activité"
                src={`data:${file.type};base64,${file.path}`}
                className="object-contain"
                style={{ maxHeight: '360px' }}
              />
            ) : null}
            <Controller
              name="activityvalidation"
              key={JSON.stringify(`files/${file}-${img}`)}
              control={form.control}
              rules={{
              }}
              defaultValue={img}
              render={(props, name) => {
                return (
                  <>
                    <File
                      key={JSON.stringify(`files-activity-${file}-${img}`)}
                      name={name}
                      label={("Photo de l'activité")}
                      className="files-dropzone"
                      form={form}
                      {...props}
                      options={{ limit: 1, customRemove: onDeleteImage }}
                      shouldPreview={true}
                      ext={['image/*']}
                    />
                    {form?.errors && form?.errors['activityvalidation'] && (
                      <div className="text-sm text-red-500">
                        {form?.errors['activityvalidation'].message}
                      </div>
                    )}
                  </>
                )
              }}
            />
            <div
              className="py-2 px-4 rounded-sm bg-green-500 text-white cursor-pointer"
              onClick={() => confirm({
                ActivityLevelId: form.watch('level') || (activity?.ChildrenActivities && activity?.ChildrenActivities[0]?.ActivityLevelId),
                media: form.watch('activityvalidation'),
                canPresent: form.watch('canPresent'),
                childrenId: !canEdit && childId ? childId.toString() : form.watch('childrenId') || []
              })}
            >
              {"Enregistrer"}
            </div>
            <div
              className="py-2 px-4 rounded-sm bg-red-500 text-white cursor-pointer"
              onClick={() => confirm(false)}
            >
              {cancel || "Annuler"}
            </div>
          </div>
        </div>
      </Modal >,
      confirmRoot
    )
  })
}

import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Login from 'components/Login'
import { USER_ROLE } from 'lib/constants'

import { useUser } from 'stores/user'
import { useNotify } from 'stores/notify'
import { useTranslation } from "react-i18next"
import useLanguage from "hooks/useLanguage"

function postLoginRedirect(role, history, method) {
  switch (role) {
    case USER_ROLE.ADMIN:
      history[method]('/admin')
      break

    default:
      history[method]('/dashboard')
  }
}

const LoginPage = (_props) => {
  const { t } = useTranslation()
  const { changeLanguage, getLanguage } = useLanguage()
  const [loading,  setLoading] = useState(false)
  const history = useHistory()
  const { login, error, token, role, setError } = useUser(
    ({ login, error, setError, token, role }) => ({
      login,
      error,
      setError,
      token,
      role,
    })
  )

  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))

  useEffect(() => {
    if (error && error.message) setErrorMessage(t(error.message))

    return () => {
      setError(null)
    }
  }, [error, setError, setErrorMessage, t])

  const submit = useCallback(
    async (params) => {
      setLoading(true)
      params.language = getLanguage()
      const user = await login(params)
      if (user) {
        changeLanguage(user.language || "fr")
        postLoginRedirect(user.role, history, 'push')
      }
      setLoading(false)
    },
    [login, history, changeLanguage, getLanguage]
  )

  useEffect(() => {
    if (token !== null && role) postLoginRedirect(role, history, 'replace')
  }, [token, role, history])

  return token == null ? <Login onSubmit={submit} loading={loading}/> : null
}

export default LoginPage

import React, { useEffect, useState, useCallback } from 'react'
import update from 'immutability-helper'
import { Card } from './Card.js'
import { CardSub } from './CardSub.js'

const style = {
  width: 400,
}

export default function SortingRank(props) {
  const {
    subDomain,
    Domain,
    loading,
    updateSubDomain,
    updateDomain,
    setErrorMessage,
    setSuccessMessage,
  } = props
  const [MergedDomainSubDom, setMergedDomainSubDom] = useState([])
  const [subDmainFromDomain, setSubDmainFromDomain] = useState([])
  const [who, setWho] = useState()

  useEffect(() => {
    let tab = []
    if (subDomain !== null && Domain !== null) {
      Domain.forEach((dom) => {
        let multiple = []
        subDomain.forEach((sub) => {
          if (sub.DomainId === dom.id) {
            multiple.push(sub)
          }
        })
        tab.push({ sub: multiple, dom: dom })
      })

      Domain.forEach((dom) => {
        if (tab.find((x) => x.dom.id === dom.id) === undefined) {
          tab.push({ sub: { name: 'non attribué' }, dom: dom })
        }
      })

      tab = tab.sort((a, b) => a.dom.sortingRank - b.dom.sortingRank)

      setMergedDomainSubDom(tab)
    }
  }, [loading, Domain, subDomain])

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setMergedDomainSubDom((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    )
  }, [])

  const moveSubCard = useCallback((dragSubIndex, hoverSubIndex) => {
    setSubDmainFromDomain((prevSubCards) =>
      update(prevSubCards, {
        $splice: [
          [dragSubIndex, 1],
          [hoverSubIndex, 0, prevSubCards[dragSubIndex]],
        ],
      })
    )
  }, [])

  useEffect(() => {
    if (who) {
      setSubDmainFromDomain(subDomainManagement(who))
    }
    //eslint-disable-next-line
  }, [who])

  function subDomainManagement(id) {
    var tab = []
    subDomain.forEach((sub) => {
      if (sub.DomainId === id) {
        tab.push(sub)
      }
    })

    return tab
  }

  function SaveSubDom() {
    if (subDmainFromDomain.length > 0) {
      subDmainFromDomain.forEach((sub, key) => {
        sub.sortingRank = key + 1
        updateSubDomain(sub.id, sub).then(function (res) {
          res.results !== undefined
            ? setSuccessMessage('Ordre enregistré !')
            : setErrorMessage('Une Erreur est survenu !')
        })
      })
    }
  }
  function SaveDom() {
    if (MergedDomainSubDom.length > 0) {
      var tab = []
      MergedDomainSubDom.forEach(function (item) {
        tab.push(item.dom)
      })
      tab.forEach(function (dom, key) {
        dom.sortingRank = key + 1
        updateDomain(dom.id, dom).then(function (res) {
          res.results !== undefined
            ? setSuccessMessage('Ordre enregistré !')
            : setErrorMessage('Une Erreur est survenu !')
        })
      })
    }
  }

  return (
    <div className="flex justify-around">
      <div className="container -ml-20:" style={style}>
        {MergedDomainSubDom.map((card, i) => (
          <Card
            setWho={setWho}
            key={card.dom?.id}
            index={i}
            id={card.dom?.id}
            text={`${i + 1} : ${card.dom?.name} / ${
              card.sub?.length
            } sous-domaine(s)`}
            moveCard={moveCard}
            isDom={true}
          />
        ))}
        <button
          onClick={() => SaveDom()}
          className="my-2 py-1 px-2 border border-black rounded hover:bg-black hover:text-white"
        >
          enregistrer les domaines
        </button>
      </div>
      <div className="bg-white w-2/6 border border-black border-dashed -ml-20">
        {who !== undefined && (
          <div className="text-center mt-2">
            <h2 className="text-2xl text-center">
              {Domain.find((a) => a.id === who)?.name}
            </h2>
            <button
              onClick={() => SaveSubDom()}
              className="my-2 py-1 px-2 border border-black rounded hover:bg-black hover:text-white"
            >
              enregistrer Sous-domaines
            </button>
          </div>
        )}

        {who !== undefined ? (
          subDmainFromDomain.map((card, i) => (
            <CardSub
              key={card?.id}
              index={i}
              id={card?.id}
              text={`${i + 1} : ${card?.name} / ${
                card?.sortingRank
              } position(s)`}
              moveCard={moveSubCard}
              isDom={false}
            />
          ))
        ) : (
          <div style={style} className="mt-10">
            <h2 className="text-2xl text-center">Selectionner un domaine.</h2>
          </div>
        )}
      </div>
    </div>
  )
}

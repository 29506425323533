import * as React from 'react'

function SvgCheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M90.789 19.031l-9.82-9.82-30.97 30.969L19.032 9.21l-9.82 9.821L40.178 50 9.21 80.969l9.82 9.82L50 59.82l30.969 30.97 9.82-9.821L59.82 50z" />
    </svg>
  )
}

export default SvgCheck
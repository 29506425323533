import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Input from 'components/form/Input'
import StepButtons from './common/StepButtons'
import Select from './form/Select'
import { ChromePicker } from 'react-color'

export default function SetDomain({
  onSubmit,
  goBack,
  domain,
  id,
  loading,
  subDomains,
  defaultSubDomains,
  subDomainWarnings,
}) {
  const form = useForm()
  const {
    register,
    handleSubmit,
    errors,
    reset,
    watch,
    control,
    setValue,
  } = form

  const watchSubDomains = watch('subDomains')
  const [warnings, setWarnings] = useState([])
  const [color, setColor] = useState('#FFFFFF')

  useEffect(() => {
    if (
      loading.prevState === false &&
      loading.current === false &&
      isNaN(Number(id))
    ) {
      reset()
    }
  }, [loading, reset, id])

  useEffect(() => {
    if (defaultSubDomains) {
      setValue('subDomains', defaultSubDomains)
    }
  }, [defaultSubDomains, domain, setValue])

  // WIll display warning message if subDomain is already associate with another domain
  useEffect(() => {
    if (
      watchSubDomains &&
      watchSubDomains.length > 0 &&
      subDomainWarnings &&
      subDomainWarnings.length > 0
    ) {
      let warnings = []
      subDomainWarnings.forEach((subDomain) => {
        if (watchSubDomains.find((s) => s.value === subDomain.id)) {
          warnings.push(subDomain)
        }
      })
      setWarnings(warnings)
    }
  }, [watchSubDomains, subDomainWarnings, setWarnings])

  useEffect(() => {
    if (domain && domain.color) {
      setColor(domain.color)
    }
  }, [domain])

  return (
    <>
      <div className="flex items-center justify-center mt-16">
        <form
          className="form-container w-full sm:w-full xl:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="font-black mb-4 text-center">
            {`${!isNaN(id) ? 'Modifier ' : 'Ajouter'} un domaine`}
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'name'}
              ref={register({ required: 'Champ obligatoire' })}
              type="text"
              name="name"
              label="Nom du domaine"
              placeholder="Nom du domaine"
              error={errors.name && errors.name.message}
              defaultValue={domain && domain.name}
              value={watch('name')}
            />
          </div>
          {warnings &&
            warnings.map((warning) => (
              <div className="flex w-full flex-col">
                <div
                  key={warning.id}
                  className=" bg-orange-100 text-orange-700 p-2"
                >
                  {`Le sous-domaine ${warning.name} est déjà associé au domaine ${warning.Domain.name}`}
                </div>
              </div>
            ))}
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Controller
              name="subDomains"
              placeholder="Sous-domaines"
              control={control}
              render={({ value, name, onChange, defaultValue }) => (
                <div className="flex flex-col w-full">
                  <Select
                    name={name}
                    label="Sous-domaines"
                    className="w-full"
                    value={value}
                    onChange={(e) => {
                      if (e) {
                        onChange(e)
                      }
                    }}
                    isMulti
                    values={subDomains}
                  />
                  {errors?.subDomains && (
                    <div className="text-sm text-red-500">
                      {errors?.subDomains.message}
                    </div>
                  )}
                </div>
              )}
            />
          </div>
        
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'sortingRank'}
              ref={register({ required: 'Champ obligatoire' })}
              type="number"
              name="sortingRank"
              label="Ordre d'affichage"
              placeholder="Ordre d'affichage"
              error={errors.sortingRank && errors.sortingRank.message}
              defaultValue={domain && domain.sortingRank}
              value={watch('sortingRank')}
            />
          </div>

          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Controller
              name="color"
              value={color}
              control={control}
              defaultValue={color}
              rules={{ required: 'Champ obligatoire' }}
              render={() => {
                return (
                  <div className="flex flex-col gap-1">
                    <div className="whitespace no-wrap text-sm">Couleur</div>
                    <ChromePicker
                      onChange={(e) => {
                        setColor(e.hex)
                        setValue('color', e.hex)
                      }}
                      color={color}
                    />
                    {errors?.color && (
                      <span className="text-sm text-red-500">
                        {errors?.color?.message}{' '}
                      </span>
                    )}
                  </div>
                )
              }}
            />
          </div>
          <StepButtons
            loading={loading.current}
            goBack={goBack}
            nextLabel={'Enregistrer'}
          />
        </form>
      </div>
    </>
  )
}

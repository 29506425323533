import React, { useState, useEffect } from 'react'
import SetUser from 'components/SetUser'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import LayoutAdmin from 'components/LayoutAdmin'
// import useRegistration from 'hooks/useRegistration'
import useUsers from 'hooks/useUsers'
import { isFormChanged } from 'lib/utils'

export default function SetUserPage() {
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()
  const [user, setUser] = useState()
  const [loading, setLoading] = useState({ prevState: null, current: false })
  const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
      setWarningMessage,
    })
  )

  const {
    getUser,
    updateUsers,
    createUser,
  } = useUsers()

  useEffect(() => {
    async function loadDatas(newElt = null) {
      try {
        if (!newElt) {
          const user = await getUser(id)
          if (user.error) {
            setErrorMessage(user.error.message)
            return
          }
          setUser(user.results)
        }
      } catch (err) {
        setErrorMessage('Service indisponible')
      }
    }
    if (!isNaN(Number(id))) {
      loadDatas()
    } else if (id === 'new') {
      loadDatas('new')
    } else {
      setErrorMessage('Impossible de trouver cet utilisateur')
    }
    // eslint-disable-next-line
  }, [])

  const goBack = () => {
    history.goBack()
  }

  const onSubmit = async (datas) => {
    setLoading({ isLoading: true, refresh: false })
    datas.role = datas.selectedRole.value
    delete datas.selectedRole
    if (!isNaN(Number(id))) { // update
      if (isFormChanged({
        email: datas.email,
        firstname: datas.firstname,
        lastname: datas.lastname,
        role: datas.role
      }, {
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        role: user.role
      })) {
        try {
          let user = await updateUsers([id], datas)
          if (user?.error) {
            setErrorMessage("Impossible de mettre l'utilisateur à jour")
            return
          }
          if (user?.results) {
            setSuccessMessage("Utilisateur mis à jour")
            setLoading({ isLoading: false, refresh: false })
            setUser(user.results)
            history.push('/userlist')
          }
        } catch (err) {
          setErrorMessage(err)
        }
      } else {
        setWarningMessage("Rien n'a changé")
      }
    } else {
      try {
        let user = await createUser(datas)
        if (user?.error) {
          setErrorMessage(user.error.message)
          setLoading({ isLoading: false, refresh: false })
          return
        }
        if (user?.results) {
          setSuccessMessage("Utilisateur créé")
          setLoading({ isLoading: false, refresh: true })
          history.push('/userlist')
          return
        }
      } catch (err) {
        setErrorMessage(err.messsage)
      }
    }
  }

  return (
    <LayoutAdmin location={location} title="Société" hasBack>
      <SetUser
        onSubmit={onSubmit}
        goBack={goBack}
        user={user}
        id={id}
        loading={loading}
      />
    </LayoutAdmin>
  )
}

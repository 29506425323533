import create from 'zustand'
import { immer, persist } from 'state/middlewares'
import shallow from 'lib/shallow'

/*
 * The Registration Store handles a local store of
 * registrations
 */
const registrationsStore = create(
  persist(
    'redirect',
    immer((set, get) => ({
      registrations: [],
      getRegistrations() {
        const r = localStorage.getItem('redirect')
        const registrations = JSON.parse(r)
        return registrations?.registrations || []

      },
      addRegistration(registration) {
        set((state) => ({
          ...state,
          registrations: [...state.registrations, registration],
        }))
      },
      setAllRegistrations(registrations) {
        set((state) => ({
          ...state,
          registrations: registrations,
        }))
      },
      changeRegistration(registration) {
        set((state) => ({
          ...state,
          registrations: state.registrations.map((r) => {
            if (r.id === registration.id) {
              return {
                ...r,
                ...registration,
              }
            } else {
              return r
            }
          }),
        }))
      },
      removeRegistration(registration) {
        set((state) => ({
          ...state,
          registrtations: state.registrations.filter(
            ({ id }) => id !== registration.id
          ),
        }))
      },
    }))
  )
)

export const useRegistrations = (slices) => shallow(registrationsStore, slices)
export const registrationsApi = registrationsStore

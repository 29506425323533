import * as React from "react"
function SvgComponent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M87.602 62.801H12.399c-5.5 0-9.898 4.5-9.898 9.898v11.2c0 5.5 4.5 9.898 9.898 9.898h75.102c5.5 0 9.898-4.5 9.898-9.899V72.7c.102-5.398-4.398-9.898-9.797-9.898zM92 83.899c0 2.5-2 4.5-4.5 4.5H12.398c-2.5 0-4.5-2-4.5-4.5V72.7c0-2.5 2-4.5 4.5-4.5H87.5c2.5 0 4.5 2 4.5 4.5z" />
      <path d="M66 73H13.602c-.5 0-.898.398-.898.898v8.899c0 .5.398.898.898.898L66 83.7c.5 0 .898-.398.898-.898v-8.899A.894.894 0 0066 73zM50 56.398c13.898 0 25.102-11.301 25.102-25.102C75.102 17.398 63.899 6.101 50 6.101S24.898 17.398 24.898 31.199c0 13.902 11.203 25.199 25.102 25.199zm-2.7-38.5c0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7V28.5h7.898c1.5 0 2.699 1.2 2.699 2.7s-1.2 2.698-2.7 2.698H50c-1.5 0-2.7-1.199-2.7-2.699z" />
    </svg>
  )
}
export default SvgComponent
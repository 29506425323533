import React, { useRef } from 'react'
import Files from 'react-files'
import { useCallback } from 'react'
// import useFiles from 'hooks/useFiles'
import useFiles from 'hooks/useFiles'
// const { REACT_APP_API_URL } = process.env
import { useTranslation } from 'react-i18next'
import Compressor from 'compressorjs'

export default function File(
  {
    label,
    name,
    // placeholder,
    required,
    // onChange,
    className,
    // helpText,
    // error,
    value = [],
    // values,
    options,
    form,
    disabled = false,
    onDownloadFile,
    ext = [], // add custom ext
    shouldPreview = false,
    compress = false,
  },
  _ref
) {
  const { t } = useTranslation()
  const refs = useRef()
  let classes = className

  if (required) {
    classes += ' required'
  }

  const onFilesError = (_error, _file) => {}

  // const { onDownloadFile } = useFiles()
  const { removeFile } = useFiles()

  const filesRemoveOne = (file) => {
    if (file.stored) {
      removeFile(file.id)
    }
    if (options.customRemove && !value[0].hasOwnProperty('preview')) {
      options.customRemove(value[0]?.id)
    }

    refs.current.removeFile(file)

    form.setValue(
      name,
      // value.filter(({ id }) => id !== file.id)
      form.getValues(name).filter(({ id }) => id !== file.id)
    )

    // If no value, set it to null to handle re quired rules
    if (form.getValues(name).length === 0) {
      form.setValue(name, false)
      value = false
    }
  }

  const getFileHref = useCallback((file) => {
    if (file.preview && file.preview.url) {
      return file.preview.url
    }
  }, [])

  const getFile = useCallback(
    (_event, file) => {
      if (!file.preview) {
        // event.preventDefault()
        // onDownloadFile(file.path)
        if (onDownloadFile) {
          onDownloadFile(file)
        }
      } else {
        const href = getFileHref(file)
        window.open(href, '_blank')
      }
    },
    [onDownloadFile, getFileHref]
  )

  const handleChanges = async (files) => {
    let newfiles = files
    if (compress) {
      newfiles = await Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            new Compressor(file, {
              quality: 0.8,
              maxWidth: 800,
              maxHeight: 800,
              success: resolve,
              error: reject,
            })
          })
        })
      )
    }

    const formValues = form.getValues(name)
    // check if values if array or empty
    const currentValues = Array.isArray(formValues) ? formValues : []

    // return only unique value
    newfiles = [
      ...currentValues,
      ...files.map((f) => {
        f.typee = 'file'
        return f
      }),
    ]
    const unique = []
    newfiles.forEach((file) => {
      if (!unique.find((elt) => elt.id === file.id)) {
        unique.push(file)
      }
    })

    form.setValue(
      name,
      // add new values to currentValues (if exists)
      // [...currentValues,
      // ...files.map((f) => {
      //   // Add dirty field, to detect file type when save.
      //   f.typee = 'file'
      //   return f
      // })]
      [...unique]
    )
    form.clearErrors([name])
  }

  return (
    <div className="flex flex-col items-center">
      {shouldPreview && value && value[0]?.preview && (
        <img
          alt="Activité"
          src={value[0].preview.url}
          className="object-contain"
          style={{ maxHeight: '360px' }}
        />
      )}
      <label className={classes} htmlFor={name}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
        {options && options.limit && (
          <span className="text-red-500 flex">
            {options.limit} {t('document(s) maximum')}
          </span>
        )}
        <span className="text-gray-600 flex">{t('5 Mo maximum')}</span>
        {!disabled && (
          <Files
            ref={(r) => (refs.current = r)}
            className="p-4 mt-3 text-xs cursor-pointer files-dropzone-list bg-gray-200 rounded"
            style={{ height: '100px' }}
            onChange={(f) => {
              handleChanges(f)
            }}
            accepts={
              ext.length > 0
                ? ext
                : ['image/*', '.pdf', '.docx', '.odt', '.doc']
            }
            onError={onFilesError}
            multiple
            maxFiles={options && options.limit}
            maxFileSize={10000000}
            minFileSize={0}
            clickable
          >
            {t(
              'Glissez-déposez vos fichiers ou cliquez ici pour téléverser vos documents'
            )}
          </Files>
        )}
        {value && value.length > 0 ? (
          <>
            <div className="files-list">
              <ul>
                {value.map((file, index) => (
                  <li
                    className="relative flex items-center mx-2 files-list-item"
                    key={`${file.uid}-${index}`}
                  >
                    <div className="files-list-item-content">
                      <div
                        className="cursor-pointer files-list-item-content-item files-list-item-content-item-1F1"
                        onClick={(e) => getFile(e, file)}
                      >
                        {file.name}
                      </div>
                      {!disabled && (
                        <div
                          className="absolute top-0 right-0 px-3 my-auto text-xl font-bold text-red-500 bg-gray-200 rounded-full cursor-pointer bg-opacity-75"
                          onClick={() => filesRemoveOne(file)}
                        >
                          x
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : null}
      </label>
    </div>
  )
}

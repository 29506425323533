import create from 'zustand'
import { immer, persist} from 'state/middlewares'
import shallow from 'lib/shallow'

const billsStore = create(
    persist(
        'bills',
        immer((set, get) => ({
            bills: [],
            setBills(bill){
                set((state) => ({
                    ...state,
                    bills: bill,
                }))
            }
        })
    ))
)

export const useBills = (slices) => shallow(billsStore, slices)
export const billsApi = billsStore
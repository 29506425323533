import React, { useEffect, useState } from 'react'
import ViewCertificate from 'components/ViewCertificate'
import useRegistration from '../hooks/useRegistration'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Layout from 'components/Layout'

export default function ViewCertificatePage(props) {

  const history = useHistory()
  const { t } = useTranslation()

  const {
    getRegistrationsForCertificate,
  } = useRegistration()

  const [registrations, setRegistrations] = useState([])

  useEffect(() => {
    async function loadRegistrations() {
      const registrations = await getRegistrationsForCertificate()

      let formatRegistrations = registrations.results.map(r => {
        return {
          firstname: r.Child.firstname,
          lastname: r.Child.lastname,
          schoolClass: r.SchoolClass.name,
          schoolingYear: Number(r.SchoolingYear.year),
        }
      }).sort((a, b) => b.schoolingYear - a.schoolingYear)

      setRegistrations(formatRegistrations)
    }
    loadRegistrations()
  }, [getRegistrationsForCertificate])

  return (
    <Layout
      buttonGoHome={{
        to: props.role === 'admin' ? 'admin' : 'dashboard',
        history,
      }}
      title={t("Certificat de scolarité")}>

      <ViewCertificate
        registrations={registrations}
      />
    </Layout>
  )
}

export const USER_ROLE = {
  ADMIN: 'admin',
  PARENT: 'Parent',
  EMPLOYEE: 'Employee',
}

export const REGISTRATION_STATUS = {
  reject: 'Refusé',
  accept: 'Accepté',
  open: 'En cours',
  pending: 'En attente',
  close: 'Fermé',
}

export const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]

export const SCHOOLINGYEAR_STATUS = {
  publish: 'publish',
  draft: 'draft',
}

export const BILL_STATUS = {
  paid: 'paid',
  pending: 'pending',
  cancel: 'cancel',
}

export const BILL_TYPE = {
  childcare: 'Nursery',
  creditnote: 'Creditnote',
  registrationFees: 'RegistrationFeesBill',
  registration: 'SchoolingYearBill',
}

export const SCHOOL_CLASSES = {
  TPS: 'TPS',
  PS: 'PS',
  MS: 'MS',
  GS: 'GS',
  CP: 'CP',
  CE1: 'CE1',
  CE2: 'CE2',
  CM1: 'CM1',
  CM2: 'CM2',
  first_group: '3-6',
  second_group: '6-9',
  undefined: 'undefined',
}

export const SCHOOL_CLASSES_USER = {
  '3-6': 'class-0',
  '6-9': 'class-1',
}

export const ALLOWED_CLASSES = [
  'TPS',
  'PS',
  'MS',
  'GS',
  'CP',
  'CE1',
  'CE2',
  'CM1',
  'CM2',
]

export const YOUNG_CLASSES = ['TPS', 'PS', 'MS', 'GS']

export const OLD_CLASSES = ['GS', 'CP', 'CE1', 'CE2', 'CM1', 'CM2']

export default {
  USER_ROLE,
}

import React, { useState, useEffect } from 'react'
import SetProgression from 'components/SetProgression'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import LayoutAdmin from 'components/LayoutAdmin'
import useActivity from 'hooks/useActivity'
import { isFormChanged } from 'lib/utils'

export default function SetProgressionPage() {
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()

  const {
    getActivities,
    createProgression,
    getProgression,
    updateProgression,

  } = useActivity()

  const [progression, setProgression] = useState()
  const [defaultActivitiesProgression, setDefaultActivitiesProgression] = useState([])

  const [loading, setLoading] = useState({ prevState: null, current: false })

  const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
      setWarningMessage,
    })
  )

  useEffect(() => {
    async function loadDatas(newElt = null) {
      try {
        if (!newElt) {
          const progression = await getProgression(id, true)
          if (progression.error) {
            setErrorMessage(progression.error.message)
            return
          }
          setProgression(progression.results)

          let defaultValue = progression.results.ActivitiesProgressions.map(ap => {
            return {
              label: ap.Activity.name,
              value: ap.ActivityId
            }
          })

          setDefaultActivitiesProgression(defaultValue)

        }
      } catch (err) {
        setErrorMessage('Service indisponible')
      }
    }
    if (!isNaN(Number(id))) {
      loadDatas()
    } else if (id === 'new') {
      loadDatas('new')
    } else {
      setErrorMessage('Impossible de trouver cette progression')
    }
    // eslint-disable-next-line
  }, [])

  const goBack = () => {
    history.goBack()
  }

  const onSubmit = async (datas) => {
    datas.activities = datas.orderedList
    delete datas.orderedList
    setLoading({ prevState: loading.current, current: true })
    if (!isNaN(Number(id))) {

      let originalProgression = {}
      progression.ActivitiesProgressions.forEach(ap => {
        originalProgression[ap.ActivityId] = ap.num
      })

      let currentProgression = {}
      datas.activities.forEach(a => {
        currentProgression[a.value] = a.num
      })

      if (isFormChanged({
        name: progression.name,
        ...originalProgression
      }, {
        name: datas.name,
        ...currentProgression,
      })) {
        let newProgression = await updateProgression(progression.id, datas)
        if (newProgression.error) {
          setErrorMessage(newProgression.error.message)
          setLoading({ prevState: loading.current, current: false })
          return
        }
        setProgression(newProgression.results)
        setDefaultActivitiesProgression(
          newProgression.results.ActivitiesProgressions.map(ap => {
            return {
              label: ap.Activity.name,
              value: ap.ActivityId
            }
          })
        )
        setSuccessMessage('Activité mise à jour')
        setLoading({ prevState: true, current: false })
      } else {
        setWarningMessage("Rien n'a été modifié")
        setLoading({ prevState: true, current: false })
      }
    } else {
      datas.id = 'new'
      let progression = await createProgression(datas)
      if (progression.error) {
        setErrorMessage(progression.error.message)
        setLoading({ prevState: loading.current, current: false })
        return
      }
      setSuccessMessage('Progression créée')
      history.push('/progressionlist')
      return
    }
  }

  return (
    <LayoutAdmin location={location} title="Progressions" hasBack>
      <SetProgression
        onSubmit={onSubmit}
        goBack={goBack}
        progression={progression}
        id={id}
        loading={loading}
        getActivities={getActivities}
        defaultActivitiesProgression={defaultActivitiesProgression}
      />
    </LayoutAdmin>
  )
}


import React, { useState, useEffect, useCallback } from 'react'
import LogBook from 'components/LogBook'
import { useLocation, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import ValidateSkillModal from 'components/common/ValidateSkillModal'

import LayoutAdmin from 'components/LayoutAdmin'
import useActivity from 'hooks/useActivity'
import { useNotify } from 'stores/notify'
import useFiles from 'hooks/useFiles'

export default function LogBookPage(props) {
  const form = useForm()
  const history = useHistory()
  const location = useLocation()

  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const {
    getActivitiesCategoriesPDF,
    saveObservation,
    updateObservation,
    getActivityLevels,
    getActivityImage,
  } = useActivity()

  const { validateChildSkill, getFiles } = useFiles()

  const [child, setChild] = useState({})
  const [year, setYear] = useState()
  const [levels, setLevels] = useState([])
  const [profilePic, setProfilePic] = useState()

  const [domains, setDomains] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onSaveObservation = async ({ DomainId, ChildId, commentJSON }) => {
    try {
      let observation
      let dbObservation = domains.find((d) => d.id === DomainId)?.Observation
      if (dbObservation && dbObservation.id) {
        observation = await updateObservation({
          commentJSON,
          DomainId,
          ChildId,
          ObservationId: dbObservation.id,
        })
      } else {
        observation = await saveObservation({
          DomainId,
          ChildId,
          commentJSON,
        })
      }

      if (observation.error) {
        setErrorMessage("Impossible d'enregister l'observation")
      }

      let newDomains = JSON.parse(JSON.stringify(domains))
      for (let domain of newDomains) {
        if (domain.id === DomainId) {
          domain.Observation = observation.results
        }
      }

      setDomains(newDomains)
      setSuccessMessage("L'observation a bien été enregistrée")
    } catch (err) {
      setErrorMessage("Impossible d'enregister l'observation")
    }
  }

  const loadActivitiesValidate = async () => {
    try {
      setIsLoading(true)
      const activities = await getActivitiesCategoriesPDF(
        child.id,
        child?.Registrations[0]?.SchoolClassId,
        child?.Registrations[0]?.SchoolingYearId
      )

      setDomains(activities.results.domains)
      setYear(activities.results.year)
      setIsLoading(false)
    } catch (err) {
      // setErrorMessage("Impossible de charger les activités")
    }
  }

  const getProfilePic = async (child) => {
    if (child?.Registrations) {
      const file = await getFiles('childPhoto', child.Registrations[0].id)
      setProfilePic(file.results.photo)
    }
  }

  useEffect(() => {
    if (props?.location?.state) {
      setChild(props?.location?.state?.child)
    }
  }, [props])

  // load categories
  useEffect(() => {
    if (child) {
      loadActivitiesValidate()
      getProfilePic(child)
    }
    //eslint-disable-next-line
  }, [child])

  useEffect(() => {
    async function loadLevels() {
      const levels = await getActivityLevels()
      setLevels(levels.results)
    }
    loadLevels()
    //eslint-disable-next-line
  }, [])

  // On validate activity
  const validateModal = useCallback(
    async (activity) => {
      let file = null
      let children = []

      if (
        activity?.ChildrenActivities &&
        activity?.ChildrenActivities[0]?.File
      ) {
        let temp = await getActivityImage(
          activity?.ChildrenActivities[0]?.FileId
        )
        file = temp.results
      }

      const level = await ValidateSkillModal({
        activity,
        levels,
        form,
        file,
        // deleteImage,
        canEdit: true,
        history,
        children,
        childId: child?.id || null,
      })

      if (!level) {
        return
      }

      try {
        level.ActivityId = activity.id
        if (level.childrenId.length === 0) {
          level.childrenId = children.map((child) => child.id).join(',')
        }
        level.includeDomain = true
        const skill = await validateChildSkill(level)

        if (skill?.results?.error) {
          setErrorMessage(skill.results.error)
          // setCardLoading(false)
          return
        }

        if (skill.results) {
          let tmp = JSON.parse(JSON.stringify(domains))
          tmp.forEach((domain) => {
            if (domain?.id === skill.results?.domain?.id) {
              domain.subDomains.forEach((subDomain) => {
                if (
                  subDomain?.id === skill.results?.domain?.SubDomains[0]?.id
                ) {
                  subDomain.cat.forEach((category) => {
                    if (category?.id === skill.results?.category?.id) {
                      category.CategoriesActivities.forEach((ca) => {
                        if (
                          ca.Activity.id ===
                          skill.results?.domain?.SubDomains[0]?.Activities[0]
                            ?.id
                        ) {
                          ca.Activity.ChildrenActivities =
                            skill.results.ChildrenActivities
                        }
                      })
                    }
                  })
                }
              })
            }
          })
          setDomains(tmp)
          setSuccessMessage('Enregistré')
          // setCardLoading(false)
        }
      } catch (err) {
        setErrorMessage('Impossible de valider la compétence')
      }
    },
    //eslint-disable-next-line
    [levels, domains]
  )

  return (
    <LayoutAdmin location={location} title="Carnet de suivi" hasBack>
      <LogBook
        child={child}
        domains={domains}
        isLoading={isLoading}
        saveObservation={onSaveObservation}
        year={year}
        schoolClass={props?.location?.state?.schoolClass}
        onClickActivity={validateModal}
        profilePic={profilePic}
      />
    </LayoutAdmin>
  )
}

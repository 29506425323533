import React, { useState, useEffect } from 'react'
import SetDomain from 'components/SetDomain'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import LayoutAdmin from 'components/LayoutAdmin'
import useActivity from 'hooks/useActivity'
import { isFormChanged } from 'lib/utils'

export default function SetDomainPage() {
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()

  const { getDomain, updateDomain, createDomain, getSubDomains } = useActivity()

  const [domain, setDomain] = useState()
  const [subDomains, setSubDomains] = useState([])
  const [loading, setLoading] = useState({ prevState: null, current: false })
  const [defaultSubDomains, setDefaultSubDomains] = useState([])
  const [subDomainWarnings, setSubDomainWarnings] = useState([])

  const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
      setWarningMessage,
    })
  )

  useEffect(() => {
    async function loadDomain(newElt = null) {
      try {
        if (!newElt) {
          const domain = await getDomain(id, true)
          if (domain.error) {
            setErrorMessage(domain.error.message)
            return
          }
          setDomain(domain.results)
          // let defaultValue = []
          let defaultValue = domain.results.SubDomains.map((subDomain) => {
            return {
              label: subDomain.name,
              value: subDomain.id,
            }
          })
          setDefaultSubDomains(defaultValue)
        }
      } catch (err) {
        setErrorMessage('Service indisponible')
      }
    }
    if (!isNaN(Number(id))) {
      loadDomain()
    } else if (id === 'new') {
      loadDomain('new')
    } else {
      setErrorMessage('Impossible de trouver ce domaine')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function loadSubDomains() {
      const subDomains = await getSubDomains(0, 1000, null, null, true)
      if (subDomains.error) {
        setErrorMessage('Impossible de charger les sous-domaines')
      }

      let subDomainWarnings = []
      let formatSubDomains = subDomains.results.map((subDomain) => {
        if (subDomain.Domain && subDomain.DomainId !== Number(id)) {
          subDomainWarnings.push(subDomain)
        }

        return {
          value: subDomain.id,
          label: subDomain.name,
        }
      })

      setSubDomains(formatSubDomains)
      setSubDomainWarnings(subDomainWarnings)
    }
    loadSubDomains()
    //eslint-disable-next-line
  }, [setErrorMessage])

  const goBack = () => {
    history.goBack()
  }
  const onSubmit = async (datas) => {
    setLoading({ prevState: loading.current, current: true })
    // creation
    if (isNaN(Number(id))) {
      let domain = await createDomain(datas)
      if (domain.error) {
        setErrorMessage(domain.error.message)
        setLoading({ prevState: loading.current, current: false })
        return
      }
      setSuccessMessage('Domaine crée')
      setLoading({ prevState: loading.current, current: false })
      return
    }

    let originalSubDomains = {}
    domain.SubDomains.forEach((subDomain) => {
      originalSubDomains[subDomain.id] = subDomain.id
    })

    let currentSubDomains = {}
    datas.subDomains.forEach((subDomain) => {
      currentSubDomains[subDomain.value] = subDomain.value
    })

    if (
      isFormChanged(
        {
          name: datas.name,
          color: datas.color,
          ...currentSubDomains,
        },
        {
          name: domain.name,
          color: domain.color,
          ...originalSubDomains,
        }
      )
    ) {
      let newDomain = await updateDomain(domain.id, datas)
      if (newDomain.error) {
        setErrorMessage(newDomain.error.message)
        setLoading({ prevState: loading.current, current: false })
        return
      }
      setDomain(newDomain.results)
      let subDomainWarnings = []
      let defaultSubDomains = newDomain.results.SubDomains.map((subDomain) => {
        if (subDomain.Domain && subDomain.DomainId !== Number(newDomain.id)) {
          subDomainWarnings.push(subDomain)
        }
        return {
          label: subDomain.name,
          value: subDomain.id,
        }
      })

      setDefaultSubDomains(defaultSubDomains)
      setSubDomainWarnings(subDomainWarnings)

      setSuccessMessage('Domaine mis à jour')
      setLoading({ prevState: true, current: false })
      return
    } else {
      setWarningMessage("Rien n'a été modifié")
      setLoading({ prevState: true, current: false })
      return
    }
  }

  return (
    <LayoutAdmin location={location} title="Domaine" hasBack>
      <SetDomain
        onSubmit={onSubmit}
        goBack={goBack}
        domain={domain}
        id={id}
        loading={loading}
        subDomains={subDomains}
        defaultSubDomains={defaultSubDomains}
        subDomainWarnings={subDomainWarnings}
      />
    </LayoutAdmin>
  )
}

import React, { useState, useEffect } from 'react'
import SchoolCertificate from 'components/SchoolCertificate'
import LayoutAdmin from 'components/LayoutAdmin'

export default function SchoolCertificatePage(props) {
  const [firstname, setFirstname] = useState()
  const [lastname, setLastname] = useState()
  const [schoolingYear, setSchoolingYear] = useState()
  const [schoolClass, setSchoolClass] = useState()

  useEffect(() => {
    if (props?.location?.state) {
      setFirstname(props.location.state.firstname)
      setLastname(props.location.state.lastname)
      setSchoolClass(props.location.state.schoolClass)
      setSchoolingYear(props.location.state.schoolingYear)
    }
  }, [props])

  return (

    <LayoutAdmin
      title={"Certificat de scolarité"}
    >
      <SchoolCertificate
        firstname={firstname}
        lastname={lastname}
        schoolingYear={schoolingYear}
        schoolClass={schoolClass}
      />
    </LayoutAdmin>
  )
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import BillDetails from '../components/BillDetails'
import Layout from 'components/Layout'
import { useNotify } from '../stores/notify'
import useChildcare from 'hooks/useChildcare'
import { useTranslation } from 'react-i18next'
import Spinner from 'components/common/Spinner'

export default function ValidNurseryPage(props) {
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )
  const history = useHistory()
  const { setChildCares, getNurseryPrice } = useChildcare()
  const [price, setPrice] = useState()
  const [totalDuration, setTotalDuration] = useState()
  const [datas, setDatas] = useState()
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const datasFromProps = props?.location?.state?.datas

  useEffect(() => {
    try {
      let today = new Date()
      today.setHours(0, 0, 0, 0)

      const filteredDate = datasFromProps.filter(p => {
        p.date.setHours(0, 0, 0, 0)
        if (p.date.getTime() >= today.getTime()) {
          return true
        } else {
          return false
        }
      })

      setDatas(filteredDate)
      let totalDuration = filteredDate
        .map((date) => {
          return date.subTotalDuration
        })
        .reduce((a, b) => a + b)
      setTotalDuration(totalDuration)
    } catch (err) {
      history.replace('/nursery')
      return
    }
  }, [history, datasFromProps])

  const onSubmit = async () => {
    setLoading(true)
    const childcares = await setChildCares({
      dates: datasFromProps,
    })
    if (childcares.error) {
      setErrorMessage(t(childcares.error.message))
      setLoading(false)
      return
    }

    if (childcares.results.childcares.filter(c => c !== null).length === 0) {
      setErrorMessage(t("Impossible de réserver à cette date"))
      setLoading(false)
      history.goBack()
      return
    }
    if (childcares) {
      setSuccessMessage(t('Enregistré'))
      setLoading(false)
      history.push('/nursery', { dates: datas[0].date })
    }
  }

  const goBack = () => {
    history.push('/nursery')
  }

  useEffect(() => {
    async function getPrice() {
      const price = await getNurseryPrice(datas[0].date)
      if (price.error) {
        setErrorMessage(t(price.error.message))
        return
      }
      setPrice(price.results.price)
    }
    if (datas) {
      getPrice()
    }
  }, [datas, getNurseryPrice, setErrorMessage, t])

  return (
    <Layout buttonGoHome={{ to: 'dashboard', history }} title={t('Garderie')}>
      {!price ? (
        <Spinner></Spinner>
      ) : (

        <BillDetails
          childcares={datas}
          onSubmit={onSubmit}
          goBack={goBack}
          price={price}
          totalPrice={totalDuration && price && (totalDuration * price)}
          totalDuration={totalDuration}
          viewMode={false}
          loading={loading}
        />
      )}
    </Layout>
  )
}

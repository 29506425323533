import create from 'zustand'
import { immer, persist } from 'state/middlewares'
import shallow from 'lib/shallow'

// user store
// TODO: move each store in its own module
const notifyStore = create(
  persist(
    'notify',
    immer((set, get) => ({
      message: null,
      getMessage: () => {
        const state = get()
        return state.message
      },
      setMessage: (message) => {
        set((state) => {
          state.message = message
        })
      },
      setInfoMessage: (text) => {
        set((state) => {
          state.message = { level: 'info', text }
        })
      },
      setWarningMessage: (text) => {
        set((state) => {
          state.message = { level: 'warning', text }
        })
      },
      setErrorMessage: (text) => {
        set((state) => {
          state.message = { level: 'error', text }
        })
      },
      setSuccessMessage: (text) => {
        set((state) => {
          state.message = { level: 'success', text }
        })
      },
    }))
  )
)

export const useNotify = (slices) => shallow(notifyStore, slices)
export const notifyApi = notifyStore

import React from 'react'
import { useLocation } from 'react-router-dom'
import SchoolingYearList from 'components/registrations/SchoolingYearList'
import LayoutAdmin from '../components/LayoutAdmin'

export default function SchoolingYearListPage() {
  const location = useLocation()

  return (
    <div>
      <LayoutAdmin location={location} title="Rentrées Scolaires" hasBack={false}>
        <SchoolingYearList />
      </LayoutAdmin>
    </div>
  )
}

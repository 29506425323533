import React, { useState, useEffect, useRef, useCallback } from 'react'
import ChildValidation from 'components/ChildValidation'
import { useLocation, useHistory } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import ValidateSkillModal from 'components/common/ValidateSkillModal'
import { useForm } from 'react-hook-form'
import LayoutAdmin from 'components/LayoutAdmin'
import useActivity from 'hooks/useActivity'
import useFiles from 'hooks/useFiles'

export default function ChildValidationPage(props) {
  const location = useLocation()
  const history = useHistory()

  const form = useForm()
  const {
    getCategoriesHavingActivities,
    getCategoryActivities,
    getActivityLevels,
    getActivitiesRecommanded,
    getActivityImage,
    deleteChildrenActivityImage,
    getChildrenHavingActivityUnDone,
  } = useActivity()

  const { validateChildSkill } = useFiles()

  const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
      setWarningMessage,
    })
  )

  const [loading, setLoading] = useState(true)
  const [child, setChild] = useState({})
  const [categories, setCategories] = useState([])
  const [levels, setLevels] = useState([])
  const [search, setSearch] = useState()
  const [searchFilter, setSearchFilter] = useState()
  const [cardLoading, setCardLoading] = useState(false)

  const timeout = useRef()

  // Load activities
  const getCategoriesActivities = async (id) => {
    let temp = JSON.parse(JSON.stringify(categories))

    // if click on specific category, toggle display
    if (id && categories.find((c) => c.id === id)) {
      let category = temp.find((c) => c.id === id)
      if (category?.Activities) {
        category.shouldDisplay = !category.shouldDisplay
        setCategories(temp)
        return
      }
    }

    try {
      setLoading(true)
      const activities = await getCategoryActivities({
        categoryId: id || null,
        childId: child?.all ? null : child.id,
        schoolClassId: child?.Registrations[0]?.SchoolClassId,
        searchFilter,
        search,
        schoolingYearId: props?.location?.state?.SchoolingYearId,
      })

      // Clear activity list if change filter
      temp.forEach((cat) => {
        if (!id) {
          cat.Activities = []
        } else {
          delete cat.Activities
        }
        cat.shouldDisplay = false
      })

      activities.results.forEach((activity) => {
        let c = temp.find(
          (t) => t.id === activity?.CategoriesActivities[0].CategoryId
        )
        if (c) {
          if (c.Activities) {
            c.Activities.push(activity)
          } else {
            c.Activities = [activity]
          }
          c.shouldDisplay = true
        }
      })
      setLoading(false)
      setCategories(temp)
    } catch (err) {
      setErrorMessage('Impossible de charger les activités')
      setLoading(false)
    }
  }

  const deleteImage = useCallback(
    async (id) => {
      try {
        const image = await deleteChildrenActivityImage(id)
        if (image.results) {
          setSuccessMessage("L'image à été supprimé")
          let tmp = JSON.parse(JSON.stringify(categories))
          tmp.forEach((category) => {
            category?.Activities &&
              category.Activities.forEach((activity) => {
                if (activity?.id === id) {
                  activity.ChildrenActivities[0].File = null
                  activity.ChildrenActivities[0].FileId = null
                }
              })
          })

          setCategories(tmp)
          return tmp
        }
      } catch (err) {
        setErrorMessage('Impossible de supprimer le fichier')
      }
    },
    [
      categories,
      deleteChildrenActivityImage,
      setErrorMessage,
      setSuccessMessage,
    ]
  )

  const loadRecommanded = async () => {
    let temp = JSON.parse(JSON.stringify(categories))

    temp.forEach((cat) => {
      cat.Activities = []
      cat.shouldDisplay = false
    })

    try {
      const activities = await getActivitiesRecommanded({
        childId: child.id,
        search: search || null,
        schoolingYearId: child?.Registrations[0]?.SchoolingYearId,
      })
      if (activities.error) {
        setErrorMessage('Impossible de récupérer les activités recommandé')
        return
      }

      temp.forEach((cat) => {
        let c = activities.results.find((c) => c.id === cat.id)
        if (c) {
          let activities = c.CategoriesActivities.map((ca) => ca.Activity)
          cat.Activities = activities
          cat.shouldDisplay = true
        }
      })
      setCategories(temp)
    } catch (err) {
      setErrorMessage('Impossible de récupérer les activitiés recommandé')
    }
  }

  const loadChildrenHavingActivityUnDone = async ({
    schoolingYear,
    activityId,
  }) => {
    try {
      const children = await getChildrenHavingActivityUnDone({
        schoolingYear,
        activityId,
      })
      return children.results
    } catch (err) {
      setErrorMessage(
        "Impossible de récupérer la liste des enfants n'ayant pas réalisé l'activité"
      )
    }
  }
  // On validate activity
  const validateModal = useCallback(
    async (activity) => {
      setCardLoading(activity.id)
      let file = null
      let children = []

      if (
        activity?.ChildrenActivities &&
        activity?.ChildrenActivities[0]?.File
      ) {
        let temp = await getActivityImage(
          activity?.ChildrenActivities[0]?.FileId
        )
        file = temp.results
      }

      if (child?.all) {
        children = await loadChildrenHavingActivityUnDone({
          schoolingYear: props?.location?.state?.SchoolingYearId,
          activityId: activity.id,
        })
      }

      const level = await ValidateSkillModal({
        activity,
        levels,
        form,
        file,
        deleteImage,
        canEdit: child?.all ? true : false,
        history,
        children,
        childId: child?.id || null,
      })

      if (!level) {
        setCardLoading(false)
        return
      }

      if (level && !level.ActivityLevelId) {
        setCardLoading(false)
        setWarningMessage("Aucun niveau d'activité n'a été renseigné")
        return
      }

      try {
        level.ActivityId = activity.id
        if (level.childrenId.length === 0) {
          level.childrenId = children.map((child) => child.id).join(',')
        }
        const skill = await validateChildSkill(level)

        if (skill?.results?.error) {
          setErrorMessage(skill.results.error)
          setCardLoading(false)
          return
        }

        if (skill.results) {
          if (searchFilter === 'recommanded') {
            loadRecommanded()
          } else {
            // overwrite activty in category list
            let tmp = JSON.parse(JSON.stringify(categories))

            tmp.forEach((category) => {
              category?.Activities &&
                category.Activities.forEach((activity) => {
                  if (activity.id === level.ActivityId) {
                    if (level.childrenId.length > 1) {
                      // If update many children, add new ChildrenActities into category (use for activityCard bg-color)
                      activity.ChildrenActivities = [
                        ...skill.results.ChildrenActivities.map((a) => ({
                          ChildId: a.ChildId,
                        })),
                        ...activity.ChildrenActivities,
                      ]

                      activity.ChildrenActivities = activity.ChildrenActivities.filter(
                        (elt, pos, self) => {
                          return self.indexOf(elt) === pos
                        }
                      )
                    } else {
                      activity.ChildrenActivities =
                        skill.results.ChildrenActivities
                    }
                  }
                })
            })
            setCategories(tmp)
          }
          setSuccessMessage('Enregistré')
          setCardLoading(false)
        }
      } catch (err) {
        setCardLoading(false)
        setErrorMessage('Impossible de valider la compétence')
      }
    },
    // eslint-disable-next-line
    [
      deleteImage,
      setCardLoading,
      setErrorMessage,
      setCategories,
      categories,
      child,
      form,
      getActivityImage,
      history,
      levels,
      props,
      searchFilter,
      setSuccessMessage,
      setWarningMessage,
      validateChildSkill,
    ]
  )

  // Handle search (text) debounced
  const searchActivity = async (search = ' ') => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(async () => {
      setSearch(search)
    }, [800])
  }

  // Load categories
  const loadCategories = async (schoolClassId) => {
    try {
      const categories = await getCategoriesHavingActivities({
        id: schoolClassId,
        // schoolingyear: child?.Registrations[0]?.SchoolingYearId
      })
      setCategories(categories.results)
    } catch (err) {
      setErrorMessage('Impossible de charger les catégories')
    }
    setLoading(false)
  }

  const logBook = () => {
    history.push(`/logbook`, {
      levels,
      child,
      schoolingYear: props?.location?.state?.SchoolingYearId,
      schoolClass: props?.location?.state?.schoolClass,
    })
  }

  // Load categories
  useEffect(() => {
    if (child && child.id) {
      loadCategories(child?.Registrations[0]?.SchoolClassId)
    }
    //eslint-disable-next-line
  }, [child])

  // Load activity levels
  useEffect(() => {
    async function loadActivityLevels() {
      try {
        const levels = await getActivityLevels()
        setLevels(levels.results)
      } catch (err) {
        setErrorMessage('Impossible de récupérer les niveaux')
      }
    }
    loadActivityLevels()
    // eslint-disable-next-line
  }, [setErrorMessage])

  // On changing search (text filter) or searchFilter (radio fiter)
  useEffect(() => {
    if (searchFilter === 'recommanded') {
      loadRecommanded()
      return
    }
    if (
      categories &&
      categories.length > 0 &&
      ((searchFilter && searchFilter !== 'recommanded') || search)
    ) {
      getCategoriesActivities()
      return
    }
    //eslint-disable-next-line
  }, [searchFilter, search])

  // If choose whole class
  useEffect(() => {
    if (props?.location?.state?.child) {
      let child = props.location?.state?.child
      if (child?.all) {
        child.firstname = 'Toute la classe'
        child.lastname = ''
      }
      setChild(child)
    }
  }, [props])

  return (
    <LayoutAdmin location={location} title="Suivi des enfants" hasBack>
      <ChildValidation
        loading={loading}
        child={child}
        categories={categories}
        onCategorySelected={getCategoriesActivities}
        onValidateSkill={validateModal}
        onSearch={searchActivity}
        onClickLogBook={logBook}
        levels={levels}
        setSearchFilter={setSearchFilter}
        isCardLoading={cardLoading}
      />
    </LayoutAdmin>
  )
}

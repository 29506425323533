import React, { useCallback } from 'react'

function getClassName({ invert, textOnly, variant, disabled }) {
  let back, front, className
  className =
    'flex justify-center items-center text-center active:opacity-50 focus:outline-none '

  if (disabled) {
    className += ' opacity-50 '
  }

  switch (variant) {
    default:
    case 'primary':
      back = 'green-500'
      front = 'white'
      break
    case 'secondary':
      back = 'blue-500'
      front = 'white'
      break
    case 'danger':
      back = 'red-500'
      front = 'white'
      break
    case 'black':
      back = 'black'
      front = 'white'
  }

  if (textOnly) {
    className += `bg-transparent text-${back}`
  } else if (invert) {
    className += `bg-${front} text-${back}`
  } else {
    className += `bg-${back} text-${front} px-6 py-2 rounded`
  }

  return className
}

function Loader() {
  return (
    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  )
}

export default function Button({
  children,
  loading,
  disabled,
  submits,
  onClick,
  invert,
  textOnly,
  variant,
}) {
  const className = getClassName({ invert, textOnly, variant, disabled })
  const handleClick = useCallback(
    (...args) => {
      if (!disabled) {
        return onClick(...args)
      }
    },
    [disabled, onClick]
  )

  return (
    <button
      onClick={handleClick}
      className={`${className} ${loading  || disabled ? 'cursor-not-allowed' : ''}`}
      type={submits ? 'submit' : 'button'}
      disabled={loading}
    >
      {loading ? <Loader /> : <span className="truncate">{children}</span>}
    </button>
  )
}

Button.defaultProps = {
  onClick: () => {},
  invert: false,
  textOnly: false,
  variant: 'primary',
  loading: false,
  disabled: false,
  submits: false,
}

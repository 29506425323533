import React from 'react'
import { useLocation } from 'react-router-dom'
import RegistrationList from 'components/registrations/List'
import LayoutAdmin from '../components/LayoutAdmin'

export default function AdminPage() {
  const location = useLocation()

  return (
    <div>
      <LayoutAdmin location={location} isHome>
        <RegistrationList />
      </LayoutAdmin>
    </div>
  )
}

import React, { useState, useEffect, useRef } from 'react'
import Bill from '../components/Bill'
import LayoutAdmin from 'components/LayoutAdmin'
import { useHistory, useLocation } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import useBill from 'hooks/useBill'
import ReactToPrint from 'react-to-print'
import Spinner from 'components/common/Spinner'

export default function BillsToPdf(props) {
  const history = useHistory()
  const location = useLocation()
  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))

  const billsToPrint = useRef()
  const [bills, setBills] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [displayAll, setDisplayAll] = useState(false)

  const { getBillsToPdf } = useBill()

  useEffect(() => {
    async function loadBills({ year, month }) {
      try {
        setIsLoading(true)
        const bills = await getBillsToPdf({ year, month })
        setBills(
          bills.results.sort((a, b) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
          })
        )
        setIsLoading(false)
      } catch (err) {
        setErrorMessage('Impossible de télécharger les factures')
        setIsLoading(false)
      }
    }
    if (props?.location?.state?.year && props?.location?.state?.month) {
      loadBills({
        year: props.location.state.year,
        month: props.location.state.month,
      })
    }
  }, [props, getBillsToPdf, setErrorMessage])

  return (
    <>
      <LayoutAdmin
        location={location}
        buttonGoHome={{ to: '/bills', history }}
        className={'m-0'}
      >
        {!isLoading && bills && bills.length === 0 ? (
          <div className="flex w-full flex-col items-center justify-center">
            <div
              className="text-blue-500 cursor-pointer flex w-1/2  items-center py-3 px-4"
              onClick={() => history.goBack()}
            >
              Précédent
            </div>
            <div className="w-1/2 p-2 bg-orange-100 text-orange-700 px-8">
              Il n'y a aucune facture à cette date
            </div>
          </div>
        ) : (
          isLoading && (
            <div className="flex w-full justify-center">
              <Spinner />
            </div>
          )
        )}

        {bills && bills.length > 0 && (
          <div className="w-full flex justify-center items-center">
            <div className="flex w-1/2 justify-between">
              <div
                className="text-blue-500 cursor-pointer flex justify-center items-center py-3 px-4"
                onClick={() => history.goBack()}
              >
                Précédent
              </div>
              <ReactToPrint
                trigger={() => (
                  <button className="bg-transparent text-blue-500 py-3 px-4 rounded-lg hover:bg-blue-500 hover:text-white">
                    {'Imprimer'}
                  </button>
                )}
                content={() => billsToPrint.current}
                bodyClass={''}
                documentTitle={'Factures'}
                pageStyle=" 
          @media all {
            .page-break {
              display: none;
            }
          }
          
          @media print {
            html, body {
              // height: initial !important;
              height: 100% !important;
              overflow: initial !important;
              -webkit-print-color-adjust: exact;
            background: white !important;

            }
          }
          
          @media print {
            .page-break {
              margin-top: 1rem;
              display: block;
            }
          }
          
          @page {
            size: auto;
            background: white !important;
            margin: 15mm;
          }

          .printable{
            page-break-after:always;
            overflow:hidden;
            break-inside: auto;
            background: white !important;

          }

          .form-container{
            padding: 10px !important;
            background: white !important;
            margin: 0px !important;
          }
          .form-container .form-content {
            width: 100%;
            padding: 0px !important;
            background: white !important;
          }

          "
              />
            </div>
          </div>
        )}
        <div ref={billsToPrint}>
          {bills &&
            bills.map((bill, index) => (
              <React.Fragment key={bill.id}>
                <div
                  className={`flex flex-col p-0 m-0 printable ${
                    index >= 1 && !displayAll && 'hidden print:flex'
                  }`}
                >
                  <Bill bill={bill} role={null} noPrintable={true} />
                </div>
              </React.Fragment>
            ))}
        </div>
        {bills && bills.length > 0 && (
          <div
            className="flex justify-center my-4"
            onClick={() => setDisplayAll(!displayAll)}
          >
            {!displayAll ? (
              <span className="text-blue-500 cursor-pointer">
                Voir les {bills.length - 1} autres factures
              </span>
            ) : (
              <span className="text-blue-500 cursor-pointer">
                Masquer les {bills.length - 1} autres factures
              </span>
            )}
          </div>
        )}
      </LayoutAdmin>
    </>
  )
}

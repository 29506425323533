import React, { useState, useEffect, useCallback } from 'react'
import BillsView from '../components/BillsView'
import { useNotify } from '../stores/notify'
import { useHistory, useParams } from 'react-router-dom'
import useBill from '../hooks/useBill'
import { useTranslation } from 'react-i18next'
import Layout from 'components/Layout'

export default function BillsViewPage(props) {
  const { setSuccessMessage, setErrorMessage } = useNotify(
    ({ setSuccessMessage, setErrorMessage }) => ({
      setSuccessMessage,
      setErrorMessage,
    })
  )
  const { getBillsAndStatus, payBill } = useBill()
  const history = useHistory()
  const { id, status } = useParams()
  const { t } = useTranslation()

  const [bill, setBill] = useState()
  const [loading, setLoading] = useState(true)

  const loadBill = useCallback(async () => {
    try {
      const bill = await getBillsAndStatus()
      if (bill?.error) {
        setErrorMessage(t(bill.error.message))
        return
      }
      if (bill?.results?.bill.length === 0) {
        history.replace('/dashboard')
        return
      }
      bill.results.bill.reverse()
      setBill(bill.results)
      setLoading(false)
    } catch (err) {
      setErrorMessage(t(err.message))
    }
  }, [
    getBillsAndStatus,
    setErrorMessage,
    setBill,
    setLoading,
    t,
    history
  ])

  const onViewBill = (id) => {
    const billClick = bill.bill.find((b) => b.id === id)
    history.push('/bill', { bill: billClick })
  }

  const onPayBill = async (id) => {
    try {
      const pay = await payBill(id)
      if (pay.error) {
        setErrorMessage(t(pay.error))
        return
      }

      if (pay.results && pay.results.url) {
        window.location = pay.results.url
      }
    } catch (err) {
      setErrorMessage(t(err.message))
    }
  }

  useEffect(() => {
    if (id && status) {
      if (status === 'ok') {
        setSuccessMessage(t('La facture a bien été payée'))
        loadBill()
      } else if (status === 'error') {
        setErrorMessage(t("La facture n'a pas été payée"))
      }
      history.replace('/billsview')
    }
  }, [history, id, loadBill, setErrorMessage, setSuccessMessage, status, t])

  return (
    <Layout
      buttonGoHome={{
        to: props.role === 'admin' ? 'admin' : 'dashboard',
        history
      }}
      title={t("Factures")}
    >
      <BillsView
        loadBill={loadBill}
        onViewBill={onViewBill}
        onPayBill={onPayBill}
        bills={bill}
        loading={loading}
        history={history}
      />
    </Layout>
  )
}

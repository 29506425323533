import * as React from "react"

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
      width="1em"
      height="1em"
      {...props}>
      <path d="M435.2 153.6H320v25.6h102.4v307.2H89.6V179.2H192v-25.6H76.8c-7.066 0-12.8 5.734-12.8 12.8v332.8c0 7.066 5.734 12.8 12.8 12.8h358.4c7.066 0 12.8-5.734 12.8-12.8V166.4c0-7.066-5.734-12.8-12.8-12.8z" />
      <path d="M341.956 234.249c-4.941-5.052-13.056-5.146-18.099-.205L268.8 287.898V12.8C268.8 5.734 263.066 0 256 0c-7.066 0-12.8 5.734-12.791 12.8v275.089l-55.057-53.854c-5.043-4.941-13.158-4.847-18.099.205-4.941 5.06-4.855 13.158.205 18.099l76.8 75.128c5.043 4.949 13.158 4.855 18.099-.188l76.595-74.931c5.051-4.941 5.145-13.039.204-18.099z" />
    </svg>
  )
}

export default SvgComponent

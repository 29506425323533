import { useUser } from 'stores/user'
import Api from 'lib/api'

export default () => {
    const { token } = useUser(({ token }) => ({ token }))

    return {
        async getAllPriceList(offset = 0, limit = 100, filters, order) {
            try {
                const priceList = await Api.getAllPriceList(token, { query: { offset, limit, filters, order } })
                return priceList
            } catch (err) {
                return { error: err }
            }
        },
        async getOnePrice(id) {
            try {
                const price = await Api.getOnePrice(token, id)
                return price
            } catch (err) {
                return { error: err }
            }
        },
        async updatePrice(id, params) {
            try {
                const price = await Api.updatePrice(token, id, params)
                return price
            } catch (err) {
                return { error: err }
            }
        },
        async deletePrice(id) {
            try {
                const price = await Api.deletePrice(token, id)
                return price
            } catch (err) {
                return { error: err }
            }
        },
        async createPrice(params) {
            try {
                const price = await Api.createPrice(token, params)
                return price
            } catch (err) {
                return { error: err }
            }
        },
        async getAllPricingsGrids(offset = 0, limit = 100, filters, order, populate = false) {
            try {
                const pricingsgridslist = await Api.getAllPricingsGrids(token, { query: { offset, limit, filters, order, populate } })
                return pricingsgridslist
            } catch (err) {
                return { error: err }
            }
        },
        async updatePricingsGrid(id, params) {
            try {
                const pricingGrid = await Api.updatePricingsGrid({ token, id, params })
                return pricingGrid
            } catch (err) {
                return { error: err }
            }
        },

    }
}

import React from 'react'

export default function ActivityPDFCard({
  activity,
  isActivityValided = () => true,
  watchHideImage,
  getCircleColor = null,
  type = '',
  recommandedOnly = false,
}) {
  const imageHeight = '75px'
  return (
    <>
      {isActivityValided(activity) && (
        <div className="activityPDFCard">
          <div className="flex w-full flex-row justify-between items-center bg-gray-200">
            <div
              className="bg-gray-200 flex p-2"
              style={{ maxHeight: '200px' }}
            >
              {!watchHideImage && activity?.Activity?.File?.url && (
                <img
                  src={activity.Activity.File.url}
                  alt={'Activité'}
                  style={{
                    height: imageHeight,
                  }}
                ></img>
              )}
            </div>
            <div className="text-lg flex-1 px-2 print:flex-auto">
              <div>{activity?.Activity?.name}</div>
            </div>
            <div className="flex gap-2 flex-1 items-center justify-end p-2">
              <div
                style={{
                  background: getCircleColor
                    ? getCircleColor(
                        activity?.Activity?.ChildrenActivities[0]?.ActivityLevel
                          ?.color
                      )
                    : '',
                  height: '20px',
                  width: '20px',
                }}
                className="block rounded-full"
              ></div>
            </div>
          </div>
          {activity?.Activity?.nationalLabel && (
            <div className="bg-gray-200 p-2">
              <div className="font-medium">
                Compétence Éducation Nationale :{' '}
              </div>
              <div>{activity.Activity.nationalLabel}</div>
            </div>
          )}
          {!recommandedOnly && (
            <div
              className={`border-b flex flex-col items-start ${
                type !== 'admin' && 'p-2'
              }`}
            >
              {type !== 'admin' &&
                activity?.Activity?.ChildrenActivities[0]?.File?.url &&
                !watchHideImage && (
                  <img
                    src={activity?.Activity?.ChildrenActivities[0]?.File?.url}
                    className="object-contain lg:w-auto "
                    style={{ height: imageHeight, maxWidth: '320px' }}
                    alt={`${activity?.Activity?.name}`}
                  ></img>
                )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

import { MONTHS } from './constants'

export function getDeepValue(obj, path, def) {
  /**
   * If the path is a string, convert it to an array
   * @param  {String|Array} path The path
   * @return {Array}             The path array
   */
  var stringToPath = function (path) {
    // If the path isn't a string, return it
    if (typeof path !== 'string') return path

    // Create new array
    var output = []

    // Split to an array with dot notation
    path.split('.').forEach(function (item) {
      // Split to an array with bracket notation
      item.split(/\[([^}]+)\]/g).forEach(function (key) {
        // Push to the new array
        if (key.length > 0) {
          output.push(key)
        }
      })
    })

    return output
  }

  // Get the path as an array
  path = stringToPath(path)

  // Cache the current object
  var current = obj

  // For each item in the path, dig into the object
  for (var i = 0; i < path.length; i++) {
    // If the item isn't found, return the default (or null)
    if (!current[path[i]] && current[path[i]] !== 0) {
      return def
    }

    // Otherwise, update the current  value
    current = current[path[i]]
  }

  return current
}

export function compareArrays(array1, array2) {
  return (
    array1.length === array2.length &&
    array1.every(function (value, index) {
      return value === array2[index]
    })
  )
}

export function formatDate(myDate) {
  if (myDate instanceof Array) {
    let dates = []
    myDate.forEach((d) => {
      const date = new Date(d.value)
      const month = date.getMonth()
      const year = date.getFullYear()

      const options = {}
      if (d.id) {
        options.id = d.id
      }

      dates.push({ date: d.value, month: MONTHS[month], year, ...options })
    })

    return dates
  } else {
    const date = new Date(myDate)
    const month = date.getMonth()
    const year = date.getFullYear()

    const options = {}
    if (myDate.id) {
      options.id = myDate.id
    }

    return { date: myDate.value, month: MONTHS[month], year, ...options }
  }
}

// hh:mm:ss (01:30:00 => 1.5)
// 1:00 -> 1
// 30 -> 0.5
export const timeToNumber = (time) => {
    if(time.indexOf(':') > 0) {
        const [h, m] = time.split(':')
        return (Number(h) * 60 + Number(m)) / 60
    } else {
        return Number(time) / 60
    }  
}

// h.m (1.5 => 1:30)
export const numberToTime = (num) => {
  const [h, m] = num.toString().split('.')
  const minutes = Number(m) > 0 ? Number(`0.${m}`) : 0
  const convertMinutes = minutes === 0 ? '00' : (60 * minutes).toFixed(0)
  return `${h}:${convertMinutes}`
}
// date to yyyy/mm/dd
export const getDateWithoutTime = (dateParams, separator = '/') => {
  // ensure dateParams is date
  let date = new Date(dateParams)
  let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  return `${date.getFullYear()}${separator}${month}${separator}${day}`
}

export const roundWithTwoDecimals = (num) => {
  return Math.round(num * 100) / 100
}

// 1 Janvier 2020
export const dateFormat = (d) => {
  let date = new Date(d)
  let day = date.getDate()
  let month = date.getMonth()
  let year = date.getFullYear()
  return `${day} ${MONTHS[month]} ${year}`
}

export const twoDecimalPlace = (number) => {
  return (Math.round(number * 100) / 100).toFixed(2)
}

// Capitalize
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

// Compare 2 dates
export const compareDates = (one, second) => {
  const dateOne = new Date(one)
  dateOne.setHours(0, 0, 0, 0)
  const secondDate = new Date(second)
  secondDate.setHours(0, 0, 0, 0)

  if (dateOne.getTime() === secondDate.getTime()) {
    return true
  } else {
    return false
  }
}

/**
 * 
 * @param {date} previous 
 * @param {date} current 
 * @param {function} cb 
 * @returns boolean
 */
export const isSchoolingyearChange = ({ previous, current, cb }) => {
  // Trigger when passing to July from August, and passing to September from August
  if (
    previous.getMonth() + 1 === 8 &&
    current.getMonth() + 1 === 9
  ) {
    const d = `${current.getFullYear()}-${current.getFullYear() + 1}`
    cb(d)
    return true
  } else if (
    previous.getMonth() + 1 === 9 &&
    current.getMonth() + 1 === 8
  ) {
    const d = `${current.getFullYear() - 1}-${current.getFullYear()}`
    cb(d)
    return true
  }
  return false
}

/**
 * @param {date} previous 
 * @param {date} current 
 * @param {function} cb 
 * @param {String|date} params 
 */
export const isYearChange = ({ previous, current, cb, params }) => {
  // Trigger when passing to January from Decembre, and to Decembre from January

  if (
    previous.getMonth() + 1 === 12 &&
    current.getMonth() + 1 === 1
  ) {
    cb(params)
  } else if (
    previous.getMonth() + 1 === 1 &&
    current.getMonth() + 1 === 12
  ) {
    cb(params)
  }
}

export const whichSchoolingYear = (date) => {
  // 2021-09-01 : current: 2021, next: 2022
  // 2022-06-01 : current: 2021, next: 2022
  const d = new Date(date)
  let current, next
  if (d.getMonth() + 1 > 8) {
    current = d.getFullYear()
    next = d.getFullYear() + 1
  } else {
    current = d.getFullYear() - 1
    next = d.getFullYear()
  }

  return {
    current,
    next
  }
}

/**
 * 
 * @param {Array[String]} disabled [] - wss, publicHolidays, holidays, beforeSchool, afterSchool, beforeNow
 * @param {Date} day 
 * @param {Object} datas { ?publiHolidays[], ?holidays[], ?custom[]} 
 */
export const daysToDisabled = ({
  disabled,
  day,
  datas = {
    publicHolidays: [],
    holidays: [],
    custom: []
  }
}) => {

  // disable saturday, sunday, and wednesday
  if (disabled.find(key => key === 'wss')) {
    const d = day.getDay()
    if (d === 0 || d === 6 || d === 3) return true
  }

  // disable public holidays
  if (
    disabled.find(key => key === "publicHolidays") &&
    datas && Array.isArray(datas.publicHolidays) &&
    datas.publicHolidays.length > 0
  ) {
    let inPublicDay = datas.publicHolidays.filter(holiday => {
      return compareDates(holiday.date, day)
    })
    if (inPublicDay.length > 0) return true
  }

  // disable holidays
  if (
    disabled.find(key => key === 'holidays') &&
    datas && Array.isArray(datas.holidays) &&
    datas.holidays.length > 0
  ) {
    let inHoliday = datas.holidays.map(holiday => {
      if (holiday.start.getTime() === holiday.end.getTime()) {
        return true
      }

      if (holiday.description === "Vacances d'Été" &&
        day.getTime() > holiday.start.getTime()
      ) {
        return true
      }
      if (
        day.getTime() >= holiday.start.getTime() &&
        day.getTime() <= holiday.end.getTime()
      ) {
        return true
      }
      return false
    })

    if (inHoliday.find(h => h === true)) return true
  }

  // disable custom
  if (
    datas && datas.custom && datas.custom.length > 0
  ) {
    let shouldDisable = datas.custom.map(date => {
      return compareDates(date, day)
    })
    if (shouldDisable.find(d => d === true)) return true
  }

  // disable before child start school
  if (
    disabled.find(key => key === 'beforeSchool') &&
    datas && datas.beforeSchool
  ) {
    if (day.getTime() <= datas.beforeSchool.getTime()) return true
  }

  // disable before today
  if (disabled.find(key => key === 'beforeNow')) {
    let today = new Date()
    today.setHours(0, 0, 0, 0)
    day.setHours(0, 0, 0, 0)
    if (day.getTime() < today.getTime()) return true
  }

  // disable after child end school
  if (disabled.find(key => key === 'afterSchool')) {
    const endSchool = new Date()
    if (endSchool.getMonth() + 1 >= 8) {
      endSchool.setFullYear(endSchool.getFullYear() + 1)
    }
    endSchool.setMonth(7)
    endSchool.setDate(10)
    if (day.getTime() >= endSchool.getTime()) {
      return true
    } else {
      return false
    }
  }
}

/**
 * @param {Object} previous 
 * @param {Object} current 
 * @param {?String[]} ignored 
 * @returns {Boolean}
 */
export const isFormChanged = (previous, current, ignored = []) => {
  previous = Object.assign({}, previous)
  current = Object.assign({}, current)

  ignored.forEach(key => {
    delete previous[key]
    delete current[key]
  })

  let previousKeys = Object.keys(previous)
  let currentKeys = Object.keys(current)

  if (previousKeys.length !== currentKeys.length) {
    return true
  }

  let changed = previousKeys.map(k => {
    if (previous[k] === current[k]) {
      return false
    } else {
      return true
    }
  })

  if (changed.includes(true)) {
    return true
  } else {
    return false
  }
}

export const disableTwoDayBefore = (dates) => {
  return dates.filter(d => {
    const max = new Date()
    max.setDate(max.getDate() + 2)
    if (d.getTime() > max.getTime()) {
      return true
    } else {
      return false
    }
  }).length > 0 ? true : false
}

export const disableBeforeToday = (dates) => {
  return dates.filter(d => {
    d.setHours(0, 0, 0, 0)
    const max = new Date()
    max.setHours(0, 0, 0, 0)
    if (d.getTime() >= max.getTime()) {
      return true
    } else {
      return false
    }
  }).length > 0 ? true : false
}

import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={21}
      height={18}
      viewBox="0 0 21 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"restore"}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M-1-3h24v24H-1z" />
        <path
          d="M12 0a9 9 0 00-9 9H0l4 3.99L8 9H5c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.954 8.954 0 0012 18a9 9 0 000-18zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V5H11z"
          fill="#1D1D1D"
        />
      </g>
    </svg>
  )
}

export default SvgComponent

import React, { useState, useEffect } from 'react'
import BillWritable from '../components/BillWritable'
import Layout from 'components/Layout'
import LayoutAdmin from 'components/LayoutAdmin'
import { useHistory, useLocation } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import StepButtons from 'components/common/StepButtons'
import { useTranslation } from 'react-i18next'
import useBill from 'hooks/useBill'
import useRegistration from 'hooks/useRegistration'
import { BILL_STATUS, BILL_TYPE, USER_ROLE } from '../lib/constants'
import Confirm from '../components/common/ConfirmModal'

export default function BillCreationPage(props) {
  const history = useHistory()
  const location = useLocation()
  const { setSuccessMessage, setErrorMessage } = useNotify(
    ({ setSuccessMessage, setErrorMessage }) => ({
      setSuccessMessage,
      setErrorMessage,
    })
  )

  const [bill, setBill] = useState()
  const [faNameExist, setFaNameExist] = useState(false)
  const [billData, setBillData] = useState()
  const [role, setRole] = useState()
  const [registrationId, setRegistrationId] = useState()
  const [editCreditNote, setEditCreditNote] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [recreate, setRecreate] = useState(false)

  const {
    payBill,
    getBill,
    generateBillWithData,
    getBills,
    updateBill,
    createCreditNote,
    reCreateBill,
  } = useBill()
  const { getRegistration } = useRegistration()
  const { t } = useTranslation()

  useEffect(() => {
    async function loadDatas(id, summary) {
      try {
        const bill = await getRegistration(id)
        setBill(bill.results)
        setRole(props.role)
      } catch (err) {
        setErrorMessage(t(err.message))
        history.replace('/dashboard')
        return
      }
    }
    try {
      const { summary, registrationId, recreate = false } = location.state
      setRecreate(recreate)

      if (registrationId) {
        setRegistrationId(registrationId)
        loadDatas(registrationId, summary)
      }
      // if (id) {

      // }
    } catch (err) {
      setErrorMessage(
        t('Impossible de récupérer les informations de cette facture')
      )
      return
    }
  }, [
    getBill,
    location.state,
    props.role,
    setErrorMessage,
    t,
    history,
    getRegistration,
  ])

  const onPayBill = async () => {
    try {
      const pay = await payBill(bill.id)
      if (pay.error) {
        setErrorMessage(t(pay.error))
        return
      }

      if (pay.results && pay.results.url) {
        window.location = pay.results.url
      }
    } catch (err) {
      setErrorMessage(t(err.message))
    }
  }

  const onSubmit = async (datas) => {
    setIsLoading(true)
    try {
      datas.originalType = bill?.type
      datas.companyId = bill?.BillUsers[0].CompanyId
      datas.originalBillId = bill?.id

      if (recreate) {
        datas.quantity = datas.qte
        delete datas.qte
        const newBill = await reCreateBill(registrationId, bill?.type, datas)
        if (newBill.results) {
          setSuccessMessage('Facture créée')
          setIsLoading(false)
          history.push('/bills')
        }
      } else {
        const creditnote = await createCreditNote(datas, registrationId)
        if (creditnote.results) {
          setSuccessMessage(t('Avoir créé'))
          const bill = await getBill(creditnote.results.billItem.BillId)
          setBill(bill.results)
          setEditCreditNote(false)
          setIsLoading(false)
        }
      }
    } catch (err) {
      setErrorMessage(t('Impossible de créer le document'))
      setIsLoading(false)
    }
  }

  const toggleBillCreation = () => {
    var billDatas = {
      amount: billData?.prixHT,
      quantity: billData?.qtt,
      designationType: billData?.type !== undefined ? billData?.type : null,
      designation:
        billData?.designtype !== undefined ? billData?.designtype : null,
      info: billData?.info,
      paymentTerms: billData?.paymentTerms,
      originalType: '',
      companyId: 0,
      originalBillId: 0,
      FA_NAME: billData?.name !== undefined ? billData?.name : null,
      parent: billData?.parent !== undefined ? billData?.parent : null,
      years:
        billData?.name?.split('-')[1] !== undefined
          ? billData?.name.split('-')[1]
          : null,
    }
    if (
      billDatas?.amount !== undefined &&
      billDatas?.quantity !== undefined &&
      billData?.name !== undefined
    ) {
      setEditCreditNote(!editCreditNote)
      getBills().then((res) => {
        var i = 0
        var exist = false

        // Checking if the bill name already exist in Database
        while (i < res.results.length) {
          if (res.results[i].name === billDatas.FA_NAME) {
            exist = true
            setFaNameExist(true)
          }
          i = i + 1
        }

        if (exist !== null && exist === false) {
          onSubmitEditing(billDatas)
        }
      })
    } else {
      setRecreate(false)
      setErrorMessage('Veuillez remplir tous les champs correctement.')
      if (billData?.name === undefined) {
        setErrorMessage('Veuillez saisir le numéro de la facture.')
      }
    }
  }

  const onSubmitEditing = async (data) => {
    try {
      const createBill = await generateBillWithData(
        bill.id,
        data?.designationType ?? BILL_TYPE.registration,
        data
      )
      if (createBill.error) {
        setErrorMessage(t(createBill.error.message))
        return
      }
      setBill(createBill.results)
      setSuccessMessage(t('Modification effectuée'))
    } catch (err) {
      setErrorMessage(t(err.message))
    }
  }

  const onEditingCreditNote = (billChange) => {
    setBill({ ...bill, ...billChange })
  }

  const onCancelBill = async () => {
    if (await Confirm("Êtes-vous sûr d'annuler cette facture ?")) {
      try {
        const cancelBill = await updateBill(bill?.id, {
          status: BILL_STATUS.cancel,
        })
        if (cancelBill.results) {
          setSuccessMessage('La facture à été annulée')
          setBill(cancelBill.results)
        }
      } catch (err) {
        console.log('err', err)
        setErrorMessage("Impossible d'annuler la facture")
      }
    }
  }

  const onRecreate = () => {
    setRecreate(!recreate)
  }
  return (
    <>
      {role === USER_ROLE.ADMIN ? (
        <LayoutAdmin
          location={location}
          buttonGoHome={{ to: '/bills', history }}
        >
          {bill && (
            <>
              <BillWritable
                bill={billData}
                faNameExist={faNameExist}
                setBillData={setBillData}
                child={location.state.summary?.child}
                userList={location.state.summary?.users}
                creditNote={editCreditNote}
                onCreateCreditNote={onSubmit}
                role={role}
                onSubmitEditing={
                  editCreditNote ? onEditingCreditNote : onSubmitEditing
                }
                isLoading={isLoading}
                onCancelBill={onCancelBill}
                recreate={recreate}
                onRecreate={onRecreate}
                setErrorMessage={setErrorMessage}
              />
              <div className="flex w-full justify-center align-center px-4">
                <div className="flex xl:w-1/2 sm:w-full w-full flex-col justify-center items-center">
                  {!editCreditNote ? (
                    <StepButtons
                      nextLabel={
                        !recreate ? 'Créer une facture' : 'Recréer la facture'
                      }
                      onNext={toggleBillCreation}
                      goBack={() => history.goBack()}
                    />
                  ) : (
                    <StepButtons
                      variant="danger"
                      nextLabel={
                        !recreate
                          ? 'Annuler la création de la facture'
                          : 'Créer une facture'
                      }
                      onNext={() => toggleBillCreation()}
                      goBack={() => history.goBack()}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </LayoutAdmin>
      ) : (
        <Layout
          buttonGoHome={{ to: '/dashboard', history }}
          title={t('Facture')}
        >
          <BillWritable bill={bill} onPayBill={onPayBill} />
        </Layout>
      )}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Input from 'components/form/Input'
import DatePicker from '../components/form/Date'
import StepButtons from './common/StepButtons'
import ProgressBar from '../components/ProgressBar'
import File from 'components/form/File'
import Spinner from 'components/common/Spinner'
import Radio from 'components/form/Radio'

import { useTranslation } from 'react-i18next'
import Button from './form/Button'

export default function Child({
  onSubmit,
  currentValue,
  goBack,
  registrationId,
  files,
  loading,
  company,
  onDownloadFile,
  photo,
  onRemovePhoto,
  setGoSummary,
  fromSummary,
}) {

  const form = useForm()
  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
  } = form
  const [preview, setPreview] = useState(null)

  const { t } = useTranslation()

  const childPhoto = watch('childPhoto')

  useEffect(() => {
    if (childPhoto) {
      setPreview(childPhoto[0]?.preview?.url)
    } else {
      setPreview(null)
    }
    if (Array.isArray(childPhoto) && childPhoto.length === 0) {
      setValue('childPhoto', undefined)
      onRemovePhoto()
    }
  }, [childPhoto, getValues, onRemovePhoto, setValue, watch])

  const gender = [
    { label: t('Garçon'), value: 'male' },
    { label: t('Fille'), value: 'female' },
  ]

  const nap = [
    { label: t('Oui'), value: 'true' },
    { label: t('Non'), value: 'false' },
  ]

  return (
    <>
      <ProgressBar
        step={2}
        name={t('Votre enfant')}
        registrationId={registrationId}
        company={company}
      />

      <div className="flex items-center justify-center">
        <form
          className="form-container w-full sm:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="font-black mb-4 text-center">{t('Votre enfant')}</div>
          {!currentValue && <Spinner></Spinner>}
          {currentValue && (
            <>
              <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
                <div className="md:w-1/2 md:pr-2">
                  <Input
                    required
                    key={'firstname'}
                    ref={register({ required: t('Champ obligatoire') })}
                    type="text"
                    name="firstname"
                    label={t('Prénom')}
                    placeholder={t('Prénom')}
                    value={watch('firstname')}
                    defaultValue={currentValue?.Child?.firstname}
                    error={errors.firstname && errors.firstname.message}
                    // required={"Champ obligatoire"}
                    autofocus={true}
                  />
                </div>
                <div className="md:w-1/2 md:pl-2">
                  <Input
                    key={'lastname'}
                    ref={register({ required: t('Champ obligatoire') })}
                    type="text"
                    name="lastname"
                    label={t('Nom')}
                    required
                    placeholder={t('Nom')}
                    value={watch('lastname')}
                    defaultValue={currentValue?.Child?.lastname}
                    error={errors.lastname && errors.lastname.message}
                  // required={"Champ obligatoire"}
                  />
                </div>
              </div>
              <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
                <div className="md:w-1/2 md:pr-2 w-full">
                  <DatePicker
                    key={'birthday'}
                    ref={register({ required: 'Champ obligatoire' })}
                    name={'birthday'}
                    label={t('Date de naissance')}
                    form={form}
                    required
                    placeholder={'Date de naissance'}
                    defaultValue={currentValue.Child?.birthday}
                    error={errors.birthday && errors.birthday.message}
                    className="bg-gray-200 rounded w-full"
                  />
                </div>
                <div className="md:w-1/2 md:pl-2 w-full">
                  <Input
                    key={'nationality'}
                    required
                    ref={register({ required: t('Champ obligatoire') })}
                    type="text"
                    name="nationality"
                    label={t('Nationalité')}
                    placeholder={t('Nationalité')}
                    value={watch('nationality')}
                    error={errors.nationality && errors.nationality.message}
                    defaultValue={currentValue?.Child?.nationality}
                  />
                </div>
              </div>
              <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
                <div className="md:w-1/2 md:pr-2 w-full">
                  <Input
                    key={'primaryLanguage'}
                    ref={register({ required: t('Champ obligatoire') })}
                    type="text"
                    required
                    name="primaryLanguage"
                    label={t('Langue maternelle')}
                    placeholder={t('Langue maternelle')}
                    value={watch('primaryLanguage')}
                    error={
                      errors.primaryLanguage && errors.primaryLanguage.message
                    }
                    defaultValue={currentValue?.Child?.primaryLanguage}
                  />
                </div>
                <div className="md:w-1/2 md:pl-2 w-full">
                  <Input
                    key={'talkLanguage'}
                    required
                    ref={register({ required: t('Champ obligatoire') })}
                    type="text"
                    name="talkLanguage"
                    label={t('Langue(s) parlée(s)')}
                    placeholder={t('Langue(s) parlée(s)')}
                    value={watch('talkLanguage')}
                    error={errors.talkLanguage && errors.talkLanguage.message}
                    defaultValue={currentValue?.Child?.talkLanguage}
                  />
                </div>
              </div>

              <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
                <Radio
                  input={{ label: t('Sexe') }}
                  inputs={gender}
                  key={JSON.stringify(currentValue?.Child.sex)}
                  form={form}
                  defaultValue={currentValue?.Child?.sex || gender[0]?.value}
                  name="sex"
                  label={t('Sexe')}
                />
              </div>

              <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
                <Radio
                  input={{ label: t('Sieste en après-midi') }}
                  inputs={nap}
                  key={JSON.stringify(currentValue?.Child.nap)}
                  form={form}
                  defaultValue={currentValue?.Child?.nap || nap[0]?.value}
                  name="nap"
                  label={t('Sieste en après-midi ?')}
                />
              </div>

              <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
                {photo && photo.path && photo.type === 'image' && (
                  <div className="md:w-1/2 md:pl-2 w-full flex justify-center align-center p-2">
                    <img
                      alt={t("Photo de l'enfant")}
                      src={`data:${photo.type};base64, ${photo.path}`}
                      className="object-contain"
                    ></img>
                  </div>
                )}
                {preview && (
                  <div className="md:w-1/2 md:pl-2 w-full flex justify-center align-center p-2">
                    <img
                      src={preview}
                      alt={t("Photo de l'enfant")}
                      className="object-contain"
                    ></img>
                  </div>
                )}
                <div className="md:w-1/2 md:pr-2 w-full">
                  <Controller
                    key={JSON.stringify(`files/${files}`)}
                    name="childPhoto"
                    control={control}
                    rules={{
                      max: { value: 1, message: 'Too much files' },
                    }}
                    defaultValue={files && files.length > 0 && files}
                    render={(props, name) => {
                      return (
                        <>
                          <File
                            name={name}
                            label={t("Photo de l'enfant")}
                            className="files-dropzone"
                            form={form}
                            {...props}
                            options={{ limit: 1 }}
                            onDownloadFile={onDownloadFile}
                          />
                          {errors && errors['childPhoto'] && (
                            <div className="text-sm text-red-500">
                              {errors['childPhoto'].message}
                            </div>
                          )}
                        </>
                      )
                    }}
                  />
                </div>
              </div>

              {fromSummary &&  childPhoto && (
                <Button
                  loading={loading}
                  submits
                  onClick={() => {
                    setGoSummary(true)
                  }}
                >
                  {t("Valider et retourner sur le récapitulatif")}
                </Button>
              )}

              <StepButtons loading={loading} goBack={goBack} />
            </>
          )}
        </form>
      </div>
    </>
  )
}

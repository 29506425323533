import React from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
// import EyeIcon from "components/svg/eye"
import EditIcon from 'components/svg/edit'

const dateFmt = (value, format = 'DD-MM-YYYY') => {
  return dayjs(value).format(format)
}

const editLinkFmt = (to) => {
  return (
    <Link to={to} className="text-2xl text-blue-500">
      <EditIcon className="fill-current" />
    </Link>
  )
}

const currencyFmt = (amount, currency = '€') => {
  return `${amount || 0} ${currency}`
}

export { dateFmt, editLinkFmt, currencyFmt }

import React, { useCallback } from 'react'

export default function DashboardLink({ onClick, disabled, children }) {
  const className = disabled
    ? 'text-gray-500 cursor-not-allowed'
    : 'hover:text-blue-600 hover:cursor-pointer'

  const handleClick = useCallback(
    (...args) => {
      if (!disabled && onClick) onClick(...args)
    },
    [disabled, onClick]
  )

  return (
    <button
      className={`block py-1 text-left ${className}`}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}

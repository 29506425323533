import React, { useEffect, useRef } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import Input from 'components/form/Input'
import Date from 'components/form/Date'
import ReactToPrint from 'react-to-print'
import montessori from 'assets/images/logo_ecole_montessori_pau.jpg'
import { REGISTRATION_STATUS } from 'lib/constants'
import StepButtons from './common/StepButtons'
import { useTranslation } from 'react-i18next'
import UserIcon from 'components/svg/user'

export default function Summary({
  summary,
  validRegistration,
  goBack,
  photo,
  role,
  onSendChildPhoto,
}) {
  const form = useForm({
    defaultValues: {
      parents: [{}],
    },
  })
  const { t } = useTranslation()
  const { control, reset } = form
  const { fields } = useFieldArray({ control, name: 'parents' })

  useEffect(() => {
    if (summary?.users) {
      let p = []
      summary.users.forEach((user) => {
        p.push({ ...user })
      })
      reset({
        parents: [...p],
      })
    }
  }, [summary, reset])

  const componentToPrint = useRef()

  return (
    <>
      <div className="flex items-center justify-center mt-4 print:mt-2 w-full">
        <form
          className={`form-container w-full ${
            role === 'admin' ? 'lg:w-3/4' : 'sm:w-1/2'
          }`}
        >
          {summary && (
            <>
              <div className="flex w-full md:flex-row flex-col my-1 justify-between">
                <div className="font-black mb-4 text-center">
                  {t('Récapitulatif')}
                </div>
                <div className="font-black mb-4 text-center">
                  <ReactToPrint
                    trigger={() => (
                      <div className="hover:text-blue-600 cursor-pointer">
                        {t('Imprimer')}
                      </div>
                    )}
                    content={() => componentToPrint.current}
                    bodyClass={''}
                    documentTitle={t('Récapitulatif')}
                    pageStyle="
                    @media all {
                      .page-break {
                        display: none;
                      }
                    }
         
                    @media print {
                      html, body {
                        height: initial !important;
                        -webkit-print-color-adjust: exact;
                      }

                      .page-break {
                        margin-top: 1rem;
                        display: block;
                        background-color: blue;
                        page-break-before: auto;
                      }
                    }
                    
                    @page {
                      size: auto;
                      margin: 20mm;
                    }
                    "
                  />
                </div>
              </div>

              <div ref={componentToPrint} className="w-full">
                {/* TOP SECTION */}
                <div className="flex justify-center">
                  <img
                    src={montessori}
                    alt="montessory-logo"
                    style={{ width: '200px' }}
                    className="logo"
                  ></img>
                </div>
                <div className="flex w-full md:flex-row my-1 justify-evenly items-center">
                  <div className="font-black mb-3 md:text-left w-full">
                    {t('Scolarité')}
                  </div>
                  <div className="font-black mb-3 text-right w-full">
                    {t('École Montessori de Pau')}
                  </div>
                </div>
                <div className="w-full flex justify-between align-center">
                  {photo && /image/.test(photo.type) ? (
                    <div className="w-1/3 flex justify-center">
                      <img
                        alt={t("Photo de l'enfant")}
                        src={`data:${photo.type};base64, ${photo.path}`}
                        className="object-contain"
                      ></img>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <UserIcon height="5em" width="5em" />
                    </div>
                  )}
                  <div className="flex w-full flex-col ml-2 pl-2">
                    <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                      <div className="w-full mb-3">
                        <Date
                          name={'schoolingyear'}
                          label={t('Date de la scolarité')}
                          defaultValue={summary?.date}
                          form={form}
                          disabled
                          className="w-full print:w-auto"
                        />
                      </div>
                    </div>

                    <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                      <div className="w-full mb-3">
                        <Input
                          key={'schoolclass'}
                          type="text"
                          name="schoolclass"
                          label={t('Classe')}
                          value={summary?.registration?.SchoolClass?.name}
                          disabled
                          inputClassName="print:w-1/2"
                        />
                      </div>
                    </div>

                    {!summary?.company && (
                      <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                        <div className="w-full mb-3">
                          <Input
                            key={'registrationFees'}
                            type="text"
                            name="registrationFees"
                            label={t('Frais de scolarité')}
                            value={summary?.registration?.registrationFees}
                            disabled
                            inputClassName="print:w-1/2"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full my-4 border border-gray-200"></div>

                {/* CHILD SECTION */}
                <div className="font-black text-left w-full">
                  {t("L'enfant")}
                </div>
                <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                  <div className="w-full mb-3">
                    <Input
                      type="text"
                      label={t('Prénom')}
                      placeholder=""
                      value={summary?.child?.firstname}
                      disabled
                    />
                  </div>
                  <div className="w-full mb-3">
                    <Input
                      type="text"
                      label={t('Nom')}
                      placeholder=""
                      value={summary?.child?.lastname}
                      disabled
                    />
                  </div>
                </div>
                <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                  <div className="w-full mb-3">
                    <Date
                      name={'birthday'}
                      label={t('Date de naissance')}
                      defaultValue={summary?.child?.birthday}
                      form={form}
                      className="w-full print:w-auto"
                      disabled
                    />
                  </div>
                  <div className="w-full mb-3">
                    <Input
                      type="text"
                      label={t('Nationalité')}
                      placeholder=""
                      value={summary?.child?.nationality}
                      disabled
                    />
                  </div>
                </div>
                <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                  <div className="w-full mb-3">
                    <Input
                      type="text"
                      label={t('Sexe')}
                      placeholder=""
                      value={
                        !summary?.child?.sex
                          ? ''
                          : summary?.child?.sex === 'male'
                          ? t('Garçon')
                          : t('Fille')
                      }
                      disabled
                    />
                  </div>
                  <div className="w-full mb-3">
                    <Input
                      type="text"
                      label={t('Sieste')}
                      placeholder=""
                      value={
                        !summary?.child?.nap
                          ? ''
                          : summary?.child?.nap
                          ? t('Oui')
                          : t('Non')
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                  <div className="w-full mb-3">
                    <Input
                      type="text"
                      label={t('Langue maternelle')}
                      placeholder=""
                      value={summary?.child?.primaryLanguage}
                      disabled
                    />
                  </div>
                </div>
                <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                  <div className="w-full mb-3">
                    <Input
                      type="text"
                      label={t('Langue(s) parlée(s)')}
                      placeholder=""
                      value={summary?.child?.talkLanguage}
                      disabled
                    />
                  </div>
                </div>
                <div className="w-full my-4 border border-gray-200"></div>

                {/* PARENT SECTION */}
                <div className="font-black text-left w-full">
                  {t('Parents')}
                </div>
                {fields.map((field, index) => {
                  return (
                    <div
                      key={index}
                      className="flex w-full md:flex-col flex-col"
                    >
                      {fields.length > 1 && (
                        <span className="text-sm">
                          {t('Parent')} {index + 1}
                        </span>
                      )}

                      <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                        <div className="w-full mb-3">
                          <Input
                            key={`parents[${index}].firstname`}
                            type="text"
                            name={`parents[${index}].firstname`}
                            label={t('Prénom')}
                            placeholder={t('Prénom')}
                            defaultValue={field?.firstname}
                            disabled
                          />
                        </div>
                        <div className="w-full mb-3">
                          <Input
                            key={`parents[${index}].lastname`}
                            type="text"
                            name={`parents[${index}].lastname`}
                            label={t('Nom')}
                            placeholder={t('Nom')}
                            defaultValue={field?.lastname}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                        <div className="w-full mb-3">
                          <Input
                            key={`parents[${index}].tel`}
                            type="text"
                            name={`parents[${index}].tel`}
                            label={t('Téléphone')}
                            placeholder="xx/xx/xx/xx/xx"
                            defaultValue={field?.tel}
                            disabled
                          />
                        </div>
                        <div className="w-full mb-3">
                          <Input
                            key={`parents[${index}].profession`}
                            type="text"
                            name={`parents[${index}].profession`}
                            label={t('Profession')}
                            placeholder={t('Profession')}
                            defaultValue={field?.profession}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                        <div className="w-full mb-3">
                          <Input
                            key={`parents[${index}].address`}
                            type="text"
                            name={`parents[${index}].address`}
                            label={t('Adresse')}
                            placeholder={t('Adresse')}
                            defaultValue={field?.address}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                        <div className="w-full mb-3">
                          <Input
                            key={`parents[${index}].email`}
                            type="text"
                            name={`parents[${index}].email`}
                            label={t('E-mail')}
                            placeholder={t('E-mail')}
                            defaultValue={field?.email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
                {/* INCOME SECTION */}
                {summary?.company && (
                  <div className="flex w-full md:flex-row flex-row mt-4 print:mt-2 justify-evenly text-sm">
                    {t('Frais de scolarité à la charge de votre société')}
                  </div>
                )}
                {!summary?.company && (
                  <>
                    <div className="w-full my-4 border border-gray-200"></div>
                    <div className="font-black text-left w-full">
                      {t('Vos revenus')}
                    </div>

                    <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                      <div className="w-full mb-3">
                        <Input
                          key={'income'}
                          type="text"
                          name="income"
                          label={t('Impôts sur le revenu')}
                          value={summary?.registration?.income}
                          disabled
                        />
                      </div>
                      <div className="w-full mb-3">
                        <Input
                          key={'allowances'}
                          type="text"
                          name="allowances"
                          label={t('Allocations familiales')}
                          value={
                            summary?.registration?.allowances === 0
                              ? '0'
                              : summary?.registration?.allowances
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                      <div className="w-full truncate">
                        <Input
                          key={'numberDependantsChildren'}
                          type="text"
                          name="numberDependantsChildren"
                          label={t("Nombre d'enfants à charge")}
                          defaultValue={0}
                          value={
                            summary?.registration?.numberDependantsChildren
                          }
                          disabled
                        />
                      </div>
                      <div className="w-full truncate">
                        <Input
                          key={'numberOtherDependants'}
                          type="text"
                          name="numberOtherDependants"
                          defaultValue={0}
                          label={t("Nombre d'autres personnes à charge")}
                          value={summary?.registration?.numberOtherDependants}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                      <div className="w-full mb-3">
                        <Input
                          key={'familyIndex'}
                          type="text"
                          name="familyIndex"
                          label={t('Indice familial')}
                          value={summary?.registration?.familyIndex}
                          disabled
                        />
                      </div>
                      <div className="w-full mb-3">
                        <Input
                          key={'amount'}
                          type="text"
                          name="amount"
                          label={t('Prix par année')}
                          value={summary?.registration?.amount}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="flex w-full print:flex-row flex-col sm:flex-col md:flex-col lg:flex-row justify-evenly gap-2">
                      <div className="w-full mb-3">
                        <Input
                          key={'numberPayment'}
                          type="text"
                          name="numberPayment"
                          label={t('Nombre de paiements')}
                          value={summary?.registration?.numberPayment}
                          disabled
                        />
                      </div>
                      <div className="w-full mb-3">
                        <Input
                          key={'staggering'}
                          type="text"
                          name="amonut"
                          label={t('Échelonnement')}
                          value={
                            Math.round(
                              (summary?.registration?.amount /
                                summary?.registration?.numberPayment) *
                                100
                            ) / 100
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </>
                )}
                {/* end of print */}
              </div>
              {summary?.files && summary.files.length > 0 && (
                <div className="flex w-full flex-col">
                  <div className="text-sm mt-4">
                    {t('Fichier(s) envoyé(s)')} :
                  </div>
                  {summary.files.map((file, index) => (
                    <div key={index} className="text-xs">
                      {file}
                    </div>
                  ))}
                </div>
              )}
              {photo && !photo.path && (
                <div className="flex w-full flex-col mt-4 p-2 bg-red-400 text-sm bg-orange-100 text-orange-700">
                  <p>
                    {t(
                      "Pour finaliser l'inscription, merci d'ajouter une photo de votre enfant"
                    )}
                  </p>
                  <p
                    className="text-blue-600 cursor-pointer"
                    onClick={onSendChildPhoto}
                  >
                    {t("Envoyer la photo de l'enfant")}
                  </p>
                </div>
              )}

              {role !== 'admin' && (
                <StepButtons
                  hideNext={
                    summary?.registration?.status !== REGISTRATION_STATUS.open
                  }
                  nextLabel={t('Soumettre')}
                  goBack={goBack}
                  onNext={validRegistration}
                  disabled={photo && photo.path ? false : true}
                />
              )}
            </>
          )}
        </form>
      </div>
    </>
  )
}

import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Input from 'components/form/Input'
import StepButtons from './common/StepButtons'
import Select from 'components/form/Select'
import { USER_ROLE } from 'lib/constants'


export default function SetUser({ onSubmit, goBack, user, id, loading }) {
  const form = useForm()
  const { register, handleSubmit, errors, reset, watch, control, setValue } = form

  const roles = Object.values(USER_ROLE).map(role => {
    return { value: role, label: role }
  })

  useEffect(() => {
    if (loading && loading.refresh === true) {
      reset()
    }
  }, [loading, reset])

  useEffect(() => {
    if(user){
      setValue('selectedRole', roles.find(r => r.label === user.role))
    }
  }, [user, setValue, roles])
 

  return (
    <>
      <div className="flex items-center justify-center mt-16">
        <form
          className="form-container w-full sm:w-full xl:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="font-black mb-4 text-center">
            {`${!isNaN(id) ? 'Modifier ' : 'Ajouter'} un utilisateur`}
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'lastname'}
              ref={register({ required: 'Champ obligatoire' })}
              type="text"
              name="lastname"
              label="Nom"
              placeholder="Nom"
              error={errors.lastname && errors.lastname.message}
              defaultValue={user && user.lastname}
              value={watch('lastname')}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'firstname'}
              ref={register({ required: 'Champ obligatoire' })}
              type="text"
              name="firstname"
              label="Prénom"
              placeholder="Prénom"
              error={errors.firstname && errors.firstname.message}
              defaultValue={user && user.firstname}
              value={watch('firstname')}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'email'}
              ref={register({
                required: 'Champ obligatoire',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Adresse e-mail non valide',
                }
              })}
              type="text"
              name="email"
              label="E-mail"
              placeholder="E-mail"
              error={errors.email && errors.email.message}
              defaultValue={user && user.email}
              value={watch('email')}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Controller
              control={control}
              key={`role${JSON.stringify(user?.role)}`}
              name={`selectedRole`}
              rules={{ required: "Champ obligatoire" }}
              defaultValue={user && user.role}
              render={({ onChange, value, name, defaultValue }) => (
                <>
                  <Select
                    placeholder={'Rôle'}
                    name={name}
                    className="w-full"
                    label="Rôle"
                    value={value}
                    defaultValue={defaultValue}
                    values={roles}
                    onChange={(e) => {
                      onChange(e)
                    }}
                  />
                </>
              )}
            />
          </div>
          {errors?.role?.message && (
            <div className="flex w-full md:flex-row flex-col">
              <div className="text-sm text-red-500">
                {errors.role.message}
              </div>
            </div>
          )}

          <StepButtons
            loading={loading.current}
            goBack={goBack}
            nextLabel={'Enregistrer'}
          />
        </form>
      </div>
    </>
  )
}

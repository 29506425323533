import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Input from 'components/form/Input'
import StepButtons from './common/StepButtons'
import Select from './form/Select'

export default function SetSubDomain({
  onSubmit,
  goBack,
  subDomain,
  id,
  loading,
  getActivities,
  defaultActivities,
  domains,
}) {
  const form = useForm()
  const {
    register,
    handleSubmit,
    errors,
    reset,
    watch,
    control,
    setValue,
  } = form

  useEffect(() => {
    if (
      loading.prevState === false &&
      loading.current === false &&
      isNaN(Number(id))
    ) {
      reset()
    }
  }, [loading, reset, id])

  useEffect(() => {
    if (domains && subDomain) {
      setValue('domain', subDomain?.Domain?.id)
      console.log();
    }
  }, [domains, subDomain, setValue])

  return (
    <>
      <div className="flex items-center justify-center mt-16">
        <form
          className="form-container w-full sm:w-full xl:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="font-black mb-4 text-center">
            {`${!isNaN(id) ? 'Modifier ' : 'Ajouter'} un sous-domaine`}
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'name'}
              ref={register({ required: 'Champ obligatoire' })}
              type="text"
              name="name"
              label="Nom du sous-domaine"
              placeholder="Nom du sous-domaine"
              error={errors.name && errors.name.message}
              defaultValue={subDomain && subDomain.name}
              value={watch('name')}
            />
          </div>
          
          <div className="flex w-full md:flex-row flex-col justify-evenly">
              <Input
                  key={'sortingRank'}
                  ref={register({ required: 'Champ obligatoire' })}
                  type="number"
                  name="sortingRank"
                  label="Ordre d'affichage"
                  placeholder="Ordre d'affichage"
                  error={errors.name && errors.order.message}
                  defaultValue={subDomain && subDomain.sortingRank}
                  value={watch('sortingRank')}
                />
          </div>


          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Controller
              name="domain"
              placeholder="Domaine"
              control={control}
              render={({ value, name, onChange, defaultValue }) => (
                <div className="flex flex-col w-full">
                  <Select
                    name={name}
                    isSearchable
                    label="Domaine"
                    className="w-full"
                    value={value}
                    onChange={(e) => {
                      if (e) {
                        onChange(e)
                      }
                    }}
                    values={domains}
                  />
                </div>
              )}
            />
          </div>

          <StepButtons
            loading={loading.current}
            goBack={goBack}
            nextLabel={'Enregistrer'}
          />
        </form>
      </div>
    </>
  )
}

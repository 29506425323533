import React, { useEffect, useState, useMemo, useCallback } from 'react'
import DayPicker from 'react-day-picker'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import Select from '../components/form/Select'
import { useNotify } from '../stores/notify'
import Spinner from './common/Spinner'
import {
  getDateWithoutTime,
  isSchoolingyearChange,
  daysToDisabled,
  disableBeforeToday,
} from '../lib/utils'
import { MONTHS } from 'lib/constants'
import { useTranslation } from 'react-i18next'
import Radio from 'components/form/Radio'

const initialForm = { child: '', times: '' }

export default function Nursery({
  updateDay,
  dates,
  modifiers,
  childs,
  onSubmit,
  nurseryList,
  onDeleteNurseryDate,
  onMonthChange,
  times,
  minDate,
  holidays,
  onSchoolingYearChange,
  onYearChange,
  publicHolidays,
  initialMonth,
}) {
  const { t } = useTranslation()
  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))

  const form = useForm({
    defaultValues: {},
  })

  const {
    control,
    errors,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
  } = form
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'childs',
  })

  const [previousDate, setPreviousDate] = useState(new Date())
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [dateFormatted, setDateFormatted] = useState({})
  const [groupSelect, setGroupSelect] = useState(false)
  const [hasReservation, setHasReservation] = useState(false)

  const listSelectionMode = [
    {
      label: t('Jour par jour'),
      value: 'select-1',
    },
    {
      label: t('Par jour de la semaine (tous les lundis...)'),
      value: 'select-2',
    },
  ]

  useEffect(() => {
    if (nurseryList && dates) {
      // get only today's date
      const filter = nurseryList.filter((n) => {
        if (
          getDateWithoutTime(new Date(n.date)) ===
          getDateWithoutTime(new Date(dates))
        ) {
          return true
        }

        return false
      })

      // merge child on same day
      let groupChild = []
      filter.forEach((timetable) => {
        const childExists = groupChild.find(
          (c) => c.ChildId === timetable.ChildId
        )
        if (childExists) {
          childExists.ChildcareHour.push({
            label: `${timetable.ChildcareHour.start}-${timetable.ChildcareHour.end}`,
            value: `${timetable.ChildcareHour.id}`,
          })
        } else {
          groupChild.push({
            date: timetable.date,
            ChildId: timetable.ChildId,
            ChildcareHour: [
              {
                label: `${timetable.ChildcareHour.start}-${timetable.ChildcareHour.end}`,
                value: `${timetable.ChildcareHour.id}`,
              },
            ],
            child: timetable.ChildId,
          })
        }
      })
      setValue('date', dates)

      if (groupChild.length === 0) {
        groupChild.push({ child: childs })
      }

      // if only one date select and it's highlighted, can display children registered this day
      if (dates.length === 1) {
        if (
          nurseryList.find((n) => {
            const date = getDateWithoutTime(dates[0], '-')
            if (date === n.date) {
              return true
            } else {
              return false
            }
          })
        ) {
          reset({
            childs: [...groupChild],
            selectionMode: groupSelect ? 'select-2' : 'select-1',
          })
        }
      }

      // clear if no dates selected, or multiples
      if (dates.length === 0) {
        reset({
          childs: [],
          selectionMode: groupSelect ? 'select-2' : 'select-1',
        })
      }
    }
    // eslint-disable-next-line
  }, [nurseryList, times, reset, setValue, dates])

  useEffect(() => {
    onYearChange(currentYear)
  }, [currentYear, onYearChange])

  let allChildren = watch('childs', [])
  let childsFiltered = useMemo(() => allChildren.map((c) => c.child), [
    allChildren,
  ])

  const disabledDays = useCallback(
    (day) => {
      return daysToDisabled({
        disabled: [
          'wss',
          'publicHolidays',
          'holidays',
          'beforeSchool',
          'beforeNow',
          'afterSchool',
        ],
        day,
        datas: {
          publicHolidays: publicHolidays?.results,
          holidays,
          custom: [
            '2022-05-27', // Missing "Pont de l'ascension"
          ],
          beforeSchool: new Date(minDate),
        },
      })
    },
    //eslint-disable-next-line
    [
      holidays,
      minDate,
      publicHolidays,
      onMonthChange,
      onYearChange,
      onSchoolingYearChange,
      modifiers,
      nurseryList,
      updateDay,
      dates,
      times,
      initialMonth,
    ]
  )

  const deleteDate = useCallback(
    (field, index) => {
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)
      if (Array.isArray(field)) {
        let datesToDelete = field.filter((f) => {
          let d = new Date(f)
          d.setHours(0, 0, 0, 0)
          // Can't delete before today
          if (d.getTime() >= currentDate.getTime()) {
            delete d.id
            return true
          } else {
            return false
          }
        })

        let fields = []
        datesToDelete.forEach((d) => {
          let date = getDateWithoutTime(d, '-')
          fields.push(...nurseryList.filter((n) => n.date === date))
        })

        onDeleteNurseryDate(fields, datesToDelete[datesToDelete.length - 1])
        updateDay('', 'clear')
      } else {
        const selectDate = new Date(field.date)
        selectDate.setHours(12, 0, 0)

        selectDate.setDate(selectDate.getDate() - 2)
        if (selectDate.getTime() >= currentDate.getTime()) {
          delete field.id
          if (!Object.values(field).every((f) => f === '')) {
            onDeleteNurseryDate([field], selectDate)
          }
          remove(index)
        } else if (Number.isNaN(selectDate.getTime())) {
          remove(index)
        } else {
          setErrorMessage(
            t(
              "Il n'est plus possible de supprimer cette inscription à la garderie"
            )
          )
        }
      }
      setHasReservation(false)
    },

    [onDeleteNurseryDate, remove, setErrorMessage, t, nurseryList, updateDay]
  )

  useEffect(() => {
    // group by month
    const format = {}

    dates.forEach((date) => {
      if (!format[date.getMonth() + 1]) {
        format[date.getMonth() + 1] = [date]
      } else {
        format[date.getMonth() + 1].push(date)
      }
    })

    setDateFormatted(format)
  }, [dates, minDate])

  const selectionMode = watch('selectionMode')

  const selectSameDayInMonth = (e) => {
    let date = new Date(e)
    let listDates = [new Date(date)]

    const searchInMonth = (selectedDate, e, mode) => {
      selectedDate = new Date(selectedDate)
      while (selectedDate.getMonth() === e.getMonth()) {
        if (mode === 'ligther') {
          selectedDate.setDate(selectedDate.getDate() - 7)
        } else if (mode === 'greater') {
          selectedDate.setDate(selectedDate.getDate() + 7)
        }
        if (selectedDate.getMonth() === e.getMonth()) {
          listDates.push(new Date(selectedDate))
        }
      }
    }

    // get ligther and greater
    searchInMonth(date, e, 'ligther')
    searchInMonth(date, e, 'greater')

    return listDates
  }

  // select-1: day by day, select-2: all mondays...
  useEffect(() => {
    let value = getValues('selectionMode')
    switch (value) {
      case 'select-1':
        setGroupSelect(false)
        break
      case 'select-2':
        setGroupSelect(true)
        break
      default:
    }
  }, [selectionMode, getValues])

  // Check if user select date with reservation, if true, can display delete button
  useEffect(() => {
    let reservations = dates.filter((d) => {
      return nurseryList.find((n) => n.date === getDateWithoutTime(d, '-'))
    })

    if (reservations && reservations.length > 0) {
      setHasReservation(true)
    } else {
      setHasReservation(false)
    }
  }, [nurseryList, dates])

  const today = new Date()

  return (
    <>
      <div className="flex items-center justify-center mt-20">
        <form
          className="w-full form-container sm:w-full xl:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          {!nurseryList && <Spinner></Spinner>}
          {nurseryList && (
            <>
              <p>
                {t(
                  'Vous pouvez sélectionner une ou plusieurs dates pour inscrire vos enfants à la garderie.'
                )}
                <br />
                <br />
                {t(
                  `Vous pouvez supprimer une réservation jusqu'à 48h avant la date.`
                )}
              </p>

              <div className="flex w-full justify-center">
                <div className="flex w-full mt-8 flex-col justify-center">
                  <div className="font-bold text-center pb-4">
                    {t('Mode de sélection')}
                  </div>
                  <Radio
                    form={form}
                    input={{ label: t('Mode de sélection') }}
                    inputs={listSelectionMode}
                    name="selectionMode"
                    noLabel
                    defaultValue={'select-1'}
                    customClass={
                      ' pr-0 w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 '
                    }
                  />
                </div>
              </div>

              <div className="flex items-center justify-center mb-0 mt-8 w-full bg-gray-100">
                <div className="w-full sm:w-3/5">
                  <Controller
                    control={control}
                    rules={{ required: t('Champ obligatoire') }}
                    name="date"
                    render={({ onChange, value, name }) => (
                      <DayPicker
                        onMonthChange={async (e) => {
                          let date = new Date(e)
                          let prevDate = previousDate
                          const schoolingyearChange = isSchoolingyearChange({
                            previous: prevDate,
                            current: date,
                            cb: onSchoolingYearChange,
                          })
                          if (schoolingyearChange) {
                            updateDay('', 'clear')
                          }
                          onMonthChange(date)
                          setPreviousDate(date)
                          setCurrentYear(date.getFullYear())
                        }}
                        onDayClick={async (e, modifiers) => {
                          const time = new Date(e)
                          e.setHours(time.getHours())
                          if (!groupSelect) {
                            // one by one
                            if (e && modifiers && !modifiers.disabled) {
                              if (
                                dates.find(
                                  (d) => d.getTime() === time.getTime()
                                )
                              ) {
                                updateDay(e, 'remove')
                              } else {
                                updateDay(e)
                              }
                              onChange(time)
                            }
                            if (modifiers.disabled && modifiers.highlighted) {
                              if (
                                dates.find(
                                  (d) => d.getTime() === time.getTime()
                                )
                              ) {
                                updateDay(e, 'remove')
                              } else {
                                updateDay(e)
                              }
                              onChange(time)
                            }
                          } else {
                            let listDates = selectSameDayInMonth(e)
                            // remove disabled
                            listDates = listDates.filter(
                              (date) => !disabledDays(date)
                            )
                            if (
                              listDates.length > 0 &&
                              dates.find((d) =>
                                listDates.find(
                                  (e) => e.getTime() === d.getTime()
                                )
                              )
                            ) {
                              updateDay(listDates, 'remove')
                            } else if (listDates.length > 0) {
                              updateDay(listDates)
                            }
                            onChange(time)
                          }
                        }}
                        name={name}
                        value={value}
                        month={initialMonth}
                        selectedDays={dates}
                        disabledDays={disabledDays}
                        months={t('_listMonth', { returnObjects: true })}
                        modifiers={modifiers}
                        weekdaysShort={t('_weekShort', { returnObjects: true })}
                        className=""
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-start w-full gap-2 text-xs p-4">
                <div className="font-bold">{t('Légende')} :</div>

                <div className="flex align-center items-center gap-2">
                  <div className="h-4 w-4 DayPicker-Day--highlighted border border-black"></div>
                  <div>{t('Jour(s) réservé(s)')}</div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="h-4 w-4 DayPicker-Selected-Color border border-black"></div>
                  <div>{t('Jour(s) sélectionné(s)')}</div>
                </div>
              </div>
              {dates &&
                dates.length > 0 &&
                hasReservation &&
                disableBeforeToday(dates) && (
                  <div>
                    <button
                      onClick={(e) => deleteDate(dates)}
                      className="w-full px-4 py-2 text-white bg-red-500 rounded-lg"
                    >
                      {t('Supprimer les réservations des dates sélectionnées')}
                    </button>
                  </div>
                )}

              {dates && dates.length > 0 && (
                <div className="flex items-center justify-center mb-8 mt-8 w-full bg-gray-100 py-2">
                  <div className="w-full sm:w-3/5">
                    <div>
                      {t('Ajouter un horaire pour le')}
                      {dateFormatted && (
                        <>
                          {Object.keys(dateFormatted).map((date, index) => (
                            <div key={index} className={`flex flex-wrap`}>
                              {dateFormatted[date]
                                .map((d) => d.getDate())
                                .sort((a, b) => a - b)
                                .map((u, i) => {
                                  return (
                                    <div
                                      key={`${date}-${u}`}
                                      className={`${
                                        Number(date) === today.getMonth() + 1 &&
                                        Number(u) < today.getDate()
                                          ? 'text-yellow-600'
                                          : ''
                                      } `}
                                    >
                                      {u}
                                      {dateFormatted[date].length === i + 1 ? (
                                        <span>&nbsp;</span>
                                      ) : (
                                        ','
                                      )}
                                    </div>
                                  )
                                })}
                              {t(`${MONTHS[date - 1]}`)}
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                    {fields.map((field, index) => {
                      let isRequired = { required: t('Champ obligatoire') }
                      return (
                        <div key={field.id}>
                          <Controller
                            control={control}
                            defaultValue={field.ChildId}
                            rules={isRequired}
                            name={`childs[${index}].child`}
                            value={field.ChildId}
                            render={({ onChange, value, name }) => (
                              <>
                                <Select
                                  placeholder={t('Enfant')}
                                  name={name}
                                  className="w-full"
                                  label={t('Enfant')}
                                  value={value}
                                  // disabled already selected childs, except this one
                                  values={childs.filter(
                                    (c) =>
                                      !childsFiltered.includes(c.value) ||
                                      c.value === value
                                  )}
                                  onChange={(e) => {
                                    onChange(e && e.value)
                                  }}
                                />
                                {errors &&
                                  errors.childs &&
                                  errors.childs.length > 0 &&
                                  errors.childs[index]?.child?.message && (
                                    <div className="text-sm text-red-500">
                                      {errors.childs[index].child.message}
                                    </div>
                                  )}
                              </>
                            )}
                          />
                          <Controller
                            control={control}
                            rules={isRequired}
                            defaultValue={field.ChildcareHour}
                            value={field.ChildcareHour}
                            name={`childs[${index}].times`}
                            render={({ onChange, value, name }) => (
                              <>
                                <Select
                                  placeholder={t('Créneau')}
                                  name={name}
                                  className="w-full"
                                  label={t('Créneau')}
                                  value={value}
                                  values={times.filter((t) => {
                                    const slots = getValues(
                                      `childs[${index}].times`
                                    )
                                    if (slots && slots.length) {
                                      const a = slots.find(
                                        (slot) => slot.value === '2'
                                      )
                                      if (a && t.value === '3') return false
                                      const b = slots.find(
                                        (slot) => slot.value === '3'
                                      )
                                      if (b && t.value === '2') return false
                                    }
                                    return true
                                  })}
                                  isMulti
                                  onChange={(e) => {
                                    onChange(e)
                                  }}
                                />
                                {errors &&
                                  errors.childs &&
                                  errors.childs.length > 0 &&
                                  errors.childs[index]?.times?.message && (
                                    <div className="text-sm text-red-500">
                                      {errors.childs[index].times.message}
                                    </div>
                                  )}
                              </>
                            )}
                          />
                          <div className="w-full mb-4 border border-gray-600 mt-8"></div>
                        </div>
                      )
                    })}

                    {fields &&
                      fields.length < childs.length &&
                      disableBeforeToday(dates) && (
                        <div className="flex justify-center w-full mt-8">
                          <button
                            type="button"
                            onClick={() => append({ ...initialForm })}
                            className="w-full sm:w-1/3 px-4 py-3 text-white bg-blue-500 rounded-lg"
                          >
                            {t('Ajouter')}
                          </button>
                        </div>
                      )}

                    {fields &&
                      fields.length === childs.length &&
                      disableBeforeToday(dates) && (
                        <div className="flex justify-center w-full mt-8">
                          <button
                            type="button"
                            onClick={() => remove(fields.length - 1)}
                            className="w-full sm:w-auto px-4 py-3 text-center text-white bg-red-500 rounded-lg"
                          >
                            {t('Supprimer')}
                          </button>
                        </div>
                      )}

                    {fields && fields.length > 0 && disableBeforeToday(dates) && (
                      <div className="flex justify-end w-full mt-8">
                        <button
                          className="w-full sm:w-1/3 px-4 py-3 text-white bg-green-500 rounded-lg"
                          type="submit"
                        >
                          {t('Valider')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </form>
      </div>
    </>
  )
}

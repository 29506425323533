import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Input from 'components/form/Input'
import { ChromePicker } from 'react-color'
import StepButtons from './common/StepButtons'

export default function SetCategorie({
  onSubmit,
  goBack,
  category,
  id,
  loading,
  dateList,
}) {
  const form = useForm()
  const {
    register,
    handleSubmit,
    errors,
    reset,
    watch,
    control,
    setValue,
  } = form

  const [color, setColor] = useState()
  const [secondColor, setSecondColor] = useState()

  useEffect(() => {
    if (loading.prevState === false && loading.current === false) {
      reset()
    }
  }, [loading, reset])

  useEffect(() => {
    if (category) {
      let color = category?.color.split(':')
      setColor(color[0] || '#FFFFFF')
      setValue('color', color[0] || '#FFFFFFF')

      setSecondColor(color[1] || color[0] || '#FFFFFF')
      setValue('secondColor', color[1] || color[0] || '#FFFFFF')

      setValue('SchoolingYearId', category?.SchoolingYearId)
    }
  }, [category, setValue])

  return (
    <>
      <div className="flex items-center justify-center mt-16">
        <form
          className="form-container w-full sm:w-full xl:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="font-black mb-4 text-center">
            {`${!isNaN(id) ? 'Modifier ' : 'Ajouter'} une catégorie`}
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'name'}
              ref={register({ required: 'Champ obligatoire' })}
              type="text"
              name="name"
              label="Nom de la catégorie"
              placeholder="Nom de la catégorie"
              error={errors.name && errors.name.message}
              defaultValue={category && category.name}
              value={watch('name')}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'description'}
              type="text"
              name="description"
              label="Description de la catégorie"
              placeholder="Description de la catégorie"
              error={errors.description && errors.description.message}
              defaultValue={category && category.description}
              value={watch('description')}
            />
          </div>
          <div className="flex w-full flex-col mb-2">
            {/* <Controller
              key={`${JSON.stringify(category)}`}
              control={control}
              name={`SchoolingYearId`}
              rules={{
                validate: (datas) => {
                  if (datas) {
                    return true
                  } else {
                    return "Champ obligatoire"
                  }
                }
              }}
              render={({ onChange, value, name }) => (
                <>
                  <Select
                    placeholder={'Année scolaire'}
                    name={name}
                    className="w-full"
                    label="Année scolaire"
                    value={value || category?.SchoolingYearId}
                    defaultValue={value || category?.SchoolingYearId}
                    values={dateList}
                    error={errors?.SchoolingYearId?.message}
                    onChange={(e) =>  {
                      onChange(e)
                    }}
                  />
                </>
              )}
            /> */}
          </div>

          <div className="flex w-full md:flex-row flex-col gap-2 justify-around">
            <Controller
              name="color"
              value={color}
              control={control}
              defaultValue={color !== undefined ? color : '#FFFFFF'}
              rules={{ required: 'Champ obligatoire' }}
              render={() => {
                return (
                  <div className="flex flex-col gap-1">
                    <div className="whitespace no-wrap text-sm">Couleur 1</div>
                    <ChromePicker
                      onChange={(e) => {
                        setColor(e.hex)
                        setValue('color', e.hex)
                      }}
                      color={color}
                    />
                    {errors?.color && (
                      <span className="text-sm text-red-500">
                        {errors?.color?.message}{' '}
                      </span>
                    )}
                  </div>
                )
              }}
            />
            <Controller
              name="secondColor"
              value={secondColor}
              control={control}
              defaultValue={color !== undefined ? color : '#FFFFFF'}
              rules={{ required: 'Champ obligatoire' }}
              render={() => {
                return (
                  <div className="flex flex-col gap-1">
                    <div className="whitespace no-wrap text-sm">Couleur 2</div>
                    <ChromePicker
                      onChange={(e) => {
                        setSecondColor(e.hex)
                        setValue('secondColor', e.hex)
                      }}
                      color={secondColor}
                    />
                    {errors?.secondColor && (
                      <span className="text-sm text-red-500">
                        {errors?.secondColor?.message}{' '}
                      </span>
                    )}
                  </div>
                )
              }}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col"></div>
          <StepButtons
            loading={loading.current}
            goBack={goBack}
            nextLabel={'Enregistrer'}
          />
        </form>
      </div>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import Parent from 'components/Parent'
import { useHistory } from 'react-router-dom'
import useRegistration from '../hooks/useRegistration'
import { useUser } from 'stores/user'
import { useNotify } from '../stores/notify'
import Layout from 'components/Layout'
import { useTranslation } from 'react-i18next'
import { isFormChanged } from 'lib/utils'

export default function ParentPage(props) {
  const history = useHistory()
  const { getParentDetails, setParentDetails } = useRegistration()
  const [parents, setParents] = useState()
  const {
    registrationId,
    company,
  } = useUser(({ registrationId, company }) => ({ registrationId, company }))
  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))
  const initialForm = {
    firstname: '',
    lastname: '',
    tel: '',
    profession: '',
    address: '',
    hasAuthority: false,
    fatherMotherTutor: '',
    email: '',
  }

  const { t } = useTranslation()
  useEffect(() => {
    async function loadDatas() {
      const parents = await getParentDetails()
      if (parents.error) {
        setErrorMessage(t(parents.error.message))
        return
      }
      if (parents.results) {
        const formatFatherMotherTutor = (parent) => {
          let p = parent.User
          p.hasAuthority = parent.hasAuthority
          if (parent.User.father) {
            p.fatherMotherTutor = 'father'
          }
          if (parent.User.mother) {
            p.fatherMotherTutor = 'mother'
          }
          if (parent.User.tutor) {
            p.fatherMotherTutor = 'tutor'
          }
          return p
        }
        if (parents.results.Child.UserChildren.length > 1) {
          let first = formatFatherMotherTutor(
            parents.results.Child.UserChildren[0]
          )
          let second = formatFatherMotherTutor(
            parents.results.Child.UserChildren[1]
          )
          setParents([first, second])
        } else if (parents.results.Child.UserChildren.length === 1) {
          let first = formatFatherMotherTutor(
            parents.results.Child.UserChildren[0]
          )
          setParents([first])
        } else {
          setParents([initialForm])
        }
      }
    }

    if (!props.status) {
      history.replace('/dashboard')
      return
    }
    loadDatas()
    // eslint-disable-next-line
  }, [history])

  const goBack = () => {
    history.push(
      '/child',
      props?.location?.state && { ...props.location.state }
    )
  }

  const onSubmit = async (datas) => {
    datas.parents.forEach(async (parent, index) => {
      // If second parent has values null or false, do nothing
      if (index === 1) {
        let p = Object.values(parent).filter((p) => p !== '' && p !== false)
        if (p.length === 0) {
          return
        }
      }

      if (
        isFormChanged(parents[index] || {}, parent, [
          'CompanyId',
          'createdAt',
          'updatedAt',
          'deletedAt',
          'father',
          'mother',
          'tutor',
          'isemailvalid',
          'language',
          'role',
          'id',
        ])
      ) {
        const p = await setParentDetails({
          datas: {
            ...parent,
            hasAuthority: !!parent.hasAuthority,
          },
          id: parents[index]?.id ? parents[index].id : '',
        })
        if (p.error) {
          setErrorMessage(t(p.error.message))
          return
        }
      }
    })
    if (company) {
      history.push(
        '/summary',
        props?.location?.state && { ...props.location.state }
      )
    } else {
      history.push(
        '/incomestatement',
        props?.location?.state && { ...props.location.state }
      )
    }
  }

  return (
    <Layout
      buttonGoHome={{
        to: props.role === 'admin' ? '/admin' : '/dashboard',
        history,
      }}
      title={t('Parents')}
    >
      <Parent
        onSubmit={onSubmit}
        goBack={goBack}
        company={company}
        parents={parents}
        registrationId={registrationId}
        role={props.role}
      />
    </Layout>
  )
}

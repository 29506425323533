import React, { useState, useEffect } from 'react'
import {
  MONTHS,
  BILL_STATUS,
  REGISTRATION_STATUS,
  BILL_TYPE,
} from '../lib/constants'
import { dateFormat } from '../lib/utils'

import DashboardCard from 'components/dashboard/DashboardCard'
import DashboardLink from 'components/dashboard/DashboardLink'

import { useTranslation } from 'react-i18next'

function FlashMessage({ children, severity, actions }) {
  let bgColor = 'blue-500'
  if (severity === 'warning') {
    bgColor = 'yellow-500'
  } else if (severity === 'error') {
    bgColor = 'red-700'
  }
  return (
    <div
      className={`flex flex-col flex-wrap flex-grow mt-2 p-2 px-3 pb-3 bg-${bgColor} rounded`}
    >
      <div className="text-white">{children}</div>
      {actions && (
        <div className="flex mt-2">
          {actions.map(({ label, onClick }, index) => (
            <button
              key={`flashMessage-${index}`}
              className={`cursor-pointer text-${bgColor} bg-white active:opacity-75 px-3 py-1 rounded mr-2`}
              onClick={onClick}
            >
              {label}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default function Dashboard({
  onCreateRegistration,
  onEditRegistration,
  onManageNursery,
  onToggleDate,
  onViewBill,
  onViewSummaryNursery,
  onPayBill,
  shouldDisplayDate,
  hasValidRegistration,
  dates,
  registrations,
  bill,
  onViewCertificate,
}) {
  let severity = 'info'

  const { t } = useTranslation()

  if (bill && bill.status) {
    switch (bill.status) {
      case 'pending':
        severity = 'warning'
        break

      case 'warning':
        severity = 'error'
        break

      default:
        break
    }
  }

  const [billStatus, setBillStatus] = useState({ status: '', date: '' })

  useEffect(() => {
    if (bill && bill.bill.length > 0) {
      const currentDate = new Date()

      let bills = bill.bill
        .map((bill) => {
          if (bill.type !== BILL_TYPE.childcare) {
            return ''
          }

          if (bill.status === BILL_STATUS.paid) {
            return { status: BILL_STATUS.paid }
          }
          if (bill.status === BILL_STATUS.cancel) {
            return { status: BILL_STATUS.cancel }
          }

          const billMaxDate = new Date(bill.createdAt)
          billMaxDate.setDate(billMaxDate.getDate() + 7)

          if (billMaxDate.getTime() < currentDate.getTime()) {
            return { status: 'warning', date: billMaxDate }
          } else if (currentDate.getTime() < billMaxDate.getTime()) {
            return { status: BILL_STATUS.pending, date: billMaxDate }
          }

          return null
        })
        .filter(
          (a) => a?.status === 'warning' || a?.status === BILL_STATUS.pending
        )
        .sort((a, b) => a.date.getTime() - b.date.getTime())
      if (bills.length > 0) {
        setBillStatus(bills[0])
      }
    }
  }, [bill])

  const actions = [
    {
      label: t('Payer la facture'),
      onClick: () => onPayBill(),
    },
  ]

  return (
    <>
      {billStatus && billStatus.status && billStatus.status !== 'clean' && (
        <FlashMessage severity={severity} actions={actions}>
          {billStatus.status !== 'warning' &&
            t(
              'Vous avez une facture à régler, avant le {{jour}} {{mois}} {{annee}} pour continuer à pouvoir inscrire vos enfants à la garderie',
              {
                date: dateFormat(billStatus.date),
                jour: dateFormat(billStatus.date).split(' ')[0],
                mois: t(dateFormat(billStatus.date).split(' ')[1]),
                annee: dateFormat(billStatus.date).split(' ')[2],
              }
            )}
          {billStatus.status === 'warning' &&
            t(
              "Vous n'avez pas réglé une facture, les services de garderie sont suspendus"
            )}
        </FlashMessage>
      )}
      <div className="flex flex-row flex-wrap flex-grow mt-20">
        <DashboardCard title={t('Scolarité')}>
          <DashboardLink onClick={onCreateRegistration}>
            {t('Pré-inscrire mon enfant')}
          </DashboardLink>
          <DashboardLink
            onClick={onEditRegistration}
            disabled={!registrations || registrations?.length === 0}
          >
            {t('Voir mes pré-inscriptions')}
            {registrations.filter((r) => r.status === REGISTRATION_STATUS.open)
              .length > 0 && (
              <>
                {` (${
                  registrations.filter(
                    (r) => r.status === REGISTRATION_STATUS.open
                  ).length
                } ${t('En cours').toLowerCase()})`}
              </>
            )}
          </DashboardLink>
          <DashboardLink
            onClick={onViewCertificate}
            disabled={!registrations || registrations?.length === 0}
          >
            {t('Certificat de scolarité')}
          </DashboardLink>
        </DashboardCard>

        <DashboardCard title={t('Garderie')}>
          <DashboardLink
            onClick={onManageNursery}
            disabled={!hasValidRegistration}
          >
            {t('Réservation de garderie')}
          </DashboardLink>
          <DashboardLink
            onClick={onViewBill}
            className="hover:text-blue-600 hover:cursor-pointer"
            disabled={!(bill && bill.bill.length > 0)}
          >
            {t('Voir mes factures')}
          </DashboardLink>
          <DashboardLink
            onClick={onToggleDate}
            disabled={!hasValidRegistration}
          >
            {t('Historique des réservations à la garderie')}
          </DashboardLink>
          {shouldDisplayDate && (
            <ul className="text-gray-600 text-sm">
              {dates &&
                Object.keys(dates).map((year, index) => (
                  <div key={`year-${index}`} className="text-black">
                    {year}
                    {dates[year].map((month, index) => (
                      <li
                        key={`month-${index}`}
                        className="text-gray-600 hover:text-blue-600 hover:cursor-pointer"
                        onClick={() => onViewSummaryNursery(year, month)}
                      >
                        {t(`${MONTHS[month - 1]}`)}
                      </li>
                    ))}
                  </div>
                ))}
              {dates && Object.keys(dates).length === 0 && (
                <div>
                  {t(
                    "Vous n'avez pas encore inscrit votre enfant à la garderie"
                  )}
                </div>
              )}
            </ul>
          )}
        </DashboardCard>
        <DashboardCard title={t('Nous contacter')}>
          <div className="py-6 pt-2 pb-0 text-gray-600">
            {t(
              'En cas de problème sur la plateforme, vous pouvez joindre le support technique directement par email'
            )}
            :
          </div>
          <DashboardLink>
            <a href="mailto:contact@wearedevx.com">contact@wearedevx.com</a>
          </DashboardLink>
          <div className="mb-4"></div>
          <div className="py-6 pt-2 pb-0 text-gray-600">
            {t("Pour les questions relatives à l'école")}:
          </div>
          <DashboardLink>
            <a href="mailto:contact@ecole-montessori-pau.fr">
              contact@ecole-montessori-pau.fr
            </a>
          </DashboardLink>
        </DashboardCard>
      </div>
    </>
  )
}

import React, { useMemo, useState, useCallback } from 'react'
import Table from 'components/common/table'
import useActivity from 'hooks/useActivity'
import { useHistory } from 'react-router-dom'
import { IDefaultColumnFilter } from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'
import ImportModal from 'components/common/ImportModal'
import { useForm } from 'react-hook-form'
import useFiles from 'hooks/useFiles'

import {
  Actions,
  EditAction,
  RemoveAction,
} from 'components/common/table/actions'

import { useNotify } from 'stores/notify'
import Spinner from 'components/common/Spinner'

const initialPageSize = 10
const initialSortBy = [
  {
    id: 'name',
    desc: false,
  },
]

const MyModelsList = () => {
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage, setWarningMessage,
    })
  )

  const [forceRefresh] = useState(1)

  const {
    removeActivity,
    getActivitiesViews
  } = useActivity()

  const { importActivities } = useFiles()

  const form = useForm()

  const onError = useCallback(
    (err) => {
      if (err && err.statusCode && err.statusCode === 401) {
        setErrorMessage(err.message)
        history.replace('/')
      } else {
        setErrorMessage('Une erreur est survenue')
      }
    },
    [history, setErrorMessage]
  )

  const fetchData = useApiFetch(getActivitiesViews, onError)

  const [removed, setRemoved] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const onRemove = useCallback(
    async (id) => {
      const req = await removeActivity(id)
      if (req.error) {
        setErrorMessage(req.error.message)
        return
      }

      if (req.results && req.results.success) {
        setSuccessMessage(`Elément supprimé avec succès`)
        setRemoved(id)
      } else {
        setErrorMessage(`Impossible de supprimer: ${req.error.message}`)
      }
    },
    [removeActivity, setErrorMessage, setSuccessMessage]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'name', // accessor is the "key" in the data
        Filter: IDefaultColumnFilter,
        className: 'font-bold',
      },
      {
        Header: 'Image',
        accessor: 'image', // accessor is the "key" in the data
        className: 'font-bold',
        disableFilters: true,
      },
      {
        Header: 'TPS',
        accessor: 'TPS', // accessor is the "key" in the data
        className: 'font-bold',
        disableFilters: true,
        Cell: (props) => {
          return props.value ? "x" : ""
        }
      },
      {
        Header: 'PS',
        accessor: 'PS', // accessor is the "key" in the data
        className: 'font-bold',
        disableFilters: true,
        Cell: (props) => {
          return props.value ? "x" : ""
        }
      },
      {
        Header: 'MS',
        accessor: 'MS', // accessor is the "key" in the data
        className: 'font-bold',
        disableFilters: true,
        Cell: (props) => {
          return props.value ? "x" : ""
        }
      },
      {
        Header: 'GS',
        accessor: 'GS', // accessor is the "key" in the data
        className: 'font-bold',
        disableFilters: true,
        Cell: (props) => {
          return props.value ? "x" : ""
        }
      },
      {
        Header: 'CP',
        accessor: 'CP', // accessor is the "key" in the data
        className: 'font-bold',
        disableFilters: true,
        Cell: (props) => {
          return props.value ? "x" : ""
        }
      },
      {
        Header: 'CE1',
        accessor: 'CE1', // accessor is the "key" in the data
        className: 'font-bold',
        disableFilters: true,
        Cell: (props) => {
          return props.value ? "x" : ""
        }
      },
      {
        Header: 'CE2',
        accessor: 'CE2', // accessor is the "key" in the data
        className: 'font-bold',
        disableFilters: true,
        Cell: (props) => {
          return props.value ? "x" : ""
        }
      },
      {
        Header: 'Categorie',
        accessor: 'CategoryName', // accessor is the "key" in the data
        className: 'font-bold',
        Filter: IDefaultColumnFilter,
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableFilters: true,
        Cell: (props) => (
          <Actions>
            <EditAction
              entity={props.value}
              message={'Voulez-vous modifier cette activité '}
              to={`/setactivity/${props.value}`}
            />
            <RemoveAction
              onRemove={onRemove}
              {...props}
              title={`Êtes-vous sûr de vouloir supprimer l'activité ${props.row.original.name}&nbsp;?`}
            />
          </Actions>
        ),
      },
    ], [onRemove]
  )

  const addActivity = useCallback(() => {
    history.push('/setactivity/new')
  }, [history])


  const onImport = async () => {
    let imp = await ImportModal({
      title: "Importer un fichier",
      form
    })
    if (!imp) {
      return
    }
    if (imp.media === undefined) {
      setWarningMessage("Aucun fichier n'a été envoyé")
      return
    }
    try {
      setIsLoading(true)
      const importFile = await importActivities(imp)
      if (importFile.results) {
        setSuccessMessage('Import réusssi')
        setIsLoading(false)
      }
    } catch (err) {
      setErrorMessage("Impossible d'importer le fichier")
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  return (
    <>
      {isLoading && (
        <div className="flex justify-center flex-col items-center">
          <div>
            Import en cours
          </div>
          <Spinner />
        </div>
      )}
      <div className="flex justify-center w-full pt-12">

        <Table
          fetchData={fetchData}
          name="Liste des activités"
          initialPageSize={initialPageSize}
          columns={columns}
          rowRemoved={removed}
          initialSortBy={initialSortBy}
          addEntity={addActivity}
          forceRefresh={forceRefresh}
          import={() => onImport()}
        />
      </div>
    </>
  )
}

export default MyModelsList

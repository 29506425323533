import LayoutAdmin from 'components/LayoutAdmin'
import SortingRank from 'components/SortingRank'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import useActivity from 'hooks/useActivity'
import { DndProvider } from 'react-dnd'
import { useNotify } from '../stores/notify'
import { HTML5Backend } from 'react-dnd-html5-backend'

export default function SortingRankPage() {
  const location = useLocation()

  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
      setWarningMessage,
    })
  )

  const [subDomain, setSubDomain] = useState(null)
  const [Domain, setDomain] = useState(null)
  const [loading, setLoading] = useState(true)

  const {
    getSubDomains,
    getDomains,
    updateSubDomain,
    updateDomain,
  } = useActivity()

  async function fetchFromApi() {
    const SubDomainList = await getSubDomains()
    const DomainList = await getDomains()

    setSubDomain(
      SubDomainList.results.sort((a, b) => a.sortingRank - b.sortingRank)
    )
    setDomain(DomainList.results.sort((a, b) => a.sortingRank - b.sortingRank))

    setLoading(false)
  }

  useEffect(() => {
    fetchFromApi()
    //eslint-disable-next-line
  }, [])

  return (
    <LayoutAdmin
      location={location}
      title="Gestion de l'ordre d'impression des domains/sous-domaines"
      hasBack
    >
      <DndProvider backend={HTML5Backend}>
        <SortingRank
          subDomain={subDomain}
          Domain={Domain}
          loading={loading}
          updateSubDomain={updateSubDomain}
          updateDomain={updateDomain}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />
      </DndProvider>
    </LayoutAdmin>
  )
}

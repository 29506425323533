import React, { useState, useEffect, useRef, useCallback } from 'react'
import useRegistration from '../hooks/useRegistration'
import useSchoolingYear from '../hooks/useSchoolingYear'
import IncomeStatement from '../components/IncomeStatement'
import { useHistory } from 'react-router-dom'
import { useUser } from 'stores/user'
import { useNotify } from '../stores/notify'
import Layout from 'components/Layout'
import { MONTHS } from '../lib/constants'
import { useTranslation } from 'react-i18next'
import { isFormChanged } from 'lib/utils'

export default function IncomeStatementPage(props) {
  const history = useHistory()
  const { registrationId } = useUser(({ registrationId }) => ({
    registrationId,
  }))
  const { t } = useTranslation()

  const { updateRegistration, getRegistration } = useRegistration()
  const { getPrice } = useSchoolingYear()
  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))

  const timeout = useRef()
  const [familyIndex, setFamilyIndex] = useState(0)

  const [price, setPrice] = useState()
  const [incomes, setIncomes] = useState()
  const [numberPayment, setNumberPayment] = useState()

  const onSubmit = useCallback(
    async (datas) => {
      if (!price) return
      // add manually familyIndex, cause input is disabled
      datas.familyIndex = familyIndex
      datas.registrationFees = price?.registrationFees
      datas.numberDependantsChildren = Number(datas.numberDependantsChildren)
      datas.numberOtherDependants = Number(datas.numberOtherDependants)
      datas.allowances = Number(datas.allowances)
      datas.income = Number(datas.income)

      if (
        isFormChanged(incomes, datas, [
          'SchoolClassId',
          'createdAt',
          'datePayment',
          'dateWish',
          'deletedAt',
          'id',
          'month',
          'motivation',
          'noFilesReason',
          'registrationBillId',
          'registrationFees',
          'registrationFeesBillId',
          'registrationBillId',
          'status',
          'updatedAt',
        ])
      ) {
        const income = await updateRegistration({ ...datas })
        if (income.error) {
          setErrorMessage(t(income.error.message))
          return
        }
        if (income.results) {
          history.push(
            '/summary',
            props?.location?.state && { ...props.location.state }
          )
        }
      } else {
        history.push(
          '/summary',
          props?.location?.state && { ...props.location.state }
        )
      }
    },
    [
      familyIndex,
      price,
      setErrorMessage,
      updateRegistration,
      history,
      props,
      t,
      incomes,
    ]
  )

  const onFamilyIndexChange = useCallback(
    async (familyIndex) => {
      clearTimeout(timeout.current)
      timeout.current = setTimeout(async () => {
        const price = await getPrice(Math.round(familyIndex))
        if (price.error) {
          setErrorMessage(t(price.error.message))
          return
        }
        setFamilyIndex(Math.round(familyIndex))
        if (price.results) {
          setPrice(price.results.getPrice)
        }
      }, [800])
    },
    [getPrice, setErrorMessage, t]
  )

  const goBack = useCallback(() => {
    history.push(
      '/parent',
      props?.location?.state && { ...props.location.state }
    )
  }, [history, props])

  useEffect(() => {
    async function loadDatas() {
      try {
        const registration = await getRegistration()

        if (registration.error) {
          setErrorMessage(t(registration))
          return
        }

        if (registration.results.companyPartner) {
          return history.goBack() // refresh window ?
        }

        if (registration) {
          const numberPayment = []

          if (registration.results.month === MONTHS[0]) {
            numberPayment.push(
              { label: `${t('{{value}} fois', { value: 1 })}`, value: 1 },
              { label: `${t('{{value}} fois', { value: 6 })}`, value: 6 }
            )
          } else if (registration.results.month === MONTHS[8]) {
            numberPayment.push(
              { label: `${t('{{value}} fois', { value: 1 })}`, value: 1 },
              { label: `${t('{{value}} fois', { value: 10 })}`, value: 10 }
            )
          } else {
            // If not January or September
            numberPayment.push(
              { label: `${t('{{value}} fois', { value: 1 })}`, value: 1 },
              { label: `${t('{{value}} fois', { value: 6 })}`, value: 6 }
            )
          }

          setNumberPayment(numberPayment)
          setIncomes(registration.results)
        }
      } catch (err) {
        setErrorMessage(t(err.message))
      }
    }

    if (!props.status) {
      history.replace('/dashboard')
      return
    }
    loadDatas()
    // eslint-disable-next-line
  }, [history])

  return (
    <Layout
      buttonGoHome={{
        to: props.role === 'admin' ? '/admin' : '/dashboard',
        history,
      }}
      title={t('Vos revenus')}
    >
      <IncomeStatement
        onSubmit={onSubmit}
        income={incomes}
        onFamilyIndexChange={onFamilyIndexChange}
        price={price}
        goBack={goBack}
        numberPayment={numberPayment}
        registrationId={registrationId}
        familyIndex={familyIndex}
      />
    </Layout>
  )
}

import React, { useCallback, useEffect, useState } from 'react'
import Signup from 'components/Signup'

import { useUser } from 'stores/user'
import { useNotify } from 'stores/notify'
import useRegistration from '../hooks/useRegistration'
import { useTranslation } from 'react-i18next'
import useLanguage from 'hooks/useLanguage'

const SignupPage = () => {
  const { reSendEmail } = useRegistration()
  const { getLanguage } = useLanguage()
  const [isMailSend, setisMailSend] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { signup, error, setError } = useUser(
    ({ signup, error, setError }) => ({
      signup,
      error,
      setError,
    })
  )
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  useEffect(() => {
    if (error && error.message)
      setErrorMessage(
        t('Adresse e-mail {{mail}} déjà prise', { mail: error.message.split(' ')[1] })
      )

    return () => {
      setError(null)
    }
  }, [error, setError, setErrorMessage, t])

  const submit = useCallback(
    async (params) => {
      if (isMailSend && isMailSend.email) {
        return
      }
      setLoading(true)
      params.language = getLanguage()
      const sign = await signup(params)
      if (sign) {
        setSuccessMessage(
          t('Un e-mail vous a été envoyé pour valider votre inscription')
        )
        setisMailSend({ email: sign.email, token: sign.login_token })
      }

      //   window.location.href = "/"
      setLoading(false)
    },
    [setSuccessMessage, signup, isMailSend, t, getLanguage]
  )

  const sendMail = async () => {
    setLoading(true)
    try {
      await reSendEmail({ ...isMailSend })
      setSuccessMessage(
        t('Un e-mail vous a été renvoyé pour valider votre inscription')
      )
    } catch (err) {
      setErrorMessage(t(err.message))
    }
    setLoading(false)
  }

  return (
    <Signup
      onSubmit={submit}
      isMailSend={isMailSend}
      sendMail={sendMail}
      loading={loading}
    />
  )
}

export default SignupPage

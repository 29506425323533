/* eslint-disable class-methods-use-this */
import { userApi } from 'stores/user'
import { notifyApi } from 'stores/notify'
import { redirectApi } from 'stores/redirect'

const { REACT_APP_API_URL } = process.env

const ROUTES = {
  USERS: `${REACT_APP_API_URL}/users`,
  MY_MODELS: `${REACT_APP_API_URL}/my-models`,
  REGISTRATIONS: `${REACT_APP_API_URL}/registrations`,
  REGISTRATIONS_VIEW: `${REACT_APP_API_URL}/registrationsviews`,
  SCHOOLINGYEAR: `${REACT_APP_API_URL}/schoolingyears`,
  COMPANIES: `${REACT_APP_API_URL}/companies`,
  PRICELIST: `${REACT_APP_API_URL}/pricelists`,
  PRICINGSGRIDS: `${REACT_APP_API_URL}/pricinggrids`,
  CHILDCARE: `${REACT_APP_API_URL}/childcare`,
  BILLS_VIEW: `${REACT_APP_API_URL}/billsviews`,
  BILLS: `${REACT_APP_API_URL}/bills`,
  CHILDCAREHOURS: `${REACT_APP_API_URL}/childcarehours`,
  SCHOOLCLASS: `${REACT_APP_API_URL}/schoolclasses`,
  CATEGORIES: `${REACT_APP_API_URL}/categories`,
  ACTIVITIES: `${REACT_APP_API_URL}/activities`,
  ACTIVITYLEVELS: `${REACT_APP_API_URL}/activitylevels`,
  CHILDRENACTIVITIES: `${REACT_APP_API_URL}/childrenactivities`,
  ACTIVITIESVIEWS: `${REACT_APP_API_URL}/activitiesviews`,
  PROGRESSIONS: `${REACT_APP_API_URL}/progressions`,
  SUBDOMAINS: `${REACT_APP_API_URL}/subdomains`,
  DOMAINS: `${REACT_APP_API_URL}/domains`,
  OBSERVATIONS: `${REACT_APP_API_URL}/observations`,
}

const DEFAULT_HEADERS = {
  Accept: 'application/json',
}

// TODO refactor and make it readable
const queryParams = (qs) => {
  if (qs && Object.keys(qs).length > 0) {
    let params = []

    const stdParams = Object.keys(qs)
      .filter((k) => k !== 'filters' && k !== 'order')
      .map((key) => [key, encodeURIComponent(qs[key] || '')].join('='))
      .join('&')

    params.push(`?${stdParams}`)

    if (qs.filters && qs.filters.length > 0) {
      const formattedFilters = qs.filters
        .map((f) => `[${f.map((v) => `"${v}"`).join(',')}]`)
        .join(',')

      params.push(`filters=${encodeURIComponent(`[${formattedFilters}]`)}`)
    }

    if (qs.order && qs.order.length > 0) {
      const formattedOrder = qs.order
        .map((f) => `[${f.map((v) => `"${v}"`).join(',')}]`)
        .join(',')

      params.push(`order=${encodeURIComponent(`[${formattedOrder}]`)}`)
    }

    return params.join('&')
  }
  return ''
}

function parseBody(body, headers) {
  if (!body) {
    return undefined
  }

  if (headers && headers['Content-Type'] === 'multipart/form-data') {
    return body
  }

  headers['Content-Type'] = 'application/json'
  return JSON.stringify(body)
}

function request(method, url, params = {}, retries = 0) {
  const { token, body, query, headers = {}, abortController } = params
  const bodyParsed = parseBody(body, headers)

  const newHeaders = { ...headers }

  // Let browser put it.
  if (newHeaders && newHeaders['Content-Type'] === 'multipart/form-data') {
    delete newHeaders['Content-Type']
  }

  const options = {
    method: method.toUpperCase(),
    headers: token
      ? {
          ...DEFAULT_HEADERS,
          ...newHeaders,
          Authorization: `Bearer ${token}`,
          // role,
        }
      : { ...DEFAULT_HEADERS, ...newHeaders },
    body: bodyParsed,
    signal: abortController && abortController.signal,
  }

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const error = new Error('Le service est temporairement indisponible')
      error.type = 'Request Timeout'
      // storeApi.setState({
      //   msgAppWide: { message: error.message, type: 'error' },
      // })
      reject(error)
    }, 60000)

    // url += queryParams(query)
    // redirectApi.getState().setRedirect(window.location.href)
    const urlWithParams = url + queryParams(query)

    fetch(urlWithParams, options)
      .then(async (response) => {
        clearTimeout(timeout)

        if (response.status === 404) {
          response.text().then((text) => {
            const error = new Error(text)
            error.type = 'Not found'
            error.statusCode = response.status
            // storeApi.setState({
            //   msgAppWide: { message: error.message, type: 'error' },
            // })
            reject(error)
          })
        } else if (response.status >= 200 && response.status <= 202) {
          const fileType = [
            'jpeg',
            'png',
            'jpg',
            'pdf',
            'txt',
            'sheet',
            'csv',
            'msword',
            'vnd.openxmlformats-officedocument.wordprocessingml.document',
            'vnd.oasis.opendocument.text',
          ].find((type) => response.headers.get('Content-Type').includes(type))
          if (fileType) {
            const blob = await response.blob()
            const fileUrl =
              typeof window !== 'undefined' && window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = fileUrl
            // the filename you want

            const regExpFilename = /filename="(?<filename>.*)"/
            const contentDispositionHeader = response.headers.get(
              'Content-disposition'
            )

            const filename =
              regExpFilename.exec(contentDispositionHeader)?.groups?.filename ??
              null
            a.download = filename

            document.body.appendChild(a)
            a.click()

            typeof window !== 'undefined' && window.URL.revokeObjectURL(fileUrl)
            resolve()
          } else {
            response.json().then((json) =>
              resolve({
                results: json,
                total: response.headers.get('X-Total-Count'),
              })
            )
          }
        } else if (response.status >= 203 && response.status < 300) {
          try {
            resolve({
              results: { success: true },
              total: response.headers.get('X-Total-Count'),
            })
          } catch (error) {
            // storeApi.setState({
            //   msgAppWide: { message: error.message, type: 'error' },
            // })
            reject(error)
          }
        } else if (response.status === 498) {
          const url = window.location.href
          // userApi.getState().setUser({token: null} )
          userApi.getState().logout()
          // setTimeout(() => {
          notifyApi.getState().setWarningMessage('Votre session a expiré.')
          redirectApi.getState().setRedirect(url)
          // }, 1000)

          // setTimeout(() => {
          window.location.href = '/'
          reject()
          // }, 2000)
        } else {
          response.text().then((text) => {
            const error = new Error(text)
            error.type = 'Not found'
            error.statusCode = response.status
            // storeApi.setState({
            //   msgAppWide: { message: error.message, type: 'error' },
            // })
            reject(error)
          })
        }
      })
      .catch((error) => {
        if (retries < 5) {
          setTimeout(() => {
            resolve(request(method, url, params, retries + 1))
          }, 2000)
          return
        }

        error = new Error('Le service est temporairement indisponible')
        error.type = 'Client Error'
        // storeApi.setState({
        //   msgAppWide: { message: error.message, type: 'error' },
        // })
        reject(error)
      })
  })
}

function post(url, params) {
  return request('post', url, params)
}

// eslint-disable-next-line
function put(url, params) {
  return request('put', url, params)
}

function patch(url, params) {
  return request('patch', url, params)
}

function get(url, params) {
  return request('get', url, params)
}

function del(url, params) {
  return request('delete', url, params)
}

// eslint-disable-next-line
async function getOne({ route, id, token, query }) {
  const req = await get(`${route}/${id}`, { token, query })

  if (req) {
    return req.results
  }

  return null
}

// DEBUG
// eslint-disable-next-line
function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

/**
 * @class
 * @name Api
 * @description Safir Api client
 * The weird wraping with a WeakMap is to keep the user token private
 */
export default {
  signin: (params) => {
    if (params.email === 'error@mail.com') {
      throw new Error('Email invalide')
    }
    return post(`${ROUTES.USERS}/login`, { body: params })
  },
  signup: (params) => {
    return post(`${ROUTES.USERS}`, { body: params })
  },
  reSendEmail: (email, token) => {
    return post(`${ROUTES.USERS}/resendemail`, { body: email, token })
  },
  passwordForgotRequest: (params) => {
    return post(`${ROUTES.USERS}/reinit-password`, { body: params })
  },
  changePassword: (token, body) => {
    return patch(`${ROUTES.USERS}/reinit-password`, { token, body })
  },
  updateUser: (token, params) => {
    return patch(`${ROUTES.USERS}`, { token, body: params })
  },
  getUser: (token, id, params) =>
    get(`${ROUTES.USERS}/${id}`, { token, ...params }),
  getUsers: (token, params) => get(ROUTES.USERS, { token, ...params }),
  deleteUser: (token, id, params) =>
    del(`${ROUTES.USERS}/${id}`, { token, ...params }),
  updateUsers: (token, params) => {
    return patch(`${ROUTES.USERS}/multiple`, { token, body: params })
  },
  createUser: (token, params) => {
    return post(`${ROUTES.USERS}/create`, { token, body: params })
  },

  getMyModels: (token, params) => get(ROUTES.MY_MODELS, { token, ...params }),

  getMyModel: (token, id, params) =>
    get(`${ROUTES.MY_MODELS}/${id}`, { token, ...params }),
  createMyModel: (token, params) =>
    post(`${ROUTES.MY_MODELS}`, { token, ...params }),
  updateMyModel: (token, id, params) =>
    patch(`${ROUTES.MY_MODELS}/${id}`, { token, ...params }),
  deleteMyModel: (token, id, params) =>
    del(`${ROUTES.MY_MODELS}/${id}`, { token, ...params }),

  reinitPassword: (params) => {
    return post(`${ROUTES.USERS}/reinit-password`, {
      body: { ...params },
    })
  },
  reinitMultiplePassword: (token, params) => {
    return post(`${ROUTES.USERS}/reinit-multiple-password`, {
      token,
      body: { ...params },
    })
  },

  checkLoginToken: (token) => {
    return post(`${ROUTES.USERS}/check-login-token`, {
      body: { token },
    })
  },
  setPassword: ({ token, password }) => {
    return post(`${ROUTES.USERS}/set-password`, {
      token,
      body: { password },
    })
  },

  /**
   *
   * MY API
   */

  createRegistration: ({ token, classSelect }) => {
    return post(`${ROUTES.REGISTRATIONS}/new`, { token, body: { classSelect } })
  },
  getRegistrations: (token, params) => {
    return get(`${ROUTES.REGISTRATIONS_VIEW}`, { token, ...params })
  },
  getSchoolingdate: (token, params) => {
    return get(`${ROUTES.SCHOOLINGYEAR}`, { token, ...params })
  },
  getOneSchoolingDate: (token, id, query) => {
    // return getOne({ route: ROUTES.SCHOOLINGYEAR, id, token, query })
    return get(`${ROUTES.SCHOOLINGYEAR}/${id}`, { token, ...query })
  },
  setSchoolingDate: ({ token, params, id }) => {
    return patch(`${ROUTES.SCHOOLINGYEAR}/${id}/setschoolingdate`, {
      body: params,
      token,
    })
  },
  setChildDetails: ({ token, id, registrationId, formData, headers }) => {
    return patch(`${ROUTES.REGISTRATIONS}/${registrationId}/setchilddetails`, {
      token,
      body: formData,
      headers,
      id,
    })
  },
  getParentDetails: ({ token, id, query }) => {
    return get(`${ROUTES.REGISTRATIONS}/${id}`, { token, query })
  },
  setParentDetails: ({ token, params, id }) => {
    return patch(`${ROUTES.REGISTRATIONS}/${id}/setparentdetails`, {
      token,
      body: params,
    })
  },
  getAllRegistrations: ({ token }) => {
    return get(`${ROUTES.REGISTRATIONS}/getallregistrations`, { token })
  },
  setCompany({ token, company, id }) {
    return post(`${ROUTES.REGISTRATIONS}/${id}/setcompany`, {
      token,
      body: { company },
    })
  },
  getPrice: ({ token, id, familyIndex }) => {
    return get(`${ROUTES.SCHOOLINGYEAR}/${id}/getprice/${familyIndex}`, {
      token,
    })
  },
  getRegistration: ({ token, id, query }) => {
    return get(`${ROUTES.REGISTRATIONS}/${id}`, { token, query })
  },
  sendFiles: ({ token, id, registrationId, formData, headers }) => {
    return post(`${ROUTES.REGISTRATIONS}/${registrationId}/sendfiles`, {
      token,
      body: formData,
      headers,
      id,
    })
  },
  getSummary: ({ token, id }) => {
    return get(`${ROUTES.REGISTRATIONS}/${id}/summary`, {
      token,
    })
  },
  updateRegistration: ({ token, id, params }) => {
    return patch(`${ROUTES.REGISTRATIONS}/${id}`, {
      token,
      body: { ...params },
    })
  },
  getFiles: ({ token, id, kind }) => {
    return get(`${ROUTES.REGISTRATIONS}/${id}/getfiles/${kind}`, {
      token,
    })
  },
  deleteFile: ({ token, id, fileId }) => {
    return del(`${ROUTES.REGISTRATIONS}/${id}/deletefile/${fileId}`, {
      token,
    })
  },
  deleteRegistration: ({ token, id }) => {
    return del(`${ROUTES.REGISTRATIONS}/${id}/deleteregistration`, {
      token,
    })
  },
  validRegistration: ({ token, id }) => {
    return patch(`${ROUTES.REGISTRATIONS}/${id}/validregistration`, {
      token,
    })
  },
  reregister: ({ token, id }) => {
    return post(`${ROUTES.REGISTRATIONS}/${id}/reregister`, {
      token,
    })
  },
  updateSchoolingDate: (token, id, params) => {
    return patch(`${ROUTES.SCHOOLINGYEAR}/${id}/updateschoolingdate`, {
      token,
      body: { ...params },
    })
  },
  deleteSchoolingDate: (token, id) => {
    return del(`${ROUTES.SCHOOLINGYEAR}/${id}`, { token })
  },
  createSchoolingDate: (token, params) => {
    return post(`${ROUTES.SCHOOLINGYEAR}`, { token, body: { ...params } })
  },
  getAllPriceList: (token, params) => {
    return get(`${ROUTES.PRICELIST}`, { token, ...params })
  },
  getOnePrice: (token, id) => {
    return get(`${ROUTES.PRICELIST}/${id}`, { token })
  },
  updatePrice: (token, id, params) => {
    return patch(`${ROUTES.PRICELIST}/${id}`, { token, body: { ...params } })
  },
  deletePrice: (token, id) => {
    return del(`${ROUTES.PRICELIST}/${id}`, { token })
  },
  createPrice: (token, params) => {
    return post(`${ROUTES.PRICELIST}`, { token, body: { ...params } })
  },
  getChilds: (token) => {
    return get(`${ROUTES.CHILDCARE}/getchilds`, { token })
  },
  setChildCares: ({ datas, token }) => {
    return post(`${ROUTES.CHILDCARE}/setchildcares`, {
      token,
      body: { ...datas },
    })
  },
  getChildsCares: ({ token, date }) => {
    return get(`${ROUTES.CHILDCARE}/getchildscares/${date}`, { token })
  },
  removeNurseryDate: ({ token, props }) => {
    return del(`${ROUTES.CHILDCARE}/removenurserydate`, {
      token,
      body: { childcares: props },
    })
  },
  downloadFile: ({ token, ...props }) => {
    return post(`${ROUTES.REGISTRATIONS}/${props.id}/downloadfile`, {
      token,
      body: props,
    })
  },
  getAllPricingsGrids: (token, params) => {
    return get(`${ROUTES.PRICINGSGRIDS}`, { token, ...params })
  },
  getOnePricingsGrid: (token, id, query) => {
    return get(`${ROUTES.PRICINGSGRIDS}/${id}`, { token, ...query })
  },
  updatePricingsGrid: ({ token, id, params }) => {
    return patch(`${ROUTES.PRICINGSGRIDS}/${id}`, {
      token,
      body: { ...params },
    })
  },
  createPricingsGrid: (token, params) => {
    return post(`${ROUTES.SCHOOLINGYEAR}/createpricingsgrid`, {
      token,
      body: { ...params },
    })
  },
  updatePricingsGridsLists: ({ token, id, params }) => {
    return post(`${ROUTES.SCHOOLINGYEAR}/${id}/updatepricingsgridslists`, {
      token,
      body: { ...params },
    })
  },
  childCaresPresent: ({ token, present }) => {
    return patch(`${ROUTES.CHILDCARE}/childcarespresent`, {
      token,
      body: present,
    })
  },
  validateEmail: (token) => {
    return get(`${ROUTES.USERS}/validate-email/${token}`)
  },
  getNurseryPrice: (token, date) => {
    return get(`${ROUTES.CHILDCARE}/getnurseryprice/${date}`, {
      token,
      ...date,
    })
  },
  getChildsCaresSummary: ({ token, month, year }) => {
    return get(`${ROUTES.CHILDCARE}/getChildsCaresSummary/${year}/${month}`, {
      token,
    })
  },
  getChildcaresMonth: ({ token }) => {
    return get(`${ROUTES.CHILDCARE}/getchildcaresmonth`, { token })
  },
  deletePricingGrid: ({ token, id }) => {
    return del(`${ROUTES.PRICINGSGRIDS}/${id}`, { token })
  },
  restoreRegistration: (token, id) => {
    return patch(`${ROUTES.REGISTRATIONS}/${id}/restore`, { token })
  },
  getPublicHolidays: (year) => {
    return get(`${ROUTES.CHILDCARE}/publicholidays/${year}`)
  },
  getHolidays: (schoolingYear) => {
    return get(`${ROUTES.CHILDCARE}/holidays/${schoolingYear}`)
  },
  getBillsAndStatus: ({ token }) => {
    return get(`${ROUTES.BILLS}/getBillsAndStatus`, { token })
  },
  generateBill: ({ token, id, type }) => {
    return get(`${ROUTES.BILLS}/generate/${id}/${type}`, { token })
  },
  generateBillWithData: ({ token, id, type, datas }) => {
    return post(`${ROUTES.BILLS}/generate/${id}/${type}`, {
      token,
      body: { datas },
    })
  },
  payBill: ({ token, id }) => {
    return post(`${ROUTES.BILLS}/${id}/pay`, { token })
  },
  getBills: ({ token, query }) => {
    return get(`${ROUTES.BILLS_VIEW}/`, { token, query })
  },
  getBill: ({ token, id }) => {
    return get(`${ROUTES.BILLS}/${id}?populate=true`, { token })
  },
  getBillsToPdf: ({ token, query }) => {
    return get(`${ROUTES.BILLS}/`, { token, query })
  },
  createCreditNote: ({ token, datas, id }) => {
    return post(`${ROUTES.BILLS}/${id}/createcreditnote`, {
      token,
      body: datas,
    })
  },
  forceDeleteRegistration: ({ token, id }) => {
    return del(`${ROUTES.REGISTRATIONS}/${id}/forcedelete`, { token, id })
  },
  hasBillGenerated: ({ token, id }) => {
    return get(`${ROUTES.BILLS}/${id}/hasbillgenerated`, { token })
  },
  getChildcareHours: ({ token, query }) => {
    return get(`${ROUTES.CHILDCAREHOURS}`, { token, query })
  },
  removePricingGridChildcareHours: ({ token, datas }) => {
    return del(`${ROUTES.CHILDCARE}/pricinggridchildcarehours`, {
      token,
      body: datas,
    })
  },
  createPricingGridChildcareHours: ({ token, add }) => {
    return post(`${ROUTES.CHILDCARE}/pricinggridchildcarehours`, {
      token,
      body: add,
    })
  },
  createChildcareHours: ({ token, add }) => {
    return post(`${ROUTES.CHILDCAREHOURS}`, { token, body: add })
  },
  updateBill: ({ token, datas, id }) => {
    return patch(`${ROUTES.BILLS}/${id}`, { token, body: datas })
  },
  getCompanies: ({ token, query }) => {
    return get(`${ROUTES.COMPANIES}`, { token, query })
  },
  getCompany: ({ id, token }) => {
    return get(`${ROUTES.COMPANIES}/${id}`, { token })
  },
  updateCompany: ({ token, id, params }) => {
    return patch(`${ROUTES.COMPANIES}/${id}`, { token, body: params })
  },
  createCompany: (token, params) => {
    return post(`${ROUTES.COMPANIES}`, { token, body: params })
  },
  deleteCompany: ({ token, id }) => {
    return del(`${ROUTES.COMPANIES}/${id}`, { token })
  },
  generateMultipleBills: ({ token, bills, type }) => {
    return post(`${ROUTES.BILLS}/generatemultiplebills`, {
      token,
      body: { bills, type },
    })
  },
  billingExport: ({ token, id }) => {
    return get(`${ROUTES.BILLS}/${id}/export`, {
      token,
    })
  },
  registrationExport: ({ token, id }) => {
    return get(`${ROUTES.REGISTRATIONS}/${id}/export`, {
      token,
    })
  },
  parentExport: ({ token, id }) => {
    return get(`${ROUTES.REGISTRATIONS}/${id}/export/parents`, {
      token,
    })
  },
  childrenExport: ({ token, id, query }) => {
    return get(`${ROUTES.REGISTRATIONS}/${id}/export/children`, {
      token,
      query,
    })
  },
  headcountExport: ({ token, id, query }) => {
    return get(`${ROUTES.REGISTRATIONS}/${id}/export/headcount`, {
      token,
      query,
    })
  },
  updateClass: ({ token, classSelect, id }) => {
    return patch(`${ROUTES.REGISTRATIONS}/${id}/updateclass`, {
      token,
      body: { classSelect },
    })
  },
  sendFirstConnectionMail: ({ token, ids }) => {
    return post(`${ROUTES.REGISTRATIONS}/sendfirstconnectionmail`, {
      token,
      body: { ids },
    })
  },
  childcareExport: ({ token, query }) => {
    return get(`${ROUTES.CHILDCARE}/export`, { token, query })
  },
  getChilrenRegistered: ({ token, date }) => {
    return get(`${ROUTES.CHILDCARE}/getchilrenregistered/${date}`, { token })
  },
  getChildrenBySchoolClass: ({ token, year }) => {
    return get(`${ROUTES.SCHOOLINGYEAR}/getchildrenbyschoolclass/${year}`, {
      token,
    })
  },
  setChildClassPresence: ({ token, date, morning, afternoon, schoolClass }) => {
    return post(`${ROUTES.SCHOOLINGYEAR}/setchildclasspresence`, {
      token,
      body: { date, morning, afternoon, schoolClass },
    })
  },
  getChildrenPresence: ({ token, date }) => {
    return get(`${ROUTES.SCHOOLINGYEAR}/getchildrenpresence/${date}`, { token })
  },
  classPresenceYearExport: ({ token, year }) => {
    return get(`${ROUTES.SCHOOLINGYEAR}/export/classpresence/year/${year}`, {
      token,
    })
  },
  classPresenceMonthExport: ({ token, query }) => {
    return get(`${ROUTES.SCHOOLINGYEAR}/export/classpresence/month`, {
      token,
      query,
    })
  },
  classPresenceMonthDetailedExport: ({ token, query }) => {
    return get(`${ROUTES.SCHOOLINGYEAR}/export/classpresence/month/detailed`, {
      token,
      query,
    })
  },
  deleteNurseryById: ({ token, id }) => {
    return del(`${ROUTES.CHILDCARE}/${id}`, { token })
  },
  updateSlot: ({ token, params }) => {
    return post(`${ROUTES.CHILDCARE}/updateslot`, {
      token,
      body: { ...params },
    })
  },
  recreateBill: ({ token, id, type, datas }) => {
    return post(`${ROUTES.BILLS}/generate/${id}/${type}`, {
      token,
      body: { datas },
    })
  },
  getSchoolClasses: ({ token, query }) => {
    return get(`${ROUTES.SCHOOLCLASS}`, { token, query })
  },
  closeRegistration: ({ id, token }) => {
    return patch(`${ROUTES.REGISTRATIONS}/${id}/closeregistration`, { token })
  },
  getRegistrationsForCertificate: ({ token }) => {
    return get(`${ROUTES.REGISTRATIONS}/getregistrationscertificate`, { token })
  },
  // ACTIVITIES
  getCategories: (token, { query }) => {
    return get(`${ROUTES.CATEGORIES}`, { token, query })
  },
  getCategoriesCurrent: ({ token }) => {
    return get(`${ROUTES.ACTIVITIES}/categories/current`, { token })
  },
  removeCategory: (token, id) => {
    return del(`${ROUTES.CATEGORIES}/${id}`, { token })
  },
  getCategory: (id, token, query) => {
    return get(`${ROUTES.CATEGORIES}/${id}`, { token, query })
  },
  updateCategory: ({ id, datas, token }) => {
    return patch(`${ROUTES.CATEGORIES}/${id}`, { token, body: datas })
  },
  createCategory: ({ token, datas }) => {
    return post(`${ROUTES.CATEGORIES}`, { token, body: datas })
  },
  getActivities: (token, { query }) => {
    return get(`${ROUTES.ACTIVITIES}`, { token, query })
  },
  removeActivity: (token, id) => {
    return del(`${ROUTES.ACTIVITIES}/${id}`, { token })
  },
  getActivity: (id, token, query) => {
    return get(`${ROUTES.ACTIVITIES}/${id}`, { token, query })
  },
  updateActivity: ({ id, datas, token }) => {
    return patch(`${ROUTES.ACTIVITIES}/${id}`, { token, body: datas })
  },
  createActivity: ({ token, datas }) => {
    return post(`${ROUTES.ACTIVITIES}`, { token, body: datas })
  },
  updateActivityClasses: ({ token, id, formData, headers }) => {
    return post(`${ROUTES.ACTIVITIES}/updateactivityclasses/`, {
      token,
      body: formData,
      headers,
      id,
    })
  },
  getChildrenbySchoolingyear: ({ token, id, abortController }) => {
    return get(`${ROUTES.REGISTRATIONS}/getchildrenbyschoolingyear/${id} `, {
      token,
      abortController,
    })
  },
  getCategoryActivities: (token, query) => {
    return get(`${ROUTES.ACTIVITIES}/getcategoryactivities`, { token, query })
  },
  getCategoriesHavingActivities: ({ id, query, token }) => {
    return get(`${ROUTES.ACTIVITIES}/getcategorieshavingactivities/${id}`, {
      token,
      query,
    })
  },
  getActivityLevels: (token) => {
    return get(`${ROUTES.ACTIVITYLEVELS}`, { token })
  },
  validateChildSkill: ({ token, formData, headers }) => {
    return post(`${ROUTES.ACTIVITIES}/new`, {
      token,
      body: formData,
      headers: headers,
    })
  },
  getActivitiesCategoriesPDF: ({ token, query }) => {
    return get(`${ROUTES.ACTIVITIES}/getactivitiescategoriespdf`, {
      token,
      query,
    })
  },
  getActivitiesViews: ({ token, query }) => {
    return get(`${ROUTES.ACTIVITIESVIEWS}`, { token, query })
  },
  getProgressions: ({ token, query }) => {
    return get(`${ROUTES.PROGRESSIONS}`, { token, query })
  },
  createProgression: ({ token, datas }) => {
    return post(`${ROUTES.ACTIVITIES}/createprogression`, {
      token,
      body: datas,
    })
  },
  removeProgression: ({ token, id }) => {
    return del(`${ROUTES.PROGRESSIONS}/${id}`, { token })
  },
  getProgression: ({ token, id, query }) => {
    return get(`${ROUTES.PROGRESSIONS}/${id}`, { token, query })
  },
  updateProgression: ({ token, id, datas }) => {
    return patch(`${ROUTES.ACTIVITIES}/progression/${id}`, {
      token,
      body: datas,
    })
  },
  getActivitiesRecommanded: ({ token, query }) => {
    return get(`${ROUTES.ACTIVITIES}/getactivitiesrecommanded`, {
      token,
      query,
    })
  },
  importActivities: ({ token, formData, headers }) => {
    return post(`${ROUTES.ACTIVITIES}/import`, {
      token,
      body: formData,
      headers: headers,
    })
  },
  getActivityImage: ({ token, fileId }) => {
    return get(`${ROUTES.ACTIVITIES}/${fileId}/activityimage`, { token })
  },
  deleteActivityImage: ({ token, id }) => {
    return del(`${ROUTES.ACTIVITIES}/${id}/activityimage`, { token })
  },
  deleteChildrenActivityImage: ({ token, id }) => {
    return del(`${ROUTES.ACTIVITIES}/${id}/childrenactivityimage`, { token })
  },
  downloadActivityImage: ({ token, id }) => {
    return post(`${ROUTES.ACTIVITIES}/${id}/downloadfile`, { token })
  },
  searchNationalLabel: ({ token, query }) => {
    return get(`${ROUTES.ACTIVITIES}/searchnationallabel`, { token, query })
  },
  getAllRecommandedActivities: ({ token, schoolingYear, schoolClass }) => {
    return get(
      `${ROUTES.ACTIVITIES}/getallrecommandedactivities/${schoolingYear}/${schoolClass}`,
      { token }
    )
  },
  getChildrenHavingActivityUnDone: ({ token, query }) => {
    return get(`${ROUTES.ACTIVITIES}/getchildrenhavingactivityundone`, {
      token,
      query,
    })
  },

  getSubDomains: ({ token, query }) => {
    return get(`${ROUTES.SUBDOMAINS}`, { token, query })
  },
  getSubDomain: ({ token, id, query }) => {
    return get(`${ROUTES.SUBDOMAINS}/${id}`, { token, query })
  },
  createSubDomain: ({ token, datas }) => {
    return post(`${ROUTES.ACTIVITIES}/createsubdomain`, { token, body: datas })
  },
  removeSubDomain: ({ token, id }) => {
    return del(`${ROUTES.SUBDOMAINS}/${id}`, { token })
  },
  updateSubDomain: ({ token, id, datas }) => {
    return patch(`${ROUTES.ACTIVITIES}/subdomain/${id}`, {
      token,
      body: datas,
    })
  },
  getDomains: ({ token, query }) => {
    return get(`${ROUTES.DOMAINS}`, { token, query })
  },
  getDomain: ({ token, id, query }) => {
    return get(`${ROUTES.DOMAINS}/${id}`, { token, query })
  },
  createDomain: ({ token, datas }) => {
    return post(`${ROUTES.ACTIVITIES}/createdomain`, { token, body: datas })
  },
  removeDomain: ({ token, id }) => {
    return del(`${ROUTES.DOMAINS}/${id}`, { token })
  },
  updateDomain: ({ token, id, datas }) => {
    return patch(`${ROUTES.ACTIVITIES}/domain/${id}`, {
      token,
      body: datas,
    })
  },
  saveObservation: (token, params) => {
    return post(`${ROUTES.OBSERVATIONS}`, { token, body: { ...params } })
  },
  updateObservation: (token, params, id) => {
    return patch(`${ROUTES.OBSERVATIONS}/${id}`, { token, body: { ...params } })
  },
}

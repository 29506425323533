import React, { useMemo, useState, useCallback } from 'react'
import Table from 'components/common/table'
import useRegistration from 'hooks/useRegistration'
import useBill from 'hooks/useBill'
import { useHistory } from 'react-router-dom'
import { dateFmt } from 'components/common/table/formats'
import { DefaultColumnFilter } from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'
import { useForm } from 'react-hook-form'
import Confirm from 'components/common/ConfirmModal'

import {
  Actions,
  RejectAction,
  ArchiveAction,
  ValidAction,
  RestoreAction,
  CloseAction,
} from 'components/common/table/actions'

import { useNotify } from 'stores/notify'
import { REGISTRATION_STATUS, BILL_TYPE } from 'lib/constants'
import CheckBox from '../form/CheckBox'

const initialPageSize = 20
const initialSortBy = [
  {
    id: 'updatedAt',
    desc: true,
  },
]

const MyModelsList = () => {
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const form = useForm()
  const { control } = form

  const [forceRefresh, setForceRefresh] = useState(1)

  const {
    getRegistrations,
    deleteRegistration,
    validRegistration,
    updateRegistration,
    restoreRegistration,
    sendFirstConnectionMail,
    closeRegistration,
  } = useRegistration()

  const { generateMultipleBills } = useBill()

  const onError = useCallback(
    (err) => {
      if (err && err.statusCode && err.statusCode === 401) {
        setErrorMessage(err.message)
        history.replace('/')
      } else {
        setErrorMessage('Une erreur est survenue')
      }
    },
    [history, setErrorMessage]
  )

  const fetchData = useApiFetch(getRegistrations, onError)

  const [removed, setRemoved] = useState(null)
  const [restored, setRestore] = useState(null)

  const [shouldDisplayOnGoing, setShouldDisplayOnGoing] = useState(false)
  const [shouldDisplayArchived, setShouldDisplayArchived] = useState(false)

  const onArchive = useCallback(
    async (id) => {
      const req = await deleteRegistration(id)
      if (req.results && req.results.success) {
        setSuccessMessage(`Elément archivé avec succès`)
        setRemoved(id)
      } else {
        setErrorMessage(`Impossible à archiver: ${req.error.message}`)
      }
    },
    [deleteRegistration, setErrorMessage, setSuccessMessage]
  )

  const onRestore = useCallback(
    async (id) => {
      const req = await restoreRegistration(id)
      if (req.error) {
        setErrorMessage(`Impossible de récupérer: ${req.error.message}`)
        return
      }
      setSuccessMessage(`Elément récupéré avec succès`)
      setRestore(id)
    },
    [restoreRegistration, setErrorMessage, setSuccessMessage]
  )

  const onValidRegistration = async (id) => {
    try {
      const test = await validRegistration(id)
      if (test.error) {
        throw new Error(test.error.message)
      }
      setSuccessMessage("L'inscription a été validée")
    } catch (err) {
      setErrorMessage(err.message)
    }
  }

  const onCloseRegistration = async (id) => {
    try {
      const registration = await closeRegistration(id)
      if (registration.error) {
        throw new Error(registration.error.message)
      }
    } catch (err) {
      setErrorMessage(err.message)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Sélection',
        accessor: 'id',
        disableFilters: true,
        className: 'font-bold',
        Cell: ({ value }) => {
          return (
            <div onClick={(e) => e.stopPropagation()} className="p-3">
              <CheckBox
                key={`registration-${value}`}
                form={form}
                control={control}
                name={`registration-${value}`}
              />
            </div>
          )
        },
      },
      {
        Header: 'Nom enfant',
        accessor: 'lastname', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Prénom enfant',
        accessor: 'firstname', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Année scolaire',
        accessor: 'year', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Rentrée',
        accessor: 'month', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Modifié le',
        accessor: 'updatedAt',
        disableFilters: true,
        className: 'whitespace-no-wrap',
        Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: 'Supprimé le',
        accessor: 'deletedAt',
        disableFilters: true,
        className: 'whitespace-no-wrap',
        Cell: ({ value }) => value && dateFmt(value),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: DefaultColumnFilter,
        classname: 'font-bold',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {/* { REGISTRATION_STATUS[original.status]} */}
              {original.status}
            </div>
          )
        },
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableFilters: true,
        Cell: (props) => {
          const status = props.row.original.status
          return (
            <Actions>
              {status !== REGISTRATION_STATUS.accept && (
                <ValidAction
                  entity={props.value}
                  message={'Accepter le dossier'}
                  validation={onValidRegistration}
                  setForceRefresh={setForceRefresh}
                  title={"Valider l'inscription"}
                  name={`${props.row.original.firstname} ${props.row.original.lastname}`}
                  year={props.row.original.year}
                />
              )}
              {status !== REGISTRATION_STATUS.reject && (
                <RejectAction
                  entity={props.value}
                  message={'Refuser le dossier'}
                  reject={() =>
                    updateRegistration({
                      status: REGISTRATION_STATUS.reject,
                      id: props.value,
                    })
                  }
                  setForceRefresh={setForceRefresh}
                  title={"Refuser l'inscription"}
                  name={`${props.row.original.firstname} ${props.row.original.lastname}`}
                  year={props.row.original.year}
                />
              )}
              {props.row.original.deletedAt ? (
                <RestoreAction onRestore={onRestore} {...props} />
              ) : (
                <ArchiveAction
                  onArchive={onArchive}
                  {...props}
                  title={`Archiver cette inscription`}
                  message={`Êtes-vous sûr de vouloir archiver le dossier de ${props.row.original.firstname} ${props.row.original.lastname} (${props.row.original.year}) &nbsp;?`}
                />
              )}
              {status === REGISTRATION_STATUS.accept && (
                <CloseAction
                  entity={props.value}
                  validation={onCloseRegistration}
                  setForceRefresh={setForceRefresh}
                  title={"Fermer l'inscription"}
                  message={`Fermer l'inscription ${props.row.original.firstname} ${props.row.original.lastname} (${props.row.original.year})&nbsp;?`}
                />
              )}
            </Actions>
          )
        },
      },
    ],
    // eslint-disable-next-line
    []
  )

  const addRegistration = useCallback(() => {
    history.push('/schoolingyear/new')
  }, [history])

  const toSummary = useCallback(
    (id) => {
      history.push(`/summary/${id}`)
    },
    [history]
  )

  const getAllPopulatedValue = () => {
    let values = form.getValues()
    let v = []
    Object.entries(values).forEach((val) => {
      if (val[1] !== undefined && val[1] !== false) {
        v.push(val[0].toString().replace('registration-', ''))
      }
    })
    return v
  }

  const onGenerateBills = async (type) => {
    const bills = getAllPopulatedValue()
    let t =
      type === BILL_TYPE.registration
        ? 'Voulez-vous générer les factures de frais de scolarité ?'
        : "Voulez-vous générer les factures de frais d'inscription ? "
    if (await Confirm(t)) {
      try {
        const b = await generateMultipleBills(bills, type)
        let numberBillGenerated = b.results.length
        let numberBillAlreadyGenerated = bills.length - b.results.length
        setSuccessMessage(
          `${numberBillGenerated} ${
            numberBillGenerated > 1 ? 'factures générées' : 'facture générée'
          }, ${numberBillAlreadyGenerated} ${
            numberBillAlreadyGenerated > 1
              ? 'étaient déjà générées'
              : 'était déjà générée'
          }`
        )
      } catch (err) {
        setErrorMessage('Impossible de générer les factures')
      }
    }
  }

  const onSendingMail = async () => {
    let ids = getAllPopulatedValue()
    if (ids.length === 0) return
    if (
      await Confirm(
        'Envoyer un e-mail de première connexion aux utilisateurs sélectionnés ?'
      )
    ) {
      try {
        const mail = await sendFirstConnectionMail(ids)
        if (mail.error) {
          throw new Error(mail.error.message)
        }
        setSuccessMessage(
          `E-mail envoyé à ${mail.results.mailSent} ${
            mail.results.mailSent > 1 ? 'personnes' : 'personne'
          }
          ${
            mail.results.mailNotSent > 0
              ? `, déjà envoyé pour ${mail.results.mailNotSent}
          ${mail.results.mailNotSent > 1 ? 'personnes' : 'personne'}`
              : ''
          }
          `
        )
      } catch (err) {
        setErrorMessage("Impossible d'envoyer d'e-mail")
      }
    }
  }

  return (
    <div className="flex justify-center w-full pt-12">
      <Table
        fetchData={fetchData}
        name="Liste des dossiers"
        initialPageSize={initialPageSize}
        columns={columns}
        rowRemoved={removed}
        rowRestored={restored}
        initialSortBy={initialSortBy}
        addEntity={addRegistration}
        forceRefresh={forceRefresh}
        onLineClick={toSummary}
        defaultFiltersOptions={{
          pending: {
            shouldDisplay: shouldDisplayOnGoing,
            toggleDisplay: () => setShouldDisplayOnGoing(!shouldDisplayOnGoing),
            filter: ['status', '!=', 'En cours'],
          },
          archived: {
            shouldDisplay: shouldDisplayArchived,
            toggleDisplay: () =>
              setShouldDisplayArchived(!shouldDisplayArchived),
            filter: ['deletedAt', '=', null],
          },
        }}
        generateRegistrationBill={() => onGenerateBills(BILL_TYPE.registration)}
        generateRegistrationFeesBill={() =>
          onGenerateBills(BILL_TYPE.registrationFees)
        }
        sendFirstConnectionMail={() => onSendingMail()}
      />
    </div>
  )
}

export default MyModelsList

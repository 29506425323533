import React, { useEffect, useState, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Input from 'components/form/Input'
import StepButtons from './common/StepButtons'
import Select from 'components/form/Select'
import File from 'components/form/File'
import Spinner from './common/Spinner'

export default function SetActivity({
  onSubmit,
  goBack,
  activity,
  id,
  loading,
  schoolClasses,
  categories,
  subdomains,
  image,
  onDownloadFile,
  onDeleteFile,
  searchNationalLabel,
}) {
  const form = useForm()
  const {
    register,
    handleSubmit,
    errors,
    reset,
    watch,
    control,
    setValue,
  } = form

  const [formattedSchoolClasses, setFormattedSchoolClasses] = useState([])
  const [
    formattedCategoriesActivities,
    setFormattedCategoriesActivities,
  ] = useState([])
  const [activityClasses, setActivityClasses] = useState([])
  const [preview, setPreview] = useState(null)
  const [nationalLabelList, setNationalLabelList] = useState([])
  const [subdomainFormatted, setSubdomainFormatted] = useState()
  const [nationalLabelFormatted, setNationalLabelFormatted] = useState()

  const onFormSubmit = useCallback(
    async (datas) => {
      await onSubmit(datas)
      // setPreview(null)
      // setValue('activityImage', null)
      // reset()
    },
    [onSubmit]
  )

  useEffect(() => {
    if (!activity && loading.prevState === false && loading.current === true) {
      reset()
    }
  }, [loading, reset, activity])

  useEffect(() => {
    if (schoolClasses) {
      let format = schoolClasses
        .map((schoolClass) => {
          return {
            label: schoolClass.name,
            value: schoolClass.id,
          }
        })
        .filter(
          (schoolClass) =>
            schoolClass.label !== '3-6' && schoolClass.label !== '6-9'
        )
      setFormattedSchoolClasses(format)
    }
  }, [schoolClasses])

  useEffect(() => {
    if (activity && schoolClasses) {
      let activitiesClasses = activity.ActivitiesClasses.map((a) => {
        return {
          value: a.SchoolClassId,
          label: schoolClasses.find((s) => s.id === a.SchoolClassId)?.name,
        }
      })

      let categoriesActivities = activity.CategoriesActivities.map((ca) => {
        return {
          label: ca.Category.name,
          value: ca.CategoryId,
        }
      })

      setFormattedCategoriesActivities(categoriesActivities)
      setActivityClasses(activitiesClasses)

      if (activity.SubDomainId) {
        setSubdomainFormatted(activity.SubDomainId)
      }

      if (activity && activity?.nationalLabel) {
        setNationalLabelFormatted(activity.nationalLabel)
        let defaultValue = {
          label: activity.nationalLabel,
          value: activity.nationalLabel,
        }
        setNationalLabelList([defaultValue])
        setValue('nationalLabel', defaultValue)
      }
    } else if (schoolClasses && schoolClasses.length) {
      // On creating new activity, set all schoolClasses by default
      let tmpClasses = schoolClasses
        .map((schoolClass) => {
          return {
            label: schoolClass.name,
            value: schoolClass.id,
          }
        })
        .filter((c) => c.label !== '3-6' && c.label !== '6-9')
      setActivityClasses(tmpClasses)
    }
  }, [activity, schoolClasses, setValue])

  // set preview
  const imageWatch = watch('activityImage')
  useEffect(() => {
    if (imageWatch) {
      setPreview(imageWatch[0]?.preview?.url)
    }
  }, [imageWatch])

  // useEffect(() => {
  //   if (image) {
  //     setValue('activityImage', [image])
  //   } else {
  //     setValue('activityImage', undefined)
  //   }
  // }, [image, setValue])

  const nationalLabelWatch = watch('nationalLabel')
  useEffect(() => {
    if (
      nationalLabelWatch &&
      nationalLabelWatch.value &&
      nationalLabelWatch.__isNew__
    ) {
      setNationalLabelList([...nationalLabelList, nationalLabelWatch])
      setNationalLabelFormatted(nationalLabelWatch.value)
    }
  }, [nationalLabelWatch, nationalLabelList])

  const getNationalLabel = async (text) => {
    // clearTimeout(nationalLabelRef.current)

    // if (text && text.length > 2) {
    return new Promise(async (resolve, reject) => {
      try {
        const nationalLabelList = await searchNationalLabel(text)
        let formatted = nationalLabelList.results.map((e) => {
          return {
            value: e.nationalLabel,
            label: e.nationalLabel,
          }
        })
        setNationalLabelList(formatted)
        resolve(formatted)
      } catch (err) {
        console.log('Impossible de récupérer les compétences')
        reject(err)
      }
      // }, 200)
    })
  }

  return (
    <>
      <div className="flex items-center justify-center mt-16">
        <form
          className="form-container w-full sm:w-full xl:w-1/2"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <div className="font-black mb-4 text-center">
            {`${!isNaN(id) ? 'Modifier ' : 'Ajouter'} une activité`}
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'name'}
              ref={register({ required: 'Champ obligatoire' })}
              type="text"
              name="name"
              label="Nom de l'activité"
              placeholder="Nom de l'activité"
              error={errors.name && errors.name.message}
              defaultValue={activity && activity.name}
              value={watch('name')}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Controller
              key={`${JSON.stringify(formattedCategoriesActivities)}`}
              name="category"
              control={control}
              placeholder="Choisir une catégorie"
              rules={{
                validate: (datas) => {
                  if (datas && datas.length > 0) {
                    return true
                  } else {
                    return 'Champ obligatoire'
                  }
                },
              }}
              value={formattedCategoriesActivities}
              defaultValue={formattedCategoriesActivities}
              render={({ name, onChange, value, defaultValue }) => (
                <div className="flex flex-col w-full">
                  <Select
                    name={name}
                    defaultValue={defaultValue}
                    className="w-full"
                    value={value}
                    values={categories}
                    label="Catégories Montessori"
                    isMulti
                    onChange={(e) => onChange(e)}
                  />
                  {errors?.category && (
                    <div className="text-sm text-red-500">
                      {errors?.category.message}
                    </div>
                  )}
                </div>
              )}
            />
          </div>

          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Controller
              key={`${JSON.stringify(subdomainFormatted)}_1`}
              name="subdomain"
              control={control}
              placeholder="Choisir un sous-domaine"
              value={subdomainFormatted}
              defaultValue={subdomainFormatted}
              render={({ name, onChange, value, defaultValue }) => (
                <div className="flex flex-col w-full">
                  <Select
                    name={name}
                    defaultValue={defaultValue}
                    className="w-full"
                    value={value}
                    values={subdomains}
                    label="Sous-domaine"
                    onChange={(e) => onChange(e)}
                  />
                  {errors?.subdomain && (
                    <div className="text-sm text-red-500">
                      {errors?.subdomain.message}
                    </div>
                  )}
                </div>
              )}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Controller
              name="nationalLabel"
              control={control}
              render={({ value, name, onChange, defaultValue }) => (
                <div className="flex flex-col w-full">
                  <Select
                    creatable={true}
                    loadOptions={async (e) => {
                      return getNationalLabel(e)
                    }}
                    formatCreateLabel={(inputText) => `Créer ${inputText}`}
                    name={name}
                    label="Compétence éducation national"
                    className="w-full"
                    value={nationalLabelFormatted}
                    onChange={(e) => {
                      if (e) {
                        onChange(e)
                        setNationalLabelFormatted(e.value || e)
                      }
                    }}
                    values={nationalLabelList}
                    defaultOptions
                    filterOption={() => true}
                    loadingMessage={(_) => 'Chargement en cours'}
                  />
                  {errors?.schoolClasses && (
                    <div className="text-sm text-red-500">
                      {errors?.nationalLabel.message}
                    </div>
                  )}
                </div>
              )}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'age'}
              ref={register({ required: 'Champ obligatoire' })}
              type="number"
              name="age"
              label="Age recommandé"
              placeholder="Age recommandé"
              error={errors.age && errors.age.message}
              defaultValue={activity && activity.age}
              value={watch('age')}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Controller
              key={JSON.stringify(activityClasses)}
              name="schoolClasses"
              placeholder="Classes"
              rules={{
                validate: (datas) => {
                  if (datas && datas.length > 0) {
                    return true
                  } else {
                    return 'Champ obligatoire'
                  }
                },
              }}
              control={control}
              value={activityClasses}
              defaultValue={activityClasses}
              render={({ value, name, onChange, defaultValue }) => (
                <div className="flex flex-col w-full">
                  <Select
                    name={name}
                    label="Classes"
                    className="w-full"
                    value={value}
                    onChange={(e) => onChange(e)}
                    isMulti
                    values={formattedSchoolClasses}
                    defaultValue={defaultValue}
                  />
                  {errors?.schoolClasses && (
                    <div className="text-sm text-red-500">
                      {errors?.schoolClasses.message}
                    </div>
                  )}
                </div>
              )}
            />
          </div>
          <div
            className={`flex w-full md:flex-row flex-col ${
              !image && !preview ? 'justify-center' : 'justify-evenly'
            } `}
          >
            {(image || preview) && (
              <div className="flex w-full md:flex-row flex-col my-2 justify-evenly p-2">
                {image === null && <Spinner />}
                {image && image.path ? (
                  <div>
                    <img
                      alt="Activité"
                      src={`data:${image.type};base64,${image.path}`}
                      className="object-contain"
                    />
                  </div>
                ) : preview ? (
                  <img
                    alt="Activité"
                    src={preview}
                    className="object-contain"
                  />
                ) : null}
              </div>
            )}
            <Controller
              key={JSON.stringify(`files/${image}`)}
              name="activityImage"
              control={control}
              rules={
                {
                  // required: t('Champ obligatoire'),
                  // max: { value: 1, message: 'Too much files' },
                }
              }
              defaultValue={image && image.length > 0}
              render={(props, name) => {
                return (
                  <>
                    <File
                      name={name}
                      label={"Image de l'activité"}
                      className="files-dropzone"
                      form={form}
                      compress
                      {...props}
                      options={{ limit: 1, customRemove: onDeleteFile }}
                      onDownloadFile={onDownloadFile}
                    />
                    {errors && errors['activityImage'] && (
                      <div className="text-sm text-red-500">
                        {errors['activityImage'].message}
                      </div>
                    )}
                  </>
                )
              }}
            />
          </div>

          <StepButtons
            loading={loading.current}
            goBack={goBack}
            nextLabel={'Enregistrer'}
          />
        </form>
      </div>
    </>
  )
}

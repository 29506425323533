import React, { useMemo, useState, useCallback, useEffect } from 'react'
import Table from 'components/common/table'
import useActivity from 'hooks/useActivity'
import useSchoolingYear from 'hooks/useSchoolingYear'
import { useHistory } from 'react-router-dom'
import {
  SearchIdByString,
  IDefaultColumnFilter,
} from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'

import {
  Actions,
  EditAction,
  RemoveAction,
} from 'components/common/table/actions'

import { useNotify } from 'stores/notify'

const initialPageSize = 10
const initialSortBy = [
  {
    id: 'color',
    desc: false,
  },
  {
    id: 'name',
    desc: false,
  },
]

const MyModelsList = () => {
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const [forceRefresh] = useState(1)
  const [schoolingyears, setSchoolingyears] = useState([])

  const { getCategories, removeCategory } = useActivity()

  const { getSchoolingdate } = useSchoolingYear()

  const onError = useCallback(
    (err) => {
      if (err && err.statusCode && err.statusCode === 401) {
        setErrorMessage(err.message)
        history.replace('/')
      } else {
        setErrorMessage('Une erreur est survenue')
      }
    },
    [history, setErrorMessage]
  )

  const fetchData = useApiFetch(getCategories, onError)

  const [removed, setRemoved] = useState(null)

  const onRemove = useCallback(
    async (id) => {
      const req = await removeCategory(id)
      if (req.error) {
        setErrorMessage(req.error.message)
        return
      }

      if (req.results && req.results.success) {
        setSuccessMessage(`Elément supprimé avec succès`)
        setRemoved(id)
      } else {
        setErrorMessage(`Impossible de supprimer: ${req.error.message}`)
      }
    },
    [removeCategory, setErrorMessage, setSuccessMessage]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Couleur',
        accessor: 'color', // accessor is the "key" in the data
        disableFilters: true,
        className: 'font-bold',
        Cell: (props) => {
          const [first, second] = props.row.original.color.split(':')
          return (
            <div
              style={{
                width: '50px',
                height: '50px',
                background: `linear-gradient(-45deg, #${first} 50%, #${
                  second || first
                } 50%)`,
              }}
              className="border border-black flex"
            ></div>
          )
        },
      },
      {
        Header: 'Nom',
        accessor: 'name', // accessor is the "key" in the data
        Filter: IDefaultColumnFilter,
        className: 'font-bold',
      },

      {
        Header: 'Image',
        accessor: 'image', // accessor is the "key" in the data
        disableFilters: true,
        className: 'font-bold',
      },
      {
        Header: 'Description',
        accessor: 'description', // accessor is the "key" in the data
        disableFilters: true,
        className: 'font-bold',
      },
      {
        Header: 'Année',
        accessor: 'SchoolingYearId', // accessor is the "key" in the data
        // disableFilters: true,
        className: 'font-bold',
        Filter: (props) => SearchIdByString(props, schoolingyears, 'year'),
        Cell: (props) => {
          return (
            <div>
              {schoolingyears && schoolingyears.length > 0 && (
                <>
                  {
                    schoolingyears.find(
                      (schoolingyear) =>
                        schoolingyear.id === props.row.original.SchoolingYearId
                    )?.year
                  }
                </>
              )}
            </div>
          )
        },
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableFilters: true,
        Cell: (props) => (
          <Actions>
            <EditAction
              entity={props.value}
              message={'Voulez-vous modifier cette catégorie '}
              to={`/setcategory/${props.value}`}
            />
            <RemoveAction
              onRemove={onRemove}
              {...props}
              title={`Êtes-vous sûr de vouloir supprimer la catégorie ${props.row.original.name}&nbsp;?`}
            />
          </Actions>
        ),
      },
      // eslint-disable-next-line
    ],
    [onRemove, schoolingyears]
  )

  const addCategory = useCallback(() => {
    history.push('/setcategory/new')
  }, [history])

  useEffect(() => {
    async function loadSchoolingYear() {
      const s = await getSchoolingdate()
      setSchoolingyears(s.results)
    }
    loadSchoolingYear()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="flex justify-center w-full pt-12">
      <Table
        fetchData={fetchData}
        name="Liste des catégories"
        initialPageSize={initialPageSize}
        columns={columns}
        rowRemoved={removed}
        initialSortBy={initialSortBy}
        addEntity={addCategory}
        forceRefresh={forceRefresh}
      />
    </div>
  )
}

export default MyModelsList

import React from 'react'

// import Logo from 'components/svg/logo'
import montessori from 'assets/images/logo_ecole_montessori_pau.jpg'
import { Link } from 'react-router-dom'

export default function LoggedOutHeader() {
  return (
    <div className="flex flex-col items-center md:items-center md:place-content-center mt-32 mb-2">
      <Link to="/">
        <img alt="logo-montessori" src={montessori}></img>
        {/* <Logo style={{ width: '200px', opacity: '1', fill: '#59227F' }} /> */}
      </Link>
      <span className="text-brand font-bold text-3xl pl-4 ">
        École Montessori
      </span>
    </div>
  )
}

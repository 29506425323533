import React from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import LoggedOutHeader from 'components/common/LoggedOutHeader'
import Input from 'components/form/Input'
import { useTranslation } from 'react-i18next'
import useLanguage from 'hooks/useLanguage'
import Button from 'components/form/Button'

import FrenchFlag from 'components/svg/frenchFlag'
import UsFlag from 'components/svg/usFlag'

const LoginComponent = ({ onSubmit, loading }) => {
  const { register, handleSubmit, watch, errors } = useForm()
  const { t } = useTranslation()
  const { changeLanguage } = useLanguage()

  return (
    <div>
      <LoggedOutHeader />
      <div className="flex items-center justify-center align-middle">
        <div className="flex flex-col w-full md:w-3/4 lg:w-1/2 px-8 pt-6 pb-8 mb-4 ">
          <form
            className="form-container w-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* <div className="login-form "> */}

            <Input
              key={`section-email`}
              ref={register({
                required: t('Champ obligatoire'),
                pattern: {
                  value:
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: t('Adresse e-mail non valide'),
                },
              })}
              type="text"
              name="email"
              label={t('E-mail')}
              placeholder={t('E-mail')}
              value={watch('email')}
              error={errors.email && errors.email.message}
              required={t('Champ obligatoire')}
              autofocus={true}
            />

            <Input
              key={`section-password`}
              ref={register({ required: t('Champ obligatoire') })}
              type="password"
              name="password"
              label={t('Mot de passe')}
              placeholder={t('Mot de passe')}
              value={watch('password')}
              error={errors.password && errors.password.message}
              required={t('Champ obligatoire')}
            />

            <div className="flex flex-col w-full pt-6 pb-8 mb-4 ">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <Button
                  variant="black"
                  loading={loading}
                  submits
                >
                  {t('Se connecter')}
                </Button>
                <div className="py-3 px-4">
                  <Link
                    to="/forgot-password"
                    className="bg-white text-accent py-3 px-4 rounded"
                  >
                    {t('Mot de passe oublié ?')}
                  </Link>
                </div>
              </div>
            </div>
            <div className="py-3 px-4">
              <Link
                to="/signup"
                className="bg-white text-accent rounded hover:text-blue-500"
              >
                {t("Pas encore inscrit ? Inscrivez-vous")}
              </Link>

            </div>

            <div className='flex w-1/2 justify-center'>
              <div className="px-2 hover:text-blue-600 flex flex-col align-center justify-center"
                onClick={() => changeLanguage('fr')}
              >
                <div className='flex align-center justify-center '>
                  <div className="flex items-center">
                    <FrenchFlag className="fill-current" />
                  </div>
                  <div className="pl-2 flex-1 flex items-center hover:cursor-pointer">
                    {t('Français')}
                  </div>
                </div>
              </div>
              <div className="px-2 hover:text-blue-600"
                onClick={() => changeLanguage('eng')}
              >
                <div className='flex align-center justify-start'>
                  <div className="flex items-center">
                    <UsFlag className="fill-current" />
                  </div>
                  <div className="pl-2 flex-1 flex items-center hover:cursor-pointer">
                    {t('Anglais')}
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginComponent

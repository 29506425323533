import React, { useState, useEffect } from 'react'

const DefaultColumnFilter = (props) => {
  const {
    column: { filterValue, /*preFilteredRows,*/ setFilter, filterOptions },
    totalRows,
    // pageCount,
    // state: { pageSize },
  } = props
  // const count = preFilteredRows.length
  let placeholder = ``
  if (filterOptions && filterOptions.placeholder) {
    placeholder = filterOptions.placeholder(totalRows)
  }
  return (
    <input
      className="w-full px-2 py-1 text-xs rounded-sm"
      value={(filterValue && filterValue.value) || ''}
      onChange={(e) => {
        if (e.target.value) {
          // console.log('LIKEEEE')
          setFilter({
            value: e.target.value,
            operator: 'like',
          })
        } else {
          setFilter(undefined)
        }
      }}
      placeholder={placeholder}
    />
  )
}

const IDefaultColumnFilter = (props) => {
  const {
    column: { filterValue, /*preFilteredRows,*/ setFilter, filterOptions },
    totalRows,
    // pageCount,
    // state: { pageSize },
  } = props
  // const count = preFilteredRows.length
  let placeholder = ``
  if (filterOptions && filterOptions.placeholder) {
    placeholder = filterOptions.placeholder(totalRows)
  }
  return (
    <input
      className="w-full px-2 py-1 text-xs rounded-sm"
      value={(filterValue && filterValue.value) || ''}
      onChange={(e) => {
        if (e.target.value) {
          // console.log('LIKEEEE')
          setFilter({
            value: e.target.value,
            operator: 'ilike',
          })
        } else {
          setFilter(undefined)
        }
      }}
      placeholder={placeholder}
    />
  )
}

const EqualDefaultFilter = (props) => {
  const {
    column: { filterValue, /*preFilteredRows,*/ setFilter, filterOptions },
    totalRows,
    // pageCount,
    // state: { pageSize },
  } = props
  // const count = preFilteredRows.length
  let placeholder = ``
  if (filterOptions && filterOptions.placeholder) {
    placeholder = filterOptions.placeholder(totalRows)
  }
  return (
    <input
      className="w-full px-2 py-1 text-xs rounded-sm"
      value={(filterValue && filterValue.value) || ''}
      onChange={(e) => {
        if (e.target.value) {
          // console.log('LIKEEEE')
          setFilter({
            value: e.target.value,
            operator: '=',
          })
        } else {
          setFilter(undefined)
        }
      }}
      placeholder={placeholder}
    />
  )
}


const DateColumnFilter = (props) => {
  const {
    column: { setFilter, filterOptions },
    totalRows,
    // state: { pageSize },
  } = props
  let placeholder = `recherche parmi ${totalRows} lignes`
  const [before, setBefore] = useState()
  const [after, setAfter] = useState()

  if (filterOptions && filterOptions.placeholder) {
    placeholder = filterOptions.placeholder(totalRows)
  }

  useEffect(() => {
    let filters = []

    if (before) {
      filters.push({
        value: before,
        operator: '<=',
      })
    }

    if (after) {
      filters.push({
        value: after,
        operator: '>=',
      })
    }

    if (setFilter) {
      setFilter(filters)
    }

    // remove setFilter from deps, it cause too much rerender
    //eslint-disable-next-line
  }, [before, after])

  return (
    <>
      <div>
        De
        <input
          className="pl-1"
          type="date"
          value={after}
          onChange={(e) => {
            if (e.target.value) {
              setAfter(e.target.value)
            }
          }}
          placeholder={placeholder}
        />
      </div>
      <div>
        à
        <input
          className="pl-1"
          type="date"
          value={before}
          onChange={(e) => {
            if (e.target.value) {
              setBefore(e.target.value)
            }
          }}
          placeholder={placeholder}
        />
      </div>
    </>
  )
}

const SearchIdByString = (props, list, key) => {
  /**
   * Search in array (list) a string (key), and return id to server
   * 
   * Ex : 
   * list = [{id: 1, year: 2021}, { id: 2, year: 2022}]
   * key = year
   * 
   * Search year, the filter will be the id
   * 
   */
  const {
    column: { setFilter, filterOptions },
    totalRows,
  } = props
  let placeholder = ``
  if (filterOptions && filterOptions.placeholder) {
    placeholder = filterOptions.placeholder(totalRows)
  }
  return (
    <input
      className="w-full px-2 py-1 text-xs rounded-sm"
      onChange={(e) => {
        if (e.target.value) {

          const sanitizeValue = e.target.value
          .replace(/[^a-zA-Z0-9]/g, '')
          // remove special chars for creating regexp

          const regexp = new RegExp(`${sanitizeValue}`)

          let id
          list.forEach(element => {
            if (element[key].match(regexp)) {
              id = element.id
            }
          })

          setFilter({
            value: list.find(element => {
              if (element.id === id) {
                return id
              }
              return 0
            })?.id || 0,
            operator: '=',
          })
        } else {
          setFilter(undefined)
        }
      }}
      placeholder={placeholder}
    />
  )
}

export { DefaultColumnFilter, DateColumnFilter, EqualDefaultFilter, SearchIdByString, IDefaultColumnFilter }

import React, { useState, useEffect } from 'react'
import SetSchoolingDate from 'components/SetSchoolingDate'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import useSchoolingYear from '../hooks/useSchoolingYear'
import { useNotify } from '../stores/notify'
import LayoutAdmin from 'components/LayoutAdmin'

import { MONTHS } from '../lib/constants'
import usePricing from 'hooks/usePricing'
import { isFormChanged, numberToTime, timeToNumber } from 'lib/utils'

export default function SetSchoolingDatePage() {
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()
  const { getAllPricingsGrids } = usePricing()
  const {
    getOneSchoolingDate,
    updateSchoolingDate,
    createSchoolingDate,
    getChildcareHours,
    removePricingGridChildcareHours,
    createPricingGridChildcareHours,
    createChildcareHours,
  } =
    useSchoolingYear()
  const [selectedDate, setSelectedDate] = useState()
  const [pricingsGrids, setPricingsGrids] = useState([])
  const [pricingChoosen, setPricingChoosen] = useState()
  const [childcareHours, setChildcareHours] = useState()
  const [loading, setLoading] = useState({ isLoading: false, refresh: false })
  const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
      setWarningMessage,
    })
  )

  useEffect(() => {
    async function loadDatas() {
      const currentDate = await getOneSchoolingDate(id)
      if (currentDate.error) {
        setErrorMessage(currentDate.error.message)
        return
      }
      if (currentDate) {
        setSelectedDate(currentDate.results)
        if (currentDate.results.PricingGrid) {
          let selected = {
            label: currentDate.results.PricingGrid.name,
            value: currentDate.results.PricingGrid.id,
          }
          setPricingChoosen(selected)
        }
      }
    }

    async function loadPricingsGrids() {
      const pricings = await getAllPricingsGrids(0, 100, [], '', true)
      if (pricings.error) {
        setErrorMessage(pricings.error.message)
        return
      }
      setPricingsGrids(pricings.results)
    }

    if (id && id !== 'new') {
      loadDatas()
    }
    loadPricingsGrids()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function getHours() {
      try {
        const hours = await getChildcareHours()
        const selectHours = []
        hours.results.forEach(hours => {
          selectHours.push({
            label: `${hours.start}-${hours.end}`,
            value: hours.id
          })
        })
        setChildcareHours(selectHours)
      } catch (err) {
        setErrorMessage(err.message)
      }
    }
    getHours()
  }, [getChildcareHours, setErrorMessage])

  const goBack = () => {
    history.goBack()
  }

  const formatPricingGridChildcareHour = (values, pricingGridId) => {
    let temp = values.map(hours => {
      let [start, end] = hours.label.split('-')
      return {
        start,
        end,
        ChildcareHourId: hours.value,
        PricingGridId: selectedDate?.PricingGridId || pricingGridId
      }
    })
    return temp
  }

  const addChildcareHours = async (add) => {
    let checkFormat = /^\d{1,2}:\d{2}\s?-\s?\d{1,2}:\d{2}$/
    if (!checkFormat.test(add.label)) {
      let error = "Le format n'est pas respecté"
      setErrorMessage(error)
      return { error }
    }
    let [start, end] = add.label.split('-')
    let test = {
      duration: numberToTime(timeToNumber(end) - timeToNumber(start)),
      start: start.split(':')[0].length === 1 ? `0${start}` : start,
      end: end.split(':')[0].length === 1 ? `0${end}` : end,
    }
    try {
      const hours = await createChildcareHours({ add: test })
      setSuccessMessage("L'horaire a été créée")
      return hours
    } catch (err) {
      setErrorMessage(err.message)
    }
  }

  const onSubmit = async (datas) => {
    setLoading({ isLoading: true, refresh: false })
    const pricingsGridId = datas.grilletarifaire
    const date = new Date(datas.date)
    const month = date.getMonth()
    const year = date.getFullYear()
    if (!isNaN(Number(id))) { // update
      if (isFormChanged({
        date: datas.date,
        year,
        status: datas.status,
        pricingsGridId: datas.grilletarifaire
      }, {
        date: selectedDate.date,
        year: Number(selectedDate.year),
        status: selectedDate.status,
        pricingsGridId: selectedDate.PricingGridId
      })) {
        const updateDate = await updateSchoolingDate({
          id: id,
          date: datas.date,
          month: MONTHS[month],
          year,
          status: datas.status,
          pricingsGridId: pricingsGridId,
        })
        if (updateDate.error) {
          setErrorMessage(updateDate.error.message)
          setLoading({ isLoading: false, refresh: false })
          return
        }
        setSelectedDate(updateDate.results)
        setSuccessMessage('Modification effectuée')
        setLoading({ isLoading: false, refresh: false })

      } else {
        setWarningMessage("Rien n'a été modifié")
        setLoading({ isLoading: false, refresh: false })
      }
    }

    else {
      // CREATING
      const newDate = await createSchoolingDate({
        date: datas.date,
        month: MONTHS[month],
        year,
        status: datas.status,
        pricingsGridId: pricingsGridId,
      })

      if (newDate.error) {
        setErrorMessage(newDate.error.message)
        setLoading({ isLoading: false, refresh: false })
        return
      }
      let temp = formatPricingGridChildcareHour(datas.childcarehours, newDate.results.schoolingYear.PricingGridId)
      const pricingGridChildcareHours = await createPricingGridChildcareHours({ add: temp })
      if (pricingGridChildcareHours.error) {
        setErrorMessage(pricingGridChildcareHours.error.message)
        setLoading({ isLoading: false, refresh: false })
        return
      }
      setLoading({ isLoading: false, refresh: true })
      setSuccessMessage('Rentrée scolaire créée')
      return
    }

    // compare original and new value
    if (
      selectedDate?.PricingGrid?.PricingGridChildcareHours.length === 0
    ) {
      let temp = formatPricingGridChildcareHour(datas.childcarehours)
      createPricingGridChildcareHours({ add: temp })
      return
    } else {
      let removedValue = selectedDate?.PricingGrid?.PricingGridChildcareHours
      if (removedValue) {
        removedValue.forEach(elt => {
          datas.childcarehours.forEach(dataValue => {
            if (dataValue.value === elt.ChildcareHourId) {
              removedValue = removedValue.filter(all => all.ChildcareHourId !== dataValue.value)
            }
          })
        })
      }

      let addedValue = []
      datas.childcarehours.forEach(dataValue => {
        if (selectedDate?.PricingGrid?.PricingGridChildcareHours.find(all => all.ChildcareHourId !== dataValue.value)) {
          addedValue.push(dataValue)
        }
      })

      if (addedValue.length > 0) {
        let temp = formatPricingGridChildcareHour(addedValue)
        createPricingGridChildcareHours({ add: temp })
      }
      // removedValue if populated by removed value      
      if (removedValue && removedValue.length > 0) {
        removePricingGridChildcareHours({ datas: removedValue })
      }
    }
  }
  return (
    <LayoutAdmin location={location} title="Rentrée scolaire" hasBack>
      <SetSchoolingDate
        onSubmit={onSubmit}
        goBack={goBack}
        date={selectedDate}
        pricingsGrids={pricingsGrids}
        pricingChoosen={pricingChoosen}
        id={id}
        childcareHours={childcareHours}
        loading={loading}
        addChildcareHours={addChildcareHours}
      />
    </LayoutAdmin>
  )
}

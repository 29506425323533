import React, { useEffect } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import Input from 'components/form/Input'
import StepButtons from 'components/common/StepButtons'
import Spinner from 'components/common/Spinner'
import ProgressBar from '../components/ProgressBar'
import Radio from './form/Radio'
import { USER_ROLE } from '../lib/constants'
import { useTranslation } from 'react-i18next'

const initialForm = {
  firstname: '',
  lastname: '',
  tel: '',
  profession: '',
  address: '',
  hasAuthority: false,
  fatherMotherTutor: '',
  email: '',
}

export default function Parent({
  onSubmit,
  goBack,
  parents,
  registrationId,
  company,
  role,
}) {
  const form = useForm({
    defaultValues: {
      parents: [initialForm, initialForm],
    },
  })
  const { t } = useTranslation()

  const { register, handleSubmit, watch, errors, control, reset } = form
  const { fields } = useFieldArray({
    control,
    name: 'parents',
  })

  const inputs = [
    { label: t('Père'), value: 'father' },
    { label: t('Mère'), value: 'mother' },
    { label: t('Tuteur'), value: 'tutor' },
  ]


  useEffect(() => {
    if (parents) {
      // always have 2 parents
      reset({ parents: [...parents, initialForm].slice(0, 2) })
    }
  }, [parents, reset])

  const required = (form, index, name) => {
    // Parent one have all inputs required
    if (index === 0) {
      return { required: t('Champ obligatoire') }
    }

    return {
      // validate is a function in react hooks forms, wait for a callback function to validate inputs
      validate: () => {
        const formValues = form.getValues()
        if (formValues.parents && formValues.parents[index]) {
          // take all inputs, and check if one got value
          const hasValue = Object.values(formValues?.parents[index]).some(
            (entrie) => entrie
          )
          // if a value is find, and the current input doesn't have any value, print error
          if (hasValue && !formValues.parents[index][name]) {
            return t('Champ obligatoire')
          }
        }
        return true
      },
    }
  }

  const isRequired = (index) => {
    if (index === 0) {
      return true
    }

    const t = form.watch()
    const isReq = Object.values(t.parents[index]).map((val) => {
      if (val === '' || val === false) {
        return false
      } else {
        return true
      }
    })

    if (isReq.find((b) => b === true)) {
      return true
    } else {
      return false
    }
  }

  const hasAuthorityRequired = (_form, _index) => {
    return {
      validate: () => {
        if (
          [0, 1].some((index) => {
            return watch(`parents[${index}].hasAuthority`)
          })
        )
          return true
        return t("Au moins une des personnes doit avoir l'autorité parentale")
      },
    }
  }

  return (
    <>
      <ProgressBar
        step={3}
        name={t("Les parents/tuteurs")}
        registrationId={registrationId}
        company={company}
      />

      <div className="flex items-center justify-center">
        <form
          className="w-full form-container sm:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-4 font-black text-center">{t("Les parents/tuteurs")}</div>

          {!parents && <Spinner></Spinner>}

          {parents && (
            <>
              {fields.map((field, index) => {
                const formValues = form.getValues()
                return (
                  <div
                    key={`${field.createdAt}-${index}`}
                    className="flex-col w-full my-2 md:flex-row justify-evenly"
                  >
                    <div className="flex flex-col w-full my-2 md:flex-row justify-evenly">
                      <div className="w-full md:w-1/2 md:pr-2">
                        <Input
                          required={isRequired(index)}
                          key={`parents[${index}].firstname`}
                          ref={register(required(form, index, `firstname`))}
                          type="text"
                          name={`parents[${index}].firstname`}
                          label={t('Prénom')}
                          placeholder={t('Prénom')}
                          error={
                            errors.parents &&
                            errors.parents.length >= 1 &&
                            errors.parents[index]?.firstname?.message
                          }
                          defaultValue={field?.firstname}
                          // required={required.required ? true : false}
                          inputClassName="bg-gray-200 rounded-lg"
                        />
                      </div>
                      <div className="w-full md:w-1/2 md:pl-2">
                        <Input
                          key={`parents[${index}].lastname`}
                          required={isRequired(index)}
                          ref={register(required(form, index, `lastname`))}
                          type="text"
                          name={`parents[${index}].lastname`}
                          label={t('Nom')}
                          placeholder={t('Nom')}
                          defaultValue={
                            field?.lastname ||
                            (formValues.parents &&
                              formValues.parents[index].lastname)
                          }
                          error={
                            errors.parents &&
                            errors.parents.length >= 1 &&
                            errors.parents[index]?.lastname?.message
                          }
                          inputClassName="bg-gray-200 rounded-lg"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col w-full my-2 md:flex-row justify-evenly">
                      <div className="w-full md:w-1/2 md:pr-2">
                        <Input
                          required={isRequired(index)}
                          key={`parents[${index}].tel`}
                          ref={register({
                            required: required(form, index, `tel`),
                            pattern: {
                              value: /^(\d{2}[\s|\-|/|. ]?){4}\d{2}$/,
                              message: 'Numéro de téléphone non valide',
                            },
                          })}
                          type="text"
                          name={`parents[${index}].tel`}
                          label={t('Téléphone')}
                          placeholder="06 00 00 00 00"
                          defaultValue={field?.tel}
                          error={
                            errors.parents &&
                            errors.parents.length >= 1 &&
                            errors.parents[index]?.tel?.message
                          }
                          inputClassName="bg-gray-200 rounded-lg"
                        />
                      </div>
                      <div className="w-full md:w-1/2 md:pl-2">
                        <Input
                          required={isRequired(index)}
                          key={`parents[${index}].profession`}
                          ref={register(required(form, index, `profession`))}
                          type="text"
                          name={`parents[${index}].profession`}
                          label={t('Profession')}
                          placeholder={t('Profession')}
                          defaultValue={field?.profession}
                          error={
                            errors.parents &&
                            errors.parents.length >= 1 &&
                            errors.parents[index]?.profession?.message
                          }
                          inputClassName="bg-gray-200 rounded-lg"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col w-full my-2 md:flex-row justify-evenly">
                      <div className="w-full md:w-1/2 md:pr-2">
                        <Input
                          required={isRequired(index)}
                          key={`parents[${index}].address`}
                          ref={register(required(form, index, `address`))}
                          type="text"
                          name={`parents[${index}].address`}
                          label={t('Adresse')}
                          placeholder={t('Adresse')}
                          defaultValue={field?.address}
                          error={
                            errors.parents &&
                            errors.parents.length >= 1 &&
                            errors.parents[index]?.address?.message
                          }
                          inputClassName="bg-gray-200 rounded-lg"
                        />
                      </div>
                      <div className="w-full md:w-1/2 md:pl-2 ">
                        <Input
                          required={isRequired(index)}
                          key={`parents[${index}].email`}
                          ref={register({
                            required: required(form, index, `email`),
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: t('Adresse e-mail non valide'),
                            },
                          })}
                          type="text"
                          name={`parents[${index}].email`}
                          label={t("E-mail")}
                          placeholder={t("E-mail")}
                          defaultValue={field?.email}
                          error={
                            errors.parents &&
                            errors.parents.length >= 1 &&
                            errors.parents[index]?.email?.message
                          }
                          inputClassName="bg-gray-200 rounded-lg"
                          disabled={
                            role === USER_ROLE.ADMIN
                              ? false
                              : index === 0
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>

                    <div className="flex flex-col w-full my-4 md:flex-row justify-evenly">
                      <div className="w-full md:w-1/2 md:pr-2">
                        <Input
                          type="checkbox"
                          required={isRequired(index)}
                          key={`parents[${index}].hasAuthority`}
                          ref={register(
                            hasAuthorityRequired(form, index, `hasAuthority`)
                          )}
                          form={form}
                          name={`parents[${index}].hasAuthority`}
                          label={t("Autorité parentale")}
                          // defaultValue={field?.hasAuthority}
                          checked={field?.hasAuthority}
                          error={
                            errors.parents &&
                            errors.parents.length >= 1 &&
                            errors.parents[index]?.hasAuthority?.message
                          }
                          inline={true}
                        />
                      </div>
                      <div className="w-full md:w-1/2 md:pl-2"></div>
                    </div>
                    <div className="flex flex-col w-full my-4 md:flex-row justify-evenly">
                      <div className="w-full md:w-11/12">
                        <Radio
                          required={isRequired(index)}
                          register={required(form, index, `fatherMotherTutor`)}
                          key={`parents[${index}].fatherMotherTutor`}
                          input={{ label: 'Père / Mère' }}
                          form={form}
                          label={t("Vous êtes le/la")}
                          inputs={inputs}
                          error={
                            errors?.parents &&
                            errors?.parents[index]?.fatherMotherTutor?.message
                          }
                          defaultValue={field?.fatherMotherTutor}
                          name={`parents[${index}].fatherMotherTutor`}
                        />
                      </div>
                    </div>
                    <div className="w-full mb-4 border border-gray-600"></div>
                  </div>
                )
              })}

              <StepButtons loading={false} goBack={goBack} />
            </>
          )}
        </form>
      </div>
    </>
  )
}

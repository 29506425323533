import React, { useState, useEffect } from 'react'
import SetPricingsGrid from 'components/SetPricingsGrid'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import useSchoolingYear from '../hooks/useSchoolingYear'
import useChildCare from '../hooks/useChildcare'
import usePricing from '../hooks/usePricing'
import { useNotify } from '../stores/notify'
import LayoutAdmin from 'components/LayoutAdmin'
import { isFormChanged } from 'lib/utils'

export default function SetPricingsGridsPage() {
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()
  const { getOnePricingsGrid } = useChildCare()
  const { updatePricingsGridsLists, createPricingsGrid } = useSchoolingYear()
  const { getAllPriceList, updatePricingsGrid } = usePricing()
  const [pricingsGrid, setPricingsGrids] = useState()
  const [loading, setLoading] = useState({ isLoading: false, refresh: false })
  const [selectedPricesLists, setSelectedPricesLists] = useState([])
  const [pricesLists, setPricesLists] = useState()
  const [reload, setReload] = useState()
  const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
      setWarningMessage,
    })
  )

  const formatList = (list) => {
    return list.map((p) => {
      return {
        nom: p.name,
        'Indice familial': p.familyIndex,
        '1 enfant': p.oneChild,
        '2 enfants': p.twoChild,
        '3 enfants et +': p.threeChild,
        "Frais d'inscripion": p.registrationFees,
        id: p.id,
      }
    })
  }

  // load pricing grid if id given
  useEffect(() => {
    async function loadPricingGrid() {
      const pricingGrid = await getOnePricingsGrid(id)
      if (pricingGrid.error) {
        setErrorMessage(pricingGrid.error.message)
        return
      }
      setPricingsGrids(pricingGrid.results)
      setSelectedPricesLists(
        formatList(pricingGrid.results.PricingGridLists.map((e) => e.PriceList))
      )
    }

    if (id && id !== 'new') {
      loadPricingGrid()
    }
  }, [getOnePricingsGrid, id, reload, setErrorMessage])

  // loading pricelist

  useEffect(() => {
    async function loadTarifications() {
      const pricingList = await getAllPriceList()
      if (pricingList.error) {
        setErrorMessage(pricingList.error.message)
        return
      }
      setPricesLists(formatList(pricingList.results))
    }
    loadTarifications()
    // eslint-disable-next-line
  }, [setErrorMessage])

  const goBack = () => {
    history.goBack()
  }
  const onSubmit = async (datas) => {
    setLoading({ isLoading: true, refresh: false })
    if (id !== 'new') {
      let update = false
      if (isFormChanged({
        name: datas.name,
        nurseryPriceHour: Number(datas.nurseryPriceHour)
      }, {
        name: pricingsGrid.name,
        nurseryPriceHour: pricingsGrid.nurseryPriceHour
      })) {
        datas.nurseryPriceHour = Number(datas.nurseryPriceHour)
        let pricingGrid = await updatePricingsGrid(id, datas)
        if (pricingGrid.error) {
          setErrorMessage(pricingGrid.error.message)
          setLoading({ isLoading: false, refresh: false })
          return
        }
        update = true
        setPricingsGrids(pricingGrid.results)
      }

      const originalPriceList = {}
      pricingsGrid.PricingGridLists.forEach(p => {
        originalPriceList[p.PriceListId] = p.PriceListId
      })

      const formPriceList = {}
      datas.priceslists.forEach(p => {
        formPriceList[p.value] = p.value
      })

      if (isFormChanged(originalPriceList, formPriceList)) {
        let pricingGridLists = await updatePricingsGridsLists(id, {
          priceListId: datas.priceslists.map(p => p.value),
          price: datas.nurseryPriceHour,
        })
        if (pricingGridLists.error) {
          setErrorMessage(pricingGridLists.error.message)
          setLoading({ isLoading: false, refresh: false })
          return
        }
        update = true
        setLoading({ isLoading: false, refresh: false })
        setReload(!reload)
      }
      if (update) {
        setSuccessMessage('Grille tarifaire mis à jour')
      } else {
        setWarningMessage("Rien n'a été modifié")
      }
      setLoading({ isLoading: false, refresh: false })
      return
    } else {
      const newPricingsGrid = await createPricingsGrid(datas)
      if (newPricingsGrid.error) {
        setErrorMessage(newPricingsGrid.error.message)
        setLoading({ isLoading: false, refresh: false })
        return
      }
      setSuccessMessage('Grille tarifaire créée')
      setLoading({ isLoading: false, refresh: true })
    }
  }

  return (
    <LayoutAdmin location={location} title="Grille tarifaire" hasBack>
      <SetPricingsGrid
        onSubmit={onSubmit}
        goBack={goBack}
        pricingsGrid={pricingsGrid}
        pricesLists={pricesLists}
        selectedPricesLists={selectedPricesLists}
        loading={loading}
        id={id}
      />
    </LayoutAdmin>
  )
}

import React, { useMemo } from 'react'
// import { useUser } from '../stores/user'
import Navigation from 'components/Navigation'
import { useRegistrations } from 'stores/registrations'
import useRegistration from 'hooks/useRegistration'
import useBill from 'hooks/useBill'


const Layout = ({ children, className = '', title, isHome, hasBack }) => {
  const { hasValidRegistration } = useRegistration()
  const { registrations, getRegistrations } = useRegistrations(({ registrations, getRegistrations }) => ({ registrations, getRegistrations }))
  const { hasBills } = useBill()

  const registrationsFromLocal = getRegistrations()

  const disabledNavigation = useMemo(() => {
    const forbiddenRoutes = ['/about']

    if (!hasValidRegistration() && registrationsFromLocal.length === 0) {
      forbiddenRoutes.push('/nursery')
      forbiddenRoutes.push('/nurseryhistory')
    }

    if (hasBills().length < 1) {
      forbiddenRoutes.push('/billsview')
    }

    if ((!Array.isArray(registrations) || registrations?.length === 0) && (registrationsFromLocal.length === 0)) {
      forbiddenRoutes.push('/chooseregistration')
    }

    return forbiddenRoutes
  }, [hasValidRegistration, registrations, hasBills, registrationsFromLocal])

  return (
    <>
      <Navigation
        title={title}
        hasBack={hasBack}
        isHome={isHome}
        disable={disabledNavigation}
      />
      <div className={`layout ${className}`}>
        <div className="main-content flex-1 pb-24 md:pb-5">{children}</div>
      </div>
    </>
  )
}

export default Layout

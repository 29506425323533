import React, { useMemo, useState, useCallback } from 'react'
import Table from 'components/common/table'
import useUser from 'hooks/useUsers'
import { useHistory } from 'react-router-dom'
import { dateFmt } from 'components/common/table/formats'
import { DefaultColumnFilter } from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'
import { useForm } from 'react-hook-form'
import Confirm from 'components/common/ConfirmModal'

import {
  Actions,
  EditAction,
  RemoveAction,
} from 'components/common/table/actions'

import { useNotify } from 'stores/notify'
import { USER_ROLE } from 'lib/constants'
import CheckBox from '../form/CheckBox'

const initialPageSize = 10
const initialSortBy = [
  {
    id: 'lastname',
    desc: false,
  },
]

const UserList = () => {
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage
    })
  )

  const form = useForm()
  const { control } = form


  const [forceRefresh, setForceRefresh] = useState(1)

  const {
    getUsers,
    updateUsers,
    deleteUser,
    reinitMultiplePassword,
  } = useUser()

  const onError = useCallback(
    (err) => {
      if (err && err.statusCode && err.statusCode === 401) {
        setErrorMessage(err.message)
        history.replace('/')
      } else {
        setErrorMessage('Une erreur est survenue')
      }
    },
    [history, setErrorMessage]
  )

  const fetchData = useApiFetch(getUsers, onError)

  const [removed, setRemoved] = useState(null)
  const [restored] = useState(null)

  const onRemove = useCallback(
    async (id) => {
      const req = await deleteUser({ id })
      if (req.results && req.results.success) {
        setSuccessMessage(`Utilisateur archivé avec succès`)
        setRemoved(id)
      } else {
        setErrorMessage(`Impossible à archiver: ${req.error.message}`)
      }
    },
    [deleteUser, setErrorMessage, setSuccessMessage]
  )

  const columns = useMemo(
    () => [
      {
        Header: "Sélection",
        accessor: "id",
        disableFilters: true,
        className: 'font-bold',
        Cell: ({ value }) => {
          return (
            <div onClick={(e) => e.stopPropagation()} className="p-3">
              <CheckBox
                key={`registration-${value}`}
                form={form}
                control={control}
                name={`registration-${value}`}
              />
            </div>
          )
        }
      },
      {
        Header: 'Nom',
        accessor: 'lastname', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Prénom',
        accessor: 'firstname', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Email',
        accessor: 'email', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Mail validé',
        accessor: 'isemailvalid', // accessor is the "key" in the data
        disableFilters: true,
        // bold: true
        className: 'font-bold',
        Cell: ({ value }) => value ? "Oui" : "Non",
      },
      {
        Header: 'Rôle',
        accessor: 'role', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Adresse',
        accessor: 'address', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Téléphone',
        accessor: 'tel', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Langue',
        accessor: 'language', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Modifié le',
        accessor: 'updatedAt',
        disableFilters: true,
        className: 'whitespace-no-wrap',
        Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableFilters: true,
        Cell: (props) => {
          return (
            <Actions>
              <EditAction
                entity={props.value}
                message={`Voulez-vous modifier cet utilisateur ?`}
                to={`/setuser/${props.value}`}
              />
              <RemoveAction
                {...props}
                title={`Supprimer l'utilisateur ${props.row.original.firstname} ${props.row.original.lastname} &nbsp;?`}
                onRemove={onRemove}
              >
                
              </RemoveAction>
            </Actions>
          )
        },
      },
    ],
    // eslint-disable-next-line
    []
  )

  const addUser = useCallback(() => {
    history.push('/setuser/new')
  }, [history])

  const getAllPopulatedValue = () => {
    let values = form.getValues()
    let v = []
    Object.entries(values).forEach(val => {
      if (val[1] !== undefined && val[1] !== false) {
        v.push(val[0].toString().replace('registration-', ''))
      }
    })
    return v
  }

  const onReinitPassword = async () => {
    const ids = getAllPopulatedValue()
    if (ids.length === 0) return
    if (await Confirm("Envoyez un mail de mot de passe oublié aux utilisateurs sélectionnés ?")) {
      try {
        const users = await reinitMultiplePassword(ids)
        if (users?.error) {
          throw new Error(users.error.message)
        }
        if (users?.results?.success) {
          setSuccessMessage("Mail(s) envoyé(s)")
        }
      } catch (err) {
        setErrorMessage("Impossible d'envoyer les emails de mot de passe oublié")
      }
    }
  }

  const onUpdateRole = async (role) => {
    const users = getAllPopulatedValue()
    if (users.length === 0) return
    if (await Confirm(`Voulez-vous attribuer le rôle ${role} aux utilisateurs sélectionnés ?`)) {
      try {
        const user = await updateUsers(users, { role })
        if (user?.error) {
          throw new Error(user.error.message)
        }
        if (user?.results?.success) {
          setSuccessMessage('Les rôles ont étés changés')
          setForceRefresh(!forceRefresh)
        }
      } catch (err) {
        setErrorMessage(err.message)
      }
    }
  }

  return (
    <div className="flex justify-center w-full pt-12">
      <Table
        fetchData={fetchData}
        name="Liste des utilisateurs"
        initialPageSize={initialPageSize}
        columns={columns}
        rowRemoved={removed}
        rowRestored={restored}
        initialSortBy={initialSortBy}
        addEntity={addUser}
        forceRefresh={forceRefresh}
        sendMailForgotPassword={() => onReinitPassword()}
        setUserRole={() => onUpdateRole(USER_ROLE.PARENT)}
        setEmployeeRole={() => onUpdateRole(USER_ROLE.EMPLOYEE)}
      />
    </div>
  )
}

export default UserList

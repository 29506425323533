import React, { useState, useEffect, useCallback } from 'react'
import Child from 'components/Child'
import { useHistory } from 'react-router-dom'
import useRegistration from '../hooks/useRegistration'
import useFiles from '../hooks/useFiles'
import { useUser } from 'stores/user'
import { useNotify } from '../stores/notify'
import Layout from 'components/Layout'
import { useTranslation } from 'react-i18next'
import { isFormChanged } from 'lib/utils'

export default function ChildPage(props) {
  const history = useHistory()
  const { getRegistration } = useRegistration()
  const { setChildDetails, getFiles, downloadFile } = useFiles()
  const [currentValue, setCurrentValue] = useState()
  const [files, setFiles] = useState()
  const [company, setCompany] = useState()
  const [photo, setPhoto] = useState()
  const [loading, setLoading] = useState(false)
  const [goSummary, setGoSummary] = useState(false)

  const { registrationId } = useUser(({ registrationId }) => ({
    registrationId,
  }))
  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))

  const { t } = useTranslation()
  useEffect(() => {
    async function loadPhoto() {
      try {
        // will not load photo until user pass submit this page
        if (!props.location?.state?.creating || props?.location?.state?.childPhoto) {
          const files = await getFiles('childPhoto')
          if (files.error) {
            setErrorMessage(t(files.error.message))
            return
          } else if (files.results) {
            const temp = []
            files.results.files.forEach((file) => {
              temp.push({
                uid: file.File.id,
                name: file.File.name,
                id: file.File.id,
                stored: true,
              })
            })
            setFiles(temp)
            let type = files?.results?.photo?.type?.split('/')[0]
            files.results.photo.type = type
            setPhoto(files.results.photo)
          }
        }
      } catch (err) {
        setErrorMessage(t(err.message))
      }
    }

    if (!props.status) {
      history.replace('/dashboard')
      return
    }
    loadPhoto()
  }, [props, t, setErrorMessage, getFiles, history])

  useEffect(() => {
    async function loadDatas() {
      try {
        const childRegistration = await getRegistration(null, {
          populate: true,
        })
        if (childRegistration.error) {
          setErrorMessage(t(childRegistration.error.message))
          return
        }
        if (childRegistration.results.Child.birthday) {
          childRegistration.results.Child.birthday = new Date(
            childRegistration.results.Child.birthday
          )
        }

        // dirty fix to allow boolean on radio input
        if (childRegistration.results.Child.nap) {
          childRegistration.results.Child.nap = 'true'
        } else {
          childRegistration.results.Child.nap = 'false'
        }

        setCurrentValue(childRegistration.results)
        if (childRegistration.results.companyPartner) {
          setCompany(childRegistration.results.companyPartner.name)
        }
      } catch (err) {
        setErrorMessage(t(err.message))
      }
    }
    if (!props.status) {
      history.replace('/dashboard')
      return
    }

    loadDatas()
  }, [getFiles, getRegistration, setErrorMessage, t, history, props.status])

  const goBack = () => {
    history.push(
      `schoolingyear/${registrationId || ''}`,
      props?.location?.state && { ...props.location.state }
    )
  }

  const onSubmit = async (datas) => {
    setLoading(true)
    if (datas.childPhoto && datas.childPhoto[0].uid) {
      currentValue.Child.childPhoto = datas.childPhoto
    }

    if (datas.nap === 'true') {
      datas.nap = true
    } else {
      datas.nap = false
    }

    if (
      isFormChanged(currentValue.Child, datas, [
        'UserChildren',
        'createdAt',
        'deletedAt',
        'id',
        'updatedAt',
      ])
    ) {
      const response = await setChildDetails({ datas, id: currentValue.id })
      if (response?.error) {
        setErrorMessage(t(response?.error.message))
        setLoading(false)
        return
      } else {
        if (goSummary) {
          history.push('/summary')
        } else {
          history.push(
            '/parent',
            props?.location?.state?.creating && {
              creating: true,
              childPhoto: true,
            }
          )
        }
      }
    } else {
      if (goSummary) {
        history.push(
          '/parent',
          props?.location?.state?.creating && {
            creating: true,
            childPhoto: true,
          }
        )
      } else {
        history.push('/summary')
      }
    }
  }

  const onDownloadFile = useCallback(
    async (file) => {
      try {
        file = await downloadFile(file.id)
        if (file?.error) {
          setErrorMessage(t(file.error.message))
          return
        }
      } catch (err) {
        setErrorMessage(t('Impossible de télécharger le fichier'))
      }
    },
    [downloadFile, setErrorMessage, t]
  )

  const onRemovePhoto = () => {
    setPhoto(null)
  }

  return (
    <Layout
      buttonGoHome={{
        to: props.role === 'admin' ? '/admin' : '/dashboard',
        history,
      }}
      title={t("L'enfant")}
    >
      <Child
        onSubmit={onSubmit}
        currentValue={currentValue}
        onDownloadFile={onDownloadFile}
        company={company}
        goBack={goBack}
        registrationId={registrationId}
        files={files}
        loading={loading}
        photo={photo}
        onRemovePhoto={onRemovePhoto}
        setGoSummary={setGoSummary}
        fromSummary={props?.location?.state?.fromSummary}
      />
    </Layout>
  )
}

import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import { useHistory } from 'react-router'
import montessori from 'assets/images/logo_ecole_montessori_pau.jpg'
import signature from 'assets/images/signature.png'

import {
  dateFormat,
} from '../lib/utils'

import { useTranslation } from 'react-i18next'

export default function SchoolCertificate({
  firstname,
  lastname,
  schoolingYear,
  schoolClass
}) {

  const componentToPrint = useRef()
  const history = useHistory()

  const { t } = useTranslation()

  return (
    <>
      <div className="flex items-center justify-center flex-col w-full my-8 ">
        <div className="flex flex-row w-full md:w-3/4 items-center justify-between">
          <div
            className="text-blue-600 cursor-pointer px-8 mt-8"
            onClick={() => history.goBack()}
          >
            {t("Précédent")}
          </div>
          <ReactToPrint
            trigger={() => (
              <button className="bg-transparent text-blue-600 py-3 px-4 ">
                {t('Imprimer')}
              </button>
            )}
            content={() => componentToPrint.current}
            bodyClass={''}
            documentTitle={`Certificat de scolarité ${firstname} ${lastname} ${schoolingYear}-${schoolingYear + 1}`}
            pageStyle=" 
          @media all {
            .page-break {
              display: none;
            }
          }
          
          @media print {
            html, body {
              height: initial !important;
              overflow: initial !important;
              -webkit-print-color-adjust: exact;
              background: white !important;
            }
          }
          
          @media print {
            .page-break {
              margin-top: 1rem;
              display: block;
              page-break-before: auto;
            }
          }
          
          @page {
            size: auto;
            margin: 10mm;
          }

          [data-form]{  
            border-style:none !important;
            box-shadow: none !important;
          }

          [data-signature]{
           display: none !important;
          }
          "
          />
        </div>
      </div>
      <div ref={componentToPrint}>
        <div className="flex w-full justify-center">
          <div
            className="w-full flex-col form-container sm:w-full xl:w-1/2"
            data-form={"data-form"}
          >
            <div className="flex justify-between w-full items-center mb-8">
              <div>
                <p>
                  Ecole Montessori de Pau
                </p>
                <p>
                  4 bis rue Maryse Bastié 64000 PAU
                </p>
                <p>
                  contact @ecole-montessori-pau.fr

                </p>
              </div>
              <div>
                <img
                  src={montessori}
                  alt="Montessori"
                  className="logo"
                  style={{ width: '200px' }}
                />
              </div>
            </div>
            <div
              className="text-center my-20 text-lg font-medium"
            >
              ATTESTATION DE SCOLARITÉ
            </div>
            <div className="px-4 flex flex-col gap-6">

              <div className="text-center text-sm">
                Je soussignée Laetitia OLIVIER, directrice de l’école Montessori de Pau, atteste que l’enfant&nbsp;:
              </div>

              <div className="text-center font-medium">
                {firstname} {lastname}
              </div>
              <div className="text-center text-sm">
                est scolarisé en {schoolClass} à l’école Montessori de Pau pour l’année scolaire {schoolingYear}-{schoolingYear + 1}.
              </div>
              <div className="text-center text-sm mt-6">
                A Pau, le {dateFormat(new Date())}
              </div>
              <div className="flex justify-center ">

                <img
                  src={signature}
                  alt='signature'
                  style={{
                    maxHeight: "100px",
                    maxWidth: "100px"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

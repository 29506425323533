import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { useNotify } from 'stores/notify'
import useUsers from 'hooks/useUsers'
import Input from 'components/form/Input'
import LoggedOutHeader from 'components/common/LoggedOutHeader'
import { useTranslation } from 'react-i18next'
import Button from 'components/form/Button'
import useLanguage from 'hooks/useLanguage'

export default function FortgotPasswordPage() {
  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))
  const { t } = useTranslation()
  const { getLanguage } = useLanguage()

  const { reinitPassword } = useUsers()
  const [email, setEmail] = useState(null)
  const [loading, setLoading] = useState(false)

  const [success, setSucccess] = useState(false)
  const onSubmit = async (params) => {
    setLoading(true)
    try {
      params.language = getLanguage()
      await reinitPassword(params)
      setEmail(params.email)
      setSucccess('true')
      setLoading(false)
    } catch (err) {
      setErrorMessage(t(err.message))
      setLoading(false)
    }
  }

  const { register, handleSubmit, errors, watch } = useForm()

  return (
    <div>
      <LoggedOutHeader />
      <div className="flex items-center justify-center align-middle">
        <div className="flex flex-col w-full md:w-3/4 lg:w-1/2 px-8 pt-6 pb-8 mb-4 ">
          <form
            className="w-full form-container"
            onSubmit={handleSubmit(onSubmit)}
          >
            {!success && (
              <>
                <p>
                  {t(
                    'Veuillez saisir votre adresse e-mail pour réinitialiser votre mot de passe'
                  )}
                </p>
                <Input
                  key={`section-email`}
                  ref={register({
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: t('Adresse e-mail non valide'),
                    },
                  })}
                  type="text"
                  name="email"
                  label={t('E-mail')}
                  placeholder={t('E-mail')}
                  value={watch('email')}
                  error={errors.email && errors.email.message}
                  required={t('Champ obligatoire')}
                  autofocus={true}
                />

                <div className="flex items-center justify-center w-full">
                  <Button variant="black" submits loading={loading}>
                    {t('Réinitialiser le mot de passe')}
                  </Button>
                </div>
              </>
            )}
            {success && (
              <div>
                {t(
                  'Un e-mail vous a été envoyé à {{email}} afin de réinitialiser votre mot de passe',
                  { email }
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

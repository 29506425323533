import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import TrashIcon from 'components/svg/trash'
import EditIcon from 'components/svg/edit'
import EyeIcon from 'components/svg/eye'
import DraftIcon from 'components/svg/draft'
import Modal from '../Modal'
import Confirm from 'components/common/ConfirmModal'
import ArchiveIcon from 'components/svg/archived'
import CheckIcon from 'components/svg/check'
import CrossIcon from 'components/svg/cross'
import RestoreIcon from 'components/svg/restore'
import Refresh from 'components/svg/refresh'
import ExitIcon from 'components/svg/exit'

const EditAction = ({ to, entity, message, title }) => {
  const history = useHistory()

  // const [redirect, setRedirect] = useState(false)
  return (
    <button
      onClick={async () => {
        // if (await Confirm(`${message} ${entity} ?`)) history.push(to)// setRedirect(true)
        history.push(to)
      }}
      to={to}
      className="ml-2 text-2xl text-blue-500"
    >
      <div title={title || "Modifier"}>
        {/* {redirect && <Redirect to={to} />} */}
        <EditIcon className="fill-current" />
      </div>
    </button>
  )
}

const ValidAction = ({ validation, message, entity, setForceRefresh, title, name, year }) => {
  return (
    <button
      onClick={async (e) => {
        e.stopPropagation()
        if (await Confirm(`${message} ${name ? name : ''} ${year ? `(${year})` : ''} &nbsp;?`)) {
          await validation(entity)
          setForceRefresh(new Date().getTime())
        }
      }}
      className="ml-2 text-2xl text-blue-500"
    >
      <div className="text-green-600" title={title}>
        <CheckIcon className="fill-current" />
      </div>
    </button>
  )
}

const RejectAction = ({ reject, message, entity, setForceRefresh, title, name, year }) => {
  return (
    <button
      onClick={async (e) => {
        e.stopPropagation()
        if (await Confirm(`${message} ${name ? name : ''} ${year ? `(${year})` : ''} &nbsp;?`)) {
          await reject(entity)
          setForceRefresh(new Date().getTime())
        }
      }}
      className="ml-2 text-2xl text-blue-500"
    >
      <div className="text-red-600" title={title}>
        <CrossIcon className="fill-current" />
      </div>
    </button>
  )
}

const CloseAction = ({ validation, message, entity, setForceRefresh, title, name, year }) => {
  return (
    <button
      onClick={async (e) => {
        e.stopPropagation()
        if (await Confirm(`${message}`)) {
          await validation(entity)
          setForceRefresh(new Date().getTime())
        }
      }}
      className="ml-2 text-2xl text-blue-500"
    >
      <div className="text-black" title={title}>
        <ExitIcon className="fill-current" />
      </div>
    </button>
  )
}

const ShowAction = ({ to, title }) => {
  return (
    <Link to={to} className="ml-2 text-2xl text-blue-500">
      {title ? (
        <div title={title}>
          <EyeIcon className="fill-current" />
        </div>
      ) : (
        <EyeIcon className="fill-current" />
      )}
    </Link>
  )
}

const RemoveAction = (props) => {
  const { value, onRemove, title } = props
  const [openModal, setOpenModal] = useState(false)
  const confirmRemove = (e) => {
    onRemove(value)
    setOpenModal(false)
  }
  return (
    <>
      <Modal
        title={title || `Êtes-vous sur de vouloir archiver l'élément ?`}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        responsive
      >
        <div>
          <div className="flex justify-end mt-8">
            <div
              className="px-4 py-2 text-white bg-gray-700 rounded-sm cursor-pointer"
              title={"Valider l'inscription"}
              onClick={() => setOpenModal(false)}
            >
              Annuler
            </div>
            <div
              className="p-2 px-4 ml-2 text-white bg-red-500 rounded-sm cursor-pointer"
              onClick={confirmRemove}
            >
              Confirmer la suppression
            </div>
          </div>
        </div>
      </Modal>
      <div
        onClick={(e) => {
          e.stopPropagation()
          setOpenModal(true)
        }}
        className="ml-2 text-xl text-red-500 cursor-pointer"
      >
        <div title={"Supprimer"}>
          <TrashIcon className="fill-current" />
        </div>
      </div>
    </>
  )
}

const ArchiveAction = (props) => {
  const { value, onArchive, title, message } = props
  const [openModal, setOpenModal] = useState(false)
  const confirmRemove = (e) => {
    onArchive(value)
    setOpenModal(false)
  }
  return (
    <>
      <Modal
        title={message}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        responsive
      >
        <div>
          <div className="flex justify-end mt-8">
            <div
              className="px-4 py-2 text-white bg-gray-700 rounded-sm cursor-pointer"
              onClick={() => setOpenModal(false)}
            >
              Annuler
            </div>
            <div
              className="p-2 px-4 ml-2 text-white bg-red-500 rounded-sm cursor-pointer"
              onClick={confirmRemove}
            >
              Continuer
            </div>
          </div>
        </div>
      </Modal>
      <div
        onClick={(e) => {
          e.stopPropagation()
          setOpenModal(true)
        }}
        className="ml-2 text-xl text-red-500 cursor-pointer"
        title={title}
      >
        <ArchiveIcon className="fill-current" />
      </div>
    </>
  )
}

const DraftAction = (props) => {
  console.log(props)
  const { value, onDraft } = props
  const [openModal, setOpenModal] = useState(false)
  const confirmDraft = () => {
    onDraft(value)
    setOpenModal(false)
  }
  return (
    <>
      <Modal
        title={`Êtes-vous sur de vouloir mettre l'élément en brouillon ?`}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        tiny
      >
        <div>
          <div className="flex justify-end mt-8">
            <div
              className="px-4 py-2 text-white bg-gray-700 rounded-sm cursor-pointer"
              onClick={() => setOpenModal(false)}
            >
              Annuler
            </div>
            <div
              className="p-2 px-4 ml-2 text-white bg-red-500 rounded-sm cursor-pointer"
              onClick={confirmDraft}
            >
              Confirmer la mise en brouillon
            </div>
          </div>
        </div>
      </Modal>
      <div
        onClick={() => setOpenModal(true)}
        className="ml-2 text-xl text-red-500 cursor-pointer"
      >
        <DraftIcon className="fill-current" />
      </div>
    </>
  )
}

const RestoreAction = (props) => {
  const { value, onRestore } = props
  const [openModal, setOpenModal] = useState(false)
  const confirmRemove = () => {
    onRestore(value)
    setOpenModal(false)
  }
  return (
    <>
      <Modal
        title={`Êtes-vous sur de vouloir récupéer cet élément ?`}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        responsive
      >
        <div>
          <div className="flex justify-end mt-8">
            <div
              className="px-4 py-2 text-white bg-gray-700 rounded-sm cursor-pointer"
              onClick={() => setOpenModal(false)}
            >
              Annuler
            </div>
            <div
              className="p-2 px-4 ml-2 text-white bg-red-500 rounded-sm cursor-pointer"
              onClick={confirmRemove}
            >
              Confirmer la récupération
            </div>
          </div>
        </div>
      </Modal>
      <div
        onClick={() => setOpenModal(true)}
        className="ml-2 text-xl text-red-500 cursor-pointer"
      >
        <RestoreIcon className="fill-current" />
      </div>
    </>
  )
}

const CreateAction = ({ entity, title, create }) => {
  return (
    <button
      onClick={async (e) => {
        e.stopPropagation()
        create()
      }}
      className="ml-2 text-2xl text-blue-500"
    > 
      <div className="text-blue-600" title={title}>
        <Refresh className="fill-current" />
      </div>
    </button>
  )
}

const ImportAction = (props) => {
  const { value, onRemove, title } = props
  const [openModal, setOpenModal] = useState(false)
  const confirmRemove = (e) => {
    onRemove(value)
    setOpenModal(false)
  }
  return (
    <>
      <Modal
        title={title || `Importer un fichier`}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        responsive
      >
        <div>
          <div className="flex justify-end mt-8">
            <div
              className="px-4 py-2 text-white bg-gray-700 rounded-sm cursor-pointer"
              title={"Valider l'inscription"}
              onClick={() => setOpenModal(false)}
            >
              Annuler
            </div>
            <div
              className="p-2 px-4 ml-2 text-white bg-red-500 rounded-sm cursor-pointer"
              onClick={confirmRemove}
            >
              Envoyer
            </div>
          </div>
        </div>
      </Modal>
      <div
        onClick={(e) => {
          e.stopPropagation()
          setOpenModal(true)
        }}
        className="ml-2 text-xl text-red-500 cursor-pointer"
      >
        <div title={"Supprimer"}>
          <TrashIcon className="fill-current" />
        </div>
      </div>
    </>
  )
}


const Actions = ({ children }) => (
  <div className="flex items-center justify-end">{children}</div>
)

export {
  EditAction,
  Actions,
  RemoveAction,
  ArchiveAction,
  DraftAction,
  ShowAction,
  ValidAction,
  RejectAction,
  RestoreAction,
  CreateAction,
  ImportAction,
  CloseAction,
}

import { useMemo } from 'react'
import { useUser } from 'stores/user'
import { useBills } from 'stores/bills'
import Api from 'lib/api'

export default () => {

  const { token } = useUser(
    ({ token, id, email }) => ({
      token,
      id,
      email,
    })
  )

  const {
    bills,
    setBills
  } = useBills(
    ({
      bills,
      setBills,
    }) => ({
      bills,
      setBills,
    })
  )

  return useMemo(
    () => ({
      async getBillsAndStatus() {
        try {
          const bill = await Api.getBillsAndStatus({ token })
          setBills(bill.results.bill)
          return bill
        } catch (err) {
          return { error: err }
        }
      },
      hasBills() {
        return bills
      },
      async payBill(id) {
        try {
          const payBill = await Api.payBill({ id, token })
          return payBill
        } catch (err) {
          return { error: err }
        }
      },
      async generateMultipleBills(bills, type) {
        try {
          const b = await Api.generateMultipleBills({ token, bills, type })
          return b
        } catch (err) {
          return { error: err }
        }
      },

      async generateBill(id, type) {
        try {
          const bill = await Api.generateBill({ id, type, token })
          return bill
        } catch (err) {
          return { error: err }
        }
      },async generateBillWithData(id, type,datas) {
        try {
          const bill = await Api.generateBillWithData({ id, type, token ,datas })
          return bill
        } catch (err) {
          return { error: err }
        }
      },
      async getBills(offset = 0, limit = 100, filters, order) {
        try {
          const bills = await Api.getBills({
            token,
            query: { limit, filters, order, offset },
          })
          return bills
        } catch (err) {
          return { error: err }
        }
      },
      async getBill(id) {
        try {
          const bill = await Api.getBill({ token, id })
          return bill
        } catch (err) {
          return { error: err }
        }
      },
      async hasBillGenerated(id) {
        try {
          const reg = await Api.hasBillGenerated({ token, id })
          return reg
        } catch (err) {
          return { error: err }
        }
      },
      async updateBill(billId, datas) {
        try {
          const bill = await Api.updateBill({ token, datas, id: billId })
          return bill
        } catch (err) {
          return { error: err }
        }
      },
      async createCreditNote(datas, id) {
        try {
          const creditnote = await Api.createCreditNote({ token, datas, id })
          return creditnote
        } catch (err) {
          return { error: err }
        }
      },
      async billingExport(id) {
        try {
          const billingExport = await Api.billingExport({ id, token })
          return billingExport
        } catch (err) {
          return { error: err }
        }
      },
      async reCreateBill(id, type, datas) {
        try {
          const bill = await Api.recreateBill({ id, type, token, datas })
          return bill
        } catch (err) {
          return { error: err }
        }
      },
      async getBillsToPdf({ year, month }) {
        try {
          let minDate = new Date(`${year}-${month}-01`)
          let maxDate = new Date(minDate)
          maxDate.setMonth(maxDate.getMonth() + 1)
          maxDate.setDate(maxDate.getDate() - 1)
          maxDate.setHours(23, 59, 59, 59)
          let query = {
            limit: 1000,
            populate: true,
            filters: [
              ['createdAt', '>=', minDate],
              ['createdAt', '<=', maxDate]
            ],
            order:[
              ['createdAt', 'ASC']
            ]
          }
          const bills = await Api.getBillsToPdf({ token, query })
          return bills
        } catch (err) {
          return { error: err }
        }
      }
    }),

    [
      bills,
      setBills,
      token
    ]
  )
}

import React from 'react'
import { useLocation } from 'react-router-dom'
import LayoutAdmin from '../components/LayoutAdmin'
import UserList from 'components/registrations/UserList'

export default function UserListPage() {
  const location = useLocation()

  return (
    <div>
      <LayoutAdmin location={location} isHome>
        <UserList />
      </LayoutAdmin>
    </div>
  )
}

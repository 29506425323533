import React, { useState, useEffect } from 'react'
import SetCategory from 'components/SetCategory'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import LayoutAdmin from 'components/LayoutAdmin'
import useActivity from 'hooks/useActivity'
import { isFormChanged } from 'lib/utils'
import useSchoolingYear from 'hooks/useSchoolingYear'

export default function SetCategoryPage() {
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams()

    const { getCategory, updateCategory, createCategory } = useActivity()
    const {
        getSchoolingdate,
    } = useSchoolingYear()

    const [category, setCategory] = useState()
    const [loading, setLoading] = useState({ prevState: null, current: false })
    const [dateList, setDateList] = useState([])

    const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
        ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
            setErrorMessage,
            setSuccessMessage,
            setWarningMessage,
        })
    )

    useEffect(() => {
        async function loadDatas(newElt = null) {
            try {
                if (!newElt) {
                    const category = await getCategory(id)
                    if (category.error) {
                        setErrorMessage(category.error.message)
                        return
                    }
                    setCategory(category.results)
                }
            } catch (err) {
                setErrorMessage('Service indisponible')
            }
        }
        if (!isNaN(Number(id))) {
            loadDatas()
        } else if (id === 'new') {
            loadDatas('new')
        } else {
            setErrorMessage('Impossible de trouver cette catégorie')
        }
        // eslint-disable-next-line
    }, [])

    const goBack = () => {
        history.goBack()
    }
    const onSubmit = async (datas) => {
        // if (datas.SchoolingYearId.hasOwnProperty('value')) {
        //     datas.SchoolingYearId = datas.SchoolingYearId.value
        // } 
        
        setLoading({ prevState: loading.current, current: true })
        datas.image = "no.jpg"
        let secondColor = datas.secondColor.replace('#', '')
        let firstColor = datas.color.replace('#', '')
        datas.color = `${firstColor}:${secondColor}`
        delete datas.secondColor
        console.log(datas);
        if (!isNaN(Number(id))) {
            if (isFormChanged({
                name: datas.name,
                description: datas.description,
                color: datas.color,
                SchoolingYearId: datas.SchoolingYearId,
            }, {
                name: category.name,
                description: category.description,
                color: category.color,
                SchoolingYearId: category.SchoolingYearId,
            })) {
                let newCategory = await updateCategory(id, datas)
                if (newCategory.error) {
                    setErrorMessage(newCategory.error.message)
                    setLoading({ prevState: loading.current, current: false })
                    return
                }
                setCategory(newCategory.results)
                setSuccessMessage('Catégorie mise à jour')
            } else {
                setWarningMessage("Rien n'a été modifié")
            }
        } else {
            let category = await createCategory(datas)
            if (category.error) {
                setErrorMessage(category.error.message)
                setLoading({ prevState: loading.current, current: false })
                return
            }
            setSuccessMessage('Catégorie créée')
        }
        setLoading({ prevState: loading.current, current: false })
    }


    useEffect(() => {
        async function loadSchoolingYearDate() {
            try {
                const date = await getSchoolingdate()
                let tmp = date.results.map(date => {
                    return {
                        label: date.year,
                        value: date.id
                    }
                })

                setDateList(tmp)

            } catch (err) {
                setErrorMessage(
                    "Impossible de charger les dates de rentrée scolaire"
                )
            }
        }
        loadSchoolingYearDate()
    }, [getSchoolingdate, setErrorMessage])


    return (
        <LayoutAdmin location={location} title="Catégorie" hasBack>
            <SetCategory
                onSubmit={onSubmit}
                goBack={goBack}
                category={category}
                id={id}
                loading={loading}
                dateList={dateList}
            />
        </LayoutAdmin>
    )
}

import React from 'react'
import { Controller } from 'react-hook-form'

import YesNoButton from './form/YesNoButton'
import Button from './form/Button'
import Select from './form/Select'
import EditIcon from 'components/svg/edit'
import TrashIcon from 'components/svg/trash'
import Confirm from './common/ConfirmModal'
import Spinner from './common/Spinner'

import {
  USER_ROLE
} from 'lib/constants'

import {
  capitalize
} from '../lib/utils'

export function NurseryViewerCard({
  name,
  halfdayName,
  halfday,
  children,
  isEditingTimeSlot,
  onEditTimeSlot,
  onSubmitSlotEditing,
  onDeleteNurseryById,
  role,
  selectedDate,
  form,
  selectChildcareHours
}) {

  const {
    watch,
    register,
    control,
    setValue,
  } = form

  const Actionbar = ({
    id,
    date
  }) => {
    return (
      <>
        {name === "evening" && !isEditingTimeSlot && (
          <div className="flex justify-center items-center">
            <button
              onClick={(e) => {
                e.preventDefault()
                onEditTimeSlot(id)
              }}
              className="text-blue-500 text-lg"
            >
              <EditIcon className="fill-current"></EditIcon>
            </button>
          </div>
        )}
        {role === USER_ROLE.ADMIN && (
          <div className="flex justify-center items-center">
            <button
              onClick={async (e) => {
                e.preventDefault()
                if (await Confirm("Retirer cette inscription à la garderie ? ")) {
                  onDeleteNurseryById(id, date)
                }
              }}
              className="text-red-500 text-lg"
            >
              <TrashIcon className='fill-current' />
            </button>
          </div>
        )}
      </>
    )
  }

  return (
    <div className="w-full md:w-1/2 text-center">
      <div>
        {halfdayName} ({halfday && halfday.length})
        {halfday &&
          halfday.map((n) => (
            <div
              key={n.id}
              className="flex flex-col justify-center m-2"
            >
              <div
                className="w-full bg-white border border-gray-500 flex p-1 flex-col lg:flex-row justify-center items-stretch"
              >
                <div className="w-full flex items-center justify-center">
                  <div className={`text-center flex-1 ${isEditingTimeSlot && isEditingTimeSlot === n.id && 'p-1'}`}>
                    {capitalize((children.find(c => c.value === n.ChildId).label))}
                  </div>
                  <div>
                    <div className="flex gap-1 justify-end items-start lg:hidden">
                      <Actionbar id={n.id} date={n.date}></Actionbar>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full flex flex-col items-stretch justify-items-stretch"
                >
                  <div className="lg:flex gap-1 justify-end items-start hidden">
                    <Actionbar id={n.id} date={n.date}></Actionbar>
                  </div>
                  <div className="flex-grow flex justify-center items-center">
                    {/* Normal view */}
                    {(!isEditingTimeSlot || (isEditingTimeSlot && isEditingTimeSlot !== n.id)) && (
                      <span>
                        {`${n.ChildcareHour.start}-${n.ChildcareHour.end}`}
                      </span>
                    )}
                    {/* Editing view */}
                    {isEditingTimeSlot && isEditingTimeSlot === n.id && (
                      <div className="w-full flex flex-col">
                        <Controller
                          control={control}
                          name={`addSlot`}
                          defaultValue={n?.ChildcareHour.id}
                          render={({ onChange, value, name, defaultValue }) => (
                            <>
                              <Select
                                placeholder={'16:30-17:30'}
                                name={name}
                                className="w-full"
                                label="Horaire"
                                value={value || n.ChildcareHour.id}
                                values={selectChildcareHours}
                                onChange={(e) => {
                                  onChange(e)
                                }}
                                defaultValue={defaultValue}
                                noOptionsMessage={() => (
                                  <div className="flex justify-center pb-2">
                                    <Spinner></Spinner>
                                  </div>
                                )}
                              />
                            </>
                          )}
                        />
                        <div className="flex justify-around text-white my-1 gap-1 px-1">
                          <div className="w-auto flex justify-center items-center">
                            <Button
                              onClick={() => onEditTimeSlot('')}
                              variant='danger'
                            >
                              Annuler
                            </Button>
                          </div>
                          <div className="w-auto flex justify-center items-center">
                            <Button
                              onClick={e => onSubmitSlotEditing({
                                e,
                                addSlot: watch('addSlot'),
                                ChildId: n.ChildId,
                                ChildcareHourId: n.id,
                                selectedDate,
                              }
                              )}
                              variant='primary'
                            >
                              Valider
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <Controller
                  name={`${name}-${n.id}`}
                  control={control}
                  defaultValue={n.presence}
                  rules={register()}
                  render={({ name, onChange, value: _ }) => (
                    <YesNoButton
                      yes="Présent"
                      no="Absent"
                      defaultValue={n.presence}
                      form={form}
                      onChange={(e) => {
                        onChange(e && e.value)
                        setValue(e.name, e.value)
                      }}
                      name={name}
                    />
                  )}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
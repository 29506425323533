import create from 'zustand'
import { immer, persist } from 'state/middlewares'
import shallow from 'zustand/shallow'

const languageStore = create(
    persist(
        'language',
        immer((set, get) => ({
            language: 'fr',
            setLanguage(lang) {
                set((state) => {
                    state.language = lang
                })
            },
        }))
    )
)


export const useLanguages = (slices) => shallow(languageStore, slices)
export const languageApi = languageStore
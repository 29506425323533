import React, { useCallback, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Spinner from './common/Spinner'
import Select from './form/Select'
import { YOUNG_CLASSES, OLD_CLASSES } from 'lib/constants'

export default function AcquiredSkillsPage({
  schoolingyears,
  onSchoolingyearChange,
  childrenList,
  loading,
  onChildClick,
  defaultSchoolingyear,
  onViewRecommandedActivities,
  schoolClasses,
  defaultSchoolClass,
}) {
  const form = useForm()
  const { control, setValue, watch } = form

  const watchSchoolClass = watch('schoolClass')

  useEffect(() => {
    if (defaultSchoolingyear && !isNaN(defaultSchoolingyear)) {
      setValue('schoolingyear', Number(defaultSchoolingyear))
    }
  }, [defaultSchoolingyear, setValue])

  useEffect(() => {
    if (defaultSchoolClass) {
      setValue('schoolClass', defaultSchoolClass)
    }
  }, [defaultSchoolClass, setValue])

  const whichClass = useCallback(
    (elt, schoolClass) => {
      if (typeof elt === 'object') {
        let f = schoolClasses.find((s) => s.value === elt.value)
        let youngOrOld
        if (f?.label === '3-6') {
          youngOrOld = YOUNG_CLASSES
        } else {
          youngOrOld = OLD_CLASSES
        }

        if (youngOrOld.includes(schoolClass)) {
          return true
        } else {
          return false
        }
      } else {
        let f = schoolClasses.find((s) => s.value === elt)
        let youngOrOld
        if (f?.label === '3-6') {
          youngOrOld = YOUNG_CLASSES
        } else {
          youngOrOld = OLD_CLASSES
        }

        if (youngOrOld.includes(schoolClass)) {
          return true
        } else {
          return false
        }
      }
    },
    [schoolClasses]
  )

  return (
    <>
      <div className="flex items-center justify-center mt-8">
        <div className="flex flex-col w-full items-center">
          <div className="md:w-1/2 w-full flex justfiy-center p-2 mb-4">
            <Controller
              name="schoolClass"
              control={control}
              defaultValue={defaultSchoolClass}
              render={({ name, value, defaultValue, onChange }) => (
                <>
                  <Select
                    name={name}
                    value={value}
                    values={schoolClasses}
                    className="w-full"
                    placeholder="Choisissez une classe"
                    defaultValue={defaultValue}
                    isClearable={false}
                    onChange={(e) => {
                      onChange(e)
                    }}
                  />
                </>
              )}
            />
          </div>
          {loading && <Spinner></Spinner>}
          {Object.keys(childrenList).length > 0 && (
            <div className="flex flex-col w-full p-2">
              {Object.keys(childrenList).map((schoolClass) => (
                <div key={`class-${schoolClass}`}>
                  {whichClass(watchSchoolClass, schoolClass) && (
                    <>
                      <div
                        className="bg-blue-500 p-1 rounded-sm text-white flex justify-start items-center gap-1"
                      >
                        <div className="text-lg font-bold">
                          {schoolClass}&nbsp;
                        </div>
                        <div
                          className="py-1 px-2 text-sm font-normal cursor-pointer bg-gray-200 text-gray-800 rounded"
                          onClick={(e) => {
                            e.stopPropagation()
                            onViewRecommandedActivities(schoolClass)
                          }}
                        >
                          Voir les activités recommandées pour chaque élève
                        </div>
                        <div
                          className="py-1 px-2 text-sm font-normal cursor-pointer bg-gray-200 text-gray-800 rounded"
                          onClick={(e) => {
                            e.stopPropagation()
                            onChildClick('all', schoolClass)
                          }}
                        >
                          Créer ou assigner une activité à plusieurs enfants
                        </div>
                      </div>

                      <div className="w-full  grid grid-cols-4 gap-2 p-2">
                        {childrenList[schoolClass].map((child) => (
                          <div
                            key={`child-${child.label}-${schoolClass}`}
                            className="hover:bg-blue-100 cursor-pointer"
                            onClick={() =>
                              onChildClick(child.value, schoolClass)
                            }
                          >
                            <div
                              className="flex justify-center"
                              style={{ height: '100px' }}
                            >
                              <img
                                src={
                                  child?.file?.url
                                    ? child.file.url
                                    : 'https://via.placeholder.com/100'
                                }
                                alt={child.label}
                                className="object-cover w-full md:w-1/3"
                              />
                            </div>
                            <div className="text-center">{child.label}</div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
          {Object.keys(childrenList).length === 0 && loading === null && (
            <div className="w-full text-lg text-center">
              Sélectionnez une année scolaire pour commencer
            </div>
          )}
        </div>
      </div>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Select from '../components/form/Select'
import ProgressBar from '../components/ProgressBar'
import CheckBox from './form/CheckBox'
import TextArea from './form/TextArea'
import StepButtons from './common/StepButtons'
import Spinner from 'components/common/Spinner'

import { useTranslation } from 'react-i18next'

export default function SchoolingYear({
  years,
  months,
  companies,
  registration,
  classList,

  loading,
  onSubmit,
  goBack,
}) {
  const form = useForm()
  const { t } = useTranslation()
  const {
    register,
    control,
    errors,
    watch,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
  } = form

  const [defaultYear, setDefaultYear] = useState()
  const [defaultMonth, setDefaultMonth] = useState()
  const [defaultClass, setDefaultClass] = useState()
  const [defaultCompany, setDefaultCompany] = useState()

  const [companyRequired, setCompanyRequired] = useState({ required: false })

  const [monthList, setMonthList] = useState([])
  const [customCompany, setCustomCumpany] = useState()

  const hascompany = watch('hascompany')
  const company = watch('company')
  const companySelector = watch('companySelector')
  const motivation = watch('motivation')

  useEffect(() => {
    // if registration
    if (
      years &&
      years.length &&
      Object.values(months).length &&
      registration?.id
    ) {
      setDefaultYear(registration.SchoolingYear.year)
      setDefaultMonth(registration.month)
    } else if (
      years &&
      years.length &&
      Object.values(months).length &&
      !registration?.id
    ) {
      setDefaultYear(years[0].value)
      setDefaultMonth(months[years[0].value][0].value)
    }
  }, [years, months, registration])

  useEffect(() => {
    // set default year and month
    if (defaultYear && defaultMonth) {
      setValue('year', defaultYear)
      setValue('month', defaultMonth)
    } else if (defaultYear && !defaultMonth) {
      setValue('year', defaultYear)
      setValue('month', [months[defaultYear.length - 1]].value)
    }
    // eslint-disable-next-line
  }, [defaultYear, defaultMonth])

  useEffect(() => {
    // update months list if year change
    let year = getValues('year')
    if (year) {
      // if months selectable change
      if (Object.keys(months[year]).length !== Object.keys(monthList).length) {
        setValue(
          'month',
          months[year].length === 1
            ? months[year][0].value
            : months[year][1].value
        )
      }
      setMonthList(months[year])
    }
    // eslint-disable-next-line
  }, [watch('year')])

  useEffect(() => {
    // set default value if registration (class, company)
    if (registration?.id) {
      if (registration?.SchoolClass?.name) {
        const test = classList.find(
          (classe) => classe.label === registration.SchoolClass.name
        )
        setDefaultClass(test?.value)
        setValue('classSelect', test?.value)
      }

      if (registration?.companyPartner?.name && companies && companies.length) {
        setValue('hascompany', true)
        // setCompany(registration.companyPartner.name)
        if (
          companies.find(
            (company) => company.value === registration.companyPartner.name
          )
        ) {
          setValue('companySelector', registration.companyPartner.name)
          setDefaultCompany(registration.companyPartner.name)
        } else {
          setValue('company', registration.companyPartner.name)
          setCustomCumpany(registration.companyPartner.name)
        }
      }

      if (registration?.motivation) {
        setValue('motivation', registration.motivation)
      }
    }
    // eslint-disable-next-line
  }, [registration, companies])

  useEffect(() => {
    // company or companySelector must have at least one full
    // if hascompany is true to submit the form
    if (hascompany) {
      if (!getValues('company') && !getValues('companySelector')) {
        setCompanyRequired({
          required: t('Au moins un des champs est obligatoire'),
        })
      } else {
        setCompanyRequired({ required: false })
        clearErrors('company', 'companySelector')
      }
    }
  }, [t, hascompany, company, companySelector, getValues, clearErrors])

  return (
    <>
      <ProgressBar
        step={1}
        name={t('Rentrée scolaire')}
        registrationId={registration && registration.id}
      />

      <div className="flex items-center justify-center">
        <form
          className="w-full form-container sm:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-4 font-black text-center">
            {t(
              'Pour quelle rentrée scolaire souhaitez-vous inscrire votre enfant ?'
            )}
            &nbsp;
          </div>
          {years === null && (
            <div className="text-gray-600">
              {t("Il n'y a pas de date d'inscription pour le moment")}
            </div>
          )}
          {Array.isArray(years) && years.length === 0 && <Spinner></Spinner>}
          {Array.isArray(years) && years.length > 0 && (
            <>
              <div className="w-full my-2 sm:w-1/2">
                <Controller
                  key="year-controller"
                  control={control}
                  rules={{ required: t('Champ obligatoire') }}
                  name="year"
                  defaultValue={defaultYear}
                  render={({ onChange, value, name }) => (
                    <>
                      <Select
                        key="year-select"
                        placeholder={t('Année scolaire')}
                        name={name}
                        className="w-full"
                        label={t('Année')}
                        value={value}
                        defaultValue={defaultYear}
                        values={years}
                        onChange={(e) => {
                          onChange(e && e.value)
                        }}
                      />
                      {errors && errors[name] && (
                        <div className="text-sm text-red-500">
                          {errors[name].message}
                        </div>
                      )}
                    </>
                  )}
                />
                <Controller
                  // pass monthList in key to force defaultValue to be update
                  key={`month-controller-${JSON.stringify(monthList)}`}
                  control={control}
                  name="month"
                  rules={{ required: t('Champ obligatoire') }}
                  defaultValue={defaultMonth}
                  render={({ onChange, value, name }) => (
                    <>
                      <Select
                        key={`month-selector`}
                        placeholder={t('Mois')}
                        name={name}
                        className="w-full"
                        label={t('Mois')}
                        value={value}
                        required={true}
                        values={monthList}
                        defaultValue={defaultMonth}
                        onChange={(e) => {
                          onChange(e && e.value)
                        }}
                      ></Select>
                      {errors && errors[name] && (
                        <div className="text-sm text-red-500">
                          {errors[name].message}
                        </div>
                      )}
                    </>
                  )}
                />
                <Controller
                  key="class-controller"
                  control={control}
                  name="classSelect"
                  defaultValue={defaultClass}
                  rules={{ required: t('Champ obligatoire') }}
                  render={({ onChange, value, name }) => (
                    <>
                      <Select
                        key="class"
                        placeholder={t('Classe')}
                        name={name}
                        className="w-full"
                        label={t('Classe')}
                        value={value}
                        values={classList}
                        defaultValue={defaultClass}
                        onChange={(e) => {
                          onChange(e && e.value)
                        }}
                      ></Select>
                      {errors && errors[name] && (
                        <div className="text-sm text-red-500">
                          {errors[name].message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full py-4">
                <TextArea
                  key="motivation"
                  ref={register({ required: t('Champ obligatoire') })}
                  type="text"
                  name="motivation"
                  label={t(
                    'Pourquoi désirez-vous inscrire dans votre enfant dans notre école ?'
                  )}
                  value={motivation || ''}
                  error={errors.motivation && errors.motivation.message}
                  style={{
                    minHeight: '240px',
                  }}
                />
              </div>
              <div className="w-full my-2">
                <CheckBox
                  key="hascompany"
                  form={form}
                  control={control}
                  name={'hascompany'}
                  label={t(
                    'Les frais de scolarité seront-ils pris en charge par une société ?'
                  )}
                />
              </div>
            </>
          )}
          {hascompany && (
            <>
              <Controller
                key="company-controller"
                control={control}
                name="companySelector"
                rules={companyRequired}
                defaultValue={defaultCompany}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      key="companySelector"
                      placeholder={t('Société')}
                      name={name}
                      className="w-full"
                      label={t('Société partenaire')}
                      value={value}
                      values={companies}
                      defaultValue={defaultCompany}
                      onChange={(e) => {
                        onChange(e && e.value)
                      }}
                    ></Select>
                    {errors && errors[name] && (
                      <div className="flex w-full">
                        <div className="text-sm text-red-500 ">
                          {errors[name].message}
                        </div>
                      </div>
                    )}
                  </>
                )}
              />
              <div>{t('OU')}</div>
              <div className="w-full py-4">
                <TextArea
                  key="company"
                  ref={register(companyRequired)}
                  type="text"
                  name="company"
                  label={t('Nom de la société')}
                  placeholder={t('Informations concernant la société')}
                  value={company || customCompany}
                  error={errors.company && errors.company.message}
                />
              </div>
            </>
          )}
          <StepButtons
            loading={loading}
            disabled={
              (Array.isArray(years) && years.length === 0) || years === null
            }
            hideNext={
              (Array.isArray(years) && years.length === 0) || years === null
            }
            goBack={goBack}
          />
        </form>
      </div>
    </>
  )
}

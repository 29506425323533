import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import StepButtons from './common/StepButtons'

export default function SetPricingsGrid({
  onSubmit,
  goBack,
  pricingsGrid,
  pricesLists,
  selectedPricesLists,
  loading,
  id,
}) {
  const form = useForm()
  const [selectValues, setSelectValues] = useState([])
  const { register, handleSubmit, errors, control, setValue, reset } = form

  useEffect(() => {
    // clear form after create
    if (loading && loading.refresh === true) {
      reset({
        name: "",
        priceslists: [],
        nurseryPriceHour: []
      })
    }
  }, [loading, reset])



  const formatList = (list) => {
    return list.map((l) => {
      const id = l.id
      delete l.id
      return {
        label: JSON.stringify(l).replace(/[{}]/gi, ''),
        value: id,
      }
    })
  }

  useEffect(() => {
    if (pricesLists) {
      const pricesListsSelect = formatList(pricesLists)
      setSelectValues(pricesListsSelect)
    }
  }, [pricesLists])

  useEffect(() => {
    if (selectedPricesLists) {
      const prices = formatList(selectedPricesLists)
      setValue('priceslists', prices)
    }
  }, [selectedPricesLists, setValue])

  return (
    <>
      <div className="flex items-center justify-center mt-16">
        <form
          className="form-container w-full sm:w-full xl:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="font-black mb-4 text-center">
            {`${!isNaN(id) ? 'Modifier ' : 'Ajouter'} une grille tarifaire`}
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'name'}
              ref={register({ required: 'Champ obligatoire' })}
              type="text"
              name="name"
              label="Nom de la grille tarifaire"
              placeholder="Année 2000"
              error={errors.name && errors.name.message}
              defaultValue={pricingsGrid && pricingsGrid.name}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <div className="w-full">

              <Controller
                control={control}
                name={`priceslists`}
                defaultValue={selectedPricesLists}
                rules={{
                  validate: (value) => {
                    return !!value && (value.length > 0 || "Champ obligatoire")
                  }
                }}
                render={({ onChange, value, name, defaultValue }) => (
                  <>
                    <Select
                      placeholder={'Tarifications'}
                      name={name}
                      className="w-full"
                      label="Tarifications"
                      value={value}
                      defaultValue={defaultValue}
                      isMulti
                      values={selectValues}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      formatOptionLabel={({ label }) => {
                        const fields = JSON.parse(`{${label}}`)
                        const name = fields.nom
                        delete fields.nom
                        return (
                          <div className="flex flex-col w-full">
                            <div className="w-full font-black">{name}</div>
                            <div className="w-full mb-1 border border-gray-400"></div>

                            <div className="w-full">
                              {Object.entries(fields).map((f) => (
                                <div>
                                  {f[0]} : {f[1]},
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      }}
                    />
                    {errors?.priceslists?.message &&
                      (
                        <div className="text-sm text-red-500">
                          {errors.priceslists.message}
                        </div>
                      )}
                  </>
                )}
              />
            </div>

          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'nurseryPriceHour'}
              ref={register({ required: 'Champ obligatoire' })}
              type="number"
              name="nurseryPriceHour"
              label="Prix horaire garderie"
              placeholder="4"
              error={errors.nurseryPriceHour && errors.nurseryPriceHour.message}
              value={pricingsGrid?.nurseryPriceHour}
              defaultValue={pricingsGrid && pricingsGrid.nurseryPriceHour}
            />
          </div>
          <StepButtons
            loading={loading.isLoading}
            goBack={goBack}
            nextLabel={'Enregistrer'}
          />
        </form>
      </div>
    </>
  )
}

import React, { useRef, useState, useEffect } from 'react'
import ReactToPrint from 'react-to-print'

import { useForm } from 'react-hook-form'
import Input from 'components/form/Input'
import Button from 'components/form/Button'
import useBill from 'hooks/useBill'

import montessori from 'assets/images/logo_ecole_montessori_pau.jpg'
import { dateFormat, twoDecimalPlace } from '../lib/utils'
import { BILL_TYPE, BILL_STATUS, USER_ROLE } from 'lib/constants'

import { useTranslation } from 'react-i18next'

export default function BillWritable({
  bill,
  setBillData,
  faNameExist,
  creditNote,
  child,
  userList,
  summary,
  onCreateCreditNote,
  role,
  onSubmitEditing,
  onPayBill,
  isLoading,
  onCancelBill,
  recreate,
  setErrorMessage,
  onRecreate,
  noPrintable = false,
}) {
  const componentToPrint = useRef()
  const [schoolingYear, setSchoolingYear] = useState()
  const [quantity, setQuantity] = useState()
  const [isEditing, setIsEditing] = useState()
  const [isEditingPaymentTerms, setIsEditingPaymentTerms] = useState()
  const [isEditingPrixHT, setIsEditingPrixHT] = useState()
  const [isEditingQtt, setIsEditingQtt] = useState()
  const [isEditingDesignation, setIsEditingDesigation] = useState()
  const [isEditingDate, setIsEditingDate] = useState()
  const [isEditingNom, setIsEditingNom] = useState()
  const [isEditingFA, setIsEditingFA] = useState()
  const [isEditingSchool, setIsEditingSchool] = useState()
  const [LastFA, setLastFA] = useState()
  const [FA, setFA] = useState()
  const [prixHT, setPrixHT] = useState(0)
  const [totalHt, setTotalHt] = useState(0)
  const [designation, setDesignation] = useState('')
  const [nom, setNom] = useState('')
  const [date, setDate] = useState(new Date())
  const [edit, setEdit] = useState('')
  const [payment, setPayment] = useState('')
  const [invoiceName, setInvoiceName] = useState(null)
  const form = useForm()
  const { register, handleSubmit, watch, getValues } = form
  const { getBills } = useBill()
  const { t } = useTranslation()

  const toggleEditing = (kind) => {
    let data = getValues(kind)
    switch (kind) {
      case 'info':
        isEditing && setEdit(data)
        setBillData({ ...bill, info: data })
        setIsEditing(!isEditing)
        break
      case 'paymentTerms':
        isEditingPaymentTerms && setPayment(data)
        setBillData({ ...bill, payment: data })
        setIsEditingPaymentTerms(!isEditingPaymentTerms)
        break
      case 'prixHT':
        isEditingPrixHT
          ? data === undefined || data === '' || isNaN(data)
            ? setErrorMessage('Veuillez choisir un prix valide')
            : setPrixHT(Number(data).toFixed(2))
          : setPrixHT(Number(data).toFixed(2))
        setBillData({ ...bill, prixHT: Number(data).toFixed(2) })
        setIsEditingPrixHT(!isEditingPrixHT)
        break
      case 'qtt':
        isEditingQtt
          ? data === undefined || data === '' || isNaN(data)
            ? setErrorMessage('Veuillez choisir une quantité valide')
            : setQuantity(Number(data).toFixed(0))
          : setQuantity(Number(data).toFixed(0))
        setBillData({ ...bill, qtt: Number(data).toFixed(0) })
        setIsEditingQtt(!isEditingQtt)
        break
      case 'Nom':
        isEditingNom && setNom(data)
        setBillData({ ...bill, nom: data })
        setIsEditingNom(!isEditingNom)
        break
      case 'designation':
        // isEditingDesigation //&& setDesignation(data)
        setIsEditingDesigation(!isEditingDesignation)
        break
      case 'Date':
        console.log(date)
        isEditingDate
          ? data === undefined || data === ''
            ? setErrorMessage('Veuillez choisir une date valide ')
            : setDate(data)
          : setDate(data)
        setBillData({ ...bill, date: data })
        setIsEditingDate(!isEditingDate)
        break
      case 'School':
        isEditingSchool
          ? data === undefined || data === ''
            ? setErrorMessage(
                'Veuillez choisir une période valide (exemple : 2020-2021)'
              )
            : setSchoolingYear(data)
          : setSchoolingYear(data)
        setBillData({ ...bill, school: data })
        setIsEditingSchool(!isEditingSchool)
        break
      case 'FA':
        isEditingFA
          ? data === undefined || data === ''
            ? setErrorMessage('Veuillez choisir un numéro de facture')
            : formatFAname(data)
          : setFA(data)
        setBillData({ ...bill, name: data })
        setIsEditingFA(!isEditingFA)
        break
      default:
        break
    }
  }

  useEffect(() => {
    const fullyear = new Date().getFullYear()
    let faName = `FA-${fullyear}-0001`
    if (LastFA) {
      let lastInvoiceNameParts = LastFA.split('-')
      if (lastInvoiceNameParts.length === 3) {
        const lastInvoiceNumber = lastInvoiceNameParts.pop()
        const lastInvoiceYear = lastInvoiceNameParts.pop()
        const newInvoiceNumber = parseInt(lastInvoiceNumber, 10) + 1
        faName = `FA-${lastInvoiceYear}-${newInvoiceNumber
          .toString()
          .padStart(4, 0)}`
      }
    }

    setInvoiceName(faName)

    //eslint-disable-next-line
  }, [LastFA])

  useEffect(() => {
    if (invoiceName && setBillData) {
      setBillData({ ...bill, name: invoiceName })
    }
    //eslint-disable-next-line
  }, [invoiceName, setBillData])

  useEffect(() => {
    let year = new Date().getFullYear()
    getBills(0, 1).then((res) => {
      if (res.results.length === 0) {
        setLastFA(`FA-${year}-0000`)
      } else {
        setLastFA(
          res.results[0]?.name ? res.results[0]?.name : `FA-${year}-0000`
        )
      }
    })
    if (!isEditingSchool) {
      setSchoolingYear(`${year - 1}-${year}`)
    }
  }, [LastFA, setSchoolingYear, isEditingSchool, bill, getBills])

  const formatFAname = (data) => {
    let regex = /FA-\d\d\d\d-\d\d\d\d/i
    if (regex.test(data)) {
      setFA(data)
    } else {
      setErrorMessage('FACTURE INVALIDE FORMAT : FA-AAAA-4Chiffres')
    }
  }

  // calcule totalHt
  useEffect(() => {
    if (faNameExist === true) {
      setErrorMessage(`La facture ${FA} existe déjà`)
    }

    if (!isNaN(prixHT) && !isNaN(quantity)) {
      setTotalHt(twoDecimalPlace(Number(prixHT) * Number(quantity)))
    } // else if (bill && bill?.BillItems[0]?.amount && quantity) {
    //   setTotalHt(twoDecimalPlace(bill.BillItems[0].amount * quantity))
    // }
  }, [prixHT, quantity, getValues, watch, FA, faNameExist, setErrorMessage])

  return (
    <>
      <div className="text-blue-600 text-center flex justify-center print:hidden">
        <div className="flex justify-end sm:w-1/2 xl:w-1/2 gap-2 pr-4 my-4">
          {bill &&
            bill.status === BILL_STATUS.pending &&
            !bill?.BillUsers[0]?.Company?.id &&
            bill.type === BILL_TYPE.childcare &&
            role &&
            role !== USER_ROLE.ADMIN && (
              <button
                className="bg-green-500 text-white py-4 px-4 rounded-lg"
                type="submit"
                onClick={onPayBill}
              >
                {t('Payer la facture')}
              </button>
            )}
          {bill &&
            bill?.status === BILL_STATUS.pending &&
            role &&
            role === USER_ROLE.ADMIN &&
            !recreate && (
              <>
                <button
                  className="text-red-500 p-4 rounded-lg"
                  onClick={onCancelBill}
                >
                  {t('Annuler la facture')}
                </button>
              </>
            )}
          {bill &&
            bill?.status === BILL_STATUS.cancel &&
            role === USER_ROLE.ADMIN &&
            bill?.type !== BILL_TYPE.creditnote && (
              <>
                {!recreate ? (
                  <button
                    className="text-yellow-500 p-4 rounded-lg"
                    onClick={onRecreate}
                  >
                    {t('Mode création de facture')}
                  </button>
                ) : (
                  <button
                    className="text-yellow-500 p-4 rounded-lg"
                    onClick={onRecreate}
                  >
                    {t("Mode création d'avoir")}
                  </button>
                )}
              </>
            )}
          <ReactToPrint
            trigger={() => (
              <button className="bg-transparent text-blue-600 py-3 px-4 rounded-lg hover:bg-blue-500 hover:text-white">
                {!noPrintable && t('Imprimer')}
              </button>
            )}
            content={() => componentToPrint.current}
            bodyClass={''}
            // documentTitle={
            //   bill && `${bill.BillUsers[0].User.lastname} ${bill.name}`
            // }
            pageStyle={
              !noPrintable &&
              `" 
            @media all {
              .page-break {
                display: none;
              }
            }
            
            @media print {
              html, body {
                height: initial !important;
                overflow: initial !important;
                -webkit-print-colorimport default from '../lib/currencies';
  -adjust: exact;import { setHours } from 'date-fns';
  import { useBill } from 'hooks/useBill';
import { useUsers } from 'hooks/useUsers';
import { useUser } from 'stores/user';
import { useApiFetch } from 'components/common/table/hooks';

              }
            }
            
            @media print {
              .page-break {
                margin-top: 1rem;
                display: block;
                page-break-before: auto;
              }
            }
            
            @page {
              size: auto;
              margin: 20mm;
            }
            "`
            }
          />
        </div>
      </div>
      <div className="flex items-center print:items-start justify-center mt-2 print:mt-0">
        <form
          className="w-full form-container sm:w-full xl:w-1/2"
          onSubmit={(e) => {
            e.preventDefault()
            if ((isEditing || isEditingPaymentTerms) && !creditNote) {
              toggleEditing(isEditing ? 'info' : 'paymentTerms')
              return
            }
          }}
        >
          <div ref={componentToPrint}>
            <div className="flex w-full justify-between">
              <div>
                <div className="text-3xl">
                  {/* get Number : LastFA.split("-")[2].split("0").slice(-1)[0] / get nm elt for number of 0 : LastFA.split("-")[2].split("0").length  */}
                  {invoiceName !== null ? invoiceName : 'FA-ANNEE-NUMERO'}
                </div>

                {role === USER_ROLE.ADMIN && (
                  <span className="text-blue-500 print:hidden">
                    {isEditingFA ? (
                      <>
                        <span
                          className="hover:text-blue-600 hover:cursor-pointer"
                          onClick={() => toggleEditing('FA')}
                        >
                          Enregistrer
                        </span>{' '}
                        &nbsp; | &nbsp;
                        <span
                          className="text-orange-600 hover:text-orange-500 hover:cursor-pointer"
                          onClick={() => setIsEditingFA(false)}
                        >
                          Annuler
                        </span>
                      </>
                    ) : (
                      <span
                        className="hover:text-blue-600 hover:cursor-pointer"
                        onClick={() => toggleEditing('FA')}
                      >
                        Modifier
                      </span>
                    )}
                  </span>
                )}
                {isEditingFA && (
                  <div>
                    <div className="text-sm">
                      <i>
                        Dernière numéro de facture :{' '}
                        {LastFA !== undefined
                          ? LastFA.split('-')[2]
                          : 'pending'}
                      </i>
                    </div>
                    <Input
                      key={'FA'}
                      type="text"
                      ref={register()}
                      name="FA"
                      value={invoiceName}
                      placeholder="FA-ANNEE-NUMERO (FA-20XX-0XXX)"
                      inputClassName="leading-10"
                    />
                  </div>
                )}

                <div className="text-xl">
                  {t('Année scolaire')} {schoolingYear}
                </div>

                {role === USER_ROLE.ADMIN && (
                  <span className="text-blue-500 print:hidden">
                    {isEditingSchool ? (
                      <>
                        <span
                          className="hover:text-blue-600 hover:cursor-pointer"
                          onClick={() => toggleEditing('School')}
                        >
                          Enregistrer
                        </span>{' '}
                        &nbsp; | &nbsp;
                        <span
                          className="text-orange-600 hover:text-orange-500 hover:cursor-pointer"
                          onClick={() => setIsEditingSchool(false)}
                        >
                          Annuler
                        </span>
                      </>
                    ) : (
                      <span
                        className="hover:text-blue-600 hover:cursor-pointer"
                        onClick={() => toggleEditing('School')}
                      >
                        Modifier
                      </span>
                    )}
                  </span>
                )}
                {isEditingSchool && (
                  <div>
                    <Input
                      key={'School'}
                      ref={register()}
                      type="text"
                      name="School"
                      value={watch('School')}
                      placeholder="A propos.."
                      inputClassName="leading-10"
                    />
                  </div>
                )}
              </div>
              <div>
                <img
                  src={montessori}
                  alt="Montessori"
                  className="logo"
                  style={{ width: '200px' }}
                />
              </div>
            </div>
            <div className="w-full my-2 border-2 border-black"></div>

            <div className="flex w-full justify-between items-center text-sm">
              <div>
                <div className="font-black">École Montessori de Pau</div>
                <div>4 bis rue Maryse Bastié</div>
                <div>64000 Pau</div>
                <div>contact@ecole-montessori-pau.fr</div>
              </div>
              <div className="text-base">
                <span>
                  {t('M / Mme')} {nom}
                </span>
                <div>
                  {role === USER_ROLE.ADMIN && (
                    <span className="text-blue-500 print:hidden">
                      {!isEditingNom ? (
                        <span
                          className="hover:text-blue-600 hover:cursor-pointer"
                          onClick={() => toggleEditing('Nom')}
                        >
                          Modifier
                        </span>
                      ) : (
                        ''
                      )}
                    </span>
                  )}
                  {isEditingNom && (
                    <div>
                      <select
                        onChange={(e) => {
                          setNom(e.target.value)
                          var idParent =
                            e.target.options[e.target.selectedIndex].accessKey
                          setBillData({
                            ...bill,
                            parent: idParent !== null ? idParent : undefined,
                          })
                          setIsEditingNom(false)
                        }}
                      >
                        <option default>Choisir un parent</option>
                        {userList.map((user) => {
                          return (
                            <option key={user.UserChildren[0].UserId}>
                              {user.lastname + ' ' + user.firstname}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  )}
                </div>
              </div>
              <div className="self-start font-black">
                {date !== undefined ? dateFormat(date) : 'Choisissez une date'}
                <div>
                  {role === USER_ROLE.ADMIN && (
                    <span className="text-blue-500 print:hidden">
                      {isEditingDate ? (
                        <>
                          <span
                            className="hover:text-blue-600 hover:cursor-pointer"
                            onClick={() => toggleEditing('Date')}
                          >
                            Enregistrer
                          </span>{' '}
                          &nbsp; | &nbsp;
                          <span
                            className="text-orange-600 hover:text-orange-500 hover:cursor-pointer"
                            onClick={() => setIsEditingDate(false)}
                          >
                            Annuler
                          </span>
                        </>
                      ) : (
                        <span
                          className="hover:text-blue-600 hover:cursor-pointer"
                          onClick={() => toggleEditing('Date')}
                        >
                          Modifier
                        </span>
                      )}
                    </span>
                  )}
                  {isEditingDate && (
                    <div>
                      <Input
                        key={'Date'}
                        type="date"
                        ref={register()}
                        name="Date"
                        value={watch('Date')}
                        placeholder="A propos.."
                        inputClassName="leading-10"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full my-2 border-2 border-black"></div>

            <div className="flex w-full justify-around items-center text-sm px-4">
              <div className="w-full text-center">
                <div className="font-black">{t('Désignation')}</div>
                {designation}
                <div>
                  {role === USER_ROLE.ADMIN && (
                    <span className="text-blue-500 print:hidden">
                      {isEditingDesignation ? (
                        <>
                          <span
                            className="hover:text-blue-600 hover:cursor-pointer"
                            onClick={() => toggleEditing('designation')}
                          >
                            Enregistrer
                          </span>{' '}
                          &nbsp; | &nbsp;
                          <span
                            className="text-orange-600 hover:text-orange-500 hover:cursor-pointer"
                            onClick={() => setIsEditingDesigation(false)}
                          >
                            Annuler
                          </span>
                        </>
                      ) : (
                        <span
                          className="hover:text-blue-600 hover:cursor-pointer"
                          onClick={() => toggleEditing('designation')}
                        >
                          Modifier
                        </span>
                      )}
                    </span>
                  )}
                  {isEditingDesignation && (
                    <div>
                      <select
                        onChange={(e) => {
                          switch (e.target.value) {
                            case BILL_TYPE.childcare:
                              setDesignation(
                                `Frais de garderie pour ${child?.lastname} ${child?.firstname}`
                              )
                              setBillData({
                                ...bill,
                                type: BILL_TYPE.childcare,
                                designtype: `Frais de garderie pour ${child?.lastname} ${child?.firstname}`,
                              })
                              break
                            case BILL_TYPE.creditnote:
                              setDesignation(
                                `Avoir pour ${child?.lastname} ${child?.firstname}`
                              )
                              setBillData({
                                ...bill,
                                designtype: `Avoir pour ${child?.lastname} ${child?.firstname}`,
                                type: BILL_TYPE.creditnote,
                              })
                              break
                            case BILL_TYPE.registration:
                              setDesignation(
                                `Frais de scolarité pour ${child?.lastname} ${child?.firstname}`
                              )
                              setBillData({
                                ...bill,
                                designtype: `Frais de scolarité pour ${child?.lastname} ${child?.firstname}`,
                                type: BILL_TYPE.registration,
                              })
                              break
                            case BILL_TYPE.registrationFees:
                              setDesignation(
                                `Frais d'inscription pour ${child?.lastname} ${child?.firstname}`
                              )
                              setBillData({
                                ...bill,
                                designtype: `Frais d'inscription pour ${child?.firstname}`,
                                type: BILL_TYPE.registrationFees,
                              })
                              break
                            default:
                              break
                          }
                        }}
                      >
                        <option default>Choisir une Désignation</option>
                        <option value={BILL_TYPE.childcare}>
                          frais de garderie
                        </option>
                        <option value={BILL_TYPE.creditnote}>avoir</option>
                        <option value={BILL_TYPE.registration}>
                          frais de scolarité
                        </option>
                        <option value={BILL_TYPE.registrationFees}>
                          frais d'inscription
                        </option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full text-center">
                <div className="font-black">{t('Prix')} HT</div>

                <>
                  <div>{Number(prixHT)} €</div>
                </>

                {role === USER_ROLE.ADMIN && (
                  <span className="text-blue-500 print:hidden">
                    {isEditingPrixHT ? (
                      <>
                        <span
                          className="hover:text-blue-600 hover:cursor-pointer"
                          onClick={() => toggleEditing('prixHT')}
                        >
                          Enregistrer
                        </span>{' '}
                        &nbsp; | &nbsp;
                        <span
                          className="text-orange-600 hover:text-orange-500 hover:cursor-pointer"
                          onClick={() => setIsEditingPrixHT(false)}
                        >
                          Annuler
                        </span>
                      </>
                    ) : (
                      <span
                        className="hover:text-blue-600 hover:cursor-pointer"
                        onClick={() => toggleEditing('prixHT')}
                      >
                        Modifier
                      </span>
                    )}
                    {isEditingPrixHT && (
                      <Input
                        key={'prixHT'}
                        ref={register()}
                        name="prixHT"
                        value={watch('prixHT')}
                        placeholder="A propos.."
                        inputClassName="leading-10"
                      />
                    )}
                  </span>
                )}
              </div>
              <div className="w-full text-center">
                <div className="font-black">{t('Quantité')}</div>

                <div>
                  {/*  && quantity.toFixed(2) */}
                  {isNaN(quantity)
                    ? 'choisir une quantité'
                    : Number(quantity).toFixed(0)}{' '}
                  {/* {bill && bill.type === BILL_TYPE.childcare && 'h'} */}
                </div>

                {role === USER_ROLE.ADMIN && (
                  <span className="text-blue-500 print:hidden">
                    {isEditingQtt ? (
                      <>
                        <span
                          className="hover:text-blue-600 hover:cursor-pointer"
                          onClick={() => toggleEditing('qtt')}
                        >
                          Enregistrer
                        </span>{' '}
                        &nbsp; | &nbsp;
                        <span
                          className="text-orange-600 hover:text-orange-500 hover:cursor-pointer"
                          onClick={() => setIsEditingQtt(false)}
                        >
                          Annuler
                        </span>
                      </>
                    ) : (
                      <span
                        className="hover:text-blue-600 hover:cursor-pointer"
                        onClick={() => toggleEditing('qtt')}
                      >
                        Modifier
                      </span>
                    )}
                  </span>
                )}
                {isEditingQtt && (
                  <Input
                    key={'qtt'}
                    ref={register()}
                    name="qtt"
                    value={watch('qtt')}
                    placeholder="A propos.."
                    inputClassName="leading-10"
                  />
                )}
              </div>
              <div className="w-full text-center">
                <div className="font-black">{t('Total')} HT</div>
                <div>{totalHt} €</div>
              </div>
            </div>

            <div className="w-full mt-4 mb-4 border border-black"></div>

            <div className="flex w-full justify-between items-center text-sm">
              <div></div>
              <div></div>
              <div>{t('Total')} HT</div>
              <div>{totalHt} €</div>
            </div>
            <div className="flex w-full justify-between items-center text-sm mt-2">
              <div></div>
              <div></div>
              <div className="text-xl font-black">
                {t('Total à payer en euros')}
              </div>
              <div className="text-xl">
                <>{totalHt} €</>
              </div>
            </div>

            <div className="w-full mt-4 mb-4 border-2 border-black"></div>

            <div className="flex w-full justify-between items-center text-sm mt-2">
              <div>
                <div className="font-black text-xs">
                  {t('Modalités de paiements')}&nbsp;{' '}
                  {role === USER_ROLE.ADMIN && (
                    <span className="text-blue-500 print:hidden">
                      {isEditingPaymentTerms ? (
                        <>
                          <span
                            className="hover:text-blue-600 hover:cursor-pointer"
                            onClick={() => toggleEditing('paymentTerms')}
                          >
                            Enregistrer
                          </span>{' '}
                          &nbsp; | &nbsp;
                          <span
                            className="text-orange-600 hover:text-orange-500 hover:cursor-pointer"
                            onClick={() => setIsEditingPaymentTerms(false)}
                          >
                            Annuler
                          </span>
                        </>
                      ) : (
                        <span
                          className="hover:text-blue-600 hover:cursor-pointer"
                          onClick={() => toggleEditing('paymentTerms')}
                        >
                          Modifier
                        </span>
                      )}
                    </span>
                  )}
                </div>
                <div>
                  {isEditingPaymentTerms ? (
                    <>
                      <Input
                        key={'paymentTerms'}
                        ref={register()}
                        name="paymentTerms"
                        value={watch('paymentTerms')}
                        placeholder="A propos.."
                        inputClassName="leading-10"
                      />
                    </>
                  ) : (
                    <>
                      {payment}
                      {/* {bill && !bill.paymentTerms && bill.type && (
                          <span>
                            {bill.type === BILL_TYPE.registrationFees
                              ? t(
                                  `Règlement par chèque à l'ordre de l'école Montessori de Pau`
                                )
                              : bill.type === BILL_TYPE.registration
                              ? t(`Règlement par prélèvement`)
                              : t('Règlement par carte bancaire')}
                          </span>
                        )} */}
                      {/* {bill &&
                          bill?.BillItems[0]?.Registration &&
                          !bill.paymentTerms &&
                          bill.type === BILL_TYPE.registration && (
                            <MonthlyBankDebit
                              year={schoolingYear}
                              amount={bill.amount}
                              dateStart={bill.BillItems[0].Registration.month}
                              numberPayment={
                                bill.BillItems[0].Registration.numberPayment
                              }
                            />
                          )}

                        {bill && bill.paymentTerms && (
                          <span>{bill.paymentTerms}</span>
                        )} */}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="flex w-full flex-col justify-between text-sm mt-2">
              <div className="font-black text-xs">
                {t('Informations complémentaires')}&nbsp;{' '}
                {role === USER_ROLE.ADMIN && (
                  <span className="text-blue-500 print:hidden">
                    {isEditing ? (
                      <>
                        <span
                          className="hover:text-blue-600 hover:cursor-pointer"
                          onClick={() => toggleEditing('info')}
                        >
                          Enregistrer
                        </span>{' '}
                        &nbsp; | &nbsp;
                        <span
                          className="text-orange-600 hover:text-orange-500 hover:cursor-pointer"
                          onClick={() => setIsEditing(false)}
                        >
                          Annuler
                        </span>
                      </>
                    ) : (
                      <span
                        className="hover:text-blue-600 hover:cursor-pointer"
                        onClick={() => toggleEditing('info')}
                      >
                        Modifier
                      </span>
                    )}
                  </span>
                )}
              </div>
              <div className="flex flex-col bg-gray-100 h-20">
                {isEditing && (
                  <Input
                    key={'info'}
                    ref={register()}
                    name="info"
                    value={watch('info')}
                    placeholder="A propos.."
                    inputClassName="leading-10"
                  />
                )}
                {edit}
                {/* {!isEditing && bill && bill.info}
                  {!isEditing && bill?.status === BILL_STATUS.paid && (
                    <p>
                      {t('Facture réglée le {{day}}/{{month}}/{{year}}', {
                        day: getDateWithoutTime(bill.datePayment).split('/')[2],
                        month: getDateWithoutTime(bill.datePayment).split('/')[1],
                        year: getDateWithoutTime(bill.datePayment).split('/')[0],
                      })}
                    </p>
                  )} */}
              </div>
            </div>

            <div className="flex w-full justify-end items-center text-xs mt-2">
              <div>
                <div>{t("Établissement d'enseignement primaire")}</div>
                <div>{t('TVA non applicable, art. 261-4-4°-a des CGI')}</div>
              </div>
            </div>

            <div className="w-full mt-4 mb-4 border border-black"></div>

            <div className="flex w-full justify-between items-center text-2xs mt-2 text-left">
              <div>
                <div>
                  {t(
                    "En cas de non règlement à l'échéance prévue, il sera dû, conformément aux articles L441-3 et L441-6 du code du commerce"
                  )}{' '}
                  :
                </div>
                <div>
                  -{' '}
                  {t(
                    "des pénalités de retard sur la base des honoraires restant dus, au taux de trois fois le taux d'intérêt en vigueur"
                  )}
                </div>
                <div>
                  -{' '}
                  {t(
                    'une indemnité forfaitaire pour frais de recouvrement de 40€'
                  )}
                </div>
              </div>
            </div>

            <div className="w-full mt-4 mb-4 border border-black"></div>

            <div className="flex w-full justify-between items-center text-xs mt-2">
              <div className="w-full text-center">
                ÉCOLE MONTESSORI DE PAU - SIRET: 82082595800011 / APE: 8520Z
              </div>
            </div>
          </div>
          {creditNote && (
            <div className="mt-4">
              {nom !== '' &&
              quantity !== 0 &&
              prixHT !== 0 &&
              designation !== '' ? (
                <Button
                  onClick={handleSubmit((e) => {
                    if (!isEditing && prixHT !== 0 && quantity !== 0) {
                      if (bill?.info) {
                        e.info = bill?.info
                      }
                      if (bill?.paymentTerms) {
                        e.paymentTerms = bill?.paymentTerms
                      }
                      onCreateCreditNote(e)
                    } else {
                      setErrorMessage(
                        'Veuillez remplir tous les champs correctement'
                      )
                    }
                  })}
                  disabled={isLoading}
                  submits
                >
                  {!recreate
                    ? 'Créer un avoir pour cette facture'
                    : 'Recréer la facture'}{' '}
                </Button>
              ) : (
                ''
              )}
            </div>
          )}
        </form>
      </div>
    </>
  )
}

import React from "react"
import { useLocation } from "react-router-dom"
import BillList from "components/registrations/BillList"
import LayoutAdmin from "../components/LayoutAdmin"

export default function AdminBillPage() {
  const location = useLocation()

  return <div ><LayoutAdmin location={location} hasBack={false}></LayoutAdmin>

    <BillList />
  </div>
}

import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Input from 'components/form/Input'
import Radio from 'components/form/Radio'
import StepButtons from './common/StepButtons'

export default function SetCompany({ onSubmit, goBack, company, id, loading }) {
  const form = useForm()
  const { register, handleSubmit, errors, reset, watch, setValue } = form

  const inputs = [
    { label: 'Oui', value: 1 },
    { label: 'Non', value: 0 },
  ]

  useEffect(() => {
    if (loading.prevState === false && loading.current === false) {
      reset()
    }
  }, [loading, reset])

  useEffect(() => {
    if (company) {
      setValue('financialPartner', company.financialPartner ? 1 : 0)
    }
  }, [company, setValue])

  return (
    <>
      <div className="flex items-center justify-center mt-16">
        <form
          className="form-container w-full sm:w-full xl:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="font-black mb-4 text-center">
            {`${!isNaN(id) ? 'Modifier ' : 'Ajouter'} une société`}
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'name'}
              ref={register({ required: 'Champ obligatoire' })}
              type="text"
              name="name"
              label="Nom de la société"
              placeholder="Nom de la société"
              error={errors.name && errors.name.message}
              defaultValue={company && company.name}
              value={watch('name')}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'address'}
              ref={register({ required: 'Champ obligatoire' })}
              type="text"
              name="address"
              label="Adresse de la société"
              placeholder="Adresse de la société"
              error={errors.address && errors.address.message}
              defaultValue={company && company.address}
              value={watch('address')}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Radio
              required={true}
              register={{ required: 'Champ obligatoire' }}
              key={'financialPartner'}
              name={`financialPartner`}
              input={{ label: '' }}
              form={form}
              label="Partenaire financier ?"
              inputs={inputs}
              value={watch('financialPartner')}
              error={
                errors?.financialPartner && errors?.financialPartner.message
              }
              defaultValue={company?.financialPartner === true ? 1 : 0}
            />
          </div>
          <StepButtons
            loading={loading.current}
            goBack={goBack}
            nextLabel={'Enregistrer'}
          />
        </form>
      </div>
    </>
  )
}

import React, { useMemo, useState, useCallback } from 'react'
import Table from 'components/common/table'
import usePricing from 'hooks/usePricing'
import { dateFmt } from 'components/common/table/formats'
import { useHistory } from 'react-router-dom'
import { DefaultColumnFilter, EqualDefaultFilter } from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'
import {
  Actions,
  EditAction,
  RemoveAction,
} from 'components/common/table/actions'

import { useNotify } from 'stores/notify'
import useChildcare from 'hooks/useChildcare'

const initialPageSize = 10
const initialSortBy = [
  {
    id: 'name',
    desc: true,
  },
]

const MyModelsList = () => {
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const [forceRefresh] = useState(1)

  const { getAllPricingsGrids } = usePricing()
  const { deletePricingGrid } = useChildcare()

  const onError = useCallback(
    (err) => {
      if (err && err.statusCode && err.statusCode === 401) {
        setErrorMessage(err.message)
        history.replace('/')
      } else {
        setErrorMessage('Une erreur est survenue')
      }
    },
    [history, setErrorMessage]
  )

  const fetchData = useApiFetch(getAllPricingsGrids, onError)

  const [removed, setRemoved] = useState(null)

  const onRemove = useCallback(
    async (id) => {
      const grid = await deletePricingGrid(id)
      if (grid.error) {
        setErrorMessage(grid.error.message)
        return
      }
      if (grid.results) {
        setSuccessMessage('Grille tarifaire supprimé')
        setRemoved(id)
      } else {
        setErrorMessage('Impossible de supprimer la grille tarifaire')
      }
    },
    [deletePricingGrid, setErrorMessage, setSuccessMessage]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'name',
        accessor: 'name', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Date mise à jour',
        accessor: 'updatedAt',
        className: 'font-bold',
        disableFilters: true,
        Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: 'Prix horaire garderie',
        accessor: 'nurseryPriceHour',
        Filter: EqualDefaultFilter,
        className: 'font-bold',
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableFilters: true,
        Cell: (props) => (
          <Actions>
            <EditAction
              entity={props.value}
              // message={'Voulez-vous modifier la grille tarifaire'}
              to={`/setpricingsgrids/${props.value}`}
            />
            <RemoveAction onRemove={onRemove} {...props} title={`Êtes-vous sûr de vouloir supprimer la grille tarifaire ${props.row.original.name}&nbsp;?`}/>
          </Actions>
        ),
      },
    ],
    [onRemove]
  )

  const addPricingsGrid = useCallback(() => {
    history.push('/setpricingsgrids/new')
  }, [history])

  return (
    <div className="flex justify-center w-full pt-12">
      <Table
        fetchData={fetchData}
        name="Liste des grilles tarifaires"
        initialPageSize={initialPageSize}
        columns={columns}
        rowRemoved={removed}
        initialSortBy={initialSortBy}
        addEntity={addPricingsGrid}
        forceRefresh={forceRefresh}
      />
    </div>
  )
}

export default MyModelsList

import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Input from 'components/form/Input'
import Radio from 'components/form/Radio'
import Spinner from 'components/common/Spinner'
import ProgressBar from '../components/ProgressBar'
import StepButtons from 'components/common/StepButtons'
import { useTranslation } from 'react-i18next'

export default function IncomeStatement({
  onSubmit,
  price,
  onFamilyIndexChange,
  goBack,
  income,
  numberPayment = [],
  registrationId,
}) {
  const form = useForm()
  const { register, handleSubmit, watch, errors } = form
  const [familyIndex, setFamilyIndex] = useState()
  const [choice, setChoice] = useState([])
  const { t } = useTranslation()

  const watchedIncome = Number(watch('income'))
  const allowances = Number(watch('allowances'))
  const numberDependantsChildren = Number(watch('numberDependantsChildren'))
  const numberOtherDependants = Number(watch('numberOtherDependants'))

  useEffect(() => {
    if (income && income.familyIndex) {
      setFamilyIndex(
        income.familyIndex /
        (income.numberDependantsChildren + income.numberOtherDependants + 2)
      )
    }
  }, [income])

  useEffect(() => {
    if (
      (watchedIncome || watchedIncome === 0) &&
      (allowances || allowances === 0) &&
      (numberDependantsChildren || numberDependantsChildren === 0) &&
      (numberOtherDependants || numberOtherDependants === 0)
    ) {
      const sum =
        (watchedIncome + allowances) /
        (numberDependantsChildren + numberOtherDependants + 2)
      if (sum !== Infinity || sum !== -Infinity || !isNaN(sum)) {
        setFamilyIndex(sum)
      }
    }
  }, [
    allowances,
    numberDependantsChildren,
    watchedIncome,
    numberOtherDependants,
  ])

  useEffect(() => {
    if (familyIndex > 0) {
      onFamilyIndexChange(familyIndex)
    }
  }, [familyIndex, onFamilyIndexChange])

  useEffect(() => {
    if (price?.oneChild) {
      const { oneChild, twoChild, threeChild } = price

      setChoice([
        { label: ' ', value: oneChild },
        { label: ' ', value: twoChild },
        { label: ' ', value: threeChild },
      ])
    }
  }, [price])

  return (
    <>
      <ProgressBar
        step={4}
        name={t('Vos revenus')}
        registrationId={registrationId}
      />

      <div className="flex items-center justify-center">
        <form
          className="form-container w-full sm:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="font-black mb-4 text-center">{t('Vos revenus')}</div>

          {!income && <Spinner></Spinner>}
          {income && (
            <>
              <div>
                <p className="text-sm mb-4 text-center">
                  {t('Revenu fiscal de référence du foyer')}
                  <br />
                  {t(
                    "En cas de déclaration d'impôt séparée, merci d'ajouter les revenus fiscaux de référence des deux parents. Dernier avis d'imposition à présenter lors de l'inscription (information gardée confidentielle.)"
                  )}
                </p>
                <p className="text-sm mb-4 text-center">
                  {t('Allocations familiales (total annuel)')}
                  <br />

                  {t('Relevé CAF à présenter, téléchargeable sur le site caf.fr')}
                  <br />
                  {t('Relevé CAF à présenter, téléchargeable sur le site caf.fr') && <><br /></>}
                  {t("Si vous n'avez pas de documents, renseignez votre revenu annuel.")}
                </p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 w-full my-2">
                <div className="md:pr-2 flex flex-col w-full">
                  <Input
                    required
                    key={'income'}
                    ref={register({
                      required: t('Champ obligatoire'),
                      max: {
                        value: 1000000,
                        message: t("Le nombre indiqué n'est pas correct"),
                      },
                    })}
                    type="number"
                    name="income"
                    label={t('Revenu fiscal')}
                    step={1}
                    placeholder="20000"
                    value={Math.round(watch('income'))}
                    defaultValue={income?.income}
                    // defaultValue={currentValue?.firstname}
                    error={errors.income && errors.income.message}
                    // required={"Champ obligatoire"}
                    autofocus={true}
                  />
                </div>
                <div className="md:pr-2 flex flex-col w-full justify-between">
                  <Input
                    required
                    key={'allowances'}
                    ref={register({
                      required: t('Champ obligatoire'),
                      max: {
                        value: 100000,
                        message: t("Le nombre indiqué n'est pas correct"),
                      },
                    })}
                    step={1}
                    type="number"
                    name="allowances"
                    label={t('Allocations familiales')}
                    placeholder="5000"
                    value={Math.round(watch('allowances'))}
                    defaultValue={income?.allowances}
                    // defaultValue={currentValue?.lastname}
                    error={errors.allowances && errors.allowances.message}
                  // required={"Champ obligatoire"}
                  />
                </div>
              </div>
              <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
                <div className="w-full">
                  <Input
                    required
                    key={'numberDependantsChildren'}
                    ref={register({
                      required: t('Champ obligatoire'),
                      max: {
                        value: 30,
                        message: t("Le nombre indiqué n'est pas correct"),
                      },
                    })}
                    type="number"
                    name="numberDependantsChildren"
                    label={t("Nombre d'enfants à charge")}
                    placeholder="0"
                    value={watch('numberDependantsChildren')}
                    step={1}
                    defaultValue={
                      income &&
                      income.numberDependantsChildren !== '' &&
                      income.numberDependantsChildren
                    }
                    error={
                      errors.numberDependantsChildren &&
                      errors.numberDependantsChildren.message
                    }
                  />
                  {/* <span className="text-xs italic"></span> */}
                </div>
              </div>

              <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
                <div className="w-full">
                  <Input
                    required
                    key={'numberOtherDependants'}
                    ref={register({
                      required: t('Champ obligatoire'),
                      max: {
                        value: 30,
                        message: t("Le nombre indiqué n'est pas correct"),
                      },
                    })}
                    type="number"
                    name="numberOtherDependants"
                    label={t("Nombre d'autres personnes à charge")}
                    placeholder="0"
                    value={watch('numberOtherDependants')}
                    step={1}
                    defaultValue={
                      income &&
                      income.numberOtherDependants !== '' &&
                      income.numberOtherDependants
                    }
                    error={
                      errors.numberOtherDependants &&
                      errors.numberOtherDependants.message
                    }
                  />
                  {/* <span className="text-xs italic"></span> */}
                </div>
              </div>

              <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
                <div className="w-full">
                  <Input
                    key={'familyIndex'}
                    type="number"
                    name="familyIndex"
                    label={t('Indice familial')}
                    placeholder=""
                    value={Math.round(familyIndex)}
                    disabled
                  />
                  <span className="text-xs italic">
                    {t('Champ complété automatiquement')}
                  </span>
                </div>
              </div>
              <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
                <div className="w-full">
                  <Input
                    key={'registrationFees'}
                    type="number"
                    defaultValue={price && price.registrationsFees}
                    name="registrationFees"
                    label="Frais d'inscription"
                    placeholder=""
                    value={price && price.registrationFees}
                    disabled
                  />
                  <span className="text-xs italic">
                    {t('Champ complété automatiquement')}
                  </span>
                </div>
              </div>
              {familyIndex > 0 && !price && <Spinner></Spinner>}
              {price && (
                <>
                  <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
                    <div className="w-full">
                      <div className="flex w-full flex-row md:flex-col my-2 justfiy-evenly">
                        <div className="flex md:flex-row flex-col w-full justify-between md:justify-center">
                          <div className="md:w-1/3 h-full px-4 py-2 text-center">
                            1<sup className="text-xs">{t('1er')} </sup>
                            {t('enfant')}
                          </div>
                          <div className="md:w-1/3 h-full px-4 py-2 text-center">
                            2<sup className="text-xs">{t('2ème')} </sup>
                            {t('enfant')}
                          </div>
                          <div className="md:w-1/3 h-full px-4 py-2 text-center">
                            3<sup className="text-xs">{t('3ème')} </sup>
                            {t('enfant')} {t('ou plus')}
                          </div>
                        </div>
                        <div className="flex md:flex-row flex-col w-full justify-between md:justify-center">
                          <div className="md:w-1/3 h-full px-4 py-2 text-center">
                            {price.oneChild} €/{t('année')}
                          </div>
                          <div className="md:w-1/3 h-full px-4 py-2 text-center">
                            {price.twoChild} €/{t('année')}
                          </div>
                          <div className="md:w-1/3 h-full px-4 py-2 text-center">
                            {price.threeChild} €/{t('année')}
                          </div>
                        </div>
                        <div className="flex flex-row w-full justify-evenly md:items-center ">
                          <Radio
                            form={form}
                            input={{ label: t("Nombre d'enfants à charge") }}
                            inputs={choice}
                            name="amount"
                            noLabel
                            defaultValue={income?.amount || price?.oneChild}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
                <Radio
                  input={{ label: t('Nombre de paiements') }}
                  inputs={numberPayment}
                  key={JSON.stringify(income?.numberPayment)}
                  form={form}
                  defaultValue={
                    income?.numberPayment || numberPayment[0]?.value
                  }
                  name="numberPayment"
                  label={t('Nombre de paiements')}
                />
              </div>
              <StepButtons disabled={!price} goBack={goBack} />
            </>
          )}
        </form>
      </div>
    </>
  )
}

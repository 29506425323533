import React from 'react'
import UserIcon from 'components/svg/user'
import StarIcon from 'components/svg/star'
import Spinner from './common/Spinner'

export default function ActivityCard({
  hasActivityDone = false,
  level,
  onValidateSkill,
  activity,
  category,
  activityName,
  all,
  isCardLoading,
}) {
  return (
    <div
      className="w-full mb-6 flex"
      onClick={() => onValidateSkill(activity, category)}
    >
      <div className="bg-white border-transparent rounded shadow-lg flex-1 cursor-pointer">
        <div className={`${hasActivityDone ? 'bg-blue-500' : 'bg-gray-300'}  text-gray-800 border-b border-gray-500 rounded-tl rounded-tr py-2 px-6 flex justify-between items-center`}>
          <h5 className={` ${hasActivityDone ? 'text-white' : 'text-gray-600'} `}>{activityName}</h5>
          {activity?.ChildrenActivities && activity?.ChildrenActivities[0]?.canPresent && (
            <div className="flex-shrink-0">
              <StarIcon height="1.5em" width="1.5em"></StarIcon>
            </div>
          )}
        </div>
        <div className="p-6 py-4 text-blue-900 flex justify-center">
          {isCardLoading ? (
            <Spinner></Spinner>
          ) : (
            <>
              {activity?.FileId ? (
                <img
                  src={activity?.File?.url}
                  alt={`${activity.name}`}
                />
              ) : (
                <UserIcon height="5em" width="5em"></UserIcon>

              )}
            </>
          )}
        </div>
        {!all && (
          <div className="p-6 py-2 text-blue-900 flex justify-around items-center gap-2">
            <div
              style={{ height: '25px', width: '25px', background: `#${level ? level : 'white'}` }}
              className="border border-black"
            >
            </div>

            <div className='flex-1'>
              {(activity?.ChildrenActivities && activity?.ChildrenActivities[0]?.ActivityLevel?.name) || "Pas encore réalisé"}
            </div>

          </div>
        )}
        {activity?.ChildrenActivities && activity?.ChildrenActivities[0]?.comment && (
          <div className="text-blue-900 p-6 py-2">
            Commentaire: {activity?.ChildrenActivities[0]?.comment}
          </div>
        )}
      </div>
    </div>
  )
}
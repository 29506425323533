import * as React from "react"
function SvgComponent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M84.172 10.16H15.828c-7.73 0-14 6.27-14 14v9.691a4.004 4.004 0 004 4h4.5v39c0 7.18 5.82 13 13 13h53.344c7.18 0 13-5.82 13-13v-39h4.5a4.004 4.004 0 004-4V24.16a14.003 14.003 0 00-14-14zm-2.5 66.691a5.003 5.003 0 01-5 5H23.328c-2.758 0-5-2.238-5-5v-39h63.344zm8.5-47H9.828V24.16c0-3.313 2.688-6 6-6h68.344c3.313 0 6 2.687 6 6z" />
      <path d="M36.629 51.43h26.75a4 4 0 000-8h-26.75a4 4 0 000 8z" />
    </svg>
  )
}
export default SvgComponent
import React, { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import LayoutAdmin from 'components/LayoutAdmin'
import NurseryViewer from 'components/NurseryViewer'
import useChildcare from 'hooks/useChildcare'
import 'index.css'
import { useTranslation } from "react-i18next"
import useSchoolingYear from 'hooks/useSchoolingYear'
import { whichSchoolingYear } from 'lib/utils'

export default function NurseryViewerPage(props) {
  const location = useHistory()
  const {
    getChilds,
    getChildsCares,
    childCaresPresent,
    getChilrenRegistered,
    setChildCares,
    getPublicHolidays,
    getHolidays,
    deleteNurseryById,
    updateSlot,
  } = useChildcare()

  const {
    getChildcareHours
  } = useSchoolingYear()
  const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
      setWarningMessage,
    })
  )

  const { t } = useTranslation()
  const [modifiers, setModifiers] = useState()
  const [dateDisplay, setDateDisplay] = useState()
  const [nurserylist, setNurseryList] = useState([])
  const [children, setChildren] = useState()
  const [childcareHours, setChildcareHours] = useState([])
  const [publicHolidays, setPublicHolidays] = useState([])
  const [holidays, setHolidays] = useState([])
  const [isEditingTimeSlot, setIsEditingTimeSlot] = useState(false)

  const loadChildCares = useCallback(async (date) => {
    const dates = await getChildsCares(date)
    if (dates.error) {
      setErrorMessage(t(dates.error.message))
      return
    }
    let temp = dates.results.childcares.childCares.map((date) => {
      return new Date(date.date)
    })
    setModifiers({ highlighted: [...temp] })
    // set date and hours to match with date select by user
    let childCaresDates = dates.results.childcares.childCares.map((cc) => {
      let d = new Date(cc.date)
      d.setHours(12, 0, 0, 0)
      cc.date = d
      return cc
    })
    dates.results.childcares.childCares = childCaresDates
    setNurseryList(dates.results.childcares.childCares)
    setDateDisplay(date)

    // eslint-disable-next-line
  }, [])

  const onSchoolingYearChange = async (date) => {
    try {
      let year = date.split('-')[0]

      // children
      const children = await getChilrenRegistered(year)
      if (children.error) {
        setErrorMessage(children.error.message)
        return
      }
      if (children.results.length === 0) {
        setWarningMessage("Il n'y a pas d'enfants inscrit sur cette année scolaire")
        setChildren([])
      }
      setChildren(children.results.map(child => {
        return { label: `${child.firstname} ${child.lastname}`, value: child.id }
      }))

      // holidays
      const h = await getHolidays(date)
      const vacs = (h.results || []).map((e) => {
        const start = new Date(e.startDate)
        let end = new Date(e.endDate)
        if (start.getTime() === end.getTime()) {
          end.setMonth(end.getMonth() + 2)
        }
        return {
          start,
          end,
          description: e.description,
          schoolingYear: e.schoolingYear,
        }
      })
      setHolidays(vacs)

    } catch (err) {
      setErrorMessage("Impossible de charger la liste des enfants")
    }
  }

  useEffect(() => {
    async function loadData() {
      const date = whichSchoolingYear(new Date())
      onSchoolingYearChange(`${date.current}-${date.next}`)
      loadChildCares(new Date())
    }
    loadData()
    //eslint-disable-next-line
  }, [getChilds, loadChildCares, setErrorMessage, t])

  const onSubmit = async (datas) => {
    // delete second form if present
    if (datas?.child) delete datas.child
    if (datas?.slot) delete datas.slot

    if(isEditingTimeSlot){
      setWarningMessage("Il faut valider ou annuler l'édition d'horaire avant de pouvoir valider les présences")
      return
    }

    // Will only request if presences are defined, and if they are updated
    Object.entries(datas).forEach((nursery) => {
      if (nursery[1] === null) {
        delete datas[nursery[0]]
      }
      let id = nursery[0].split('-')[1]
      let nur = nurserylist.find((e) => e.id === Number(id))
      if (nur && nur.presence === nursery[1]) {
        delete datas[nursery[0]]
      }
    })

    if (!Object.entries(datas).length) {
      setWarningMessage(t("Rien n'a été modifié"))
      return
    }
    let childcaresPresent = await childCaresPresent(datas)
    if (childcaresPresent.error) {
      setErrorMessage(childcaresPresent.error.message)
      return
    }
    loadChildCares(dateDisplay)
    setSuccessMessage(t('Enregistré'))
  }

  const onSubmitAdding = async (datas, date, hideAddingForm) => {
    const formatDates = [{
      children: [
        ...datas.child.map(c => {
          return {
            child: c.value,
            childcare: [{
              value: datas.slot
            }],
            firstname: c.label.split(' ')[0],
            lastname: c.label.split(' ')[1],
            times: [
              {
                value: datas.slot
              }
            ]
          }
        })
      ],
      date: date
    }]

    try {
      const childcare = await setChildCares({
        dates: formatDates
      })

      if (childcare.error) {
        throw new Error(childcare.error.message)
      }
      setSuccessMessage("Enregistré")
      loadChildCares(date)
      hideAddingForm()
    } catch (err) {
      setErrorMessage(err.message)
    }
  }

  useEffect(() => {
    async function loadChildcareHours() {
      try {
        const childcareHours = await getChildcareHours()
        if (childcareHours.error) {
          setErrorMessage(childcareHours.message)
          return
        }
        setChildcareHours(
          childcareHours.results.map(hour => {
            return {
              label: `${hour.start}-${hour.end}`,
              value: hour.id
            }
          })
        )
        onYearChange(new Date().getFullYear())

      } catch (err) {
        setErrorMessage("Impossible de charger les créneaux horaires")
      }
    } loadChildcareHours()
    //eslint-disable-next-line
  }, [])

  const onYearChange = useCallback(
    async (year) => {
      const publicHolidays = await getPublicHolidays(year)
      setPublicHolidays(publicHolidays)
    },
    [getPublicHolidays]
  )

  const onDeleteNurseryById = async (id, date) => {
    try {
      const childcare = await deleteNurseryById(id)
      if (childcare?.error) {
        throw new Error(childcare.error.message)
      }
      if (childcare?.results) {
        setSuccessMessage("Inscription à la garderie supprimée")
        loadChildCares(new Date(date))
      }
    } catch (err) {
      setErrorMessage('Impossible de supprimer cette inscription à la garderie')
    }
  }

  const onSubmitSlotEditing = async ({
    e,
    addSlot,
    ChildId,
    ChildcareHourId,
    selectedDate,
  }) => {
    e.preventDefault()
    try {
      const slot = await updateSlot({
        slotId: addSlot.value,
        ChildId,
        ChildcareHourId,
      })

      if (slot?.error) {
        setErrorMessage(slot.error.message)
      }
      if (slot.results) {
        setSuccessMessage("Modification effectuée")
        loadChildCares(selectedDate)
        setIsEditingTimeSlot(false)
      }
    } catch (err) {
      setErrorMessage("Impossible de mettre à jour le créneau")
    }
  }

  const onEditTimeSlot = (id) => {
    if (id === '') {
      setIsEditingTimeSlot(false)
    } else {
      setIsEditingTimeSlot(id)
    }
  }

  return (
    <div>
      <LayoutAdmin location={location} title="Garderie" hasBack={false} />
      <NurseryViewer
        modifiers={modifiers}
        nurseryList={nurserylist}
        loadChildCares={loadChildCares}
        onSubmit={onSubmit}
        onSchoolingYearChange={onSchoolingYearChange}
        children={children}
        childcareHours={childcareHours}
        onSubmitAdding={onSubmitAdding}
        onYearChange={onYearChange}
        publicHolidays={publicHolidays}
        holidays={holidays}
        onDeleteNurseryById={onDeleteNurseryById}
        role={props.role}
        onSubmitSlotEditing={onSubmitSlotEditing}
        onEditTimeSlot={onEditTimeSlot}
        isEditingTimeSlot={isEditingTimeSlot}
      />
    </div>
  )
}

import React, { useEffect } from 'react'
import { getDateWithoutTime } from '../lib/utils'
import Spinner from 'components/common/Spinner'
import { useTranslation } from 'react-i18next'
import { BILL_STATUS, BILL_TYPE } from '../lib/constants'

export default function BillsView({
  loadBill,
  onViewBill,
  onPayBill,
  bills,
  loading,
}) {
  useEffect(() => {
    loadBill()
    // eslint-disable-next-line
  }, [])

  const { t } = useTranslation()
  return (
    <div className="flex items-center justify-center mt-20">
      {loading ? (
        <Spinner />
      ) : (
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 justify-center align-center gap-4">
          {bills &&
            bills.bill.map((bill, index) => (
              <div
                key={`bill-${index}`}
                className="flex flex-col justify-center align-center"
              >
                <div className="w-full rounded overflow-hidden shadow-lg flex-grow hover:bg-gray-200">
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">
                      {bill.name}
                    </div>
                    <ul className="text-gray-700 text-base h-100">
                      <li>
                        {' '}
                        {t('Date')} :&nbsp;
                        {t('{{jour}}/{{mois}}/{{annee}}', {
                          jour: getDateWithoutTime(bill.createdAt).split(
                            '/'
                          )[2],
                          mois: getDateWithoutTime(bill.createdAt).split(
                            '/'
                          )[1],
                          annee: getDateWithoutTime(bill.createdAt).split(
                            '/'
                          )[0],
                        })}
                      </li>
                      <li>
                        {' '}
                        {t('Montant')} : {bill.amount} €{' '}
                      </li>
                      {bill.type === BILL_TYPE.childcare && bill.status === 'paid' ? (
                        <li className="text-green-600">
                          {' '}
                          {t('Facture payée')}{' '}
                        </li>
                      ) : bill.type === BILL_TYPE.childcare && bill.status === 'pending' ? (
                        <li className="text-red-600">
                          {t('Facture à payer')}
                        </li>
                      ) : bill.type === BILL_TYPE.childcare && bill.status === 'cancel' && (
                        <li className="text-yellow-600">
                          {t('Facture annulée')}
                        </li>
                      )}

                      {bill.BillItems[0]?.designation &&
                        bill.BillChildren[0]?.Child && (
                          <li>
                            {t('Désignation')}&nbsp;:
                            {bill.type === 'Nursery' ? (
                              <>
                                {' '}
                                {t(
                                  'Frais de garderie pour {{firstname}} {{lastname}}',
                                  {
                                    firstname:
                                      bill.BillChildren[0].Child.firstname,
                                    lastname:
                                      bill.BillChildren[0].Child.lastname,
                                  }
                                )}
                              </>
                            ) : bill.type === 'RegistrationFeesBill' ? (
                              <>
                                {' '}
                                {t(
                                  "Frais d'inscription pour {{firstname}} {{lastname}}",
                                  {
                                    firstname:
                                      bill.BillChildren[0].Child
                                        .firstname,
                                    lastname:
                                      bill.BillChildren[0].Child.lastname,
                                  }
                                )}
                              </>
                            ) : bill.type === 'SchoolingYearBill' ? (
                              <>
                                {' '}
                                {t(
                                  'Frais de scolarité pour {{firstname}} {{lastname}}',
                                  {
                                    firstname:
                                      bill.BillChildren[0].Child
                                        .firstname,
                                    lastname:
                                      bill.BillChildren[0].Child.lastname,
                                  }
                                )}
                              </>
                            ) :
                              bill.type === 'Creditnote' ? (
                                <>
                                  {' '}
                                  {t(
                                    'Avoir',
                                    {
                                      firstname:
                                        bill.BillChildren[0].Child
                                          .firstname,
                                      lastname:
                                        bill.BillChildren[0].Child.lastname,
                                    }
                                  )}
                                </>
                              ) : (
                                <>
                                  {' '}
                                  {t(bill.type, {
                                    firstname:
                                      bill.BillChildren[0].Child.firstname,
                                    lastname:
                                      bill.BillChildren[0].Child.lastname,
                                  })}
                                </>
                              )}
                          </li>
                        )}
                    </ul>
                  </div>
                </div>
                <div className="flex flex-col w-full align-center justify-center">
                  <button
                    className="mb-1 m-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded flex-1"
                    onClick={() => onViewBill(bill.id)}
                  >
                    {t('Voir la facture')}
                  </button>
                  {bill.status === BILL_STATUS.pending && bill.type === BILL_TYPE.childcare && (
                    <button
                      className={`mt-1 m-2 bg-blue-500 text-white font-bold py-2 px-4 rounded flex-1 ${!bill?.BillUsers[0]?.Company?.name
                        ? 'hover:bg-green-700'
                        : 'cursor-default'
                        }`}
                      onClick={() => onPayBill(bill.id)}
                    >
                      {bill.type === BILL_TYPE.childcare && (
                        <>{t("Payer la facture")}</>
                      )}
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

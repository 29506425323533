import React, { useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import useBill from 'hooks/useBill'
import useRegistration from 'hooks/useRegistration'
import { useNotify } from 'stores/notify'
import Export from 'components/Export'
import LayoutAdmin from 'components/LayoutAdmin'
import useSchoolingYear from 'hooks/useSchoolingYear'
import useChildcare from 'hooks/useChildcare'

export default function ExportPage() {
  const location = useLocation()
  const [schoolingyears, setSchoolingyears] = useState([])
  const [years, setYears] = useState([])
  const {
    getSchoolingdate,
    classPresenceYearExport,
    classPresenceMonthExport,
    classPresenceMonthDetailedExport,
  } = useSchoolingYear()
  const {
    billingExport
  } = useBill()
  const {
    registrationExport,
    parentExport,
    childrenExport,
    headcountExport,
  } = useRegistration()
  const {
    childcareExport
  } = useChildcare()

  const {
    setSuccessMessage,
    setErrorMessage,
    setWarningMessage
  } = useNotify(({
    setSuccessMessage,
    setErrorMessage,
    setWarningMessage
  }) => ({

    setErrorMessage,
    setSuccessMessage,
    setWarningMessage
  })
  )

  useEffect(() => {

    async function loadSchoolingyear() {
      try {
        const schoolingyears = await getSchoolingdate()
        if (schoolingyears?.error) {
          throw new Error(schoolingyears.error)
        }

        const formatSelect = schoolingyears.results.map(schoolingyear => {
          return {
            value: schoolingyear.id,
            label: `${schoolingyear.year}-${Number(schoolingyear.year) + 1}`
          }
        })

        setSchoolingyears(formatSelect)

        const selectYear = schoolingyears.results.map(schoolingyear => {
          return {
            value: schoolingyear.year,
            label: schoolingyear.year
          }
        })

        setYears(selectYear)


      } catch (err) {
        setErrorMessage('Impossible de récupérer les années scolaires')
      }
    }

    loadSchoolingyear()

    //eslint-disable-next-line
  }, [])

  const onExport = useCallback(
    async (id, type, query) => {
      if (id && id.value) {
        try {
          let document
          switch (type) {
            case 'registrations':
              document = await registrationExport(id.value)
              break
            case 'bills':
              document = await billingExport(id.value)
              break
            case 'parents':
              document = await parentExport(id.value)
              break
            case 'children':
              document = await childrenExport({ id: id.value, order: query.order, fields: query.fields })
              break
            case 'headcount':
              document = await headcountExport({ id: id.value, filter: query.filter })
              break
            case 'childcare':
              if (!query.filter.month) {
                setWarningMessage("Il faut saisir un mois")
                return
              }
              document = await childcareExport({ year: id.value, filter: query.filter })
              break
            case 'classpresenceyear':
              document = await classPresenceYearExport({ year: id.value })
              break
            case 'classpresencemonth':
              if (!query.filter.month) {
                setWarningMessage("Il faut saisir un mois")
                return
              }
              document = await classPresenceMonthExport({ year: id.value, filter: query.filter })
              break
            case 'classpresencemonthdetailed':
              if (!query.filter.month) {
                setWarningMessage("Il faut saisir un mois")
                return
              }
              document = await classPresenceMonthDetailedExport({ year: id.value, filter: query.filter })
              break
            default:
              break
          }
          if (document?.error) {
            throw new Error(document.error.message)
          }
          setSuccessMessage('Téléchargement en cours')
        } catch (err) {
          setErrorMessage(err.message)
        }
      } else {
        setWarningMessage('Il faut définir une année scolaire')
      }
    }, [
    registrationExport,
    setSuccessMessage,
    setErrorMessage,
    setWarningMessage,
    billingExport,
    childrenExport,
    parentExport,
    headcountExport,
    childcareExport,
    classPresenceMonthExport,
    classPresenceYearExport,
    classPresenceMonthDetailedExport,
  ]
  )

  return (
    <LayoutAdmin location={location} title="Export" hasBack>
      <Export
        schoolingyears={schoolingyears}
        years={years}
        onExport={onExport}
      />
    </LayoutAdmin>
  )
}

import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useUser } from 'stores/user'
import { useNotify } from 'stores/notify'
import { SCHOOL_CLASSES, SCHOOL_CLASSES_USER } from '../lib/constants'

import useRegistration from '../hooks/useRegistration'
import useSchoolingYear from '../hooks/useSchoolingYear'
import SchoolingYear from 'components/SchoolingYear'
import Layout from 'components/Layout'
import { useTranslation } from 'react-i18next'

import { isFormChanged } from 'lib/utils'

export default function SchoolingYearPage(props) {
  const params = useParams()
  const history = useHistory()

  const { t } = useTranslation()

  const { getSchoolingdate, setSchoolingDate } = useSchoolingYear()

  const {
    getCompanies,
    getRegistration,
    createRegistration,
    setCompany,
    updateClass,
  } = useRegistration()

  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))

  let { registrationId, setCurrentRegistration, clearRegistration } = useUser(
    ({ registrationId, setCurrentRegistration, clearRegistration }) => ({
      registrationId,
      setCurrentRegistration,
      clearRegistration,
    })
  )

  const [years, setYears] = useState([])
  const [months, setMonths] = useState({})
  const [companies, setCompanies] = useState([])
  const [registration, setRegistration] = useState({})

  const [classList, setClassList] = useState([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // load dates
    async function loadDates() {
      try {
        const dates = await getSchoolingdate()
        if (dates?.error) {
          throw new Error(dates.error)
        }

        if (dates.results.length === 0) {
          setErrorMessage(
            t("Il n'y a pas de date d'inscription pour le moment")
          )
          setYears(null)
          return
        }
        // Format for years select
        let years = dates.results.map((d) => {
          return {
            value: d.year,
            label: d.year,
          }
        })

        // avoid years duplicates
        years = years.reduce((years_nodups, year) => {
          if (!years_nodups.find((y) => y.value === year.value)) {
            years_nodups.push(year)
          }
          return years_nodups
        }, [])

        setYears(years)

        // Format for months select, base on current year
        let _months = {}
        years.forEach((year) => {
          //   if (
          //     Number(year.label) === new Date().getFullYear() &&
          //     new Date().getMonth() >= 7
          //   ) {
          //     months[year.value] = [{ label: t('Janvier'), value: 'Janvier' }]
          //   } else {
          _months[year.value] = [
            { label: t('Septembre'), value: 'Septembre' },
            { label: t('Janvier'), value: 'Janvier' },
          ]
          // }
        })
        setMonths(_months)
      } catch (err) {
        setErrorMessage(t('Impossible de récupérer les dates'))
      }
    }
    loadDates()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // load companies
    async function loadCompanies() {
      try {
        // quick fix : as user, it should be done in backend
        const filters = [['financialPartner', true]]
        const companies = await getCompanies(undefined, undefined, filters)
        if (companies?.error) {
          throw new Error(companies.error)
        }
        // format companies for select
        const formatCompanies = companies.results.map((company) => {
          return {
            label: company.name,
            value: company.name,
          }
        })
        setCompanies(formatCompanies)
      } catch (err) {
        setErrorMessage('Impossible de récupérer les sociétés')
      }
    }
    loadCompanies()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function loadRegistration(id) {
      try {
        const registration = await getRegistration(id, {
          populate: true,
        })
        if (registration?.error) {
          throw new Error(registration.error)
        }
        setRegistration(registration.results)
      } catch (err) {
        setErrorMessage("Impossible de charger l'inscription")
      }
    }

    if (params && params.registration === 'new') {
      // if new registration, force remove registration from storage
      clearRegistration()
    } else if ((params && params.registration) || registrationId) {
      // if id given in url or storage, load datas
      loadRegistration(params?.registration || registrationId)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let classList
    if (props?.role && props.role === 'admin') {
      classList = Object.entries(SCHOOL_CLASSES).map((classe) => {
        if (classe[0] === 'first_group') {
          return { label: '3-6', value: 'class-0' }
        } else if (classe[0] === 'second_group') {
          return { label: '6-9', value: 'class-1' }
        } else {
          return { label: classe[0], value: `class-${classe[1]}` }
        }
      })
    } else {
      classList = Object.entries(SCHOOL_CLASSES_USER).map((classe) => {
        return {
          label: classe[0],
          value: classe[1],
        }
      })
    }

    setClassList(classList)
    // eslint-disable-next-line
  }, [props.role])

  const onSubmit = async (datas) => {
    setLoading(true)

    let currentRegistration = registration

    // creation
    if (!currentRegistration.id) {
      const { results, error } = await createRegistration(
        datas.classSelect.split('-')[1]
      )
      if (error) {
        setErrorMessage(t(error.message))
        setLoading(false)
        return
      }
      setCurrentRegistration({
        registrationId: results.registration.id,
        status: results.registration.status,
        company: results.registration?.companyPartner?.name,
      })

      currentRegistration = results.registration
      setLoading(false)
      history.replace(`/schoolingyear/${currentRegistration.id}`)
    }

    // update
    let {
      company,
      year,
      month,
      companySelector,
      classSelect,
      motivation,
    } = datas

    // merge company / companySelector
    classSelect = classSelect.split('-')[1]
    if (companySelector) {
      company = companySelector
    }

    if (
      isFormChanged(
        { name: company },
        { name: currentRegistration?.companyPartner?.name }
      )
    ) {
      const setcompany = await setCompany(company || '', currentRegistration.id)
      if (setcompany.error) {
        setErrorMessage(t(setcompany.error.message))
        setLoading(false)
        return
      }
    }

    if (
      isFormChanged(
        {
          month,
          year,
          motivation,
        },
        {
          motivation: currentRegistration?.motivation,
          month: currentRegistration?.month,
          year: currentRegistration?.SchoolingYear?.year,
        }
      )
    ) {
      const dates = await setSchoolingDate({
        year,
        month,
        registrationId: currentRegistration?.id,
        motivation,
      })

      if (dates.error) {
        setErrorMessage(t(dates.error.message))
        setLoading(false)
        return
      }
    }

    // update class
    if (
      currentRegistration?.SchoolClass === null ||
      (classSelect === '0' &&
        currentRegistration?.SchoolClass?.name ===
          SCHOOL_CLASSES.second_group) ||
      (classSelect === '1' &&
        currentRegistration?.SchoolClass?.name === SCHOOL_CLASSES.first_group)
    ) {
      await updateClass(classSelect, currentRegistration.id)
    }

    if (
      props.role === 'admin' &&
      currentRegistration?.SchoolClass?.name !== classSelect
    ) {
      await updateClass(classSelect, currentRegistration.id)
    }

    setCurrentRegistration({
      registrationId: currentRegistration?.id,
      status: currentRegistration?.status,
      company,
    })

    setLoading(false)
    history.push(
      '/child',
      props.location?.state?.creating && { creating: true }
    )
    return
  }

  return (
    <Layout title={t('Pré-inscription')} hasBack>
      <SchoolingYear
        years={years}
        months={months}
        companies={companies}
        registration={registration}
        classList={classList}
        goBack={() => history.push('/')}
        loading={loading}
        onSubmit={onSubmit}
      />
    </Layout>
  )
}

import React from 'react'
import { useLocation } from 'react-router-dom'
import LayoutAdmin from '../components/LayoutAdmin'
import CompanyList from '../components/registrations/CompanyList'

export default function CompanyListpage() {
  const location = useLocation()

  return (
    <div>
      <LayoutAdmin
        location={location}
        title="Liste des sociétés"
        hasBack={false}
      >
        <CompanyList />
      </LayoutAdmin>
    </div>
  )
}

import React from 'react'
import ReactDOM from 'react-dom'
import Modal from './Modal'
import { Controller } from 'react-hook-form'
import File from 'components/form/File'
import Input from 'components/form/Input'

const confirmRoot = document.createElement('div')

export default ({
  title,
  form,
  cancel,
}) => {
  return new Promise((resolve) => {
    const confirm = (answer) => {
      ReactDOM.unmountComponentAtNode(confirmRoot)
      resolve(answer)
    }

    ReactDOM.render(
      <Modal
        title={title || 'Importer un fichier'}
        responsive
        large
        isOpen={true}
        onClose={() => confirm(false)}
      >
        <div className="flex justify-center text-orange-600">(Le premier import peut prendre du temps)</div>
        <div className="flex w-full justify-center flex-col">
          <div>
            <Input
              key={'name'}  
              ref={form.register({ required: 'Champ obligatoire' })}
              type="number"
              name="year"
              label="Année"
              placeholder="Année"
              error={form.errors.name && form.errors.name.message}
              value={form.watch('name')}
            />
          </div>
          <div className="flex justify-center mt-8 flex-col gap-2">
            <Controller
              name="importfile"
              control={form.control}
              rules={{
                max: { value: 1, message: 'Too much files' },
              }}
              render={(props, name) => {
                return (
                  <>
                    <File
                      name={name}
                      label={("Fichier à impoter")}
                      className="files-dropzone"
                      form={form}
                      {...props}
                      options={{ limit: 1 }}
                      ext={['.zip']}
                    />
                    {form?.errors && form?.errors['importfile'] && (
                      <div className="text-sm text-red-500">
                        {form?.errors['importfile'].message}
                      </div>
                    )}
                  </>
                )
              }}
            />
            <div className="flex w-full justify-evenly">

              <div
                className="py-2 px-4 rounded-sm bg-red-500 text-white cursor-pointer"
                onClick={() => confirm(false)}
              >
                {cancel || "Annuler"}
              </div>
              <div
                className="py-2 px-4 rounded-sm bg-green-500 text-white cursor-pointer"
                onClick={() => confirm({
                  media: form.watch('importfile'),
                  selectYear: form.watch('year'),
                })}
              >
                Valider
              </div>
            </div>
          </div>
        </div>
      </Modal >,
      confirmRoot
    )
  })
}

import { useUser } from 'stores/user'
import Api from 'lib/api'
import { USER_ROLE } from 'lib/constants'

export default () => {
  const { token, BusinessLines, role } = useUser((o) => o)

  function isDeclarant() {
    const isReferent = BusinessLines.find(
      (bl) =>
        bl.UserBusinessLine && bl.UserBusinessLine.role === USER_ROLE.DECLARANT
    )

    return isReferent != null
  }

  function isReferent() {
    const isReferent = BusinessLines.find(
      (bl) =>
        bl.UserBusinessLine && bl.UserBusinessLine.role === USER_ROLE.REFERENT
    )

    return isReferent != null
  }

  function isAdmin() {
    return role === USER_ROLE.ADMIN
  }

  return {
    upsertAccreditation: async (user) => {
      //   setLoading(true)
      try {
        return await Api.upsertAccreditation(token, {
          body: user,
          query: { populate: true },
        })
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
        // setLoading(false)
      }
    },
    declineAccreditation(userAccreditationtoken) {
      return Api.declineAccreditation(token, userAccreditationtoken)
    },
    acceptAccreditation(params) {
      return Api.acceptAccreditation(token, params)
    },

    checkRegistrationToken(token) {
      return Api.checkRegistrationToken(token)
    },

    registerAccreditation(params) {
      return Api.registerAccreditation(params)
    },

    requestAccess(email) {
      return Api.requestAccess(email)
    },
    reinitPassword(params) {
      return Api.reinitPassword(params)
    },
    checkLoginToken(token) {
      return Api.checkLoginToken(token)
    },
    getAllMyBusinessLines() {
      return Api.getAllMyBusinessLines(token)
    },
    setPassword({ token, password }) {
      return Api.setPassword({ token, password })
    },
    getUser: async (id) => {
      try {
        const rawResponse = await Api.getUser(token, id, {
          query: { populate: true },
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },


    getUsersView: async (offset = 0, limit = 100, filters, order) => {
      //   setLoading(true)
      try {
        const rawResponse = await Api.getUsersView(token, {
          query: { offset, limit, filters, order, populate: true },
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
        // setLoading(false)
      }
    },
    getUsers: async (offset = 0, limit = 100, filters, order, populate = false) => {
      //   setLoading(true)
      try {
        const rawResponse = await Api.getUsers(token, {
          query: { offset, limit, filters, order, populate },
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
        // setLoading(false)
      }
    },
    deleteUser: async (payload) => {
      const { id } = payload

      try {
        const rawResponse = await Api.deleteUser(token, id)

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },
    isDeclarant: () => {
      return isDeclarant() || isReferent() || isAdmin()
    },
    isReferent: () => {
      return isReferent() || isAdmin()
    },
    isAdmin: isAdmin,

    async loginByToken(token) {
      try {
        const login = await Api.validateEmail(token)
        return login
      } catch (err) {
        return { error: err }
      }
    },
    async updateUsers(ids, role) {
      try {
        const users = await Api.updateUsers(token, { ids, ...role })
        return users
      } catch (err) {
        return { error: err }
      }
    },
    async reinitMultiplePassword(params) {
      try {
        const pass = await Api.reinitMultiplePassword(token, { ids: params })
        return pass
      } catch (err) {
        return { error: err }
      }
    },
    async createUser(params) {
      try {
        const user = await Api.createUser(token, params)
        return user
      } catch (err) {
        return { error: err }
      }
    }
  }
}

import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import useRegistration from 'hooks/useRegistration'
import useBill from 'hooks/useBill'
import { BILL_TYPE } from '../lib/constants'
import Confirm from 'components/common/ConfirmModal'

import { useNotify } from 'stores/notify'
import Button from 'components/form/Button'
import { REGISTRATION_STATUS } from '../lib/constants'

const SummaryBilling = ({ bills }) => {
  const orderedBills = useMemo(() => {
    return bills.sort((a, b) => {
      if (a.name < b.name) return 1
      if (a.name > b.name) return -1
      return 0
    })
  }, [bills])

  const recent6 = useMemo(() => {
    return orderedBills.slice(0, 6)
  }, [orderedBills])

  return (
    <div className="form-container w-full mt-4">
      <div className="font-bold mb-3">Factures récentes</div>
      <div>
        {recent6.map((bill) => {
          return (
            <div className="mb-3">
              <div>
                <span className="text-sm font-bold">{bill.name}</span>
              </div>
              <div>
                <span className="rounded text-xs mr-2 px-2 color-green-600 bg-green-200">
                  {bill.amount}€
                </span>
                <span className="rounded text-xs px-2 color-gray-600 bg-gray-200">
                  {bill.status}
                </span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default function AdminPanelSummary({
  registrationId,
  childName,
  status,
  summary,
}) {
  const history = useHistory()
  const [bills, setBills] = useState({})
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const { forceDeleteRegistration } = useRegistration()

  const { hasBillGenerated, generateBill } = useBill()

  useEffect(() => {
    const checkBills = async (id) => {
      try {
        const billsGenerated = await hasBillGenerated(id)
        if (billsGenerated.results && billsGenerated.results.length > 0) {
          // onSetBills(billsGenerated)
          setBills(billsGenerated.results)
        }
      } catch (err) {
        setErrorMessage('Impossible de récupérer les factures')
      }
    }
    checkBills(registrationId)
  }, [hasBillGenerated, registrationId, setErrorMessage])

  // const onSetBills = (list) => {
  //   let types = {}
  //   list.results.forEach((bill) => {
  //     const key = Object.keys(BILL_TYPE).filter(
  //       (type) => BILL_TYPE[type] === bill.type
  //     )
  //     types[key] = bill
  //   })
  //   if (types) {
  //     setBills(types)
  //   }
  // }

  const checkCompanyValid = useCallback(async () => {
    let confirm = true
    if (summary && summary.company && !summary.company.financialPartner) {
      if (
        await Confirm(
          `Cette inscription est associée à une société n'étant pas partenaire, continuer ?`
        )
      ) {
        confirm = true
      } else {
        confirm = false
      }
    }
    return confirm
  }, [summary])

  const onGenerateRegistrationBill = useCallback(
    async (id) => {
      if (await checkCompanyValid()) {
        try {
          const req = await generateBill(id, BILL_TYPE.registration)
          if (req.results) {
            setSuccessMessage('Facture générée')
            setBills(req.results)
          }
        } catch (err) {
          setErrorMessage('Impossible de générer la facture')
        }
      }
    },
    [setSuccessMessage, setErrorMessage, checkCompanyValid, generateBill]
  )

  const onGenerateRegistrationFeesBill = useCallback(
    async (id) => {
      if (await checkCompanyValid()) {
        try {
          const req = await generateBill(id, BILL_TYPE.registrationFees)
          if (req.results) {
            setSuccessMessage('Facture générée')
            setBills(req.results)
          }
        } catch (err) {
          setErrorMessage('Impossible de générer la facture')
        }
      }
    },
    [setSuccessMessage, setErrorMessage, generateBill, checkCompanyValid]
  )

  const onGenerateBillWithForm = async (id, summary) => {
    history.push(`/billsWithForm`, { registrationId: id, summary: summary })
  }

  const onModifyRegistration = useCallback(
    (id) => {
      history.push(`/schoolingyear/${id}`)
      return
    },
    [history]
  )

  const onDeleteRegistration = useCallback(
    async (id, childName) => {
      if (
        await Confirm(
          `Êtes-vous sur de vouloir supprimer l'inscription de ${childName}&nbsp;? `
        )
      ) {
        try {
          const del = await forceDeleteRegistration(id)
          if (del.results) {
            setSuccessMessage('Inscription supprimée')
            setTimeout(() => {
              console.log(
                'Redirect after 2s, this page should not be exists anymore'
              )
              history.goBack()
            }, '2000')
          }
        } catch (err) {
          setErrorMessage('Impossible de supprimer cette inscription')
        }
      }
    },
    [forceDeleteRegistration, history, setErrorMessage, setSuccessMessage]
  )

  // const onViewBill = ({ bill, id, registrationId }) => {
  //   history.push('/bill', { bill, id, registrationId })
  //   return
  // }

  const onGenerateSchoolCertificate = () => {
    history.push('/schoolcertificate', {
      firstname: summary?.child?.firstname,
      lastname: summary?.child?.lastname,
      schoolingYear: summary?.date.getFullYear(),
      schoolClass: summary?.child?.registration?.SchoolClass?.name,
    })
  }

  return (
    <div className="mt-4" style={{ minWidth: '275px' }}>
      <div className="form-container w-full">
        <div className="grid grid-cols-1 gap-4">
          {status && (
            <div className="text-lg text-center">Statut: {status}</div>
          )}

          {status === REGISTRATION_STATUS.accept && (
            <>
              <Button
                onClick={() => {
                  onGenerateRegistrationFeesBill(registrationId)
                }}
                variant="secondary"
              >
                Créer fact. inscription
              </Button>
              <Button
                onClick={() => {
                  onGenerateRegistrationBill(registrationId)
                }}
                variant="secondary"
              >
                Créer fact. scolarité
              </Button>
            </>
          )}

          <Button
            onClick={() => onGenerateBillWithForm(registrationId, summary)}
          >
            Créer facture
          </Button>

          <hr />
          <Button
            onClick={() => onGenerateSchoolCertificate()}
            variant="secondary"
          >
            Créer certificat de scolarité
          </Button>
          <hr />
          <Button
            onClick={() => onModifyRegistration(registrationId)}
            variant="black"
          >
            Modifier
          </Button>
          <Button
            onClick={() => onDeleteRegistration(registrationId, childName)}
            variant="danger"
          >
            Supprimer définitivement
          </Button>
          <hr />
        </div>
      </div>
      {bills && bills.length > 0 && <SummaryBilling bills={bills} />}
    </div>
  )
}

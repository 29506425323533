import React, { useState, useEffect } from 'react'
import Summary from '../components/Summary'
import useRegistration from '../hooks/useRegistration'
import { useHistory, useParams } from 'react-router-dom'
import confirm from 'components/common/ConfirmModal'
import { useUser, userApi } from 'stores/user'
import { useNotify } from '../stores/notify'
import Layout from 'components/Layout'
import { REGISTRATION_STATUS } from 'lib/constants'
import AdminPanelSummary from 'components/AdminPanelSummary'
import Spinner from '../components/common/Spinner'
import { useTranslation } from 'react-i18next'

export default function SummaryPage(props) {
  const { registration } = useParams()
  const { t } = useTranslation()
  const [summary, setSummary] = useState()
  const [company, setCompany] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [photo, setPhoto] = useState(null)
  const history = useHistory()
  const user = userApi()
  const { getSummary, updateRegistration } = useRegistration()
  const { status, setUser, registrationId } = useUser(
    ({ status, setUser, registrationId }) => ({
      status,
      setUser,
      registrationId,
    })
  )
  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))

  useEffect(() => {
    async function loadData() {
      if (!registration && !registrationId) {
        history.replace('/dashboard')
        return
      }
      if (registration) {
        setUser({ ...user, registrationId: registration })
      }
      const datas = await getSummary(registration)
      if (datas.error) {
        setErrorMessage(t(datas.error.message))
        setIsLoading(false)
        return
      }
      if (datas.results) {
        datas.results.summary.child.birthday = new Date(
          datas.results.summary.child.birthday
        )
        datas.results.summary.schoolingYear.date = new Date(
          datas.results.summary.schoolingYear.date
        )
        let d = new Date(datas.results.summary.schoolingYear.date)
        datas.results.summary.date = new Date(
          `${
            datas.results.summary.registration.month === 'Janvier' ? '01' : '09'
          }/${d.getDate()}/${d.getFullYear()}`
        )
        if (datas.results.summary.company) {
          setCompany(datas.results.summary.company.name)
        }
        setSummary(datas.results.summary)
      }
      setPhoto(datas.results.photo)
      setIsLoading(false)
    }
    loadData()
    //eslint-disable-next-line
  }, [])

  const validRegistration = async () => {
    try {
      const userConfirm = await confirm(
        t("Vous ne pourrez plus modifier l'inscription, confirmer ?"),
        t('Annuler')
      )
      if (userConfirm) {
        setIsLoading(true)
        let registration = await updateRegistration({
          status: REGISTRATION_STATUS.pending,
        })
        if (registration.error) {
          setErrorMessage(t(registration.error.message))
          setIsLoading(false)
          return
        }
        let temp = user
        delete temp.status
        delete temp.registrationId
        setUser({ ...temp })
        history.push(user.role === 'admin' ? '/admin' : '/dashboard')
      }
    } catch (err) {
      setErrorMessage(t('Service indisponible'))
    }
  }

  const goBack = () => {
    if (status !== REGISTRATION_STATUS.open) {
      history.goBack()
    } else {
      if (company) {
        history.push(
          '/parent',
          props?.location?.state && { ...props.location.state }
        )
      } else {
        history.push(
          '/incomestatement',
          props?.location?.state && { ...props.location.state }
        )
      }
    }
  }

  const onSendChildPhoto = () => {
    history.push('/child', { fromSummary: true })
  }

  return (
    <Layout
      buttonGoHome={{
        to: user.role === 'admin' ? '/admin' : '/dashboard',
        history,
      }}
      title={t('Récapitulatif')}
    >
      <div className="flex lg:flex-row flex-col justify-center">
        {isLoading ? (
          <Spinner></Spinner>
        ) : (
          <>
            {user.role === 'admin' && (
              <AdminPanelSummary
                registrationId={registrationId || ''}
                childName={
                  `${summary?.child?.firstname} ${summary?.child?.lastname}` ||
                  ''
                }
                status={(summary && summary?.registration?.status) || ''}
                summary={summary || {}}
              />
            )}
            <Summary
              summary={summary || {}}
              goBack={goBack}
              validRegistration={validRegistration}
              photo={photo || ''}
              role={user.role}
              onSendChildPhoto={onSendChildPhoto}
            />
          </>
        )}
      </div>
    </Layout>
  )
}

import React from 'react'

import { Controller } from 'react-hook-form'

export default function CheckBox(
  {
    label,
    name,
    required,
    // onChange,
    className = '',
    helpText,
    error,
    // value,
    // suffix,
    // defaultValue,
    form,
    // register,
    disabled = undefined,
    reverse,
    defaultChecked = false,
  },
  _ref
) {
  let classes = className

  if (required) {
    classes += ' required'
  }

  return (
    <div>
      <label
        htmlFor={name}
        className={`${classes} checkbox-label flex items-center ${reverse && 'flex-col-reverse'}`}
      >
        <Controller
          name={name}
          control={form.control}
          defaultValue={defaultChecked}
          render={({ onChange, value, name, defaultValue }) => (
            <input
              type="checkbox"
              className="mr-1"
              id={name}
              defaultChecked={defaultValue}
              defaultValue={defaultValue}
              checked={value}
              name={name}
              onChange={(e) => {
                onChange(e.target.checked)
              }}
              disabled={disabled}
            />
          )}
        />

        <span>{label}</span>
      </label>

      {error ? (
        <span className="help-text error">{error}</span>
      ) : (
        <span className="help-text">{helpText}</span>
      )}
    </div>
  )
}

import React from 'react'

import { Link } from 'react-router-dom'

import { useForm } from 'react-hook-form'

import LoggedOutHeader from 'components/common/LoggedOutHeader'

import Input from 'components/form/Input'
import Button from 'components/form/Button'
import { useTranslation } from 'react-i18next'
import useLanguage from 'hooks/useLanguage'

import FrenchFlag from 'components/svg/frenchFlag'
import UsFlag from 'components/svg/usFlag'

const SignupComponent = ({ onSubmit, isMailSend, sendMail, loading }) => {
  const { register, handleSubmit, watch, errors } = useForm()
  const { t } = useTranslation()
  const { changeLanguage } = useLanguage()
  const PasswordHint = () => {
    return (
      <ul>
        <li>
          •{' '}
          {t(
            'le mot de passe doit avoir une longueur minimale de 8 caractères'
          )}
        </li>
        <li>
          •{' '}
          {t(
            'le mot de passe doit être composé avec une combinaison d’au moins 3 types de caractères parmi les 4 suivants : majuscules (A-Z), minuscules (a-z), chiffres (0-9) et caractères spéciaux'
          )}
          ;
        </li>
        <li>
          •{' '}
          {t(
            'le mot de passe ne doit pas être constitué d’une combinaison d’un ou plusieurs mots communs et de chiffres.'
          )}
        </li>
      </ul>
    )
  }

  const validatePassword = (value) => {
    const validation1 =
      [
        '^(?=.*[a-z]).+$',
        '^(?=.*[A-Z]).+$',
        '^(?=.*\\d).+$',
        '^(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\|,.<>\\/?]).+$',
      ]
        .map((re) => !!value.match(new RegExp(re)))
        .filter((v) => v).length >= 3

    const validation2 = value.length >= 8
    return (validation1 && validation2) || <PasswordHint />
  }

  return (
    <div>
      <LoggedOutHeader />
      <div className="flex items-center justify-center align-middle">
        <div className="flex flex-col w-full md:w-3/4 lg:w-1/2 px-8 pt-6 pb-8 mb-4 ">
          {!isMailSend && (
            <form
              className="form-container w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                key={`section-email`}
                ref={register({
                  required: t('Champ obligatoire'),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: t('Adresse e-mail non valide'),
                  },
                })}
                type="text"
                name="email"
                label={t('E-mail')}
                placeholder={t('E-mail')}
                value={watch('email')}
                error={errors.email && errors.email.message}
                required={t('Champ obligatoire')}
                autofocus={true}
              />
              <Input
                key={`section-firstname`}
                ref={register({ required: t('Champ obligatoire') })}
                type="text"
                name="firstname"
                label={t('Prénom')}
                placeholder={t('Prénom')}
                value={watch('firstname')}
                error={errors.firstname && errors.firstname.message}
                required={t('Champ obligatoire')}
              />
              <Input
                key={`section-lastname`}
                ref={register({ required: t('Champ obligatoire') })}
                type="text"
                name="lastname"
                label={t('Nom')}
                placeholder={t('Nom')}
                value={watch('lastname')}
                error={errors.lastname && errors.lastname.message}
                required={t('Champ obligatoire')}
              />
              <Input
                key={`section-password`}
                ref={register({
                  required: t('Champ obligatoire'),
                  validate: validatePassword,
                })}
                type="password"
                name="password"
                label={t('Mot de passe')}
                placeholder={t('Mot de passe')}
                value={watch('password')}
                error={errors.password && errors.password.message}
                required={t('Champ obligatoire')}
              />

              <div className="flex flex-col w-full pt-6 pb-8 mb-4 ">
                <div className="flex flex-col md:flex-row items-center justify-between">
                  <Button
                    variant="black"
                    loading={loading}
                    submits
                    disabled={isMailSend}
                  >
                    {t("S'inscrire")}
                  </Button>
                </div>
              </div>
              <div className="py-3 px-4">
                <Link
                  to="/"
                  className="bg-white text-accent rounded hover:text-blue-500"
                >
                  {t('Déjà inscrit ? Connectez-vous')}
                </Link>
              </div>

              <div className="flex w-1/2 justify-center">
                <div
                  className="px-2 hover:text-blue-600 flex flex-col align-center justify-center"
                  onClick={() => changeLanguage('fr')}
                >
                  <div className="flex align-center justify-center ">
                    <div className="flex items-center">
                      <FrenchFlag className="fill-current" />
                    </div>
                    <div className="pl-2 flex-1 flex hover:cursor-pointer">{t('Français')}</div>
                  </div>
                </div>
                <div
                  className="px-2 hover:text-blue-600"
                  onClick={() => changeLanguage('eng')}
                >
                  <div className="flex align-center justify-start">
                    <div className="flex items-center">
                      <UsFlag className="fill-current" />
                    </div>
                    <div className="pl-2 flex-1 flex items-center hover:cursor-pointer">
                      {t('Anglais')}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
          {isMailSend && (
            <div className="form-container w-full ">
              <div className="text-center flex flex-col gap-3">
                <div className="text-3xl">
                  {t('Merci pour votre inscription')}
                </div>
                <div>
                  {t(
                    "Un e-mail a été envoyé à l'adresse {{email}} pour valider votre compte.",
                    { email: isMailSend.email }
                  )}
                </div>
                <div className="font-bold">
                  {t(
                    'Vérifiez votre boîte mail et cliquez sur le lien que nous venons de vous envoyer pour valider votre compte.'
                  )}
                </div>
                <div>
                  {t(
                    'Vous pourrez remplir un formulaire pour pré-inscrire votre enfant.'
                  )}
                </div>
                <span
                  className="text-blue-500 hover:text-blue-600 hover:cursor-pointer"
                  onClick={() => sendMail()}
                >
                  {t(
                    "Vous n'avez pas reçu d'e-mail ? Cliquez ici pour l'envoyer à nouveau."
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SignupComponent

import React, { useEffect, useState, useCallback } from 'react'
// import { useTable } from 'react-table'
import {
  useFilters,
  useAsyncDebounce,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { SortIndicator } from 'components/common/table/sort'
import ArrowLeftIcon from 'components/svg/arrowLeft'
import ArrowRightIcon from 'components/svg/arrowRight'
import FilterIcon from 'components/svg/filter'
import RefreshIcon from 'components/svg/refresh'
import AddIcon from 'components/svg/add'
import FormWaitingIcon from 'components/svg/formWaiting'
import BillIcon from 'components/svg/bill'
import ArchivedIcon from 'components/svg/archived'
import MailIcon from 'components/svg/mail'
import UserIcon from 'components/svg/user'
import TeacherIcon from 'components/svg/teacher'
import ImportIcon from 'components/svg/import'

import { useRef } from 'react'
import { useFilter } from 'stores/filter'
import { REGISTRATION_STATUS, BILL_STATUS, USER_ROLE } from 'lib/constants'
import Spinner from 'components/common/Spinner'

const PaginateNumber = ({ gotoPage, index, pageIndex }) => {
  return (
    <span
      onClick={() => gotoPage(index)}
      className={`mx-2 cursor-pointer px-3 py-1 rounded-sm text-gray-700 ${pageIndex === index ? 'font-bold bg-gray-300 text-white' : ''
        }`}
    >
      {index + 1}
    </span>
  )
}

const Pagination = ({
  maxPageShown = 7,
  gotoPage,
  setPageSize,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageSize,
  pageCount,
}) => {
  if (maxPageShown < 5) {
    throw new Error("maxPageShown can't be less than 5")
  }

  let pageNumbers = [...Array(pageCount).keys()].map((i) => {
    return (
      <PaginateNumber
        gotoPage={gotoPage}
        pageIndex={pageIndex}
        index={i}
        key={pageIndex + ' ' + i}
      />
    )
  })

  if (pageCount > maxPageShown) {
    const pages = [...Array(pageCount).keys()]
    const sideLength = Math.round(maxPageShown / 2)

    // Take siblings, page one after the other
    // first the pageIndex
    // then going right
    // then going left
    // until either side is empty
    // or the maxPageShown is reached
    const range = []
    let newCursor = pageIndex
    let direction = -1
    const maxSide = sideLength - 1
    for (let i = 0; i < maxSide;) {
      let idx = newCursor + direction * i
      direction = direction * -1
      if (pages[idx] !== undefined) {
        range.push(pages[idx])
        i++
      } else {
        direction = direction * -1
        idx = idx - (i + 1) * direction
        if (pages[idx] !== undefined) {
          range.push(pages[idx])
          i++
        } else {
          // no more pages to find
          break
        }
      }
      newCursor = idx
    }

    let sideWithIndex = range.sort((a, b) => {
      if (a < b) return -1
      if (a > b) return 1
      return 0
    })

    let sideRight = []

    // compute distance of pageIndex from the end of the Array
    const pageIndexIsLeft = pages.length - pageIndex > pageIndex ? true : false
    let sideLeft = pages.slice(pages.length - sideLength, pages.length - 1)
    if (!pageIndexIsLeft) {
      sideRight = sideWithIndex
      sideLeft = pages.slice(0, sideLength - 1)
    } else {
      sideRight = sideLeft
      sideLeft = sideWithIndex
    }

    pageNumbers = [
      ...sideLeft.map((i) => (
        <PaginateNumber gotoPage={gotoPage} pageIndex={pageIndex} index={i} />
      )),
      <span className="text-gray-500">...</span>,
      ...sideRight.map((i) => (
        <PaginateNumber gotoPage={gotoPage} pageIndex={pageIndex} index={i} />
      )),
    ]
  }

  return (
    <div className="flex justify-center">
      <div className="flex items-center">
        <span
          className={`${canPreviousPage ? 'cursor-pointer text-gray-700' : 'text-gray-400'
            }`}
          onClick={() => (canPreviousPage ? previousPage() : null)}
        >
          <ArrowLeftIcon className="fill-current" />
        </span>
        {pageNumbers.map((p) => p)}
        <span
          className={`${canNextPage ? 'cursor-pointer text-gray-700' : 'text-gray-400'
            }`}
          onClick={() => (canNextPage ? nextPage() : null)}
        >
          <ArrowRightIcon className="fill-current" />
        </span>
      </div>
    </div>
  )
}

export default ({
  name = 'Listing',
  columns = [],
  rowRemoved = null,
  rowRestored = null,
  // data = [],
  fetchData = () => null,
  initialPageSize = 10,
  initialSortBy,
  addEntity,
  onError = console.error,
  // manualPagination = false,
  // manualFilters = false,
  // loading = false,
  // pageCount: controlledPageCount,
  forceRefresh,
  onLineClick = () => null,
  defaultFiltersOptions,
  ...rest
}) => {
  const fetchDataDebounced = useAsyncDebounce(fetchData, 500)
  const skipPageResetRef = useRef()
  const [data, setData] = useState([])
  // const [loading, setLoading] = useState(false)
  // const [controlledPageSize, setControlledPageSize] = useState(undefined)
  const [controlledPageCount, setControlledPageCount] = useState(undefined)
  const [controlledTotalRows, setControlledTotalRows] = useState(undefined)
  const [error, setError] = useState(false)
  const {
    setFilters,
    resetFilters,
    filters: storedFilters,
  } = useFilter((o) => o)

  const asyncFetchData = useCallback(async (params = {}) => {
    const {
      pageSize = initialPageSize,
      pageIndex = 0,
      order = [],
      filters = [],
    } = params
    // setLoading(true)
    try {
      const [results, totalCount, nbRows] = await fetchDataDebounced({
        pageIndex,
        pageSize,
        filters,
        order,
      })
      if (results) {
        setData(results)
        setControlledPageCount(totalCount)
        setControlledTotalRows(nbRows)
        setError(false)
      }
    } catch (err) {
      setError('Impossible de charger les données.')
      console.error(err)
    }

    // TODO refreshing bug
    // if we set the loading flag
    // the pagination resets at the first page
    // setLoading(false)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    skipPageResetRef.current = false
  })

  // useEffect(() => {
  //   asyncFetchData()
  // }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    // totalRows,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setAllFilters,
    state: { pageIndex, pageSize, filters, sortBy },
  } = useTable(
    {
      columns,
      data,
      totalRows: controlledTotalRows,
      pageCount: controlledPageCount,
      manualSortBy: true, // disable make sorting work
      manualPagination: true,
      manualFilters: true,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        ...(initialSortBy && { sortBy: initialSortBy }),
        // sortBy: undefined
        ...(storedFilters[name] && { filters: storedFilters[name] }),
      },
      disableSortRemove: true, // (uncomment for switching asc/desc only)
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
    },
    useFilters,
    useSortBy,
    usePagination
  )

  const [showFilters, setShowFilters] = useState(false)

  useEffect(() => {
    if (filters) {
      setFilters(name, filters)
      if (filters.length > 0) setShowFilters(true)
    }
  }, [name, setFilters, filters])

  useEffect(() => {
    skipPageResetRef.current = true

    // Filters can be an array of filters.
    // So reconstruct them
    let newFilters =
      storedFilters[name] &&
      storedFilters[name].filter(
        (f) =>
          (Array.isArray(f.value) && f.value.length) || !Array.isArray(f.value)
      )

    newFilters =
      newFilters &&
      newFilters.reduce((newFilters, filter) => {
        if (Array.isArray(filter.value)) {
          filter.value.forEach((f) => {
            newFilters.push({
              id: filter.id,
              value: {
                value: f.value,
                operator: f.operator,
              },
            })
          })
        } else {
          newFilters.push(filter)
        }
        return newFilters
      }, [])

    asyncFetchData({
      pageIndex,
      pageSize,
      filters: newFilters,
      order: sortBy,
    }).catch(onError)
  }, [
    pageIndex,
    pageSize,
    storedFilters,
    rowRemoved,
    sortBy,
    forceRefresh,
    rowRestored,
    name,
    asyncFetchData,
    onError,
  ]) // remove sortBy from dependency, otherwise, it would refresh the whole compoment

  const toggleFilters = useCallback(() => {
    if (!skipPageResetRef.current) {
      // if (showFilters) {
      //   setAllFilters([])
      // }
      setShowFilters(!showFilters)
    }
  }, [showFilters])


  // this effect allow us to add filter by clicking top right buttons
  useEffect(() => {
    let defaultFilter = []
    if (defaultFiltersOptions) {

      Object.keys(defaultFiltersOptions).forEach(filter => {
        if (!defaultFiltersOptions[filter].shouldDisplay) {
          defaultFilter.push(setDefaultFilter(defaultFiltersOptions[filter].filter))
        }
        setFilters(name, [...filters, ...defaultFilter])
      })

    }
    // unmount component
    return () => {
      setFilters(name, [])
    }
  }, [name, filters, defaultFiltersOptions, setFilters])

  const setDefaultFilter = (filter) => {
    return {
      id: filter[0],
      value: {
        value: filter[2],
        operator: filter[1]
      }
    }
  }

  return (
    <div className="md:w-4/5 w-full">
      <div {...getTableProps()} className="w-full px-4 mb-12">
        <div className="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white rounded shadow-lg">
          <div className="px-4 py-3 mb-0 border-0 rounded-t">
            <div className="flex flex-wrap items-center justify-between">
              <div className="relative flex-1 flex-grow w-full max-w-full px-4">
                <h3 className="text-lg font-semibold text-gray-800">{name}</h3>
              </div>
              {rest && rest.sendFirstConnectionMail && (
                <div
                  title="Envoyer mail de première inscription"
                  className="text-gray-800 cursor-pointer mr-4 fill-current"
                  onClick={rest.sendFirstConnectionMail}
                >
                  <MailIcon />
                </div>
              )}

              {rest && rest.generateRegistrationBill && (
                <div
                  title="Générer facture frais de scolarité"
                  className="text-gray-800 cursor-pointer mr-4 fill-current"
                  onClick={rest.generateRegistrationBill}
                >
                  <BillIcon />
                </div>
              )}
              {
                rest &&
                rest.generateRegistrationFeesBill &&
                (
                  <div
                    title="Générer facture frais d'inscription"
                    className="text-gray-800 cursor-pointer mr-4 fill-current"
                    onClick={rest.generateRegistrationFeesBill}
                  >
                    <BillIcon />
                  </div>
                )}

              {rest && rest.sendMailForgotPassword && (
                <div
                  title="Envoyer un mail de mot de passe oublié"
                  className="text-gray-800 cursor-pointer mr-4 fill-current"
                  onClick={rest.sendMailForgotPassword}
                >
                  <MailIcon />
                </div>
              )}
              {rest && rest.setUserRole && (
                <div
                  title="Définir comme parent"
                  className="text-gray-800 cursor-pointer mr-4 fill-current"
                  onClick={rest.setUserRole}
                >
                  <UserIcon />
                </div>
              )}

              {rest && rest.setEmployeeRole && (
                <div
                  title="Définir comme employé"
                  className="text-gray-800 cursor-pointer mr-4 fill-current"
                  onClick={rest.setEmployeeRole}
                >
                  <TeacherIcon />
                </div>
              )}


              {rest && rest.import && (
                <div
                  title="Importer un fichier"
                  className="text-gray-800 cursor-pointer mr-4 fill-current"
                  onClick={rest.import}
                >
                  <ImportIcon />
                </div>
              )}

              {rest && rest.onDisplaysBills && (
                <div
                  title="Télécharger les factures"
                  className="text-gray-800 cursor-pointer mr-4 fill-current"
                  onClick={rest.onDisplaysBills}
                >
                  <ImportIcon />
                </div>
              )}


              {showFilters && (
                <div
                  title="Réinitialiser les filtres"
                  onClick={() => {
                    setAllFilters([])
                    resetFilters(name)
                  }}
                  className={`text-gray-800  cursor-pointer mr-4`}
                >
                  <RefreshIcon />
                </div>
              )}
              <div
                className={`${showFilters ? 'text-gray-800' : 'text-gray-500'
                  } cursor-pointer mr-4`}
                title="Filtrer les éléments"
              >
                <FilterIcon className="fill-current" onClick={toggleFilters} />
              </div>

              {addEntity && (
                <div
                  title="Ajouter un élément"
                  className={`text-gray-500 text-xl cursor-pointer mr-2`}
                >
                  <AddIcon className="fill-current" onClick={addEntity} />
                </div>
              )}

              {defaultFiltersOptions && defaultFiltersOptions.pending && (
                <div
                  title="Afficher les inscriptions en cours"
                  className={`${defaultFiltersOptions.pending.shouldDisplay ? 'text-gray-800' : 'text-gray-500'} text-xl cursor-pointer mr-2`}
                >
                  <FormWaitingIcon className="fill-current" onClick={() => defaultFiltersOptions.pending.toggleDisplay()} />
                </div>
              )}
              {defaultFiltersOptions && defaultFiltersOptions.archived && (
                <div
                  title="Afficher les inscriptions archivées"
                  className={`${defaultFiltersOptions.archived.shouldDisplay ? 'text-gray-800' : 'text-gray-500'}  text-xl cursor-pointer mr-2`}
                >
                  <ArchivedIcon className="fill-current" onClick={() => defaultFiltersOptions.archived.toggleDisplay()} />
                </div>
              )}
            </div>
          </div>

          <div className="block w-full overflow-x-auto felist">
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={JSON.stringify(
                      headerGroup.headers.map(({ id }) => id)
                    )}
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        key={column.id}
                        className="px-6 py-3 text-xs font-semibold text-left text-gray-600 uppercase whitespace-no-wrap align-baseline bg-gray-100 border border-l-0 border-r-0 border-gray-200 border-solid"
                      >
                        <div
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          title="Trier par"
                          className="flex items-center cursor-pointer"
                        >
                          <span className="w-full">
                            {column.render('Header')}
                          </span>
                          <span className="inline-block text-xl">
                            <SortIndicator column={column} />
                          </span>
                        </div>
                        {column.canFilter && showFilters && (
                          <div className="mt-2">{column.render('Filter')}</div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length < 1 && !error && pageCount === undefined && (
                  <tr className="flex justify-center p-2">
                    <td>
                      <Spinner />
                    </td>
                  </tr>
                )}
                {page.length < 1 && !error && pageCount === 0 ? (
                  <tr className="bg-orange-100 text-orange-700 px-8 font-medium">
                    <td className="p-2" colSpan={columns.length}>
                      Il n'y a aucune donnée
                    </td>
                  </tr>
                ) :
                  error ? (
                    <tr className='bg-orange-100 text-orange-700 px-8 font-medium'>
                      <td className="p-2" colSpan={columns.length}>
                        {error} {columns.length}
                      </td>
                    </tr>
                  ) : (
                    <>
                      {page.map((row) => {
                        prepareRow(row)
                        return (
                          <tr
                            onClick={() => onLineClick(row.original.id, row.original)}
                            {...row.getRowProps()}
                            className={`hover:bg-gray-100 ${
                              // TODO: put that in a function
                              row.original.status === REGISTRATION_STATUS.accept ||
                                row.original.status === BILL_STATUS.paid
                                ? 'text-green-600'
                                : row.original.status === REGISTRATION_STATUS.reject
                                  ? 'text-red-600'
                                  : row.original.status ===
                                    REGISTRATION_STATUS.pending ||
                                    row.original.status === BILL_STATUS.pending
                                    ? 'text-orange-600'
                                    : 'text-black'
                              }

                              ${row.original.role === USER_ROLE.ADMIN ? "text-red-500"
                                : row.original.role === USER_ROLE.EMPLOYEE ? "text-blue-500" :
                                  "text-black"
                              }
                              
                              `}
                          >
                            {row.cells.map((cell, i) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className="p-4 px-6 text-xs align-middle border-t-0 border-l-0 border-r-0"
                                >
                                  <span className={`${cell.column.className}`}>
                                    {cell.render('Cell')}
                                  </span>
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </>
                  )}
              </tbody>
            </table>
          </div>
          <div className="mx-4 mt-4 mb-2 text-right">
            <select
              value={pageSize}
              className="text-sm text-gray-600"
              onChange={(e) => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Afficher {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        setPageSize={setPageSize}
      />
    </div>
  )
}

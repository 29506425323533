import React, { useState, useEffect } from 'react'
import RecommandedActivities from 'components/RecommandedActivities'
import { useLocation, useParams } from 'react-router-dom'

import LayoutAdmin from 'components/LayoutAdmin'
import useActivity from 'hooks/useActivity'

export default function RecommandedActivitiesPage(props) {

  const { schoolingYear, schoolClass } = useParams()
  const location = useLocation()

    const {
        getAllRecommandedActivities,
    } = useActivity()

  const [isLoading, setIsLoading] = useState(true)
  const [children, setChildren] = useState([])
  

    useEffect(() => {
        async function loadActivities(){
            let children = await getAllRecommandedActivities({ schoolingYear, schoolClass})
            setChildren(children.results)
            setIsLoading(false)
        }
        loadActivities()
        //eslint-disable-next-line
    }, [schoolingYear, schoolClass])

  return (
    <LayoutAdmin location={location} title="PDF" hasBack>
      <RecommandedActivities
        isLoading={isLoading}
        children={children}
        schoolClass={schoolClass}
      />
    </LayoutAdmin>
  )
}

import React, { useState, useEffect } from 'react'
import SetPriceList from 'components/SetPrice'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import usePricing from '../hooks/usePricing'
import { useNotify } from '../stores/notify'
import LayoutAdmin from 'components/LayoutAdmin'
import { isFormChanged } from 'lib/utils'

export default function SetPricePage() {
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()
  const { getOnePrice, updatePrice, createPrice } = usePricing()
  const [price, setPrice] = useState()
  const [loading, setLoading] = useState({ prevState: null, current: false })
  const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
      setWarningMessage,
    })
  )

  useEffect(() => {
    async function loadDatas(newElt = null) {
      try {
        if (!newElt) {
          const currentPrice = await getOnePrice(id)
          if (currentPrice.error) {
            setErrorMessage(currentPrice.error.message)
            return
          }
          setPrice(currentPrice.results)
        }
      } catch (err) {
        setErrorMessage('Service indisponible')
      }
    }
    if (!isNaN(Number(id))) {
      loadDatas()
    } else if (id === 'new') {
      loadDatas('new')
    } else {
      setErrorMessage('Impossible de trouver cette tarification')
    }
    // eslint-disable-next-line
  }, [])

  const goBack = () => {
    history.goBack()
  }
  const onSubmit = async (datas) => {
    setLoading({ prevState: loading.current, current: true })

    if (!isNaN(Number(id))) { // is updated ?
      if (isFormChanged({
        familyIndex: Number(datas.familyIndex),
        name: datas.name,
        oneChild: Number(datas.oneChild),
        registrationFees: Number(datas.registrationFees),
        threeChild: Number(datas.threeChild),
        twoChild: Number(datas.twoChild),
      }, {
        familyIndex: price.familyIndex,
        name: price.name,
        oneChild: price.oneChild,
        registrationFees: price.registrationFees,
        threeChild: price.threeChild,
        twoChild: price.twoChild,
      })) {
        let newPrice = await updatePrice(id, datas)
        if (newPrice.error) {
          setErrorMessage(newPrice.error.message)
          setLoading({ prevState: loading.current, current: false })
          return
        }
        setPrice(newPrice.results)
        setSuccessMessage('Tarification mis à jour')
      } else {
        setWarningMessage("Rien n'à changé")
      }
    }
    else {
      let newPrice = await createPrice(datas)
      if (newPrice.error) {
        setErrorMessage(newPrice.error.message)
        setLoading({ prevState: loading.current, current: false })
        return
      }
      setSuccessMessage('Tarification créée')
    }
    setLoading({ prevState: loading.current, current: false })
  }


  return (
    <LayoutAdmin location={location} title="Tarification" hasBack>
      <SetPriceList onSubmit={onSubmit} goBack={goBack} price={price} id={id} loading={loading} />
    </LayoutAdmin>
  )
}

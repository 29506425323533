import React, { useEffect } from 'react'

import { Controller } from 'react-hook-form'

export default function Radio({
  input,
  name,
  // yes,
  // no,
  form,
  error,
  whenYes,
  whenNo,
  register,
  // disabled,
  value,
  setValue,
  defaultValue,
  inputs,
  label,
  // up, // callback function use to up state
  noLabel,
  customClass = false,
  customLabel = false,
}) {
  useEffect(() => {
    if (input.whenYes && `${value}` === 'false') {
      input.whenYes.map((row) =>
        row.map((input) => input.name && setValue(input.name, ''))
      )
    }
    if (input.whenNo && `${value}` === 'true') {
      input.whenNo.map((row) =>
        row.map((input) => input.name && setValue(input.name, ''))
      )
    }
  }, [setValue, value, input])

  const realDefaultValue = value === undefined ? defaultValue : undefined

  return (
    <div className="flex flex-row md:flex-col my-0 w-full">
      <div className="flex flex-row md:items-center item-start justify-between w-full gap-2">
        {!input.labelJsx && !noLabel && (
          <span
            dangerouslySetInnerHTML={{ __html: label }}
            style={{ maxWidth: '80%' }}
            className="md:pl-4 pl-0 lg:whitespace-no-wrap flex items-center text-center"
          />
        )}

        {input.labelJsx}

        <div className={customClass ? customClass : " pr-0 w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2"}>
          <Controller
            control={form.control}
            name={name}
            defaultValue={realDefaultValue}
            rules={register}
            render={({ onChange, value }) => {
              return (
                <>
                  {inputs.map((input, index) => (
                    <div key={index}
                    >
                      {input && input.label && input.value ? (
                        <label
                          htmlFor={input.label}
                          onClick={() => onChange(input.value)}
                          className={customLabel ? customLabel : "flex justify-center items-center w-full"}
                        >
                          <input
                            type="radio"
                            key="index"
                            className="mr-1"
                            name={name}
                            value={value}
                            checked={value === input.value ? true : false}
                            onChange={() => {
                              onChange(input.value)
                            }}
                          />
                          <span>{input.label}</span>
                        </label>
                      ) : (
                        <div className="hidden"></div>
                      )}
                    </div>
                  ))}
                </>
              )
            }}
          />
        </div>
      </div>
      {error ? (
        <span className="text-red-500 text-sm">{error}</span>
      ) : (
        <span className="help-text">{input.helpText}</span>
      )}
      {`${value}` === 'true' && (
        <div className="complement-input">{whenYes}</div>
      )}
      {`${value}` === 'false' && (
        <div className="complement-input">{whenNo}</div>
      )}
    </div>
  )
}

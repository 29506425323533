import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import BillDetails from '../components/BillDetails'
import Layout from 'components/Layout'
import { timeToNumber } from '../lib/utils'
import { useNotify } from '../stores/notify'
import useChildcare from 'hooks/useChildcare'
import { userApi } from 'stores/user'
import { useTranslation } from 'react-i18next'

export default function SummaryNurseryPage(props) {
  const history = useHistory()
  const { getNurseryPrice, getChildsCaresSummary } = useChildcare()
  const [price, setPrice] = useState()
  const [nurseryList, setNurseryList] = useState()
  const [date, setDate] = useState()
  const [totalDuration, setTotalDuration] = useState()
  const [cares, setCares] = useState()
  const user = userApi()

  const { t } = useTranslation()

  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))
  useEffect(() => {
    if (props?.location?.state) {
      const { year, month } = props.location.state
      if (props.location.state?.cares) {
        //    setNurseryList(props.location.state.cares)
        setCares(props.location.state.cares)
        delete props.location.state.cares
      }
      setDate(new Date(`${year}/${month}/01`))
    } else {
      setErrorMessage(t('Impossible de charger les informations de la garderie'))
      history.replace('/dashboard')
      return
    }
  }, [props, setErrorMessage, t, history])

  useEffect(() => {
    async function getPrice() {
      const price = await getNurseryPrice(date)
      if (price.error) {
        setErrorMessage(t(price.error.message))
        return
      }
      if (price.results.price) {
        setPrice(price.results.price)
      } else {
        setPrice(true)
      }
    }
    if (date) {
      getPrice()
    }
  }, [date, getNurseryPrice, setErrorMessage, t])

  useEffect(() => {
    async function getNurseryList() {
      const nurseryList =
        cares ||
        (await getChildsCaresSummary(date.getFullYear(), date.getMonth()))
      if (nurseryList.error) {
        setErrorMessage(t(nurseryList.error.message))
        return
      }
      if (nurseryList?.results?.length > 0 || nurseryList) {
        let n = nurseryList.results || nurseryList
        setNurseryList(n)
        let totalDuration = n
          .map((n) => {
            let children = n.children
              .map((child) => {
                return child.childcare
                  .map((care) => {
                    return timeToNumber(care.duration)
                  })
                  .reduce((acc, curr) => acc + curr)
              })
              .reduce((acc, curr) => acc + curr)
            n.subTotalDuration = children
            return children
          })
          .reduce((acc, curr) => acc + curr)

        setTotalDuration(totalDuration)
      } else {
        console.log('Pas encore de date ... ')
      }
    }
    if (date) {
      getNurseryList()
    }
  }, [cares, date, getChildsCaresSummary, setErrorMessage, t])

  return (
    <Layout
      buttonGoHome={{
        to: user.role === 'admin' ? 'invoice' : 'dashboard',
        history,
      }}
      title={t("Garderie")}
    >
      <BillDetails
        childcares={nurseryList}
        price={price}
        totalPrice={totalDuration * price}
        totalDuration={totalDuration}
        viewMode={true}
      />
    </Layout>
  )
}

import React from 'react'
import { REGISTRATION_STATUS } from 'lib/constants'
import Spinner from 'components/common/Spinner'

import DashboardCard from 'components/dashboard/DashboardCard'
import Button from 'components/form/Button'
// import DashboardLink from 'components/dashboard/DashboardLink'
import { useTranslation } from 'react-i18next'
export default function ChooseRegistration({
  registrations,
  onSelectRegistration,
  onReregister,
}) {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-row flex-wrap flex-grow mt-20 justify-center">
        {registrations &&
          registrations.map((registration, index) => (
            <DashboardCard
              key={`registrationCard-${index}`}
              title={t('Inscription de {{firstname}}', {
                firstname: registration.Child.firstname,
              })}
            >
              <div
                className={`${registration.status === REGISTRATION_STATUS.reject
                  ? 'text-red-600'
                  : registration.status === REGISTRATION_STATUS.close
                    ? 'text-red-600'
                    : registration.status === REGISTRATION_STATUS.accept
                      ? 'text-green-600'
                      : registration.status === REGISTRATION_STATUS.pending
                        ? 'text-yellow-600'
                        : ''
                  } my-2`
                }
              >
                {t('Statut')} &nbsp;: {t(registration.status)}
              </div>
              <div
                className={`flex w-full mt-4  ${registration.status === REGISTRATION_STATUS.accept &&
                  registration.reregister !== true
                  ? 'justify-around flex-col gap-2 lg:flex-row '
                  : 'justify-end'
                  }`}
              >
                {registration.status === REGISTRATION_STATUS.accept &&
                  registration.reregister !== true && (
                    <Button
                      variant="primary"
                      onClick={() => onReregister(registration.id)}
                    >
                      {t('Réinscrire')}
                    </Button>
                  )}
                <Button
                  variant="secondary"
                  onClick={() =>
                    onSelectRegistration({
                      id: registration.id,
                      status: registration.status,
                    })
                  }
                >
                  {registration.status === REGISTRATION_STATUS.open
                    ? t('Modifier')
                    : t('Voir')}{' '}
                  {t("l'inscription")}
                </Button>
              </div>
            </DashboardCard>
          ))}

        {!registrations && <Spinner></Spinner>}
      </div>
    </>
  )
}

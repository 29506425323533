import React, { useState, useEffect } from 'react'
import SetSubDomain from 'components/SetSubDomain'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import LayoutAdmin from 'components/LayoutAdmin'
import useActivity from 'hooks/useActivity'
import { isFormChanged } from 'lib/utils'

export default function SetSubDomainPage() {
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()

  const {
    getSubDomain,
    updateSubDomain,
    createSubDomain,
    getDomains,
  } = useActivity()

  const [subDomain, setSubDomain] = useState()
  const [domains, setDomains] = useState([])
  const [loading, setLoading] = useState({ prevState: null, current: false })

  const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
      setWarningMessage,
    })
  )

  useEffect(() => {
    async function loadDatas(newElt = null) {
      try {
        if (!newElt) {
          const subDomain = await getSubDomain(id, true)
          if (subDomain.error) {
            setErrorMessage(subDomain.error.message)
            return
          }
          setSubDomain(subDomain.results)
        }
      } catch (err) {
        setErrorMessage('Service indisponible')
      }
    }
    if (!isNaN(Number(id))) {
      loadDatas()
    } else if (id === 'new') {
      loadDatas('new')
    } else {
      setErrorMessage('Impossible de trouver ce sous-domaine')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function loadDomains() {
      try {
        const domains = await getDomains()
        let tmp = domains.results.map((domain) => {
          return {
            label: domain.name,
            value: domain.id,
          }
        })
        setDomains(tmp)
      } catch (err) {
        setWarningMessage('Impossible de récupérer les domaines')
      }
    }
    loadDomains()
    //eslint-disable-next-line
  }, [])

  const goBack = () => {
    history.goBack()
  }
  const onSubmit = async (datas) => {
    setLoading({ prevState: loading.current, current: true })
    // creation
    if (isNaN(Number(id))) {
      let subDomain = await createSubDomain(datas)
      if (subDomain.error) {
        setErrorMessage(subDomain.error.message)
        setLoading({ prevState: loading.current, current: false })
        return
      }
      setSuccessMessage('Sous-domaine crée')
      setLoading({ prevState: loading.current, current: false })
      return
    }

    if (
      isFormChanged(
        {
          name: datas.name,
          domainId: datas.domain,
        },
        {
          name: subDomain.name,
          domainId: subDomain?.Domain?.id,
        }
      )
    ) {
      let newSubDomain = await updateSubDomain(subDomain.id, datas)
      if (newSubDomain.error) {
        setErrorMessage(newSubDomain.error.message)
        setLoading({ prevState: loading.current, current: false })
        return
      }
      setSubDomain(newSubDomain.results)
      setSuccessMessage('Sous-domaine mis à jour')
      setLoading({ prevState: true, current: false })
      return
    } else {
      setWarningMessage("Rien n'a été modifié")
      setLoading({ prevState: true, current: false })
      return
    }
  }

  return (
    <LayoutAdmin location={location} title="Sous-domaine" hasBack>
      <SetSubDomain
        onSubmit={onSubmit}
        goBack={goBack}
        subDomain={subDomain}
        id={id}
        loading={loading}
        domains={domains}
      />
    </LayoutAdmin>
  )
}

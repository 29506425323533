import React, { useCallback } from 'react'
import Button from '../form/Button'
import { useTranslation } from 'react-i18next'

export default function StepButtons({
  loading,
  disabled,
  hideNext,
  goBack,
  nextLabel,
  onNext,
  variant = "primary",
}) {
  const { t } = useTranslation()
  nextLabel = nextLabel ? nextLabel : t('Suivant')

  const handleClick = useCallback(() => {
    if (!disabled && !hideNext && onNext) onNext()
  }, [disabled, hideNext, onNext])

  return (
    <div className="flex w-full mt-8 justify-between">
      <Button textOnly variant="secondary" onClick={() => goBack()}>
        { t('Précédent')}
      </Button>
      {!hideNext && (
        <Button
          variant={variant}
          submits={onNext === null}
          disabled={disabled}
          loading={loading}
          onClick={handleClick}
        >
          {t(nextLabel)}
        </Button>
      )}
    </div>
  )
}

StepButtons.defaultProps = {
  loading: false,
  disabled: false,
  hideNext: false,
  goBack: () => { },
  // nextLabel: 'Suivant',
  onNext: null,
}

import { useMemo } from 'react'
import { useUser } from 'stores/user'
import { useRegistrations } from 'stores/registrations'
import { useNotify } from 'stores/notify'
import Api from 'lib/api'
import { SCHOOLINGYEAR_STATUS, REGISTRATION_STATUS } from 'lib/constants'

export default () => {
  const { token, registrationId } = useUser(
    ({ token, registrationId, id, email }) => ({
      token,
      registrationId,
      id,
      email,
    })
  )
  const { setSuccessMessage } = useNotify(
    ({ setSuccessMessage }) => ({
      setSuccessMessage,
    })
  )
  const {
    registrations,
    addRegistration,
    setAllRegistrations,
    changeRegistration,
    removeRegistration,
  } = useRegistrations(
    ({
      registrations,
      addRegistration,
      setAllRegistrations,
      changeRegistration,
      removeRegistration,
    }) => ({
      registrations,
      addRegistration,
      setAllRegistrations,
      changeRegistration,
      removeRegistration,
    })
  )

  return useMemo(
    () => ({
      getMyModels(offset = 0, limit = 100, filters, order, populate = true) {
        return Api.getMyModels(token, {
          query: { offset, limit, filters, order, populate },
        })
      },
      getMyModel(id, populate = false) {
        return Api.getMyModel(token, id, { query: { populate } })
      },
      deleteMyModel(id) {
        return Api.deleteMyModel(token, id)
      },
      createMyModel(body) {
        return Api.createMyModel(token, { body })
      },
      updateMyModel(body) {
        return Api.updateMyModel(token, body.id, { body })
      },
      hasValidRegistration() {
        return registrations.some(
          (r) => r.status === REGISTRATION_STATUS.accept
        )
      },
      async createRegistration(classSelect) {
        try {
          const registration = await Api.createRegistration({ token, classSelect })
          addRegistration(registration)
          return registration
        } catch (err) {
          return { error: err }
        }
      },
      async getParentDetails() {
        try {
          const parent = await Api.getParentDetails({
            token,
            id: registrationId,
            query: { populate: true },
          })
          return parent
        } catch (err) {
          return { error: err }
        }
      },
      async setParentDetails(params) {
        try {
          const parent = await Api.setParentDetails({
            token,
            params,
            id: registrationId,
          })
          return parent
        } catch (err) {
          return { error: err }
        }
      },
      async getAllRegistrations() {
        try {
          const registration = await Api.getAllRegistrations({ token })
          setAllRegistrations(registration.results.registration)

          return registration
        } catch (err) {
          return { error: err }
        }
      },
      async setCompany(company, registrationId) {
        try {
          const savedCompany = await Api.setCompany({
            token,
            company,
            id: registrationId,
          })
          return savedCompany
        } catch (err) {
          return { error: err }
        }
      },
      async getRegistration(id = null, query = {}) {
        try {
          return Api.getRegistration({
            token,
            id: id ? id : registrationId,
            query,
          })
        } catch (err) {
          return { error: err }
        }
      },
      async getSummary(regId) {
        try {
          const summary = await Api.getSummary({
            token,
            id: regId || registrationId,
          })
          return summary
        } catch (err) {
          return { error: err }
        }
      },
      async updateRegistration(params) {
        let id = params.id ? params.id : registrationId
        try {
          const reason = await Api.updateRegistration({ token, id, params })
          changeRegistration({ id, ...params })
          return reason
        } catch (err) {
          return { error: err }
        }
      },
      async getRegistrations(offset = 0, limit = 100, filters, order) {
        try {
          const registrations = await Api.getRegistrations(token, {
            query: { offset, limit, filters, order, populate: true },
          })
          return registrations
        } catch (err) {
          return { error: err }
        }
      },
      async deleteRegistration(id) {
        try {
          const registration = await Api.deleteRegistration({ token, id })
          removeRegistration({ id })
          return registration
        } catch (err) {
          return { error: err }
        }
      },
      async validRegistration(id) {
        try {
          const registration = await Api.validRegistration({ token, id })
          changeRegistration(registration)
          return registration
        } catch (err) {
          return { error: err }
        }
      },
      async reregister(id) {
        try {
          const registration = await Api.reregister({ token, id })
          changeRegistration(registration)
          return registration
        } catch (err) {
          return { error: err }
        }
      },
      async publishSchoolingDate(id) {
        try {
          const date = await Api.updateSchoolingDate(token, id, {
            status: SCHOOLINGYEAR_STATUS.publish,
          })
          setSuccessMessage('La date est maintenant publié')
          return date
        } catch (err) {
          return { error: err }
        }
      },
      async restoreRegistration(id) {
        try {
          const registration = await Api.restoreRegistration(token, id)
          changeRegistration(registration)
          return registration
        } catch (err) {
          return { error: err }
        }
      },
      async forceDeleteRegistration(id) {
        try {
          const reg = await Api.forceDeleteRegistration({ token, id })
          return reg
        } catch (err) {
          return { error: err }
        }
      },
      async reSendEmail(email, token) {
        try {
          const mail = await Api.reSendEmail(email, token)
          return mail
        } catch (err) {
          return { error: err }
        }
      },

      async getCompanies(offset = 0, limit = 100, filters, order) {
        try {
          const companies = await Api.getCompanies({
            token,
            query: { offset, limit, filters, order },
          })
          return companies
        } catch (err) {
          return { error: err }
        }
      },
      async getCompany(id) {
        try {
          const company = await Api.getCompany({ id, token })
          return company
        } catch (err) {
          return { error: err }
        }
      },
      async updateCompany(id, params) {
        try {
          const company = await Api.updateCompany({ id, token, params })
          return company
        } catch (err) {
          return { error: err }
        }
      },
      async createCompany(params) {
        try {
          const company = await Api.createCompany(token, params)
          return company
        } catch (err) {
          return { error: err }
        }
      },
      async deleteCompany(id) {
        try {
          const company = await Api.deleteCompany({ token, id })
          return company
        } catch (err) {
          return { error: err }
        }
      },
      async registrationExport(id) {
        try {
          const registrations = await Api.registrationExport({ token, id })
          return registrations
        } catch (err) {
          return { error: err }
        }
      },
      async parentExport(id) {
        try {
          const parents = await Api.parentExport({ token, id })
          return parents
        } catch (err) {
          return { error: err }
        }
      },
      async childrenExport({ id, order, fields }) {
        try {
          const children = await Api.childrenExport({ token, id, query: { ...fields, ...order } })
          return children
        } catch (err) {
          return { error: err }
        }
      },
      async headcountExport({ id, filter }) {
        try {
          const headcount = await Api.headcountExport({ token, id, query: { ...filter } })
          return headcount
        } catch (err) {
          return { error: err }
        }
      },
      async updateClass(classSelect, id = null) {
        try {
          const registration = await Api.updateClass({ token, classSelect, id: id || registrationId })
          return registration
        } catch (err) {
          return { error: err }
        }
      },
      async sendFirstConnectionMail(ids) {
        try {
          const mail = await Api.sendFirstConnectionMail({ token, ids })
          return mail
        } catch (err) {
          return { error: err }
        }
      },
      async getSchoolClasses() {
        try {
          const schoolClasses = await Api.getSchoolClasses({ token, query: { limit: 100 } })
          return schoolClasses
        } catch (err) {
          return { error: err }
        }
      },
      async getChildrenbySchoolingyear(id, abortController) {
        try {
          const children = await Api.getChildrenbySchoolingyear({ token, id, abortController })
          return children
        } catch (err) {
          return { error: err }
        }
      },
      async closeRegistration(id) {
        try {
          const registration = await Api.closeRegistration({ id, token })
          return registration
        } catch (err) {
          return { error: err }
        }
      },
      async getRegistrationsForCertificate() {
        try {
          const registrations = await Api.getRegistrationsForCertificate({ token })
          return registrations
        } catch (err) {
          return { error: err }
        }
      }
    }),
    [
      addRegistration,
      changeRegistration,
      registrationId,
      registrations,
      removeRegistration,
      setAllRegistrations,
      setSuccessMessage,
      token,
    ]
  )
}

import React from 'react'
import { useLocation } from 'react-router-dom'
import PricingsGrids from 'components/registrations/PricingsGrids'
import LayoutAdmin from '../components/LayoutAdmin'

export default function PriceListPage() {
  const location = useLocation()

  return (
    <div>
      <LayoutAdmin location={location} title="Grilles tarifaires" hasBack={false}>
        <PricingsGrids />
      </LayoutAdmin>
    </div>
  )
}

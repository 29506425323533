import React, { useEffect, useState } from 'react'
import { useNotify } from 'stores/notify'
import { useForm } from 'react-hook-form'
import { useUser } from 'stores/user'
import useUsers from 'hooks/useUsers'
import passwords from 'lib/passwords'
import Input from 'components/form/Input'
import LoggedOutHeader from 'components/common/LoggedOutHeader'
import Button from 'components/form/Button'
import { useTranslation } from 'react-i18next'

const PasswordHint = () => {
  const { t } = useTranslation()
  return (
    <ul>
      <li>
        •{' '}
        {t('le mot de passe doit avoir une longueur minimale de 8 caractères')}
      </li>
      <li>
        •{' '}
        {t(
          'le mot de passe doit être composé avec une combinaison d’au moins 3 types de caractères parmi les 4 suivants : majuscules (A-Z), minuscules (a-z), chiffres (0-9) et caractères spéciaux;'
        )}
      </li>
      <li>
        •{' '}
        {t(
          'le mot de passe ne doit pas être constitué d’une combinaison d’un ou plusieurs mots communs et de chiffres.'
        )}
      </li>
    </ul>
  )
}

const validatePassword = (value) => {
  const validation1 =
    [
      '^(?=.*[a-z]).+$',
      '^(?=.*[A-Z]).+$',
      '^(?=.*\\d).+$',
      '^(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\|,.<>\\/?]).+$',
    ]
      .map((re) => !!value.match(new RegExp(re)))
      .filter((v) => v).length >= 3

  const validation2 = value.length >= 8

  const validation3 = !passwords.find((p) =>
    value.toLowerCase().includes(p.toLowerCase())
  )
  return (validation1 && validation2 && validation3) || <PasswordHint />
}

export default function SetPasswordPage({ history }) {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const { checkLoginToken, setPassword } = useUsers()
  const { setUser } = useUser((o) => o)
  // const [userAccreditate, setUserAccreditate] = useState(null)
  // const [finishMessage, setFinishMessage] = useState(null)
  // const [email, setEmail] = useState(null)

  const params = new URL(document.location).searchParams
  const token = params.get('token')

  const { errors, handleSubmit, register, watch, clearErrors } = useForm()

  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const password = watch('password')
  const passwordComfirmation = watch('passwordComfirmation')

  useEffect(() => {
    if (errors) {
      clearErrors()
    }
    /* eslint-disable-next-line */
  }, [password, passwordComfirmation])

  const onSubmit = async (params) => {
    setLoading(true)
    try {
      const res = await setPassword({ ...params, token })
      if (res && res.results && res.results.tokens) {
        setUser(res.results)
      }
      setSuccessMessage(
        t('Votre mot de passe a été enregistré. Bienvenue dans MONTESSORI.')
      )
      setLoading(false)
      if (res.results.role === 'Admin') {
        history.replace('/admin')
      } else {
        history.replace('/')
      }
    } catch (err) {
      setErrorMessage(t(err.message))
      setLoading(false)
    }
  }

  useEffect(() => {
    const checkUserToken = async () => {
      try {
        await checkLoginToken(token)
      } catch (err) {
        setErrorMessage(t('Le token a expiré.'))
        history.replace('/')
        // if no token, route throw error
      }
    }
    checkUserToken()
    // eslint-disable-next-line
  }, [token, setErrorMessage])

  function validatePasswordComfirmation(value) {
    return watch('password') === value || ' '
  }

  return (
    <div>
      <LoggedOutHeader />
      <div className="flex items-center justify-center align-middle">
        <div className="flex flex-col w-full md:w-3/4 lg:w-1/2 px-8 pt-6 pb-8 mb-4 ">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full form-container"
          >
            <p>
              {t("Veuillez définir votre mot de passe d'accès à l'application")}
            </p>
            <Input
              key={`section-password`}
              ref={register({
                required: t('Champ obligatoire'),
                validate: validatePassword,
              })}
              type="password"
              name="password"
              label={t('Mot de passe')}
              value={watch('password')}
              error={errors.password && errors.password.message}
              required={t('Champ obligatoire')}
              autofocus={true}
            />
            <Input
              key={`section-confirmation-password`}
              ref={register({
                required: t('Champ obligatoire'),
                validate: validatePasswordComfirmation,
              })}
              type="password"
              name="passwordComfirmation"
              label={t('Confirmation du mot de passe')}
              value={watch('passwordComfirmation')}
              error={
                errors.passwordComfirmation &&
                errors.passwordComfirmation.message
              }
              required={t('Champ obligatoire')}
            />
            {errors.passwordComfirmation &&
              errors.passwordComfirmation.message && (
                <span className="text-sm text-red-500">
                  {t('Les mots de passes ne correspondent pas.')}
                </span>
              )}

            <div className="flex items-center justify-center w-full">
              <Button variant="black" submits loading={loading}>
                {t('Enregistrer')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
// import logo from "./logo.svg"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import { useUser } from './stores/user'
import LoginPage from './pages/LoginPage'
import SignupPage from './pages/SignupPage'
import DashboardPage from './pages/DashboardPage'

import ForgotPasswordPage from './pages/ForgotPasswordPage'
import SetPasswordPage from './pages/SetPasswordPage'
import SchoolingYearPage from './pages/SchoolingYearPage'
import ChildPage from './pages/ChildPage'
import ParentPage from './pages/ParentPage'
import IncomeStatementPage from './pages/IncomeStatementPage'
// import SendFilesPage from './pages/SendFilesPage'
import SummaryPages from './pages/SummaryPage'
import ChooseRegistrationPage from './pages/ChooseRegistrationPage'
import SchoolingYearListPage from './pages/SchoolingYearListPage'
import AdminPage from './pages/AdminPage'
import NurseryViewerPage from './pages/NurseryViewerPage'
import PricingsGridsListPage from './pages/PricingsGridsListPage'
import SetPricingsGridsPage from './pages/SetPricingsGridsPage'

import Notify from 'components/Notify'
import { USER_ROLE } from 'lib/constants'
import SetSchoolingDatePage from 'pages/SetSchoolingDatePage'
import PriceListPage from 'pages/PriceListPage'
import SetPricePage from 'pages/SetPricePage'
import NurseryPage from 'pages/NurseryPage'
import { REGISTRATION_STATUS } from './lib/constants'
import LoginTokenPage from 'pages/LoginTokenPage'
import ValidNurseryPage from 'pages/ValidNurseryPage'
import SummaryNurseryPage from 'pages/SummaryNurseryPage'
// import InvoicePage from 'pages/InvoicePage'
import BillPage from 'pages/BillPage'
import BillCreationPage from 'pages/BillCreationPage'
import AdminBillPage from 'pages/AdminBillPage'
import BillsViewPage from 'pages/BillsViewPage'

import './lib/i18n'

import CompanyListPage from 'pages/CompanyListPage'
import SetCompanyPage from 'pages/setCompanyPage'
import ExportPage from 'pages/ExportPage'
import UserListPage from 'pages/UserListPage'
import SetUserPage from 'pages/SetUserPage'
import ClassPresencePage from './pages/ClassPresencePage'

import CategoryListPage from './pages/CategoryListPage'
import SetCategoryPage from 'pages/SetCategoryPage'

import ActivityListPage from './pages/ActivityListPage'
import SetActivityPage from './pages/SetActivityPage'
import ProgressionListPage from './pages/ProgressionListPage'
import SetProgressionPage from 'pages/SetProgressionPage'

import AcquiredSkillsPage from 'pages/AcquiredSkillsPage'
import ChildValidationPage from 'pages/ChildValidationPage'

import RecommandedActivitiesPage from 'pages/RecommandedActivitiesPage'
import SubDomainListPage from 'pages/SubDomainListPage'
import SetSubDomainPage from 'pages/SetSubDomainPage'

import DomainListPage from 'pages/DomainListPage'
import SetDomainPage from 'pages/SetDomainPage'

import LogBookPage from 'pages/LogBookPage'
import SchoolCertificatePage from 'pages/SchoolCertificatePage'

import ViewCertificatePage from 'pages/ViewCertificatePage'
import BillsToPdf from 'pages/BillsToPdf'
import SortingRankPage from 'pages/SortingRankPage'

function App() {
  // Load persisted data from local/async storage

  const NotFound = () => {
    console.log('not found ...')
    return <>Not found</>
  }

  return (
    <div className="app-container ">
      <Router>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route path="/signup" component={SignupPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/set-password" component={SetPasswordPage} />
          <Route
            path="/validate-email/:login_token"
            component={LoginTokenPage}
          />

          <ProtectedRoute path="/dashboard" component={DashboardPage} />
          <ProtectedRoute
            path="/billsview/:id?/:status?"
            component={BillsViewPage}
          />
          <ClosedRegistration
            path="/schoolingyear/:registration?"
            component={SchoolingYearPage}
          />
          <ClosedRegistration path="/child" component={ChildPage} />
          <ClosedRegistration path="/parent" component={ParentPage} />
          <ClosedRegistration
            path="/incomestatement"
            component={IncomeStatementPage}
          />
          <ProtectedRoute
            path="/summary/:registration?"
            component={SummaryPages}
          />
          <ProtectedRoute
            path="/chooseregistration"
            component={ChooseRegistrationPage}
          />
          <ProtectedRoute path="/nursery" component={NurseryPage} />
          <ProtectedRoute path="/validnursery" component={ValidNurseryPage} />
          <ProtectedRoute
            path="/summarynursery"
            component={SummaryNurseryPage}
          />
          <ProtectedRoute path="/bill" component={BillPage} />
          <ProtectedRoute path="/billsWithForm" component={BillCreationPage} /> 
         

          <ProtectedRoute
            path="/schoolcertificate"
            component={SchoolCertificatePage}
          />
          <ProtectedRoute
            path="/viewcertificate"
            component={ViewCertificatePage}
          />
          <AdminRoute path="/admin" component={AdminPage} />
          <AdminRoute path="/bills" component={AdminBillPage} />
          <AdminRoute
            path="/schoolingyearlist"
            component={SchoolingYearListPage}
          />
          <AdminRoute
            path="/setschoolingdate/:id"
            component={SetSchoolingDatePage}
          />

          <AdminRoute path="/pricelist" component={PriceListPage} />
          <AdminRoute path="/setprice/:id" component={SetPricePage} />

          <AdminRoute
            path="/pricingsgridslist"
            component={PricingsGridsListPage}
          />
          <AdminRoute
            path="/setpricingsgrids/:id"
            component={SetPricingsGridsPage}
          />
          <AdminRoute path="/companylist" component={CompanyListPage} />
          <AdminRoute path="/setcompany/:id" component={SetCompanyPage} />
          <AdminRoute path="/export" component={ExportPage} />
          <AdminRoute path="/userlist" component={UserListPage} />
          <AdminRoute path="/sorting" component={SortingRankPage} />

          <AdminRoute path="/setuser/:id" component={SetUserPage} />
          <AdminRoute path="/billstopdf" component={BillsToPdf} />

          <EmployeeRoute path="/category" component={CategoryListPage} />

          <EmployeeRoute path="/setcategory/:id" component={SetCategoryPage} />

          <EmployeeRoute path="/activity" component={ActivityListPage} />

          <EmployeeRoute path="/setActivity/:id" component={SetActivityPage} />
          <EmployeeRoute
            path="/setprogression/:id"
            component={SetProgressionPage}
          />
          <EmployeeRoute
            path="/progressionlist"
            component={ProgressionListPage}
          />

          <EmployeeRoute path="/nurseryviewer" component={NurseryViewerPage} />

          <EmployeeRoute path="/classpresence" component={ClassPresencePage} />
          <EmployeeRoute
            path="/acquiredskills/:id?"
            component={AcquiredSkillsPage}
          />
          <EmployeeRoute
            path="/childvalidation"
            component={ChildValidationPage}
          />
          <EmployeeRoute
            path="/recommandedactivities/:schoolingYear/:schoolClass"
            component={RecommandedActivitiesPage}
          />

          <EmployeeRoute path="/domains" component={DomainListPage} />
          <EmployeeRoute path="/setdomain/:id" component={SetDomainPage} />

          <EmployeeRoute path="/subdomains" component={SubDomainListPage} />
          <EmployeeRoute
            path="/setsubdomain/:id"
            component={SetSubDomainPage}
          />
          <EmployeeRoute path="/logbook" component={LogBookPage} />

          <Route component={NotFound} />
        </Switch>
      </Router>
      <Notify />
    </div>
  )
}

const ClosedRegistration = ({ component: Component, ...rest }) => {
  const { status, role, token } = useUser(({ status, role, token }) => ({
    status,
    role,
    token,
  }))
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {token ? (
            <>
              {(status && status === REGISTRATION_STATUS.open) ||
              !status ||
              role === USER_ROLE.ADMIN ? (
                <Component {...props} role={role} status={status} />
              ) : (
                <Redirect
                  to={{ pathname: '/summary', state: { from: props.location } }}
                />
              )}
            </>
          ) : (
            <Redirect
              to={{ pathname: '/', state: { from: props.location } }}
            ></Redirect>
          )}
        </>
      )}
    />
  )
}

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { token, role } = useUser(({ token, role }) => ({ token, role }))

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {token ? (
            <Component {...props} role={role} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )}
        </>
      )}
    />
  )
}

const AdminRoute = ({ component: Component, ...rest }) => {
  const { role } = useUser((u) => u)

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {role === USER_ROLE.ADMIN ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )}
        </>
      )}
    />
  )
}

const EmployeeRoute = ({ component: Component, ...rest }) => {
  const { role, SchoolClassId } = useUser((u) => u)
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {role === USER_ROLE.ADMIN || role === USER_ROLE.EMPLOYEE ? (
            <Component {...props} role={role} SchoolClassId={SchoolClassId} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )}
        </>
      )}
    />
  )
}

export default App

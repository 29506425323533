import React from 'react'

import DashboardCard from 'components/dashboard/DashboardCard'
import DashboardLink from 'components/dashboard/DashboardLink'

import { useTranslation } from 'react-i18next'

export default function DashboardEmployee({
  onClickNurseryViewer,
  onClickClassPresence,
  onClickAcquiredSkills,
  onClickCategoriesList,
  onClickActivitiesList,
  onClickProgressionList,
}) {

  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-row flex-wrap flex-grow mt-20">
        <DashboardCard title={t('Présence')}>
          <DashboardLink onClick={onClickNurseryViewer}>
            {t('Calendrier garderie')}
          </DashboardLink>
          <DashboardLink onClick={onClickClassPresence}>
            {t('Présence en classe')}
          </DashboardLink>
          <DashboardLink onClick={onClickAcquiredSkills}>
            {t('Suivi des enfants')}
          </DashboardLink>
        </DashboardCard>

        <DashboardCard title={t('Administration')}>
          <DashboardLink onClick={onClickCategoriesList}>
            {t('Liste des catégories')}
          </DashboardLink>
          <DashboardLink onClick={onClickActivitiesList}>
            {t('Liste des activités')}
          </DashboardLink>
          <DashboardLink onClick={onClickProgressionList}>
            {t('Liste des progressions')}
          </DashboardLink>
        </DashboardCard>

      </div>
    </>
  )
}
import React, { useRef } from 'react'
import StepButtons from './common/StepButtons'
import { useHistory } from 'react-router-dom'
import Spinner from './common/Spinner'
import ReactToPrint from 'react-to-print'
import ActivityPDFCard from './ActivityPDFCard'

export default function RecommandedActivities({
  isLoading,
  children,
  schoolClass,
}) {

  const componentToPrint = useRef()
  const history = useHistory()

  return (
    <>
      <div className="flex items-center justify-center flex-col w-full mt-8 ">
        <div
          className="flex flex-col w-full md:w-3/4"
        >
          <div className="px-8">
            <StepButtons
              goBack={() => history.goBack()}
              hideNext
            />
          </div>

          <>
            <ReactToPrint
              trigger={() => (
                <button className="bg-transparent text-blue-600 py-3 px-4 my-4 rounded-lg border-none">Imprimer</button>
              )}
              content={() => componentToPrint.current}
              bodyClass=''
              documentTitle={`Activités recommandé pour les ${schoolClass}`
              }
              pageStyle=" 
                @media all {
                  .page-break {
                    display: none;
                  }
                }
                
                @media print {
                  html, body {
                    height: initial !important;
                    overflow: initial !important;
                    -webkit-print-color-adjust: exact;
                  }
                }
                
                @media print {
                  .page-break {
                    margin-top: 1rem;
                    display: block;

                  }
                }
                
                @page {
                  size: auto;
                  margin: 20mm;
                }

                [data-child]{
                  page-break-after: always;
                  break-inside: auto;
                }

                .activityPDFCard{
                  page-break-before: auto;
                  break-before: auto;
                  break-inside: avoid;
                  border: 1px black solid;
                  padding: 1px;
                  margin: 10px;
                }
                  "
            />
            <div ref={componentToPrint}>

              <div
                className="text-center text-2xl font-bold"
              >
                Activités recommandées ({schoolClass})
              </div>

              {isLoading && (
                <Spinner />
              )}

              {!isLoading && children && children.length > 0 && children.map(child => (
                <div key={child.id} data-child="children">
                  <div
                    className="w-full flex flex-col gap-2 p-4 bg-blue-500 py-2 mt-4 text-white text-xl font-bold"
                  >
                    <div className="flex items-center">
                      <div>
                        <img
                          alt={`${child.firstname} ${child.lastname}`}
                          src={child?.file && child.file.length > 0 ? `data:${child?.file[0]?.fileType};base64,${child?.file[0]?.path}` : 'https://via.placeholder.com/100'}
                          className="object-contain w-full md:w1/3"
                          style={{ height: '100px' }}
                        />
                      </div>
                      <div className="px-2">
                        {child.firstname} {child.lastname}
                      </div>
                    </div>
                  </div>
                  {
                    child.categories && child.categories.map((category, index) => (
                      <div
                        key={`${category.id}-${child.id}-${index}`}
                      >
                        <div className="text-xl p-2 font-bold">
                          {category.name}
                        </div>
                        {
                          category?.CategoriesActivities &&
                          category?.CategoriesActivities.map(activity => (
                            <ActivityPDFCard
                              key={`${activity.id}-${child.id}`}
                              activity={activity}
                              watchHideImage={true}
                              recommandedOnly={true}
                            />
                          ))}
                      </div>
                    ))
                  }

                </div>
              ))}
            </div>
          </>
        </div>
      </div>
    </>
  )
}

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Api from '../lib/api'
import { useUser } from 'stores/user'

export default () => {
  const { token } = useUser(({ token }) => ({
    token,
  }))
  const { i18n } = useTranslation()

  return useMemo(
    () => ({
      async changeLanguage(lang) {
        i18n.changeLanguage(lang)
      },
      getLanguage() {
        const code = i18n.language
        let shortcode = 'fr'
        if (code) {
          shortcode = code.split()[0]
        }

        if (shortcode === 'fr') return 'fr'
        else return 'eng'
      },
      async updateLanguage(lang) {
        await Api.updateUser(token, { language: lang })
      },
    }),
    [i18n, token]
  )
}

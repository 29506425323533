import React from 'react'

import { Controller } from 'react-hook-form'

import DatePicker, { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import eng from 'date-fns/locale/en-GB'

import 'react-datepicker/dist/react-datepicker.css'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

registerLocale('fr', fr)
registerLocale('eng', eng)

export default function ControlledDatePicker({
  className,
  name,
  label,
  error,
  helpText,
  defaultValue,
  required,
  rules,
  form,
  options,
  disabled = false,
  prefix,
  placeholder = '',
  inputClassName,
}) {
  let classes = className || 'flex flex-col'

  if (required) {
    classes += ' required'
  }

  let labelClasses = !disabled ? 'block' : 'block opacity-50'

  const wrapValidate = useCallback(
    (fn) => {
      return (value) => fn(value, form, prefix)
    },
    [form, prefix]
  )

    const { i18n, t } = useTranslation()

  return (
    <Controller
      control={form.control}
      /* rules={required ? { required: 'Date obligatoire' } : {}} */
      rules={{
        ...rules,
        required: required && t('Champ obligatoire'),
        validate: rules && rules.validate && wrapValidate(rules.validate),
      }}
      name={name}
      render={({ onChange, onBlur, value, name }) => {
        let selected = null

        try {
          if (value) {
            selected = new Date(value)
            if (isNaN(selected)) {
              selected = new Date()
            }
          }

          if (!value && defaultValue) {
            form.setValue(name, defaultValue)
            selected = defaultValue
          }
        } catch (e) {
          // ignore
        }

        return (
          <label className={classes}>
            <div className={required ? 'required' : ''}>
              <span className={labelClasses}>{label}</span>
              <DatePicker
                showYearDropdown
                showMonthDropdown
                locale={i18n.language === 'fr' ? fr : eng}
                wrapperClassName={classes}
                className={`${classes} ${inputClassName} bg-gray-200 rounded print:bg-white`}
                placeholderText={label}
                selected={selected}
                shouldCloseOnSelect={true}
                onChange={(date, e) => {
                  if (e && typeof e.preventDefault === 'function') {
                    e.preventDefault()
                  }
                  if (onChange) {
                    onChange(date)
                  }
                  onChange((date && date.toISOString()) || date)
                }}
                onBlur={onBlur}
                disabled={disabled}
                firstDayOfWeek={1}
                showMonthYearPicker={options && options.showMonthYearPicker}
                dateFormat={
                  options && options.showMonthYearPicker
                    ? 'MM/yyyy'
                    : 'dd/MM/yyyy'
                }
                minDate={
                  options && options.min
                    ? new Date(options.min)
                    : new Date('1971-01-01')
                }
                maxDate={
                  options && options.max ? new Date(options.max) : new Date()
                }
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: '5px, 10px',
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: document.querySelector('.form-content'),
                  },
                }}
              />
              {error && (
                <div>
                  <span className="help-text error">{error}</span>
                </div>
              )}
              {helpText && !error && (
                <span className="help-text">{helpText}</span>
              )}
            </div>
          </label>
        )
      }}
    />
  )
}

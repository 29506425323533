import React, { useMemo, useState, useCallback } from 'react'
import Table from 'components/common/table'
import useRegistration from 'hooks/useRegistration'
import useSchoolingYear from 'hooks/useSchoolingYear'
import { useHistory } from 'react-router-dom'
import { dateFmt } from 'components/common/table/formats'
import { DefaultColumnFilter, DateColumnFilter } from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'
import { SCHOOLINGYEAR_STATUS } from 'lib/constants'

import {
  Actions,
  EditAction,
  RejectAction,
  RemoveAction,
  ValidAction,
} from 'components/common/table/actions'

import { useNotify } from 'stores/notify'

const initialPageSize = 10
const initialSortBy = [
  {
    id: 'date',
    desc: true,
  },
]

const MyModelsList = () => {
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const [forceRefresh, setForceRefresh] = useState(1)

  const { publishSchoolingDate } = useRegistration()
  const { getSchoolingdate, draftSchoolingDate, deleteSchoolingDate } =
    useSchoolingYear()

  const onError = useCallback(
    (err) => {
      if (err && err.statusCode && err.statusCode === 401) {
        setErrorMessage(err.message)
        history.replace('/')
      } else {
        setErrorMessage('Une erreur est survenue')
      }
    },
    [history, setErrorMessage]
  )

  const fetchData = useApiFetch(getSchoolingdate, onError)

  const [removed, setRemoved] = useState(null)

  const onRemove = useCallback(
    async (id) => {
      const req = await deleteSchoolingDate(id)
      if (req.error) {
        setErrorMessage(req.error.message)
        return
      }

      if (req.results && req.results.success) {
        setSuccessMessage(`Elément supprimé avec succès`)
        setRemoved(id)
      } else {
        setErrorMessage(`Impossible de supprimer: ${req.error.message}`)
      }
    },
    [deleteSchoolingDate, setErrorMessage, setSuccessMessage]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date', // accessor is the "key" in the data
        Filter: DateColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Année',
        accessor: 'year', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Statut',
        accessor: 'status', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
        Cell: ({ row: { original } }) => {
          switch (original.status) {
            case SCHOOLINGYEAR_STATUS.publish:
              return 'Publiée'
            case SCHOOLINGYEAR_STATUS.draft:
              return 'Brouillon'
            default:
              return 'Statut invalid'
          }
        },
      },
      {
        Header: 'Date mise à jour',
        accessor: 'updatedAt',
        disableFilters: true,
        className: 'font-bold',
        Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableFilters: true,
        Cell: (props) => (
          <Actions>
            <ValidAction
              entity={props.value}
              validation={publishSchoolingDate}
              message={'Voulez-vous publier la rentrée'}
              name={props.row.original.year}
              setForceRefresh={setForceRefresh}
              title="Publier"
            />
            <RejectAction
              entity={props.value}
              name={props.row.original.year}
              reject={draftSchoolingDate}
              message={'Voulez-vous passer en brouillon la date'}
              setForceRefresh={setForceRefresh}
              title="Mettre en brouillon"
            />
            <EditAction
              entity={props.value}
              message={'Voulez-vous modifier la rentrée'}
              to={`/setschoolingdate/${props.value}`}
            />
            <RemoveAction onRemove={onRemove} {...props} title={`Êtes-vous sûr de vouloir supprimer la rentrée ${props.row.original.year}&nbsp;?`}/>
          </Actions>
        ),
      },
    ],
    [draftSchoolingDate, onRemove, publishSchoolingDate]
  )

  const addSchoolingDate = useCallback(() => {
    history.push('/setschoolingdate/new')
  }, [history])

  return (
    <div className="flex justify-center w-full pt-12">
      <Table
        fetchData={fetchData}
        name="Liste des rentrées scolaire"
        initialPageSize={initialPageSize}
        columns={columns}
        rowRemoved={removed}
        initialSortBy={initialSortBy}
        addEntity={addSchoolingDate}
        forceRefresh={forceRefresh}
      />
    </div>
  )
}

export default MyModelsList

import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useUsers from '../hooks/useUsers'
import { useNotify } from '../stores/notify'
import { useTranslation } from 'react-i18next'

export default function LoginTokenPage() {
  const { login_token } = useParams()
  const { setSuccessMessage, setErrorMessage } = useNotify(
    ({ setSuccessMessage, setErrorMessage }) => ({
      setSuccessMessage,
      setErrorMessage,
    })
  )

  const history = useHistory()
  const { t } = useTranslation()
  const { loginByToken } = useUsers()

  useEffect(() => {
    async function validateToken() {
      const valid = await loginByToken(login_token)
      if (valid.error) {
        setErrorMessage(valid.error.message)
        return
      }
      if (valid) {
        setSuccessMessage(t('Inscription réussie'))
        setTimeout(() => {
          history.replace('/')
        }, 1000)
      }
    }
    validateToken()
  }, [login_token, history, loginByToken, setSuccessMessage, setErrorMessage, t])

  return <> Loading ...</>
}

export default {
  // dashboard
  'Payer la facture': 'Pay the bill',
  'Vous avez une facture à régler, avant le {{jour}} {{mois}} {{annee}} pour continuer à pouvoir inscrire vos enfants à la garderie':
    'You have a bill to pay before {{mois}} {{jour}}, {{annee}} to continue to use our childcare service',
  'Pré-inscription': 'Pre-register',
  "Vous n'avez pas réglé une facture, les services de garderie sont suspendus":
    'You have not paid a bill, childcare service is suspended',
  'Pré-inscrire mon enfant': 'Pre-register my child',
  'Voir mes pré-inscriptions': 'See my pre-registrations',
  'Voir mes factures': 'See my invoices',
  Factures: 'Invoices',
  "Frais d'inscription": 'Registration fees',
  'Frais de scolarité': 'Schooling fees',
  'Frais de garderie': 'Childcare fees',
  Garderie: 'Childcare',
  'Réservation de garderie': 'Childcare booking',
  'Historique des réservations à la garderie': 'Childcare booking history',
  "À propos de l'école": 'About the school',
  'Nous contacter': 'Contact us',
  'En cas de problème sur la plateforme, vous pouvez joindre le support technique directement par email':
    'Something went wrong? Feel free to contact the support by email',
  "Pour les questions relatives à l'école":
    'For questions regarding the school',
  "Vous n'avez pas encore inscrit votre enfant à la garderie":
    'You have not yet registered your child for childcare',
  Présence: 'Presence',
  'Certificat de scolarité': 'School certificate',
  "Voir le certificat d'inscription": 'See school certificate',
  'Liste des catégories': 'Categories list',
  'Liste des activités': 'Activities list',
  'Liste des progressions': 'Progressions list',
  'Validé pour les': 'Valided for',
  deux: 'both',
  Matin: 'Morning',
  'Après-midi': 'Afternoon',
  'Les présences ont bien étés enregistrées': 'Presences have been saved',
  "Impossible d'enregistrer les présences": 'Unable to save presences',
  Administration: 'Administration',

  // child
  'Votre enfant': 'Your child',
  'Langue maternelle': 'Primary language',
  'Langue(s) parlée(s)': 'Spoken languages',
  "Photo de l'enfant": "Child's photo",
  Garçon: 'Male',
  Fille: 'Female',
  'Sieste en après-midi ?': 'Nap in the afternoon?',
  Oui: 'Yes',
  Non: 'No',
  Sexe: 'Sex',
  "L'extension n'est pas valide": 'Extension is not valid',
  'Fichier trop lourd': 'File too large',
  'Valider et retourner sur le récapitulatif':
    'Save and go back to summary page',

  // common
  Prénom: 'Firstname',
  Nom: 'Name',
  'Date de naissance': 'Date of birth',
  Nationalité: 'Nationality',
  'Champ obligatoire': 'Required',
  Français: 'French',
  Anglais: 'English',
  Janvier: 'January',
  Février: 'February',
  Mars: 'March',
  Avril: 'April',
  Mai: 'May',
  Juin: 'June',
  Juillet: 'July',
  Août: 'August',
  Septembre: 'September',
  Octobre: 'October',
  Novembre: 'November',
  Décembre: 'Décember',
  Suivant: 'Next',
  Précédent: 'Back',
  Accueil: 'Home',

  // schoolingyear
  'Pour quelle rentrée scolaire souhaitez-vous inscrire votre enfant ?':
    'For which schooling year do you wish to register your child?',
  'Rentrée scolaire': 'School entry',
  'Année scolaire': 'Schooling year',
  Année: 'Year',
  Mois: 'Month',
  'Les frais de scolarité seront-ils pris en charge par une société ?':
    'Will schooling fees be covered by a company?',
  'Nom de la société': 'Company name',
  'Informations concernant la société': 'About the company',
  'Société partenaire': 'Partner company',
  Société: 'Company',
  OU: 'OR',
  'Au moins un des champs est obligatoire': 'At least one field is required',
  '3-6 ans': '3-6 year-old',
  '6-9 ans': '6-9 year-old',
  Classe: 'Class',
  'Impossible de récupérer les socétés': 'Unable to load companies',
  "Impossible de charger l'inscription": 'Unable to load the registration',
  "Il n'y a pas de date d'inscription pour le moment":
    'There is no registration date at the moment',
  'Motivation de la demande': 'Motivation of the demand',
  // parents
  Père: 'Father',
  Mère: 'Mother',
  Tuteur: 'Tutor',
  "Au moins une des personnes doit avoir l'autorité parentale":
    'At least one of these people must have parental authority',
  'Les parents/tuteurs': 'Parents/tutors',
  Téléphone: 'Phone number',
  Profession: 'Occupation',
  Adresse: 'Address',
  'E-mail': 'Email',
  'Autorité parentale': 'Parental authority',
  'Vous êtes le/la': 'You are',
  'Pourquoi désirez-vous inscrire dans votre enfant dans notre école ?':
    'Why do you wish to register your child in our school ?',

  // signup
  'le mot de passe doit avoir une longueur minimale de 8 caractères':
    'password should be at least 8 letters long',
  "le mot de passe doit être composé d'une combinaison d'au moins 3 types de caractères parmi les 4 suivants : majuscules (A-Z), minuscules (a-z), chiffres (0-9) et caractères spéciaux": `password should have uppercase (A-Z) and lowercase (a-z) letters, numbers (0-9) and special characters;`,
  "le mot de passe ne doit pas être constitué d'une combinaison d'un ou plusieurs mots communs et de chiffres.":
    'password should not be a combination of common words and numbers',
  'Mot de passe': 'Password',
  "S'inscrire": 'Signup',
  'Déjà inscrit ? Connectez-vous': 'Already signed up? Login',
  'Adresse e-mail {{mail}} déjà prise': '{{mail}} is already in use',
  'Merci pour votre inscription': 'Thank you for your signup',
  "Un e-mail a été envoyé à l'adresse {{email}} pour valider votre compte.":
    'An email has been sent to {{email}} to validate your account',
  'Vérifiez votre boîte mail et cliquez sur le lien que nous venons de vous envoyer pour valider votre compte.':
    'Please check your inbox and click the link we just sent you to validate your account.',
  'Vous pourrez remplir un formulaire pour pré-inscrire votre enfant.':
    'You will be able to submit a pre-registration form for your child afterward.',
  "Vous n'avez pas reçu d'e-mail ? Cliquez ici pour l'envoyer à nouveau.":
    "You haven't received your email? Click here to send it again.",

  // login
  'Mot de passe oublié ?': 'Forgot password?',
  'Se connecter': 'Login',
  'Pas encore inscrit ? Inscrivez-vous': 'Not Registered yet? Signup now',
  'Adresse e-mail non valide': 'Invalid email address',

  // navigation
  Retour: 'Back',
  'Liste des inscriptions': 'Registrations list',
  'Liste des tarifications': 'Prices list',
  'Grille tarifaire': 'Pricing grids list',
  'Liste des rentrées scolaires': 'Schooling years list',
  'Calendrier garderie': 'Childcare planning',
  'Tableau de bord': 'Dashboard',
  'Historique des inscriptions': 'Registrations history',
  Langues: 'Languages',
  Déconnexion: 'Logout',
  'Liste des sociétés': 'Companies list',
  Export: 'Export',
  Domaines: 'Domains',
  'Sous-domaines': 'Sub-domains',

  // progress bar
  Dossier: 'File',
  Étape: 'Step',
  "Le formulaire est enregistré entre chaque page, vous pourrez le reprendre là où vous l'aviez laissé":
    'The form is saved between each page, you will can pick it up where you left off',

  // bill
  Imprimer: 'Print',
  Désignation: 'Designation',
  Prix: 'Price',
  Quantité: 'Quantity',
  Total: ' Total',
  'Total à payer en euros': 'Total to pay in euros',
  'Modalités de paiements': 'Payment terms',
  'Informations complémentaires': 'Further informations',
  "Établissement d'enseignement primaire": 'Elementary educational institution',
  'TVA non applicable, art. 261-4-4°-a des CGI':
    'VAT not applicable, art. 261-4-4°-a of the General Conditions of Intervention',
  "En cas de non règlement à l'échéance prévue, il sera dû, conformément aux articles L441-3 et L441-6 du code du commerce":
    'In case of non payment on the due date, it will be due, according to articles L441-3 and L441-6 of the commercial code',
  "des pénalités de retard sur la base des honoraires restant dus, au taux de trois fois le taux d'intérêt en vigueur":
    'late penalties based on fees still due, at the rate of three times the current interset rate',
  'une indemnité forfaitaire pour frais de recouvrement de 40€':
    'a lump sum compensation for collection costs of 40€',
  Facture: 'Bill',
  'Voir la facture': 'See bill',
  'Facture payée': 'Bill paid',
  'Facture réglée le {{day}}/{{month}}/{{year}}':
    'Bill paid on {{month}}/{{day}}/{{year}}',
  'Facture annulée': 'Bill canceled',
  Date: 'Date',
  Montant: 'Amount',
  '{{jour}}/{{mois}}/{{annee}}': '{{mois}}/{{jour}}/{{annee}}',
  'Frais de garderie pour {{firstname}} {{lastname}}':
    'Childcare fees for {{firstname}} {{lastname}}',
  'Frais de scolarité pour {{firstname}} {{lastname}}':
    'Schooling fees for {{firstname}} {{lastname}}',
  "Frais d'inscription pour {{firstname}} {{lastname}}":
    'Registration fees for {{firstname}} {{lastname}}',
  'M / Mme': 'Mr / Mrs',
  'Facture à payer': 'Bill to pay',
  'Règlement par prélèvement': 'Payment by direct debit',
  'Règlement par carte bancaire': 'Payment by credit card',
  "Règlement par chèque à l'ordre de l'école Montessori de Pau":
    'Payment by check payable to École Montessori de Pau',
  '{{monthlyAmount}}€ le 06/{{mois}}/{{annee}}':
    '{{monthlyAmount}}€ on {{mois}}/06/{{annee}}',
  'La facture a été payée': 'The bill has been paid',
  "La facture n'a pas été payée": 'The bill has not been paid',
  'Pour votre société': 'For your company',
  Avoir: 'Credit note',

  // chooseregistration
  Statut: 'Status',
  Accepté: 'Accepted',
  'En cours': 'Open',
  Refusé: 'Rejected',
  'En attente': 'Pending',
  Réinscrire: 'Re-register',
  Modifier: 'Edit',
  Voir: 'See',
  "l'inscription": 'registration',
  'Inscription de {{firstname}}': "{{firstname}}'s registration",
  'Liste des dossiers': 'Files list',
  Fermé: 'Closed',
  // file
  'document(s) maximum': 'file(s) maximum',
  '5 Mo maximum': '5 Mo maximum',
  'Glissez-déposez vos fichiers ou cliquez ici pour téléverser vos documents':
    'Drag and drop yours files or click for upload yours files',

  // incomestatement
  'Vos revenus': 'Your income',
  'Revenu fiscal de référence du foyer': 'Reference household taxable income',
  "En cas de déclaration d'impôt séparée, merci d'ajouter les revenus fiscaux de référence des deux parents. Dernier avis d'imposition à présenter lors de l'inscription (information gardée confidentielle.)": `If you both declare taxable income separately, please add each reference taxable income. Last tax notice must be present when registering (information kept confidential)`,
  'Allocations familiales (total annuel)': 'Family allowances (yearly total)',
  'Relevé CAF à présenter, téléchargeable sur le site caf.fr': '',
  // 'CAF statement to present, downloadable from the caf.fr website',
  "Si vous n'avez pas de documents, renseignez votre revenu annuel.":
    "If you don't have any documents, please fill your annual income.",
  'Revenu fiscal': 'Taxable income',
  "Le nombre indiqué n'est pas correct": 'Number is incorrect',
  'Allocations familiales': 'Family allowances',
  "Nombre d'enfant(s)": 'Number of children',
  'Indice familial': 'Family index',
  'Champ complété automatiquement': 'Autocomplete field',
  '1er': '1st',
  '2ème': '2nd',
  '3ème': '3rd',
  année: 'year',
  'Nombre de paiements': 'Number of payments',
  enfant: 'child',
  'ou plus': 'or more',
  "Nombre d'enfants à charge": 'Number of dependant children',
  "Nombre d'autres personnes à charge": 'Number of other dependents',
  '{{value}} fois': '{{value}} times',

  // forgot password
  'Veuillez saisir votre adresse e-mail pour réinitialiser votre mot de passe':
    "Please type your email address, we'll send you a link to reset your password",
  'Réinitialiser le mot de passe': 'Reset password',
  'Un e-mail vous a été envoyé à {{email}} afin de réinitialiser votre mot de passe':
    'We sent you an email at {{email}} to reset your password',

  // send file
  'Il faut au moins 1 document': 'At least 1 document is required',
  'Envoi des justificatifs': 'Send proofs',
  Justificatifs: 'Proofs',
  'Impôts sur le revenu': 'Income taxes',
  "Avis d'imposition déjà envoyé(s)": 'Income taxes already sent',
  'Revelé CAF': 'CAF statement',
  'Relevé CAF déjà envoyé(s)': 'CAF statement already sent',
  'Je ne dispose pas des justificatifs': "I don't have any proofs",
  'Indiquez la raison': 'Tell us why',
  'Laissez nous un message': 'Leave us a message',

  // summary
  Récapitulatif: 'Summary',
  Scolarité: 'Schooling',
  'École Montessori de Pau': "Montessori's school of Pau",
  'Date de la scolarité': 'Schooling year date',
  "L'enfant": 'Child',
  Parents: 'Parents',
  Parent: 'Parent',
  'Frais de scolarité à la charge de votre société':
    'Schooling fees payable by your company',
  'Prix par année': 'Price per year',
  Échelonnement: 'Staggering',
  'Fichier(s) envoyé(s)': 'Files sent',
  Soumettre: 'Submit',
  "Vous ne pourrez plus modifier l'inscription, confirmer ?":
    "After submiting, you'll not be able to edit this registration, continue?",
  Annuler: 'Cancel',
  Sieste: 'Nap',
  "Pour finaliser l'inscription, merci d'ajouter une photo de votre enfant":
    'To complete your registration, please add a picture of your child',
  "Envoyer la photo de l'enfant": "Send the child's photo",

  // childcare
  "Il n'est plus possible de supprimer cette inscription à la garderie":
    "It's too late for removing this registration to childcare",
  // FIXME: phrasing
  'Vous pouvez sélectionner une ou plusieurs dates pour inscrire vos enfants à la garderie.': `You can select one or few dates to register your children into childcare.`,
  "Toute réservation est facturée même si l'enfant est absent.":
    'A booking is due even if the child did not come.',
  "Vous pouvez supprimer une réservation jusqu'à 48h avant la date.":
    'You can cancel your reservation until 48h before the date.',
  'Désélectionner toutes les dates': 'Unselect all dates',
  'Ajouter un horaire pour le': 'Add schedule for ',
  Créneau: 'Slot',
  Valider: 'Save',
  Enfant: 'Child',
  'Jour par jour': 'Day by day',
  'Par jour de la semaine (tous les lundis...)':
    'By day of week (all monday...)',
  'Mode de sélection': 'Selection mode',
  'Supprimer les réservations des dates sélectionnées':
    'Remove reservations from selected dates',
  "{{totalDelete}} supprimés, {{totalRequest }} n'étaient pas autorisés":
    '{{totalDelete}} deleted, {{totalRequest}} were not allowed',
  'Êtes-vous sûr de vouloir supprimer ces réservations ?':
    'Are you sure you want to delete these reservations ?',
  Ajouter: 'Add',
  Supprimer: 'Remove',
  Légende: 'Caption',
  'Jour(s) réservé(s)': 'Day(s) reserved',
  'Jour(s) sélectionné(s)': 'Day(s) selected',
  'Vous devez régler votre facture avant de pouvoir utiliser ce service':
    'You must pay your bill before you can use this service',
  'Impossible de réserver à cette date': 'Impossible to book on this date',

  // summarychildcare
  Horaires: 'Slots',
  Durée: 'Duration',
  'Sous-total': 'Sub-total',

  // required on each different language
  _listMonth: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  _weekShort: ['Su', 'Mo', 'Tu', 'Wed', 'Th', 'Fr', 'Sa'],

  // set-password
  "Veuillez définir votre mot de passe d'accès à l'application":
    'Type your password to access this application',
  'Confirmation du mot de passe': 'Confirm your password',
  'Les mots de passes ne correspondent pas.': 'Passwords do not match.',
  Enregistrer: 'Save',

  // error
  "L'utilisateur n'existe pas ou le mot de passe n'est pas valide":
    "User doesn't exists or password is invalid",
  'Le service est temporairement indisponible':
    'Service is temporarily unavailable',
  'Utilisateur non Administrateur': 'User is not an admin',
  "L'utilisateur n'a pas accès à cette ressource":
    'User does not have access to this resource',
  'Utilisateur non authentifié': 'Unauthenticated user',
  "Pas d'utilisateur dans la requête": 'No user in request',
  "L'utilisateur n'a pas le droit d'accéder à cette fonctionnalité":
    'User does not have access to this functionality',
  "L'adresse e-mail de l'utilisateur n'est pas validée":
    "User's mail has not been validated",
  'Ressource non autorisée': 'Unauthorized resource',

  'Impossible de récupérer les dates': 'Unable to retrieve dates',
  'Service indisponible': 'Service unavailable',
  'Impossible de charger les informations de la garderie':
    'Unable to load childcare informations',
  'Impossible de récupérer les informations de cette facture':
    'Unable to get bill informations',
  'Impossible de créer un avoir': 'Unable to generate credit note',
  "Il n'y pas d'inscription pour ce mois": "There's no registration this month",
  'Impossible de créer une nouvelle inscription':
    'Unable to create new registration',
  'Impossible de modifier les informations': 'Unable to update informations',
  'Impossible de récupérer cette inscription':
    'Unable to get registration informations',
  "Impossible d'ajouter la société": 'Unable to add company',
  "Impossible d'envoyer les fichiers": 'Impossble to upload files',
  'Impossible de récupérer le récapitulatif': 'Unable to get summary',
  'Impossible de récupérer les fichiers': 'Unable to get files',
  'Impossible de supprimer ce fichier': 'Unable to delete this file',
  'Impossible de supprimer cette inscription':
    'Unable to remove this registration',
  'Impossible de réinscrire cet enfant': 'Unable to re-register this child',
  'Impossible de télécharger le fichier': 'Unable to download this file',
  "Impossible de récupérer l'inscription": 'Unable to get this registration',
  'Impossible de créer la facture': 'Unable to generate the bill',
  'Impossible de régler la facture': 'Unable to pay the bill',
  'Impossible de récupérer les factures': 'Unable to get bills',
  'Le dossier à déjà été accepté': 'File already accepted',
  "L'enfant n'a pas l'âge requis pour être inscrit dans l'école":
    "Child doesn't have the required age to be registere in the school",

  'Il faut au moins une tarification pour pouvoir publier une date':
    'At least one tarification is required to publish a date',
  'Impossible de créer une année scolaire': 'Unable to create schooling year',
  'Pas de prix trouvé pour cet indice familial':
    "There's no price for this family index",
  'Impossible de définir la date': 'Unable to set the date',
  'Impossible de mettre à jour les tarifications de la grille tarifaire':
    "Unable to update the pricing grid's tarifications",
  'Impossible de créer de grille tarifaire':
    'Unable to generate the pricing grid',

  'Impossible de récupérer un enfant': "Unable to get child's informations",
  "Impossible d'enregistrer cette inscription à la garderie":
    'Unable to save this registration to childcare',
  'Impossible de récupérer les inscriptions à la garderie':
    'Unable to get registrations to childcare',
  'Impossible de supprimer cette inscription à la garderie':
    'Unable to remove this registration to childcare',
  'Impossible de mettre à jour les présences':
    'Impossible to update presence sheet',
  'Impossible de trouver le tarif de la garderie':
    "Unable to find childcare's pricing",
  Impossible: 'Impossible',
  'Impossible de récupérer les jours fériés': 'Unable to get public holidays',
  'Impossible de récupérer les vacances scolaires': 'Unable to get holidays',
  "Impossible d'ajouter ces horaires": 'Unable to add slots',
  'Impossible de supprimer ces horaires': 'Unable to remove slots',
  "Le mot de passe n'est pas valide": 'Password is invalid',
  "L'adresse e-mail n'a pas été validée":
    "The email address hasn't been validated yet",
  'Adresse e-mail ou mot de passe manquant':
    'Email address or password is missing',
  'Adresse e-mail non validée': 'Mail not valid yet',
  'Token manquant': 'Token is missing',
  'Impossible de se connecter': 'Unable to connect',
  "Rien n'a été modifié": 'Nothing has been changed',
  'Avoir créé': 'Credit note generated',
  'Modification effectuée': 'Save',
  'Un mail a été envoyé à {{mail}}': 'Mail has been sent to {{mail}}',
  "Aucune inscription n'est liée à cette adresse":
    "There's no registration linked to this mail",
  'Inscription réussie': 'Registration done',
  Supprimé: 'Deleted',
  Enregistré: 'Saved',
  'Un e-mail à été envoyé à votre adresse e-mail':
    'Mail has been sent to you mail address',
  'Votre mot de passe a été enregistré. Bienvenue dans MONTESSORI.':
    'Password saved. Welcome to MONTESSORI.',
  'Un e-mail vous a été envoyé pour valider votre inscription':
    'Mail has been sent to valid your registration',
  'Un nouvel e-mail vous a été envoyé pour valider votre inscription':
    'Mail has been again to valid your registration',

  //menu

  Activités: 'Activities',
  'Présence en classe': 'Class attendance',
  Progressions: 'Progressions',
  'Suivi des enfants': 'Skills acquired',
  Catégories: 'Categories',
  Utilisateurs: 'Users',
}

import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import ReactToPrint from 'react-to-print'
import { useTranslation } from 'react-i18next'

import Spinner from './common/Spinner'
import {
  timeToNumber,
  numberToTime,
  roundWithTwoDecimals,
  capitalize,
  getDateWithoutTime,
} from '../lib/utils'

export default function Bill({
  childcares,
  viewMode,
  onSubmit,
  price,
  totalPrice,
  totalDuration,
  loading = false,
  goBack,
}) {
  const form = useForm()
  const { handleSubmit } = form
  const componentToPrint = useRef()
  const { t } = useTranslation()

  return (
      <div className="flex items-center justify-center mt-20">
        <form
          className="w-full form-container sm:w-full xl:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          {!(childcares || !price) && <Spinner></Spinner>}
          {childcares && (
            <>
              <div className="flex w-full md:flex-row flex-col my-2 justify-between text-center">
                <h3 className="w-1/4 font-bold tracking-wide">
                  {/* <h3 className="font-bold pl-2">{title}</h3> */}

                  {/* {datas && dateFormat(datas.date[0])} */}
                </h3>
              </div>
              <div className="w-full">
                <div className="flex w-full justify-between flex-row px-2">
                  <div className="mb-4 text-center">
                    {t('Récapitulatif')}
                  </div>
                  {viewMode && (
                    <div className="mb-4 text-center">
                      <ReactToPrint
                        trigger={() => (
                          <div className="text-blue-600 cursor-pointer">
                            {t('Imprimer')}
                          </div>
                        )}
                        content={() => componentToPrint.current}
                        bodyClass={'m-12 p-6'}
                      />
                    </div>
                  )}
                </div>
                <div className="w-full mb-4 border border-gray-100"></div>
                <div ref={componentToPrint}>
                  {viewMode && (
                    <div>
                      <div className="font-black mb-4 text-center">
                        {t('Récapitulatif')}
                      </div>
                      <div className="mb-4 text-center">
                        {t("Toute réservation est facturée même si l'enfant est absent.")}
                        {t("Vous pouvez supprimer une réservation jusqu'à 48h avant la date.")}
                      </div>
                    </div>
                  )}

                  <div className="flex w-full flex-row my-2 justify-center text-sm text-center bg-gray-100 text-gray-700">
                    <div className="w-full"></div>
                    <div className="w-full">{t('Enfant')}</div>
                    <div className="w-full">{t('Horaires')}</div>
                    <div className="w-full">{t('Durée')}</div>
                    <div className="w-full">{t('Prix')}</div>
                  </div>
                  {childcares &&
                    childcares.length > 0 &&
                    price && 
                    childcares.map((childcare, index) => (
                      <div
                        key={`date-${index}`}
                      >
                        <div
                          className="flex w-full md:flex-row flex-col mt-2 justify-between"
                        >
                          <div className="w-1/5 text-center text-sm">
                            {childcare &&
                              t("{{jour}}/{{mois}}/{{annee}}", {
                                jour: getDateWithoutTime(childcare.date).split('/')[2],
                                mois: getDateWithoutTime(childcare.date).split('/')[1],
                                annee: getDateWithoutTime(childcare.date).split('/')[0]
                              })}
                          </div>
                        </div>

                        {childcare.children.map((child, index) => (
                          <div
                            key={`child-${index}`}
                            className="flex w-full flex-col my-2 justify-between text-center"
                          >
                            <div className="w-full flex flex-row md:pr-2">
                              <div className="w-1/5"></div>
                              <div className="w-1/5 text-center ml-2 text-gray-900 font-bold mb-2 text-xs">
                                {capitalize(child.firstname)}{' '}
                                {capitalize(child.lastname)}
                              </div>
                            </div>
                            {child.childcare.map((care, index) => (
                              <div key={`${index}`} className="flex flex-row text-xs">
                                <div className="w-1/5"></div>
                                <div className="w-1/5"></div>
                                <div className="w-1/5">
                                  {care.start} - {care.end}
                                </div>
                                <div className="w-1/5">{care.duration}</div>
                                <div className="w-1/5">
                                  {roundWithTwoDecimals(
                                    timeToNumber(care.duration) * price
                                  )}{' '}
                                  €
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    ))}
                  <div className="flex w-full flex-row my-6 font-bold justify-between text-center bg-gray-100">
                    <div className="w-full">{t('Total')}</div>
                    <div className="w-full"></div>
                    <div className="w-full"></div>
                    <div className="w-full">
                      {totalDuration && numberToTime(totalDuration)}
                    </div>
                    <div className="w-full">
                      {totalPrice && roundWithTwoDecimals(totalPrice)} €
                    </div>
                  </div>
                </div>
                {!viewMode && (
                  <div className="flex w-full mt-8 justify-between">
                    <button
                      className="bg-red-500 text-white py-3 px-4 mr-4 rounded-lg md:w-1/3 w-1/2"
                      onClick={() => goBack()}
                      type="button"
                    >
                      {t('Annuler')}
                    </button>
                    <button
                      className={`bg-green-500 text-white py-3 px-4 rounded-lg md:w-1/3 w-1/2 flex justify-center ${loading ? 'cursor-not-allowed' : ''
                        }`}
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <svg
                          className="animate-spin h-5 w-5 mr-3"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        <>{t('Valider')}</>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </form>
      </div>
  )
}

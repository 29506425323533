import React, { useMemo, useState, useCallback } from 'react'
import Table from 'components/common/table'
import useBill from 'hooks/useBill'
import { useHistory } from 'react-router-dom'
import { dateFmt } from 'components/common/table/formats'
import {
  DefaultColumnFilter,
  EqualDefaultFilter,
} from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'
import {
  Actions,
  ValidAction,
  CreateAction,
} from 'components/common/table/actions'
import { BILL_STATUS, BILL_TYPE } from '../../lib/constants'
import { useNotify } from 'stores/notify'
import { useForm } from 'react-hook-form'

import BillsPdfChoseDateModal from 'components/common/BillsPdfChoseDateModal'

const initialPageSize = 10
const initialSortBy = [
  {
    id: 'name',
    desc: true,
  },
]

const MyModelsList = () => {
  const history = useHistory()
  const form = useForm()
  const { getBill } = useBill()

  const { setErrorMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setWarningMessage }) => ({
      setErrorMessage,
      setWarningMessage,
    })
  )

  const [forceRefresh, setForceRefresh] = useState(1)

  const { payBill, getBills } = useBill()

  const onError = useCallback(
    (err) => {
      if (err && err.statusCode && err.statusCode === 401) {
        setErrorMessage(err.message)
        history.replace('/')
      } else {
        setErrorMessage('Une erreur est survenue')
      }
    },
    [history, setErrorMessage]
  )

  const fetchData = useApiFetch(getBills, onError)

  const columns = useMemo(
    () => [
      {
        Header: 'Référence',
        accessor: 'name',
        Filter: DefaultColumnFilter,
        className: 'font-bold',
      },
      {
        Header: 'Montant',
        accessor: 'amount', // accessor is the "key" in the data
        Filter: EqualDefaultFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Type',
        accessor: 'type', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Status',
        accessor: 'status', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Prénom enfant',
        accessor: 'ChildFirstname', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Nom enfant',
        accessor: 'ChildLastname', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Société',
        accessor: 'CompanyName', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },

      {
        Header: 'Créee le',
        accessor: 'createdAt',
        disableFilters: true,
        className: 'whitespace-no-wrap',
        Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableFilters: true,
        Cell: (props) => {
          return (
            <Actions>
              {props.row.original.status !== BILL_STATUS.paid && (
                <ValidAction
                  entity={props.value}
                  message={'Marquer la facture comme payée'}
                  validation={payBill}
                  setForceRefresh={setForceRefresh}
                  title={'Marquer comme payée'}
                  name={`(${props.row.original.name})`}
                />
              )}
              {props.row.original.type !== BILL_TYPE.creditnote && (
                <CreateAction
                  entity={props.value}
                  create={() => {
                    history.push('/bill', {
                      id: props.row.original.id,
                      registrationId: props.row.original.RegistrationId,
                      recreate: true,
                    })
                  }}
                  title={'Recréer la facture'}
                />
              )}
            </Actions>
          )
        },
      },
    ],
    [payBill, history]
  )

  const toBill = useCallback(
    (id, original) => {
      getBill(id).then((res) => {
        var bill = res.results
        history.push(`/bill`, { bill, registrationId: original.RegistrationId })
      })
    },
    [history, getBill]
  )

  const onDisplayBills = async () => {
    const { year, month } = await BillsPdfChoseDateModal({
      form,
      title: 'Liste des factures',
    })

    if (!year || !month) {
      return
    }

    if (!Number.isInteger(year)) {
      setWarningMessage("L'année demandée n'est pas valide")
      return
    }

    history.push('/billstopdf', {
      year,
      month,
    })
  }

  return (
    <div className="flex justify-center w-full pt-12">
      <Table
        fetchData={fetchData}
        name="Liste des factures"
        initialPageSize={initialPageSize}
        columns={columns}
        onLineClick={toBill}
        initialSortBy={initialSortBy}
        forceRefresh={forceRefresh}
        onDisplaysBills={() => onDisplayBills()}
      />
    </div>
  )
}

export default MyModelsList

import React, { useEffect } from "react"
import { useForm } from "react-hook-form";
import Input from "components/form/Input"
import StepButtons from './common/StepButtons'

export default function SetPrice({ onSubmit, goBack, price, id, loading }) {
  const form = useForm()
  const { register, handleSubmit, errors, reset } = form

  useEffect(() => {
    if (
      loading.prevState === false &&
      loading.current === false
    ) {
      reset()
    }
  }, [loading, reset])

  return (
    <>
      <div className="flex items-center justify-center mt-16">
        <form
          className="form-container w-full sm:w-full xl:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="font-black mb-4 text-center">
            {`${!isNaN(id) ? "Modifier " : "Ajouter"} une tarification`}
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={"name"}
              ref={register({ required: "Champ obligatoire" })}
              type="text"
              name="name"
              label="Nom de la tarification"
              placeholder="Tranche 2021 A"
              error={errors.name && errors.name.message}
              defaultValue={price && price.name}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={"registrationFees"}
              ref={register({ required: "Champ obligatoire" })}
              type="number"
              name="registrationFees"
              label="Frais d'inscription"
              placeholder="100"
              error={errors.registrationFees && errors.registrationFees.message}
              defaultValue={price && price.registrationFees}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
            <div className="md:w-1/2 md:pr-2 w-full">
              <Input
                key={"familyIndex"}
                ref={register({ required: "Champ obligatoire" })}
                type="number"
                name="familyIndex"
                label="Indice Familial (à partir de)"
                placeholder="6000"
                error={errors.familyIndex && errors.familyIndex.message}
                defaultValue={price && price.familyIndex}
              />
            </div>
            <div className="md:w-1/2 md:pr-2 w-full">
              <Input
                key={"oneChild"}
                ref={register({ required: "Champ obligatoire" })}
                type="number"
                name="oneChild"
                label="1 enfant"
                placeholder="2000"
                error={errors.oneChild && errors.oneChild.message}
                defaultValue={price && price.oneChild}
              />
            </div>
          </div>

          <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
            <div className="md:w-1/2 md:pr-2 w-full">
              <Input
                key={"twoChild"}
                ref={register({ required: "Champ obligatoire" })}
                type="number"
                name="twoChild"
                label="2 enfants"
                placeholder="2000"
                error={errors.twoChild && errors.twoChild.message}
                defaultValue={price && price.twoChild}
              />
            </div>
            <div className="md:w-1/2 md:pr-2 w-full">
              <Input
                key={"threeChild"}
                ref={register({ required: "Champ obligatoire" })}
                type="number"
                name="threeChild"
                label="3 enfants"
                placeholder="3000"
                error={errors.threeChild && errors.threeChild.message}
                defaultValue={price && price.threeChild}
              />
            </div>
          </div>
          <StepButtons
          loading={loading.current}
            goBack={goBack}
            nextLabel={'Enregistrer'}
          />
        </form>
      </div>
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const STEPS = 4

export default function ProgressBar({
  registrationId = '',
  step,
  name,
  company,
}) {
  // numbers of steps in the registration form
  const [number, setNumber] = useState()

  useEffect(() => {
    if (company) {
      setNumber(STEPS - 1)
    } else {
      setNumber(STEPS)
    }
  }, [company])

  const { t } = useTranslation()

  const Check = () => {
    let classes = `shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-pink-500`
    if (step === number) {
      classes += ` w-full`
    } else {
      classes += ` w-${step}/${number}`
    }
    return <div className={classes}></div>
  }

  return (
    <>
      <div className="flex items-center place-content-center mt-16 mb-2">
        <span className="text-brand font-bold text-2xl sm:text-3xl pl-4">
          {t('Dossier')} #{registrationId}
        </span>
      </div>
      <div className="flex items-center justify-center mb-4 mt-12">
        <span className="w-full sm:w-1/2 text-sm text-gray-600 text-left">
          <p className="px-1">
            {t('Étape')} {step}/{number === 1 ? '-' : number} : {name}
          </p>
          <div className="relative pt-1">
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-400">
              <Check />
            </div>
          </div>
        </span>
      </div>
      <div className="flex justify-center mb-4">
        <div className="w-1/2 px-2 text-gray-600 text-sm text-justify">
          {t(
            "Le formulaire est enregistré entre chaque page, vous pourrez le reprendre là où vous l'aviez laissé"
          )}
        </div>
      </div>
    </>
  )
}

import React, { useState, useEffect, useCallback } from 'react'
import DayPicker from 'react-day-picker'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import {
  compareDates,
  daysToDisabled,
  isSchoolingyearChange,
  isYearChange
} from '../lib/utils'
import Button from 'components/form/Button'
import Select from 'components/form/Select'
import Radio from 'components/form/Radio'
import Input from 'components/form/Input'
import Spinner from 'components/common/Spinner'
import { YOUNG_CLASSES, OLD_CLASSES } from '../lib/constants'

import { useTranslation } from 'react-i18next'

export default function ClassPresence({
  children,
  publicHolidays,
  holidays,
  onSchoolingYearChange,
  onYearChange,
  onSubmit,
  loadChildrenPresence,
  presences,
  loading,
  childrenSchoolClass
}) {

  const [selectedDate, setSelectedDate] = useState()
  const [previousDate, setPreviousDate] = useState(new Date())
  const [childrenToDisplay, setChildrenToDisplay] = useState([])

  const [modifiers, setModifiers] = useState({})

  const form = useForm()
  const { t } = useTranslation()
  const { control, handleSubmit, reset, watch, register, setValue, getValues } = form

  const { fields } = useFieldArray({
    control,
    name: 'children'
  })

  const classSelected = watch('class-selected')

  const presence = [
    {
      label: t("Oui"), value: "y"
    }, {
      label: t('Non'), value: 'n'
    }
  ]

  const halfdays = ['morning', 'afternoon']

  const classList = [
    { value: 1, label: '3-6' },
    { value: 2, label: '6-9' },
  ]

  const disabledDays = useCallback(
    (day) => {
      return daysToDisabled({
        disabled: [
          'wss', 'publicHolidays', 'holidays'
        ],
        day,
        datas: {
          custom: [
            '2022-05-27'
          ],
          publicHolidays: publicHolidays?.results,
          holidays
        }
      })
    },
    //eslint-disable-next-line
    [
      holidays,
      publicHolidays,
    ]
  )

  const findPresenceInDate = (date, childId, halfday) => {
    let p = presences.filter(p => {
      if (compareDates(p.date, date)) {
        if (p.ChildId === childId && p.halfday === halfday) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    })
    if (p.length === 0) {
      return 'y'
    }

    return p[0]?.presence ? 'y' : 'n'
  }

  const findReason = (date, childId, halfday) => {
    let p = presences.filter(p => {
      if (
        p.ChildId === childId &&
        p.presence === false &&
        p.halfday === halfday &&
        compareDates(p.date, date)
      ) {
        return true
      } else {
        return false
      }
    })
    return typeof p[0]?.reason === 'string' ? p[0]?.reason || " " : false
  }

  // this effect set value of radio already selected ...
  useEffect(() => {
    if (
      children && children.length > 0 &&
      presences && presences.length > 0 &&
      selectedDate
    ) {
      let childrenDay = presences.filter(p => compareDates(p.date, selectedDate))
      childrenDay.forEach(c => {
        if (c.halfday === 'morning') {
          setValue(`child-morning-${c.ChildId}`, c.presence ? 'y' : 'n')
          setValue(`child-reason-morning-${c.ChildId}`, c.reason ? c.reason : '')
        } else if (c.halfday === 'afternoon') {
          setValue(`child-afternoon-${c.ChildId}`, c.presence ? 'y' : 'n')
          setValue(`child-reason-afternoon-${c.ChildId}`, c.reason ? c.reason : '')
        }
      })
    } else if (!selectedDate) {
      setChildrenToDisplay([])
    }
  }, [children, presences, selectedDate, setValue])


  useEffect(() => {
    const classValue = getValues('class-selected')
    let available = []
    let childrenToDisplay = []
    // 3-6 or 6-9
    if (classValue?.value === 1) {
      available = YOUNG_CLASSES
    } else if (classValue?.value === 2) {
      available = OLD_CLASSES
    }

    const childrenFilter = children.filter(child => available.includes(child.schoolClassName))
    childrenToDisplay = childrenFilter.map(child => {
      return {
        value: child.id,
        label: `${child.firstname} ${child.lastname}`
      }
    })
    setChildrenToDisplay(childrenToDisplay || [])
    reset({ children: childrenToDisplay, 'class-selected': classSelected })

  }, [classSelected, children, getValues, reset])

  useEffect(() => {
    if (presences && presences.length > 0 && childrenSchoolClass && childrenSchoolClass.length > 0) {
      let youngClass = [
        'TPS', 'PS', 'MS', 'GS'
      ]

      let daysPresence = {
        young: [],
        old: [],
        all: [],
        temp: {},
      }

      presences.forEach(presence => {
        let whichClass
        if (youngClass.includes(presence.SchoolClass.name)) {
          whichClass = 'young'
        } else {
          whichClass = 'old'
        }

        if (daysPresence.temp.hasOwnProperty(presence.date)) {
          daysPresence.temp[presence.date][whichClass] += 1
        }
        else {
          daysPresence.temp[presence.date] = {
            young: 0,
            old: 0
          }
          daysPresence.temp[presence.date][whichClass] = 1
        }
      })

      Object.keys(daysPresence.temp).forEach(date => {
        let y = daysPresence.temp[date].young
        let o = daysPresence.temp[date].old
        if (y > 0 && o > 0) {
          daysPresence.all.push(new Date(date))
        } else if (y > 0) {
          daysPresence.young.push(new Date(date))
        } else {
          daysPresence.old.push(new Date(date))
        }
      })

      delete daysPresence.temp

      setModifiers({
        young: daysPresence.young,
        old: daysPresence.old,
        all: daysPresence.all,
        selected: new Date(selectedDate) || null
      })
    }
  }, [childrenSchoolClass, presences, selectedDate])

  let modifiersStyle = {
    selected: {
      color: "white",
      backgroundColor: "rgba(245, 101, 101)"
    }
  }

  return (
    <>
      {
        !modifiers ? (
          <div className="flex items-center flex-col justify-center mt-2">
            <Spinner />
          </div>
        ) : (
          <div className="flex items-center flex-col justify-center mt-2">
            <div className="flex w-full sm:w-1/2 py-3">
              <h3 className="text-lg font-semibold text-gray-800 px-8">{t("Présence en classe")}</h3>
            </div>
            <form
              className="w-full form-container sm:w-full xl:w-1/2"
              onSubmit={handleSubmit(datas => onSubmit(datas, selectedDate))}
            >
              <div className="flex items-center justify-center mb-8 mt-8 w-full bg-gray-100">
                <div className="w-full sm:w-3/5">
                  <DayPicker
                    onMonthChange={(e) => {
                      let date = new Date(e)
                      loadChildrenPresence(date)
                      // if change to next year
                      isSchoolingyearChange({
                        previous: previousDate,
                        current: date,
                        cb: onSchoolingYearChange
                      })

                      isYearChange({
                        previous: previousDate,
                        current: date,
                        cb: onYearChange,
                        params: date.getFullYear()
                      })

                      setSelectedDate(null)
                      setValue('class-selected', null)

                      setPreviousDate(date)

                    }}
                    onDayClick={(e, modifiers) => {
                      if (modifiers && !modifiers?.disabled) {
                        let date = new Date(e)
                        date.setHours(12)
                        setSelectedDate(date)
                      } else {
                        setSelectedDate(null)
                      }
                    }}
                    name={'date'}
                    selectedDays={selectedDate}
                    months={t('_listMonth', { returnObjects: true })}
                    weekdaysShort={t('_weekShort', { returnObjects: true })}
                    modifiers={modifiers}
                    className=""
                    disabledDays={disabledDays}
                    modifiersStyles={modifiersStyle}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-start w-full gap-2 text-xs p-4">
                <div className="font-bold">
                  {t("Légende")} :
                </div>

                <div className="flex align-center items-center gap-2">
                  <div className="h-4 w-4 DayPicker-Day--young border border-black"></div>
                  <div>
                    {t("Validé pour les")} 3-6
                  </div>
                </div>
                <div className='flex items-center gap-2'>
                  <div className="h-4 w-4 DayPicker-Day--old border border-black"></div>
                  <div>
                  {t("Validé pour les")} 6-9
                  </div>
                </div>
                <div className='flex items-center gap-2'>
                  <div className="h-4 w-4 DayPicker-Day--all border border-black"></div>
                  <div>
                  {t("Validé pour les")} {t('deux')}
                  </div>
                </div>

              </div>
              {selectedDate && (
                <Controller
                  control={control}
                  name={`class-selected`}
                  render={({ onChange, value, name }) => (
                    <>
                      <Select
                        placeholder={t('Classe')}
                        name={name}
                        className="w-full"
                        label={t("Classe")}
                        value={value}
                        values={classList}
                        onChange={(e) => {
                          onChange(e)
                        }}
                        noOptionsMessage={() => (
                          <div className="flex justify-center pb-2">
                            <Spinner></Spinner>
                          </div>
                        )}
                      />
                    </>
                  )}
                />
              )}
              {selectedDate && childrenToDisplay.length > 0 && (
                <>
                  <div className="flex justify-center align-center my-8 w-full py-8 flex-col">
                    <div className="flex justify-center my-8 w-full bg-gray-100 py-8 flex-col md:flex-row p-2 gap-2">

                      {halfdays.map((halfday, index) => (
                        <div className={`w-full md:w-1/2 text-center`} key={halfday}>
                          <div className="py-4 font-bold">
                            {halfday === 'morning' ? t("Matin") : t("Après-midi")}
                          </div>
                          <div>
                            {fields.map(field => {
                              return (
                                <div
                                  key={`${JSON.stringify(`child-${halfday}-${field.value}-${selectedDate}`)}`}
                                  className="flex w-full md:flex-row flex-col mt-6 lg:justify-center md:items-start items-center bg-white border border-gray-500 p-1"
                                >
                                  <div className='w-full md:w-1/2 font-medium'>
                                    {field.label} ({childrenSchoolClass.find(p => p?.childId === field.value)?.className})
                                  </div>
                                  <div className='w-full md:w-1/2'>
                                    <Radio
                                      input={{ label: field.label }}
                                      inputs={presence}
                                      register={{
                                        validate: (data) => {
                                          if (data === '' || data === undefined) {
                                            return false
                                          } else {
                                            return true
                                          }
                                        }
                                      }}
                                      key={JSON.stringify(`${selectedDate}-${field.value}-${halfday}`)}
                                      form={form}
                                      defaultValue={findPresenceInDate(selectedDate, field.value, halfday)}
                                      name={`child-${halfday}-${field.value}`}
                                      noLabel
                                    />
                                    {(findReason(selectedDate, field.value, halfday) ||
                                      watch(`child-${halfday}-${field.value}`) === 'n') && (
                                        <div className="px-2">
                                          <Input
                                            ref={register}
                                            key={`reason-${field.value}-${halfday}`}
                                            type="text"
                                            name={`child-reason-${halfday}-${field.value}`}
                                            label="Motif"
                                            placeholder="Motif"
                                            defaultValue="Malade"
                                            value={findReason(selectedDate, field.value, halfday) || watch(`child-reason-${halfday}-${field.value}`)}
                                          />
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex w-full justify-end">
                    <Button
                      loading={loading}
                      submits
                    >
                      {t("Valider")}
                    </Button>
                  </div>
                </>
              )}

            </form>
          </div>
        )}
    </>
  )
}

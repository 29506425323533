import React, { useState, useEffect, useCallback } from 'react'
import DayPicker from 'react-day-picker'
import { useForm, Controller } from 'react-hook-form'
import {
  isSchoolingyearChange,
  isYearChange,
  daysToDisabled

} from '../lib/utils'
import Spinner from 'components/common/Spinner'
import Button from 'components/form/Button'
import AddIcon from 'components/svg/add'
import Select from '../components/form/Select'
import { NurseryViewerCard } from './NurseryViewerCard'
import { useTranslation } from 'react-i18next'

export default function NurseryViewer({
  modifiers,
  children,
  nurseryList,
  loadChildCares,
  onSubmit,
  onSchoolingYearChange,
  childcareHours,
  onSubmitAdding,
  onYearChange,
  publicHolidays,
  holidays,
  onDeleteNurseryById,
  role,
  onSubmitSlotEditing,
  onEditTimeSlot,
  isEditingTimeSlot,
}) {
  const [selectedDate, setSelectedDate] = useState()
  const [morning, setMorning] = useState([])
  const [evening, setEvening] = useState([])
  const [previousDate, setPreviousDate] = useState(new Date())
  const [adding, setAdding] = useState(false)
  const [isRequired, setIsRequired] = useState({ required: false })
  const [selectChildcareHours, setSelectChildcareHours] = useState([])

  const form = useForm()
  const { control, handleSubmit, setValue, errors, reset, watch, getValues } = form
  const { t } = useTranslation()

  useEffect(() => {
    if (nurseryList && nurseryList.length && selectedDate) {
      let mor = []
      let ev = []
      nurseryList.forEach((n) => {
        if (new Date(n.date).getTime() === selectedDate.getTime()) {
          if (n.ChildcareHour.start.charAt(0) === '0') {
            mor.push(n)
          } else if (n.ChildcareHour.start.charAt(0) === '1') {
            ev.push(n)
          }
        }
      })
      setMorning(mor)
      setEvening(ev)
    }
  }, [selectedDate, nurseryList])

  useEffect(() => {
    setValue('child', [])
    setValue('slot')
  }, [nurseryList, reset, setValue])

  const disabledDays = useCallback(
    (day) => {
      return daysToDisabled({
        disabled: [
          'wss',
          'publicHolidays',
          'holidays'
        ],
        day,
        datas: {
          publicHolidays: publicHolidays?.results,
          holidays: holidays,
          custom: [
            "2022-05-27"
          ]
        }
      })
    },
    //eslint-disable-next-line
    [
      holidays,
      publicHolidays,
    ]
  )

  const child = watch('child')
  const slot = watch('slot')

  useEffect(() => {

    if (child && (child.length === 0 || !child) && !slot) {
      setIsRequired({ required: false })
    } else {
      setIsRequired({
        child: {
          validate: (value) => {
            return !!value && (value.length > 0 || "Champ obligatoire")
          }
        },
        slot: { required: "Champ obligatoire" },
      })
    }

  }, [child, slot, getValues])

  useEffect(() => {
    if (childcareHours) {
      setSelectChildcareHours(childcareHours.filter(slot => slot.label.charAt(0) === "1"))
    }
  }, [childcareHours])

  return (
    <>
      {
        !modifiers ? (
          <div className="flex items-center flex-col justify-center mt-2">
            <Spinner />
          </div>
        ) : (
          <div className="flex items-center flex-col justify-center mt-2">
            <div className="flex w-full sm:w-1/2 py-3">
              <h3 className="text-lg font-semibold text-gray-800 px-8">Garderie</h3>
            </div>
            <form
              className="w-full form-container sm:w-full xl:w-1/2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex items-center justify-center mb-8 mt-8 w-full bg-gray-100">
                <div className="w-full sm:w-3/5">
                  <DayPicker
                    onMonthChange={(e) => {
                      let date = new Date(e)
                      loadChildCares(date)

                      isSchoolingyearChange({
                        previous: previousDate,
                        current: date,
                        cb: onSchoolingYearChange,
                      })

                      isYearChange({
                        previous: previousDate,
                        current: date,
                        cb: onYearChange,
                        params: date.getFullYear()
                      })

                      setPreviousDate(date)
                      setSelectedDate(null)
                      setAdding(false)
                    }}
                    onDayClick={(e, modifiers) => {
                      onEditTimeSlot('') // clear edition mode if change date
                      if (modifiers && !modifiers?.disabled) {
                        let date = new Date(e)
                        date.setHours(12)
                        setSelectedDate(date)
                      } else {
                        setSelectedDate(null)
                      }
                      setAdding(false)
                    }}
                    name={'date'}
                    selectedDays={selectedDate}
                    months={t('_listMonth', { returnObjects: true })}
                    weekdaysShort={t('_weekShort', { returnObjects: true })}
                    // showOutsideDays
                    modifiers={modifiers}
                    className=""
                    disabledDays={disabledDays}
                  />
                </div>
              </div>
              {selectedDate && !adding ? (
                <div className="flex justify-center align-center my-8 w-full py-8 flex-col">
                  <div className="text-center">
                    Ajouter un enfant
                  </div>
                  <div className="flex justify-center p-4">
                    <button
                      className="p-4 text-center flex-1 flex justify-center"
                      type='button'
                      onClick={() => setAdding(true)}
                    >
                      <AddIcon></AddIcon>
                    </button>
                  </div>
                </div>
              ) : selectedDate && adding && (
                <div className="flex justify-center align-center my-8 w-full py-8 flex-col">
                  <div>
                    <button
                      className="text-left text-blue-500 pl-4"
                      onClick={() => setAdding(false)}
                      type='button'
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="flex justify-evenly p-4 md:flex-row flex-col gap-2">
                    <Controller
                      name='child'
                      key='child'
                      control={control}
                      rules={{
                        required: isRequired.child
                      }}
                      render={({ onChange, value, name }) => (
                        <>
                          <Select
                            key={'child-select'}
                            name={'child-select'}
                            placeholder={"Enfant"}
                            className="w-auto"
                            value={value}
                            isMulti
                            values={children}
                            onChange={(e) => onChange(e)}
                          />
                          {errors && errors[name] && (
                            <div className="text-sm text-red-500">
                              {errors[name].message}
                            </div>
                          )}
                        </>
                      )}
                    />
                    <Controller
                      name='slot'
                      key='slot'
                      control={control}
                      rules={{ required: isRequired.slot }}
                      render={({ onChange, value, name }) => (
                        <>
                          <Select
                            key={'slot-select'}
                            name={'slot-select'}
                            placeholder={"Horaires"}
                            className="w-auto"
                            value={value}
                            values={childcareHours}
                            onChange={(e) => onChange(e && e.value)}
                          />
                          {errors && errors[name] && (
                            <div className="text-sm text-red-500">
                              {errors[name].message}
                            </div>
                          )}
                        </>
                      )}
                    />
                    <div className="w-auto flex justify-center items-center">
                      <Button
                        onClick={
                          handleSubmit(e => onSubmitAdding(e, selectedDate, () => setAdding(false)))
                        }
                      >
                        Valider
                      </Button>
                    </div>

                  </div>
                </div>
              )}
              {selectedDate && (
                <div className="flex flex-col md:flex-row justify-center mb-8 mt-8 w-full bg-gray-100 py-8">
                  <NurseryViewerCard
                    halfdayName="Matin"
                    name="morning"
                    halfday={morning}
                    children={children}
                    isEditingTimeSlot={isEditingTimeSlot}
                    onEditTimeSlot={onEditTimeSlot}
                    onSubmitSlotEditing={onSubmitSlotEditing}
                    onDeleteNurseryById={onDeleteNurseryById}
                    role={role}
                    selectedDate={selectedDate}
                    form={form}
                  />
                  <NurseryViewerCard
                    halfdayName="Après-midi"
                    name='evening'
                    halfday={evening}
                    children={children}
                    isEditingTimeSlot={isEditingTimeSlot}
                    onEditTimeSlot={onEditTimeSlot}
                    onSubmitSlotEditing={onSubmitSlotEditing}
                    onDeleteNurseryById={onDeleteNurseryById}
                    role={role}
                    selectedDate={selectedDate}
                    form={form}
                    selectChildcareHours={selectChildcareHours}
                  />
                </div>
              )}
              {morning.length + evening.length >= 1 && selectedDate && (
                <div className="flex w-full mt-8 justify-end">
                  <Button
                    submits
                  >
                    Valider
                  </Button>
                </div>
              )}
            </form>
          </div>
        )}
    </>
  )
}

import React from 'react'
import { useHistory } from 'react-router'
import Spinner from 'components/common/Spinner'
import DashboardCard from 'components/dashboard/DashboardCard'
import { useTranslation } from 'react-i18next'
import Button from './form/Button'

export default function ViewCertificate({
  registrations,
}) {
  const { t } = useTranslation()
  const history = useHistory()

  const onGenerateCertificate = (r) => {
    history.push('/schoolcertificate', {
      firstname: r?.firstname,
      lastname: r?.lastname,
      schoolingYear: r.schoolingYear,
      schoolClass: r.schoolClass,
    })
  }

  return (
    <>
      <div className="flex flex-row flex-wrap flex-grow mt-20 justify-center">
        {registrations &&
          registrations.map((registration, index) => (
            <DashboardCard
              key={`registrationCard-${index}`}
              title={`${registration.firstname} ${registration.lastname}`}
            >
              <div className="flex flex-col">
                <div>
                  {t('Année')} &nbsp;: {registration.schoolingYear} - {registration.schoolingYear + 1}
                </div>
                <div>
                  {t('Classe')} &nbsp;: {registration.schoolClass}
                </div>
                <div className="mt-4 flex justify-center">

                  <Button
                    variant="primary"
                    onClick={() => onGenerateCertificate(registration)}
                  >
                    {t("Voir le certificat d'inscription")}
                  </Button>
                </div>
              </div>
            </DashboardCard>
          ))}
        {!registrations && <Spinner></Spinner>}
      </div>
    </>
  )
}

import React, { useCallback } from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatebleSelect from 'react-select/async-creatable'

function findValue(options, value) {
  return options.reduce((optionFound, option) => {
    let val
    if (option.hasOwnProperty('options')) {
      val = findValue(option.options, value)
    } else if (option.value === value) {
      val = option
    }

    return optionFound || val
  }, null)
}

export default React.forwardRef((props, ref) => {
  const {
    label,
    name,
    placeholder = 'Choisir une valeur',
    // required,
    onChange,
    className,
    helpText,
    error,
    value,
    values = [],
    defaultOptions = [],
    options,
    defaultValue,
    when,
    isMulti,
    isClearable = true,
    creatable = undefined,
    formatOptionLabel = undefined,
    filterOption,
    disabled = false,
    menuPlacement = 'bottom',
    noOptionsMessage = () => 'Aucune valeur disponible',
    onInputChange = () => {},
    formatCreateLabel,
    loadOptions = null,
    loadingMessage = () => {},
    avoidSorted = false,
    isSearchable = false,
  } = props
  let SelectComponent = Select
  if (creatable) {
    if (loadOptions) {
      SelectComponent = AsyncCreatebleSelect
    } else {
      SelectComponent = CreatableSelect
    }
  }

  let classes = className

  if (options && options.required) {
    classes += ' required'
  }

  let width = 'w-64'

  const classNames = (className || '').split(' ')

  if (className) {
    const widthFound = classNames.find((c) => c.startsWith('w-'))

    if (widthFound) {
      width = widthFound
    }
  }

  const classNameWithoutWidth =
    (className && classNames.filter((c) => !c.startsWith('w-'))) || ''

  const dynamicProps = {}

  let realValue = undefined
  let realDefaultValue = undefined

  if (isMulti && value) {
    realValue = value
  } else if (value) {
    realValue = findValue(values, value)
  } else if (!value) {
    realValue = null
  }

  if (isMulti && defaultValue) {
    realDefaultValue = defaultValue
  } else if (defaultValue) {
    realDefaultValue = findValue(values, defaultValue)
  }

  // Set filter on label by default
  const filterOptionFn =
    filterOption ||
    useCallback(({ label /*, value, data*/ }, inputValue) => {
      if (inputValue && typeof inputValue === 'string') {
        return label.toLowerCase().indexOf(inputValue) >= 0
      }

      // by default show all entries
      if (!inputValue) return true

      return false
    }, [])

  let sortedOptions = values
  if (!avoidSorted) {
    sortedOptions = values.reduce((sortedOptions, option) => {
      if (option.hasOwnProperty('options')) {
        option.options.sort((a, b) => a.label.localeCompare(b.label))
      }
      sortedOptions.push(option)
      return sortedOptions
    }, [])

    sortedOptions = sortedOptions.sort((a, b) => a.label.localeCompare(b.label))
  }

  return (
    <label className={classes} htmlFor={name}>
      <span dangerouslySetInnerHTML={{ __html: label }} />
      <SelectComponent
        filterOption={filterOptionFn}
        noOptionsMessage={noOptionsMessage}
        ref={ref}
        isClearable={isClearable}
        isMulti={isMulti}
        placeholder={placeholder}
        defaultValue={realDefaultValue}
        name={name}
        isDisabled={disabled}
        className={`${classNameWithoutWidth} ${width}`}
        value={realValue}
        options={sortedOptions}
        loadOptions={loadOptions}
        label={label}
        formatOptionLabel={formatOptionLabel}
        onChange={(e) => onChange(e)}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        required={!!options && !!options.required}
        menuPlacement={menuPlacement || 'bottom'}
        onInputChange={onInputChange}
        formatCreateLabel={formatCreateLabel}
        defaultOptions={defaultOptions}
        loadingMessage={loadingMessage}
        isSearchable={isSearchable}
        {...dynamicProps}
      />
      {error ? (
        <span className="help-text error">{error}</span>
      ) : (
        <span className="help-text">{helpText}</span>
      )}

      {when && value && when[value] && (
        <div className="complement-input">{when[value]}</div>
      )}
    </label>
  )
})

import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import { useTranslation } from "react-i18next"
import Layout from 'components/Layout'
import Nursery from '../components/Nursery'
import useChildcare from 'hooks/useChildcare'
import { capitalize, timeToNumber } from '../lib/utils'
import Confirm from 'components/common/ConfirmModal'
import 'index.css'

export default function NurseryPage(props) {
  const history = useHistory()

  const {
    getChilds,
    getChildsCares,
    removeNurseryDate,
    getHolidays,
    getPublicHolidays,
  } = useChildcare()

  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const { t } = useTranslation()

  const [dates, setDates] = useState([])
  const [nurseryTimes, setNurseryTimes] = useState([])
  const [availableChildren, setAvailableChildren] = useState()
  const [times, setTimes] = useState()
  const [modifiers, setModifiers] = useState()
  const [nurserylist, setNurseryList] = useState()
  const [minDate, setMinDate] = useState()
  const [childrenList, setChildrenList] = useState()
  const [holidays, setHolidays] = useState([])
  const [publicHolidays, setPublicHolidays] = useState([])
  const [initialMonth, setInitialMonth] = useState()

  const updateDay = useCallback(
    (day, action = 'add') => {
      switch (action) {
        case 'add':
          if (Array.isArray(day)) {
            setDates([...dates, ...day])
          } else {
            setDates([...dates, day])
          }
          break
        case 'remove':
          if (Array.isArray(day)) {
            let datesToKeep = dates.filter(e => {
              if (day.find(d => d.getTime() === e.getTime())) {
                return false
              } else {
                return true
              }
            })
            setDates(datesToKeep)
          } else {
            setDates([...dates.filter((e) => e.getTime() !== day.getTime())])
          }
          break
        case 'clear':
          setDates([])
          break
        default:
      }
    },
    [dates]
  )

  const onSchoolingYearChange = useCallback(
    async (schoolingyear) => {
      if (
        (holidays.length > 0 && holidays[0].schoolingYear !== schoolingyear) ||
        holidays.length === 0
      ) {
        const date = await getHolidays(`${schoolingyear}`)
        const vacs = (date.results || []).map((e) => {
          const start = new Date(e.startDate)
          let end = new Date(e.endDate)
          if (start.getTime() === end.getTime()) {
            end.setMonth(end.getMonth() + 2)
          }
          return {
            start,
            end,
            description: e.description,
            schoolingYear: e.schoolingYear,
          }
        })
        setHolidays(vacs)
      }
    },
    // eslint-disable-next-line
    []
  )

  // Run on month change
  const loadChildCares = useCallback(
    async (date) => {
      let d
      if (dates.length === 0 && props.location?.state?.dates) {
        d = new Date(props.location.state.dates)
        delete props.location.state.dates
        setDates([])
      }
      else {
        d = new Date(date)
      }
      if (!initialMonth) {
        setInitialMonth(d)
      }
      const childCares = await getChildsCares(d)
      if (childCares.error) {
        setErrorMessage(t(childCares.error.message))
        history.replace('/dashboard')
        return
      }

      let temp = childCares.results.childcares.childCares.map((date) => {
        return new Date(date.date)
      })
      setNurseryList(childCares.results.childcares.childCares)

      if (childCares.results.childcares.hours) {
        let hours = childCares.results.childcares.hours.map((h) => {
          return { label: `${h.start}-${h.end}`, value: `${h.id}` }
        })

        setNurseryTimes(childCares.results.childcares.hours)
        setTimes(hours)
      }

      setModifiers({ highlighted: [...temp] })
      setMinDate(childCares.results.childcares.minDate)

      // If current day if < minDate, will request childcare of minDate

      if (new Date(childCares.results.childcares.minDate).getTime() > d.getTime() && !nurserylist) {
        loadChildCares(new Date(childCares.results.childcares.minDate))
        const d = new Date(childCares.results.childcares.minDate)
        setInitialMonth(new Date(d))
        return
      }

      // update children list in select component
      if (childrenList) {
        // need this for deep cloning value, otherwise, it'll affect childrenList data
        const cl = JSON.parse(JSON.stringify(childrenList))
        setAvailableChildren(
          cl.map((c) => ({
            label: `${capitalize(c.firstname)} ${capitalize(c.lastname)}`,
            value: c.id,
          }))
        )
      }
    },
    [
      childrenList,
      dates,
      getChildsCares,
      props.location,
      setErrorMessage,
      nurserylist,
      t,
      history,
      initialMonth,
    ]
  )

  useEffect(() => {
    async function load() {
      if (props.location.state?.dates) {
        loadChildCares(props.location.state.dates)
        // updateDay(props.location.state.dates)
      } else {
        // should set date to today's date, only if current date is in schooling year
        // otherwise, should set the first day of the schoolingyear
        // updateDay(new Date()) // select the today date
        // updateDay(new Date())
        loadChildCares(dates[0] || new Date())
      }
    }

    if (childrenList) {
      load()
    }
    // disable update and loadchildcares because it results to create useless request
    // eslint-disable-next-line
  }, [childrenList, props.location.state])

  const onDeleteNurseryDate = useCallback(
    async (datas, selectDate) => {
      if (datas.length === 0) return
      if (await Confirm(t("Êtes-vous sûr de vouloir supprimer ces réservations ?"))) {
        const childcare = await removeNurseryDate(datas)
        if (childcare.error) {
          setErrorMessage(t(childcare.error.message))
          return
        }

        if (childcare) {
          loadChildCares(new Date(selectDate))
          const totalDelete = childcare.results.childcare.filter(c => c !== null).length
          const totalRequest = childcare.results.childcare.length - totalDelete

          if (totalRequest === 0) {
            setSuccessMessage(t('Supprimé'))
          } else if (totalRequest > 0) {
            setSuccessMessage(t(`{{totalDelete}} supprimés, {{totalRequest }} n'étaient pas autorisés`, {
              totalDelete,
              totalRequest
            }))
          }
        }
      }

    },
    [loadChildCares, removeNurseryDate, setErrorMessage, setSuccessMessage, t]
  )

  const onYearChange = useCallback(
    async (year) => {
      const publicHolidays = await getPublicHolidays(year)
      setPublicHolidays(publicHolidays)
    },
    [getPublicHolidays]
  )

  // FIRST LOAD ONLY

  // Load children list having registration accepted
  // Need to storage all children in variable to filter 
  // Base on which schooling year is

  // Childs will be use in select component
  useEffect(
    () => {
      async function loadData() {
        try {
          const childs = await getChilds()
          if (childs.error) {
            throw new Error(childs.error.message)
          }
          const formatChildren = childs.results.childs.map(child => {
            return {
              label: `${capitalize(child.firstname)} ${capitalize(child.lastname)}`,
              value: child.id,
            }
          })
          setChildrenList(childs.results.childs)
          setAvailableChildren(formatChildren)
        } catch (err) {
          setErrorMessage(t(err.message))
        }
      }
      loadData()
    },
    // eslint-disable-next-line
    []
  )

  const onSubmit = useCallback(
    async (datas) => {
      const childsNursery = datas.childs.map((c) => {
        let child = availableChildren.find((d) => d.value === c.child)
        let [firstname, lastname] = child.label.split(' ')

        c.firstname = firstname
        c.lastname = lastname

        c.times.forEach((t) => {
          let time = nurseryTimes.find((n) => n.id === Number(t.value))
          t.start = time.start
          t.end = time.end
          t.duration = time.duration
        })

        return c
      })
      datas.childs = childsNursery

      datas.childs.forEach((child) => {
        child.childcare = child.times
      })
      const test = datas.date
        .map((date) => {
          return {
            date: date,
            children: datas.childs,
            // subTotalDuration: datas.childs.map(child => child.childcare.map(e => timeToNumber(e.duration))).reduce((a,b) => a+b)
            subTotalDuration: datas.childs
              .map((child) =>
                child.childcare
                  .map((childcare) => timeToNumber(childcare.duration))
                  .reduce((a, b) => a + b)
              )
              .reduce((a, b) => a + b),
          }
        })
        .sort((a, b) => a.date.getTime() - b.date.getTime())

      history.push('/validnursery', { datas: test })
    },
    // eslint-disable-next-line
    [availableChildren]
  )

  useEffect(() => {
    if (initialMonth) {
      if (initialMonth.getMonth() + 1 < 8) {
        onSchoolingYearChange(`${initialMonth.getFullYear() - 1}-${initialMonth.getFullYear()}`)
      } else if (initialMonth.getMonth() + 1 > 8) {
        onSchoolingYearChange(`${initialMonth.getFullYear()}-${initialMonth.getFullYear() + 1}`)
      }
    }
  }, [initialMonth, onSchoolingYearChange])

  return (
    <Layout buttonGoHome={{ to: 'dashboard', history }} title={t('Garderie')}>
      <Nursery
        onSubmit={onSubmit}
        onDeleteNurseryDate={onDeleteNurseryDate}
        onMonthChange={loadChildCares}
        onYearChange={onYearChange}
        onSchoolingYearChange={onSchoolingYearChange}
        modifiers={modifiers}
        childs={availableChildren}
        nurseryList={nurserylist}
        updateDay={updateDay}
        dates={dates}
        times={times}
        minDate={minDate}
        holidays={holidays}
        publicHolidays={publicHolidays}
        initialMonth={initialMonth}
      />
    </Layout>
  )
}

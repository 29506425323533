import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Input from 'components/form/Input'
import StepButtons from './common/StepButtons'
import Select from 'components/form/Select'
import WarningIcon from 'components/svg/warning'

import DndList from 'react-dnd-list'

export default function SetProgression({
  onSubmit,
  goBack,
  id,
  loading,
  getActivities,
  progression,
  defaultActivitiesProgression,
}) {
  const form = useForm()
  const {
    register,
    handleSubmit,
    errors,
    reset,
    watch,
    control,
    setValue,
  } = form

  const [formattedActivities, setFormattedActivities] = useState([])
  const [notBinded, setNotBinded] = useState([])

  const [list, setList] = useState([])
  const watchActivities = watch('activities') || []

  const search = useRef()

  const getActivitiesBySearch = useCallback(
    (text) => {
      clearTimeout(search.current)
      if (text && text.length > 2) {
        search.current = setTimeout(async () => {
          try {
            let filters = [['name', 'like', text]]
            const activities = await getActivities(0, 10, filters, null, true)

            let tmpNotBinded = notBinded
            activities.results.forEach(activity => {
              if (activity?.ActivitiesClasses.length === 0) {
                tmpNotBinded.push(activity.id)
              }
            })
            setNotBinded(tmpNotBinded)

            setFormattedActivities(
              activities.results.map(activity => {
                return {
                  label: activity.name,
                  value: activity.id,
                }
              })
            )
          } catch (err) {
            console.log('Impossible de récupérer les activités', err)
          }
        }, 500)
      }
    }, [getActivities, notBinded])

  useEffect(() => {
    if (loading.prevState === false && loading.current === false) {
      reset()
    }
  }, [loading, reset])

  useEffect(() => {
    if (defaultActivitiesProgression) {
      setValue('activities', defaultActivitiesProgression)
    }
  }, [defaultActivitiesProgression, progression, setValue])

  const Item = (props) => {
    const dnd = props.dnd
    let activityNotBinded = [...notBinded]

    if (progression) {
      progression.ActivitiesProgressions.forEach(ap => {
        if (ap.Activity?.ActivitiesClasses && ap?.Activity?.ActivitiesClasses.length === 0) {
          activityNotBinded.push(ap.Activity.id)
        }
      })
    }

    return (
      <div
        style={{ ...dnd.item.styles, ...dnd.handler.styles, userSelect: 'none', zIndex: 2 }}
        className={`${dnd.item.classes} p-2 border border-white w-full bg-gray-200 flex justify-evenly`}
        ref={dnd.item.ref}
        {...dnd.handler.listeners}
      >
        <div className="flex-1 flex justify-start items-center ">
          {props.item.label}
        </div>
        {
          activityNotBinded.includes(props.item.value) && (
            <div className="flex flex-col items-center justify-center p-2">
              <div>
                <WarningIcon />
              </div>
              <div>
                <small className="overflow-clip text-center">Lié à aucune classe</small>
              </div>
            </div>
          )}
      </div>
    )
  }

  useEffect(() => {
    setList(watchActivities)
  }, [watchActivities])

  useEffect(() => {
    let formatedList = list.map((item, index) => {
      return {
        ...item,
        num: index + 1
      }
    })
    setValue('orderedList', formatedList)

  }, [list, setValue])

  return (
    <>
      <div className="flex items-center justify-center mt-16">
        <form
          className="form-container w-full sm:w-full xl:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="font-black mb-4 text-center">
            {`${!isNaN(id) ? 'Modifier ' : 'Ajouter'} une progression`}
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Input
              key={'name'}
              ref={register({ required: 'Champ obligatoire' })}
              type="text"
              name="name"
              label="Nom de la progression"
              placeholder="Ex: Mathématiques"
              error={errors.name && errors.name.message}
              defaultValue={progression && progression?.name}
              value={watch('name')}
            />
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            <Controller
              name="activities"
              placeholder="Activités"
              rules={{
                validate: (datas) => {
                  if (datas && datas.length > 0) {
                    return true
                  } else {
                    return "Champ obligatoire"
                  }
                }
              }}
              control={control}
              render={({ value, name, onChange, defaultValue }) => (
                <div className="flex flex-col w-full">
                  <Select
                    name={name}
                    label="Activités"
                    className="w-full"
                    value={value}
                    onChange={e => {
                      if (e) {
                        onChange(e)
                      }
                    }}
                    onInputChange={e => getActivitiesBySearch(e)}
                    isMulti
                    values={formattedActivities}
                  />
                  {errors?.activities && (
                    <div className="text-sm text-red-500">
                      {errors?.activities.message}
                    </div>
                  )}
                </div>
              )}
            />
          </div>
          <div
            className="w-full"
            key={JSON.stringify(list)}
          >
            <Controller
              name="orderedList"
              rules={{ required: true }}
              control={control}
              render={() => (
                <DndList
                  items={list}
                  itemComponent={Item}
                  setList={setList}
                />
              )}
            />
          </div>
          <StepButtons
            loading={loading.current}
            goBack={goBack}
            nextLabel={'Enregistrer'}
          />
        </form>
      </div>
    </>
  )
}

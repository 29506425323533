import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import Select from 'components/form/Select'
import Button from './form/Button'
import CheckBox from './form/CheckBox'
import Spinner from 'components/common/Spinner'
import { MONTHS } from '../lib/constants'

export default function Export({
  schoolingyears,
  onExport,
  years
}) {

  const form = useForm()

  const {
    control,
    getValues
  } = form

  const order = [
    {
      label: 'Classe croissant',
      value: 'ASC'
    }
  ]

  const filter = [
    { label: 'Septembre', value: 'Septembre' },
    { label: 'Janvier', value: 'Janvier' },
  ]

  let months = []
  for (let i = 0; i < MONTHS.length; i++) {
    let num = i + 1 < 10 ? `0${i + 1}` : i + 1
    months.push(
      { label: `${num} - ${MONTHS[i]}`, value: i + 1 }
    )
  }

  return (
    <>
      <div className="flex w-full py-3 justify-center">
        <div className="w-1/2">
          <h3
            className="text-lg font-semibold text-gray-800 px-8"
          >
            Export
          </h3>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <form
          className="form-container w-full sm:w-full xl:w-1/2"
        >
          <div className="flex w-full md:flex-row flex-col justify-evenly">
            Factures
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly gap-3 mb-4">
            <div className="flex-1">
              <Controller
                control={control}
                name={`bills-schoolingyear`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Année scolaire'}
                      name={name}
                      className="w-full"
                      label="Année scolaire"
                      value={value}
                      values={schoolingyears}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className='flex justify-center items-end'>
              <Button
                onClick={() => onExport(getValues('bills-schoolingyear'), 'bills')}
              >
                Télécharger
              </Button>
            </div>
          </div>

          <div className="flex w-full md:flex-row flex-col justify-evenly">
            Inscriptions
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly gap-3 mb-4">
            <div className="flex-1">
              <Controller
                control={control}
                name={`registration-schoolingyear`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Année scolaire'}
                      name={name}
                      className="w-full"
                      label="Année scolaire"
                      value={value}
                      values={schoolingyears}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className='flex justify-center items-end'>
              <Button
                onClick={() => onExport(getValues('registration-schoolingyear'), 'registrations')}
              >
                Télécharger
              </Button>
            </div>
          </div>

          <div className="flex w-full md:flex-row flex-col justify-evenly">
            Parents
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly gap-3 mb-4">
            <div className="flex-1">
              <Controller
                control={control}
                name={`parents-schoolingyear`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Année scolaire'}
                      name={name}
                      className="w-full"
                      label="Année scolaire"
                      value={value}
                      values={schoolingyears}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className='flex justify-center items-end'>
              <Button
                onClick={() => onExport(getValues('parents-schoolingyear'), 'parents')}
              >
                Télécharger
              </Button>
            </div>
          </div>

          <div className="flex w-full md:flex-row flex-col justify-evenly">
            Enfants
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly gap-3">
            <div className="flex-1">
              <Controller
                control={control}
                name={`children-schoolingyear`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Année scolaire'}
                      name={name}
                      className="w-full"
                      label="Année scolaire"
                      value={value}
                      values={schoolingyears}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className="flex-1">
              <Controller
                control={control}
                name={`children-order`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Sans tri'}
                      name={name}
                      className="w-full"
                      label="Tri"
                      value={value}
                      values={order}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className="flex-2">
              <CheckBox
                key='city'
                form={form}
                control={control}
                name={'city'}
                label="Inclure l'adresse ?"
                reverse
              />
            </div>
            <div className='flex justify-center items-end'>
              <Button
                onClick={() => {
                  onExport(
                    getValues('children-schoolingyear'),
                    'children',
                    {
                      order: {
                        classes: getValues('children-order')?.value,
                      },
                      fields: {
                        city: getValues('city')
                      }
                    })
                }}
              >
                Télécharger
              </Button>
            </div>
          </div>

          <div className="flex w-full md:flex-row flex-col justify-evenly">
            Effectifs
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly gap-3 mb-4">
            <div className="flex-1">
              <Controller
                control={control}
                name={`headcount-schoolingyear`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Année scolaire'}
                      name={name}
                      className="w-full"
                      label="Année scolaire"
                      value={value}
                      values={schoolingyears}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className="flex-1">
              <Controller
                control={control}
                name={`headcount-filter`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Septembre'}
                      name={name}
                      className="w-full"
                      label="Mois"
                      value={value}
                      values={filter}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className='flex justify-center items-end'>
              <Button
                onClick={() => onExport(
                  getValues('headcount-schoolingyear'),
                  'headcount',
                  {
                    filter: {
                      month: getValues('headcount-filter')?.value
                    }
                  }
                )}
              >
                Télécharger
              </Button>
            </div>
          </div>


          <div className="flex w-full md:flex-row flex-col justify-evenly">
            Garderies
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly gap-3 mb-4">
            <div className="flex-1">
              <Controller
                control={control}
                name={`childcare-schoolingyear`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Année scolaire'}
                      name={name}
                      className="w-full"
                      label="Année scolaire"
                      value={value}
                      menuPlacement='auto'
                      values={years}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className="flex-1">
              <Controller
                control={control}
                name={`childcare-filter`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Septembre'}
                      name={name}
                      className="w-full"
                      label="Mois"
                      value={value}
                      values={months}
                      menuPlacement='top'
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className='flex justify-center items-end'>
              <Button
                onClick={() => onExport(
                  getValues('childcare-schoolingyear'),
                  'childcare',
                  {
                    filter: {
                      month: getValues('childcare-filter')?.value
                    }
                  }
                )}
              >
                Télécharger
              </Button>
            </div>
          </div>

          <div className="flex w-full md:flex-row flex-col justify-evenly mb-4">
            Présence en classe
          </div>
          <div className="text-sm">
            (Synthèse mensuel)
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly gap-3 mb-4">
            <div className="flex-1">
              <Controller
                control={control}
                name={`classpresence-month`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Année scolaire'}
                      name={name}
                      className="w-full"
                      label="Année scolaire"
                      value={value}
                      values={years}
                      menuPlacement='top'
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className="flex-1">
              <Controller
                control={control}
                name={`classpresence-month-filter`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Septembre'}
                      name={name}
                      className="w-full"
                      label="Mois"
                      value={value}
                      values={months}
                      menuPlacement='top'
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className='flex justify-center items-end'>
              <Button
                onClick={() => onExport(
                  getValues('classpresence-month'),
                  'classpresencemonth',
                  {
                    filter: {
                      month: getValues('classpresence-month-filter')?.value
                    }
                  }
                )}
              >
                Télécharger
              </Button>
            </div>
          </div>
          <div className="text-sm">
            (Détail mensuel)
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly gap-3 mb-4">
            <div className="flex-1">
              <Controller
                control={control}
                name={`classpresence-month-detailed`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Année scolaire'}
                      name={name}
                      className="w-full"
                      label="Année scolaire"
                      value={value}
                      values={years}
                      menuPlacement='top'
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className="flex-1">
              <Controller
                control={control}
                name={`classpresence-month-filter-detailed`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Septembre'}
                      name={name}
                      className="w-full"
                      label="Mois"
                      value={value}
                      values={months}
                      menuPlacement='top'
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className='flex justify-center items-end'>
              <Button
                onClick={() => onExport(
                  getValues('classpresence-month-detailed'),
                  'classpresencemonthdetailed',
                  {
                    filter: {
                      month: getValues('classpresence-month-filter-detailed')?.value
                    }
                  }
                )}
              >
                Télécharger
              </Button>
            </div>
          </div>
          <div className='text-sm'>
            (Synthèse annuel)
          </div>
          <div className="flex w-full md:flex-row flex-col justify-evenly gap-3 mb-4">
            <div className="flex-1">
              <Controller
                control={control}
                name={`classpresence-year`}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      placeholder={'Année scolaire'}
                      name={name}
                      className="w-full"
                      label="Année scolaire"
                      menuPlacement='top'
                      value={value}
                      values={years}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      noOptionsMessage={() => (
                        <div className="flex justify-center pb-2">
                          <Spinner></Spinner>
                        </div>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className='flex justify-center items-end'>
              <Button
                onClick={() => onExport(getValues('classpresence-year'), 'classpresenceyear')}
              >
                Télécharger
              </Button>
            </div>
          </div>


        </form>
      </div>
    </>
  )
}

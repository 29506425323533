import React from 'react'
import ReactDOM from 'react-dom'
import Modal from './Modal'
import { Controller } from 'react-hook-form'
import Select from 'components/form/Select'

import { MONTHS } from 'lib/constants'

const confirmRoot = document.createElement('div')

export default ({ title, form, cancel }) => {
  const monthList = MONTHS.map((month, index) => {
    return {
      label: month,
      value: index + 1,
    }
  })

  const yearList = [
    {
      label: '2021',
      value: '2021',
    },
    {
      label: '2022',
      value: '2022',
    },
    {
      label: '2023',
      value: '2023',
    },
    {
      label: '2024',
      value: '2024',
    },
    {
      label: '2025',
      value: '2025',
    },
    {
      label: '2026',
      value: '2026',
    },
  ]

  return new Promise((resolve) => {
    const confirm = (answer) => {
      ReactDOM.unmountComponentAtNode(confirmRoot)
      resolve(answer)
    }

    ReactDOM.render(
      <Modal
        title={title || 'Liste des factures'}
        responsive
        large
        isOpen={true}
        onClose={() => confirm(false)}
        allowScroll={true}
      >
        <div className="flex justify-center text-orange-600"></div>
        <div className="flex w-full justify-center flex-col">
          <form>
            <div>
              <Controller
                name="year"
                control={form.control}
                render={({ name, value, defaultValue, onChange }) => (
                  <>
                    <Select
                      name={name}
                      value={value}
                      values={yearList}
                      className="w-full"
                      placeholder="Choisissez une année"
                      defaultValue={defaultValue}
                      isClearable={false}
                      avoidSorted={true}
                      onChange={(e) => {
                        onChange(e)
                      }}
                    />
                  </>
                )}
              />
            </div>
            <div className="flex justify-center mt-8 flex-col gap-2">
              <Controller
                name="month"
                control={form.control}
                render={({ name, value, defaultValue, onChange }) => (
                  <>
                    <Select
                      name={name}
                      value={value}
                      values={monthList}
                      className="w-full"
                      placeholder="Choisissez un mois"
                      defaultValue={defaultValue}
                      isClearable={false}
                      avoidSorted={true}
                      onChange={(e) => {
                        onChange(e)
                      }}
                    />
                  </>
                )}
              />
              <div className="flex w-full justify-evenly">
                <div
                  className="py-2 px-4 rounded-sm bg-red-500 text-white cursor-pointer"
                  onClick={() => confirm(false)}
                >
                  {cancel || 'Annuler'}
                </div>
                <div
                  className="py-2 px-4 rounded-sm bg-green-500 text-white cursor-pointer"
                  onClick={() =>
                    confirm({
                      year: Number(form.watch('year')?.value),
                      month: form.watch('month')?.value,
                    })
                  }
                >
                  Valider
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>,
      confirmRoot
    )
  })
}

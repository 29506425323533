import { useMemo } from 'react'
import { useUser } from 'stores/user'
import Api from 'lib/api'

export default () => {
  const { token } = useUser(({ token }) => ({ token }))

  return useMemo(
    () => ({
      async getChilds() {
        try {
          const childs = await Api.getChilds(token)
          return childs
        } catch (err) {
          return { error: err }
        }
      },
      async setChildCares(datas) {
        try {
          const childcares = await Api.setChildCares({ datas, token })
          return childcares
        } catch (err) {
          return { error: err }
        }
      },
      async getChildsCares(date) {
        try {
          const childcares = await Api.getChildsCares({ token, date })
          return childcares
        } catch (err) {
          return { error: err }
        }
      },
      async removeNurseryDate(props) {
        try {
          const childcare = await Api.removeNurseryDate({ token, props })
          return childcare
        } catch (err) {
          return { error: err }
        }
      },
      async getOnePricingsGrid(id) {
        try {
          const pricingGrid = await Api.getOnePricingsGrid(token, id, {
            query: { populate: true },
          })
          return pricingGrid
        } catch (err) {
          return { error: err }
        }
      },
      async childCaresPresent(present) {
        try {
          const childcare = await Api.childCaresPresent({ token, present })
          return childcare
        } catch (err) {
          return { error: err }
        }
      },
      async getNurseryPrice(date) {
        try {
          const nurseryPrice = await Api.getNurseryPrice(token, date)
          return nurseryPrice
        } catch (err) {
          return { error: err }
        }
      },
      async getChildsCaresSummary(year, month) {
        try {
          const nurseryList = await Api.getChildsCaresSummary({
            token,
            month,
            year,
          })
          return nurseryList
        } catch (err) {
          return { error: err }
        }
      },
      async getChildcaresMonth() {
        try {
          const dates = await Api.getChildcaresMonth({ token })
          return dates
        } catch (err) {
          return { error: err }
        }
      },
      async deletePricingGrid(id) {
        try {
          const grid = await Api.deletePricingGrid({ token, id })
          return grid
        } catch (err) {
          return { error: err }
        }
      },
      async getPublicHolidays(year) {
        try {
          const holidays = await Api.getPublicHolidays(year)
          return holidays
        } catch (err) {
          return { error: err }
        }
      },
      async getHolidays(year) {
        try {
          const holidays = await Api.getHolidays(year)
          return holidays
        } catch (err) {
          return { error: err }
        }
      },
      async childcareExport({ year, filter}){
        try{  
          const childcares = await Api.childcareExport({token, query: { ...filter, year}})
          return childcares
        }catch(err){
          return { error: err}
        }
      },
      async getChilrenRegistered(date){
        try{
          const children = await Api.getChilrenRegistered({ token, date})
          return children
        }catch(err){
          return { error: err}
        }
      },
      async deleteNurseryById(id){
        try{
          const childcare = await Api.deleteNurseryById({ token, id})
          return childcare
        }catch(err){
          return { error: err}
        }
      },
      async updateSlot({...params}){
        try{
          const slot = await Api.updateSlot({ token, params })
          return slot
        }catch(err){
          return { error: err}
        }

      }
    }),
    [token]
  )
}

import React from 'react'
import { useLocation } from 'react-router-dom'
import SubDomainList from 'components/registrations/SubDomainList'
import LayoutAdmin from '../components/LayoutAdmin'
import { USER_ROLE } from "../lib/constants"

export default function AdminPage(props) {
  const location = useLocation()

  let isEmployee = props.role === USER_ROLE.EMPLOYEE ? true : false

  return (
    <div>
      <LayoutAdmin location={location} isHome={!isEmployee} hasBack={isEmployee}>
        <SubDomainList />
      </LayoutAdmin>
    </div>
  )
}

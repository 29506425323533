import React, { useCallback } from "react"
import { useNotify } from "stores/notify"

const Notify = () => {
  const { message, setMessage } = useNotify(({ message, setMessage }) => ({
    message,
    setMessage,
  }))
  const reset = useCallback(() => {
    setMessage(null)
  }, [setMessage])

  if (message) {
    setTimeout(() => {
      setMessage(null)
    }, 3000)
  }

  return (
    <>
      {message ? (
        <div
          onClick={reset}
          className={`z-50 notify notify-${message.level}`}
          role="alert"
        >
          <div>{message.text}</div>
          <button className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-2 mr-4 outline-none focus:outline-none">
            <span>×</span>
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Notify

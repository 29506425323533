import React from 'react'
import ReactDOM from 'react-dom'
import './tailwind.output.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import StackdriverErrorReporter from 'stackdriver-errors-js'

import { initialize } from 'state'

const errorHandler = new StackdriverErrorReporter()
errorHandler.start({
  key: process.env.REACT_APP_GCP_ERROR_REPORTING_KEY,
  projectId: process.env.REACT_APP_GCP_PROJECT_ID,
  service: 'montessori-front',
})

initialize()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import * as React from 'react'

function SvgCheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M80.879 24.977a4.987 4.987 0 00-3.438 1.5L40.537 63.383l-18.5-14.312a5 5 0 10-6.125 7.906l22 17a5.006 5.006 0 006.593-.406l40-40a5 5 0 00-3.625-8.594z" />
    </svg>
  )
}

export default SvgCheck

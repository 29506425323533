import React, { useState, useEffect } from 'react'
import Bill from '../components/Bill'
import Layout from 'components/Layout'
import LayoutAdmin from 'components/LayoutAdmin'
import { useHistory, useLocation } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import StepButtons from 'components/common/StepButtons'
import { useTranslation } from 'react-i18next'
import useBill from 'hooks/useBill'
import { BILL_STATUS, USER_ROLE } from '../lib/constants'
import Confirm from '../components/common/ConfirmModal'

export default function BillPage(props) {
  const history = useHistory()
  const location = useLocation()
  const { setSuccessMessage, setErrorMessage } = useNotify(
    ({ setSuccessMessage, setErrorMessage }) => ({
      setSuccessMessage,
      setErrorMessage,
    })
  )

  const [bill, setBill] = useState()
  const [role, setRole] = useState()
  const [registrationId, setRegistrationId] = useState()
  const [editCreditNote, setEditCreditNote] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [recreate, setRecreate] = useState(false)

  const {
    payBill,
    getBill,
    updateBill,
    createCreditNote,
    reCreateBill,
  } = useBill()
  const { t } = useTranslation()

  useEffect(() => {
    async function loadDatas(id) {
      try {
        const bill = await getBill(id)
        setBill(bill.results)
        setRole(props.role)
      } catch (err) {
        setErrorMessage(t(err.message))
        history.replace('/dashboard')
        return
      }
    }
    try {
      const { bill, registrationId, recreate = false } = location.state
      setRecreate(recreate)

      if (registrationId) {
        setRegistrationId(registrationId)
      }
      if (bill.id) {
        loadDatas(bill.id)
      }
    } catch (err) {
      setErrorMessage(
        t('Impossible de récupérer les informations de cette facture')
      )
      return
    }
  }, [getBill, location.state, props.role, setErrorMessage, t, history])

  const onPayBill = async () => {
    try {
      const pay = await payBill(bill.id)
      if (pay.error) {
        setErrorMessage(t(pay.error))
        return
      }

      if (pay.results && pay.results.url) {
        window.location = pay.results.url
      }
    } catch (err) {
      setErrorMessage(t(err.message))
    }
  }

  const onSubmit = async (datas) => {
    setIsLoading(true)
    try {
      datas.originalType = bill?.type
      datas.companyId = bill?.BillUsers[0].CompanyId
      datas.originalBillId = bill?.id

      if (recreate) {
        datas.quantity = datas.qte
        delete datas.qte
        const newBill = await reCreateBill(registrationId, bill?.type, datas)
        if (newBill.results) {
          setSuccessMessage('Facture créée')
          setIsLoading(false)
          history.push('/bills')
        }
      } else {
        const creditnote = await createCreditNote(datas, registrationId)
        if (creditnote.results) {
          setSuccessMessage(t('Avoir créé'))
          const bill = await getBill(creditnote.results.billItem.BillId)
          setBill(bill.results)
          setEditCreditNote(false)
          setIsLoading(false)
        }
      }
    } catch (err) {
      setErrorMessage(t('Impossible de créer le document'))
      setIsLoading(false)
    }
  }

  const toggleCreditNoteCreation = () => {
    setEditCreditNote(!editCreditNote)
  }

  const onSubmitEditing = async (body) => {
    try {
      const updatedBill = await updateBill(bill.id, { ...body })
      if (updatedBill.error) {
        setErrorMessage(t(updatedBill.error.message))
        return
      }
      setBill(updatedBill.results)
      setSuccessMessage(t('Modification effectuée'))
    } catch (err) {
      setErrorMessage(t(err.message))
    }
  }

  const onEditingCreditNote = (billChange) => {
    setBill({ ...bill, ...billChange })
  }

  const onCancelBill = async () => {
    if (await Confirm("Êtes-vous sûr d'annuler cette facture ?")) {
      try {
        const cancelBill = await updateBill(bill?.id, {
          status: BILL_STATUS.cancel,
        })
        if (cancelBill.results) {
          setSuccessMessage('La facture à été annulée')
          setBill(cancelBill.results)
        }
      } catch (err) {
        console.log('err', err)
        setErrorMessage("Impossible d'annuler la facture")
      }
    }
  }

  const onRecreate = () => {
    setRecreate(!recreate)
  }
  return (
    <>
      {role === USER_ROLE.ADMIN ? (
        <LayoutAdmin
          location={location}
          buttonGoHome={{ to: '/bills', history }}
        >
          {bill && (
            <>
              <Bill
                bill={bill}
                creditNote={editCreditNote}
                onCreateCreditNote={onSubmit}
                role={role}
                onSubmitEditing={
                  editCreditNote ? onEditingCreditNote : onSubmitEditing
                }
                isLoading={isLoading}
                onCancelBill={onCancelBill}
                recreate={recreate}
                onRecreate={onRecreate}
              />
              <div className="flex w-full justify-center align-center px-4">
                <div className="flex xl:w-1/2 sm:w-full w-full flex-col justify-center items-center">
                  {!editCreditNote ? (
                    <StepButtons
                      nextLabel={
                        !recreate
                          ? 'Créer un avoir pour cette facture'
                          : 'Recréer la facture'
                      }
                      onNext={toggleCreditNoteCreation}
                      goBack={() => history.goBack()}
                    />
                  ) : (
                    <StepButtons
                      variant="danger"
                      nextLabel={
                        !recreate
                          ? "Annuler la création de l'avoir"
                          : 'Annuler la création de la facture  '
                      }
                      onNext={() => toggleCreditNoteCreation()}
                      goBack={() => history.goBack()}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </LayoutAdmin>
      ) : (
        <Layout
          buttonGoHome={{ to: '/dashboard', history }}
          title={t('Facture')}
        >
          <Bill bill={bill} onPayBill={onPayBill} />
        </Layout>
      )}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import Spinner from './common/Spinner'
import StepButtons from './common/StepButtons'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Input from './form/Input'
import { useForm } from 'react-hook-form'
import Button from './form/Button'
import ActivityCard from './ActivityCard'
import Radio from './form/Radio'

export default function ChildValidation({
  loading,
  child,
  categories,
  onCategorySelected,
  onValidateSkill,
  onSearch,
  onClickLogBook,
  levels,
  setSearchFilter,
  isCardLoading,
}) {

  const history = useHistory()
  const form = useForm()
  const { register, watch } = form

  const [filters, setFilters] = useState([])
  const [noActivityFound, setNoActivityFound] = useState(null)

  const activitySearch = watch('activitysearch')
  const filterWatch = watch('filter')

  useEffect(() => {
    if (activitySearch || activitySearch === '') {
      onSearch(activitySearch)
    }
    //eslint-disable-next-line
  }, [activitySearch])


  useEffect(() => {
    if (levels && levels.length > 0) {
      let f = []
      f.push({ label: "Fait", value: "done" })
      f.push({ label: "Non fait", value: "notyet" })
      f.push(...levels.map(l => {
        return {
          label: l.name, value: l.name
        }
      })
      )
      f.push({ label: "", value: '' })
      f.push({ label: "Peut présenter", value: "canPresent" })
      f.push({ label: "Recommandé", value: "recommanded" })
      setFilters(f)
    }
  }, [levels])

  useEffect(() => {
    if (filterWatch) {
      setSearchFilter(filterWatch)
    }
  }, [filterWatch, setSearchFilter])

  useEffect(() => {
    if (activitySearch || filterWatch) {
      let hasActivity = categories.some((category) => {
        return category?.Activities?.length > 0
      })
      setNoActivityFound(!hasActivity)
    }
    //eslint-disable-next-line
  }, [categories])

  return (
    <>
      <div className="flex items-center justify-center flex-col w-full mt-8 ">
        <div
          className="flex flex-col w-full md:w-3/4 items-center"
        >
          <div className="w-full px-8">
            <StepButtons
              goBack={() => history.goBack()}
              hideNext
            />
          </div>
          {child && (
            <>
              <div className="text-lg m-4">
                {child.firstname} {child.lastname}
              </div>
              {!child?.all && categories.length > 0 && (
                <>
                  <div className="flex gap-2 py-2">
                    <Button variant="secondary" onClick={() => onClickLogBook('logbook')}>Carnet de suivi</Button>
                  </div>
                </>
              )}
            </>
          )}
          <div className="form-container w-full">
            <div className="flex w-full md:flex-row flex-col justify-evenly">
              <Input
                ref={register()}
                type="text"
                name="activitysearch"
                label="Rechercher une activité"
                placeholder="Ex: verbe"
              />
            </div>
            {child?.all && (
              <Button
                variant="primary"
                onClick={() => history.push('/setactivity/new')}
              >
                Créer une nouvelle activité
              </Button>
            )}
            {!child?.all && (
              <div className="w-full p-4">
                <Radio
                  input={{ label: "Filtre " }}
                  inputs={filters}
                  form={form}
                  name="filter"
                  label={'Filtre : '}
                  customClass={"pr-0 w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2"}
                  customLabel={' '}
                />
              </div>
            )}
            {categories && categories.length > 0 && (
              <>
                {loading && (
                  <Spinner></Spinner>
                )}
                {noActivityFound ? (
                  <div className="p-2 bg-orange-100 text-orange-700 w-full px-8">
                    Aucune activité n'a été trouvé
                  </div>
                ) : (

                  <div className="flex flex-col w-full pb-1 bg-gray-300 mt-4">
                    {categories.map(category => {
                      let [first, second] = category.color.split(':')
                      // hide category if there's no activities
                      return (
                        <div
                          key={`categorie-${category.id}`}
                        >
                          {((category?.Activities && category.Activities.length > 0) || !category.hasOwnProperty('Activities')) && (
                            <div
                              style={{ background: `white` }}
                              className="flex flex-col px-1 gap-1 mt-1"
                            >
                              <div className="flex items-center">
                                <div
                                  style={{
                                    display: 'block',
                                    height: "25px",
                                    width: "25px",
                                    background: `linear-gradient(-45deg, #${first} 50%, #${second || first} 50%)`
                                  }}
                                  className="border border-black"
                                ></div>
                                <div
                                  className="w-full p-1 text-lg cursor-pointer"
                                  onClick={() => {
                                    onCategorySelected(category.id)
                                  }}
                                >
                                  {category.name}
                                </div>
                              </div>
                              {category?.Activities && category?.shouldDisplay && (
                                <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-rows-1 gap-2 px-2 py-4">
                                  {category.Activities.length === 0 ? (
                                    <div className="p-2 bg-orange-100 text-orange-700 font-medium">
                                      Aucun résultat
                                    </div>
                                  ) :

                                    category.Activities.map(activity => {
                                      let hasActivityDone
                                      if (child?.all) {
                                        hasActivityDone = activity?.ChildrenActivities && activity?.ChildrenActivities.length >= child.all.length && true
                                      } else {
                                        hasActivityDone = activity?.ChildrenActivities && activity?.ChildrenActivities.length > 0 && true
                                      }
                                      const level = activity?.ChildrenActivities && activity?.ChildrenActivities[0]?.ActivityLevel?.color
                                      return (
                                        <div key={activity.id} className="grid ">
                                          <ActivityCard
                                            hasActivityDone={hasActivityDone}
                                            level={level}
                                            onValidateSkill={onValidateSkill}
                                            activity={activity}
                                            category={category}
                                            activityName={activity.name}
                                            all={child?.entities ? true : false}
                                            isCardLoading={isCardLoading && isCardLoading === activity.id}
                                          >
                                          </ActivityCard>
                                        </div>
                                      )
                                    }
                                    )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )
                    }
                    )}
                  </div>
                )}
              </>
            )}
            {!loading && categories && categories.length === 0 && (
              <div className="p-2 bg-orange-100 text-orange-700 w-full px-8">
                Il n'y a aucune catégorie associée à cette classe
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

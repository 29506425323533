import * as React from "react"

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 200" {...props} width="1em" height="1em">
      <g transform="translate(0 -852.36)">
        <rect ry={0} height={200} width={97} y={852.36} fill="#0000be" />
        <path
          d="M198 852.36h102v200H198v-200z"
          fill="#ec0000"
          fillOpacity={0.982}
        />
      </g>
    </svg>
  )
}

export default SvgComponent

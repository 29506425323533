import React from 'react'
import ReactDOM from 'react-dom'
import Modal from './Modal'

const confirmRoot = document.createElement('div')

export default (question, cancel) => {
  return new Promise((resolve) => {
    const confirm = (answer) => {
      ReactDOM.unmountComponentAtNode(confirmRoot)
      resolve(answer)
    }

    ReactDOM.render(
      <Modal
        title={question}
        responsive
        isOpen={true}
        onClose={() => confirm(false)}
        allowScroll={true}
      >
        <div>
          <div className="flex justify-end mt-8">
            <div
              className="py-2 px-4 rounded-sm bg-gray-700 text-white cursor-pointer"
              onClick={() => confirm(false)}
            >
              { cancel || "Annuler" }
            </div>
            <div
              className="ml-2 p-2 px-4 rounded-sm bg-red-500 text-white cursor-pointer"
              onClick={() => confirm(true)}
            >
              Ok
            </div>
          </div>
        </div>
      </Modal>,
      confirmRoot
    )
  })
}

import React, { useState, useEffect, useCallback } from 'react'
import SetActivity from 'components/SetActivity'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import LayoutAdmin from 'components/LayoutAdmin'
import useActivity from 'hooks/useActivity'
import useRegistration from 'hooks/useRegistration'
import { isFormChanged } from 'lib/utils'
import useFiles from 'hooks/useFiles'

export default function StActivityPage() {
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()

  const {
    getActivity,
    // getCategoriesCurrent was used to get the current categories but remove because produce error 500 by sorting the categories by date and it was not neccessary
    getCategories, // replace getCategoriesCurrent
    getActivityImage,
    deleteActivityImage,
    downloadActivityImage,
    searchNationalLabel,
    getSubDomains,
  } = useActivity()
  const { getSchoolClasses } = useRegistration()

  const { updateActivityClasses } = useFiles()

  const [activity, setActivity] = useState()
  const [loading, setLoading] = useState({ prevState: null, current: false })
  const [schoolClasses, setSchoolClasses] = useState([])
  const [categories, setCategories] = useState([])
  const [subdomains, setSubdomains] = useState([])
  const [image, setImage] = useState(null)

  const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
      setWarningMessage,
    })
  )

  useEffect(() => {
    async function loadDatas(newElt = null) {
      try {
        if (!newElt) {
          const activity = await getActivity(id)
          if (activity.error) {
            setErrorMessage(activity.error?.message)
            return
          }
          setActivity(activity.results)
        }
      } catch (err) {
        setErrorMessage(`Service indisponible, ${err.message}`)
      }
    }
    if (!isNaN(Number(id))) {
      loadDatas()
    } else if (id === 'new') {
      loadDatas('new')
    } else {
      setErrorMessage('Impossible de trouver cette activité')
    }
    // eslint-disable-next-line
  }, [])

  // load schoolClasses
  const loadSchoolClasses = useCallback(async () => {
    try {
      const schoolClasses = await getSchoolClasses()
      setSchoolClasses(schoolClasses.results)
    } catch (err) {
      setErrorMessage('Impossible de charger les classes')
    }
  }, [getSchoolClasses, setErrorMessage])

  // load categories
  const loadCategories = useCallback(async () => {
    try {
      const categories = await getCategories()
      let formatCategories = categories.results.map((category) => ({
        label: category.name,
        value: category.id,
      }))
      setCategories(formatCategories)
    } catch (err) {
      setErrorMessage('Impossible de charger les catégories')
    }

    //eslint-disable-next-line
  }, [setErrorMessage])

  const loadSubdomains = useCallback(async () => {
    try {
      const subdomains = await getSubDomains()
      let formatSubdomains = subdomains.results.map((subdomain) => ({
        label: subdomain.name,
        value: subdomain.id,
      }))
      setSubdomains(formatSubdomains)
    } catch (err) {
      setErrorMessage('Impossible de charger les sous-domaines')
    }

    //eslint-disable-next-line
  }, [setErrorMessage])
  // load image
  const loadImage = useCallback(
    async (id) => {
      if (id === 'new') {
        setImage(false)
        return
      }
      if (activity) {
        try {
          const imageFromBack = await getActivityImage(activity.FileId)
          setImage({
            path: imageFromBack.results.path,
            type: imageFromBack.results.type,
            name: imageFromBack.results.file.name,
            id: imageFromBack.results.file.id,
          })
        } catch (err) {
          setErrorMessage("Impossible de charger l'image de l'activité")
        }
      }
    },
    //eslint-disable-next-line
    [activity]
  )

  const deleteImage = useCallback(async () => {
    try {
      const image = await deleteActivityImage(activity.id)
      if (image.results) {
        setSuccessMessage("L'image à été supprimé")
        setImage(false)
      }
    } catch (err) {
      setErrorMessage('Impossible de supprimer le fichier')
    }
  }, [activity, deleteActivityImage, setErrorMessage, setSuccessMessage])

  useEffect(() => {
    loadSchoolClasses()
    loadCategories()
    loadSubdomains()
  }, [loadSchoolClasses, loadCategories, loadSubdomains])

  useEffect(() => {
    if (activity && activity.FileId) {
      loadImage(id)
    } else {
      setImage(false)
    }
  }, [activity, id, loadImage])
  // ICI
  const onDownloadFile = useCallback(
    async (file) => {
      try {
        file = await downloadActivityImage(file.id)
        if (file?.error) {
          setErrorMessage(file.error.message)
          return
        }
      } catch (err) {
        setErrorMessage('Impossible de télécharger le fichier')
      }
    },
    [setErrorMessage, downloadActivityImage]
  )

  const goBack = () => {
    history.goBack()
  }
  const onSubmit = async (datas) => {
    if (!datas.activityImage) {
      datas.activityImage = {}
    }

    if (datas.nationalLabel?.label) {
      datas.nationalLabel = datas.nationalLabel.label
    }

    if (datas.subdomain) datas.SubDomainId = datas.subdomain.value

    setLoading({ prevState: loading.current, current: true })
    // datas.image = "no.jpg"

    if (!isNaN(Number(id))) {
      // check if classes changed
      let datasSchoolClass = {}
      datas.schoolClasses.forEach((s) => {
        datasSchoolClass[s.value] = s.value
      })

      let activitySchoolClass = {}
      activity.ActivitiesClasses.forEach((s) => {
        activitySchoolClass[s.SchoolClassId] = s.SchoolClassId
      })

      // check if categories changed
      let datasCategories = {}
      let activityCategories = {}
      datas.category.forEach((cat) => {
        datasCategories[cat.value] = cat.value
      })

      activity.CategoriesActivities.forEach((cat) => {
        activityCategories[cat.CategoryId] = cat.CategoryId
      })

      if (
        isFormChanged(
          {
            activityName: datas.name,
            ...datasSchoolClass,
            ...datasCategories,
            ...datas.activityImage[0],
            nationalLabel: datas.nationalLabel,
            SubDomainId: datas.subdomain,
          },
          {
            activityName: activity.name,
            ...activitySchoolClass,
            ...activityCategories,
            ...image,
            nationalLabel: activity.nationalLabel,
            SubDomainId: activity.SubDomainId,
          }
        )
      ) {
        datas.id = activity.id
        let newActivity = await updateActivityClasses(datas, id)
        if (newActivity.error) {
          setErrorMessage(newActivity.error.message)
          setLoading({ prevState: loading.current, current: false })
          return
        }
        setActivity(newActivity.results)
        setSuccessMessage('Activité mise à jour')
        setImage(false)
        setLoading({ prevState: loading.current, current: false })
      } else {
        setWarningMessage("Rien n'a été modifié")
        setLoading({ prevState: loading.current, current: false })
      }
    } else {
      datas.id = 'new'
      let activity = await updateActivityClasses(datas)
      if (activity.error) {
        setErrorMessage(activity.error.message)
        setLoading({ prevState: loading.current, current: false })
        return
      }
      setSuccessMessage('Activité créée')
      setImage(false)
      setLoading({ prevState: loading.current, current: false })
    }
  }

  return (
    <LayoutAdmin location={location} title="Activités" hasBack>
      <SetActivity
        onSubmit={onSubmit}
        goBack={goBack}
        activity={activity}
        id={id}
        loading={loading}
        schoolClasses={schoolClasses}
        categories={categories}
        subdomains={subdomains}
        image={image}
        onDownloadFile={onDownloadFile}
        onDeleteFile={deleteImage}
        searchNationalLabel={searchNationalLabel}
      />
    </LayoutAdmin>
  )
}

import React, { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useNotify } from '../stores/notify'
import LayoutAdmin from 'components/LayoutAdmin'
import useChildcare from 'hooks/useChildcare'
import useSchoolingYear from 'hooks/useSchoolingYear'
import ClassPresence from 'components/ClassPresence'
import 'index.css'
import { whichSchoolingYear } from 'lib/utils'
import { useTranslation } from 'react-i18next'

export default function ClassPresencePage() {
  const location = useHistory()
  const {
    getPublicHolidays,
    getHolidays,
  } = useChildcare()

  const {
    getChildrenBySchoolClass,
    setChildClassPresence,
    getChildrenPresence,
  } = useSchoolingYear()

  const { setErrorMessage, setSuccessMessage, setWarningMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage, setWarningMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
      setWarningMessage,
    })
  )
    const { t } = useTranslation()

  const [modifiers, setModifiers] = useState()
  const [presences, setPresences] = useState([])
  const [children, setChildren] = useState([])
  const [childrenSchoolClass, setChildrenSchoolClass] = useState([])
  const [publicHolidays, setPublicHolidays] = useState([])
  const [holidays, setHolidays] = useState([])
  const [loading, setLoading] = useState(false)

  // Load presence already saved :
  // on changing month
  // on first load
  // after submit
  const loadChildrenPresence = useCallback(async (date) => {
    try {
      const presences = await getChildrenPresence({ date })
      if (presences?.error) {
        throw new Error(presences.error)
      }
      if (presences.results) {
        setPresences(presences.results)

        setModifiers(
          {
            highlighted: presences.results.map(p => {
              return new Date(p.date)
            })
          }
        )
      }
    } catch (err) {
      setErrorMessage(err.message)
    }
    // eslint-disable-next-line
  }, [children, childrenSchoolClass])

  const onSchoolingYearChange = async (date) => {
    try {
      let year = date.split('-')[0]

      // children
      const children = await getChildrenBySchoolClass({ year: year })
      if (children.error) {
        setErrorMessage(children.error.message)
        return
      }
      if (children.results.length === 0) {
        setWarningMessage("Il n'y a pas d'enfants inscrit sur cette année scolaire")
        setChildren([])
      } else {
        setChildrenSchoolClass(children.results.map(c => {
          return { childId: c.id, schoolClass: c.Registrations[0].SchoolClass.id, className: c.Registrations[0].SchoolClass.name }
        }))
        setChildren(children.results.map(child => {
          return {
            lastname: child.lastname,
            firstname: child.firstname,
            id: child.id,
            schoolClassName: child.Registrations[0].SchoolClass.name,
            schoolClassId: child.Registrations[0].SchoolClass.id,
          }
        }))
      }

      // holidays
      const h = await getHolidays(date)
      const vacs = (h.results || []).map((e) => {
        const start = new Date(e.startDate)
        let end = new Date(e.endDate)
        if (start.getTime() === end.getTime()) {
          end.setMonth(end.getMonth() + 2)
        }
        return {
          start,
          end,
          description: e.description,
          schoolingYear: e.schoolingYear,
        }
      })
      setHolidays(vacs)

      // public holiday
      if (publicHolidays.length === 0) {
        onYearChange(year)
      }

    } catch (err) {
      setErrorMessage("Impossible de charger la liste des enfants")
    }
  }

  // first load only
  useEffect(() => {
    const date = whichSchoolingYear(new Date())
    onSchoolingYearChange(`${date.current}-${date.next}`)
    loadChildrenPresence(new Date())
    //eslint-disable-next-line
  }, [])


  const onSubmit = async (datas, selectedDate) => {
    delete datas['class-selected']
    setLoading(true)
    let morning = { childrenList: {}, reason: {} }
    let afternoon = { childrenList: {}, reason: {} }

    Object.keys(datas).forEach(d => {
      if (d.match(/morning/)) {
        if (d.match(/reason/)) {
          morning.reason[d] = datas[d]
        } else {
          morning.childrenList[d] = datas[d]
        }
      } else {
        if (d.match(/reason/)) {
          afternoon.reason[d] = datas[d]
        } else {
          afternoon.childrenList[d] = datas[d]
        }
      }
    })

    try {
      const presence = await setChildClassPresence({
        date: selectedDate,
        morning,
        afternoon,
        schoolClass: childrenSchoolClass
      })
      if (presence?.error) {
        setLoading(false)
        throw new Error(presence.error)
      }
      if (presence.results) {
        setSuccessMessage(t('Les présences ont bien étés enregistrées'))
        setLoading(false)
        loadChildrenPresence(new Date(selectedDate))
      }
    } catch (err) {
      setLoading(false)
      setErrorMessage(t("Impossible d'enregistrer les présences"))
    }
  }

  const onYearChange = useCallback(
    async (year) => {
      const publicHolidays = await getPublicHolidays(year)
      setPublicHolidays(publicHolidays)
    },
    [getPublicHolidays]
  )

  return (
    <div>
      <LayoutAdmin location={location} title={t("Présence en classe")} hasBack={false} />
      <ClassPresence
        children={children}
        holidays={holidays}
        publicHolidays={publicHolidays}
        onYearChange={onYearChange}
        onSchoolingYearChange={onSchoolingYearChange}
        onSubmit={onSubmit}
        loadChildrenPresence={loadChildrenPresence}
        presences={presences}
        modifiers={modifiers}
        loading={loading}
        childrenSchoolClass={childrenSchoolClass}
      />
    </div>
  )
}

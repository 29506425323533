import React, { useRef, useEffect, useState } from 'react'
import Close from '../svg/close'
import usePortal from 'react-useportal'

export default function Modal({
  title = '',
  isOpen = false,
  onClose = () => undefined,
  children,
  tiny = false,
  medium = false,
  responsive = false,
  verticalScroll = false,
  large = false,
  allowScroll = false
}) {
  const { Portal } = usePortal()
  const modalBox = useRef()

  const [positionY, setPositionY] = useState(0)

  useEffect(() => {
    let modalHeight = modalBox?.current?.clientHeight
    let maxHeight = window.screen.height
    let offset = window.pageYOffset
    let yPosition = 0
    if(modalHeight < maxHeight){
      yPosition = offset + ((maxHeight-modalHeight)/4) 
    }else{
      yPosition = offset + 15
    }
    setPositionY(yPosition)
}, [modalBox])

  if (!isOpen) return null

  if (verticalScroll) {
    // document.body.classList.toggle('overflow-hidden', 'h-full')
    document.body.classList.add('overflow-hidden', 'h-full')
  }

  function onCloseEvent() {
    document.body.classList.remove('overflow-hidden', 'h-full')

    onClose()
  }

  return (
    <Portal>
      <div
        className={`${(tiny || medium || responsive) && !allowScroll  ? 'fixed' : 'absolute'
          } z-40  fixed top-0  left-0 w-full flex flex-col ${!allowScroll && "justify-center justify-content-center items-center"}
        `}
        style={{
          background: 'rgba(0, 0, 0, 0.45)',
          backdropFilter: 'blur(4px)',
          minHeight: '100%',
        }}
        onClick={onCloseEvent}
      >
        <div
        ref={modalBox}
          className={`form-container mx-auto  ${tiny
              ? 'w-1/4'
              : medium
                ? 'w-1/2'
                : responsive && !large
                  ? 'w-full md:w-1/2 lg:w-1/4'
                  : 'w-full md:w-3/4 lg:w-3/4'
            } relative rounded-sm bg-white p-6 shadow-lg mb-16
          ${verticalScroll ? 'overflow-auto' : ''}`}
          onClick={(e) => e.stopPropagation()}
          style={{top: positionY}}
        >
          <div
            className="absolute top-0 right-0 mt-4 mr-4 text-2xl cursor-pointer"
            onClick={onCloseEvent}
          >
            <Close />
          </div>
          <div className="my-4 text-3xl font-bold"><span dangerouslySetInnerHTML={{ __html: title }} /></div>
          <div className="w-full">{children}</div>
        </div>
      </div>
    </Portal>
  )
}

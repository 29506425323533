import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useUser } from 'stores/user'
import { USER_ROLE } from 'lib/constants'

import ArrowLeft from 'components/svg/arrowLeft'
import { useTranslation } from 'react-i18next'
import useLanguage from 'hooks/useLanguage'
import FrenchFlag from 'components/svg/frenchFlag'
import UsFlag from 'components/svg/usFlag'

function IfAdmin({ user, children }) {
  if (user && user.role === USER_ROLE.ADMIN) {
    return children
  }
  return null
}

function IfUser({ user, children }) {
  if (user && user.role === USER_ROLE.PARENT) {
    return children
  }
  return null
}

function IfEmployee({ user, children }) {
  if (user && user.role === USER_ROLE.EMPLOYEE) {
    return children
  }
  return null
}

function BackButton({ toHome, visible }) {
  const history = useHistory()
  const { t } = useTranslation()

  const handleBack = useCallback(() => {
    if (toHome) {
      history.push('/')
    } else {
      history.push('/')
    }
  }, [toHome, history])

  return (
    <>
      {visible && (
        <button onClick={handleBack} className="flex items-center">
          <ArrowLeft />
          &nbsp;{t('Accueil')}
        </button>
      )}
    </>
  )
}

function Dropdown({ button, children }) {
  return (
    <div className="mr-8 user-menu-button" tabIndex="0">
      {button}
      <div className="flex flex-col user-menu-dropdown">{children}</div>
    </div>
  )
}

function getNameInitials(user) {
  const firstname = user.firstname || 'A'
  const lastname = user.lastname || 'A'

  return `${firstname[0]}${lastname[0]}`
}

function UserMenuButton({ user }) {
  return (
    <>
      <span className="uppercase">{getNameInitials(user)}</span>
      <svg
        className="h-3 fill-current inline"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </>
  )
}

// eslint-disable-next-line
function MenuSeparator() {
  return <div className="w-full h-4" />
}

function MenuSection({ title }) {
  return (
    <span className="user-menu-section w-full block uppercase text-2xs pl-2 pr-8 py-1 mt-4 bg-gray-200">
      {title}
    </span>
  )
}

function MenuLink({ to, disabled, children, onClick }) {
  const history = useHistory()

  const handleClick = useCallback(() => {
    if (!disabled) {
      if (onClick) onClick()
      else history.push(to)
    }
  }, [disabled, history, onClick, to])

  return (
    <button
      onClick={handleClick}
      className={`text-left w-full block ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      {children}
    </button>
  )
}

/**
 * Navigation Bar
 * @param {Object} props
 * @param {string} [props.title="École Montessori"]
 * @param {boolean} [props.hasBack=false]
 * @param {boolean} [props.isHome=false]
 *  @param {Array<string>} [props.disable=[]]
 *
 * @return {React.Element}
 */
export default function Navigation({ title, hasBack, isHome, disable }) {
  const user = useUser((o) => o)
  const history = useHistory()

  const { t } = useTranslation()
  const { changeLanguage, updateLanguage } = useLanguage()

  const logout = useCallback(() => {
    user.logout()
    history.push('/')
  }, [history, user])

  return (
    <nav className="navigation" style={{ zIndex: 100 }}>
      <div className="nav-content">
        <div className="menu-left w-12">
          <BackButton toHome={!hasBack} visible={!isHome} />
        </div>
        <div className="menu-left flex items-center">
          <span className="ml-4 text-brand font-bold">{title}</span>
        </div>

        <div className="menu-right">
          <div className="hidden xl:flex"></div>

          <Dropdown button={<UserMenuButton user={user} />}>
            <IfAdmin user={user}>
              <MenuLink to="/admin">{t('Liste des inscriptions')}</MenuLink>
              <MenuLink to="/bills">{t('Factures')}</MenuLink>
              <MenuLink to="/export">{t('Export')}</MenuLink>
              <MenuLink to="/userlist">{t('Utilisateurs')}</MenuLink>
              <MenuSection title={t('Suivi')} />
              <MenuLink to="/nurseryviewer">
                {t('Calendrier garderie')}
              </MenuLink>
              <MenuLink to="/classpresence">{t('Présence en classe')}</MenuLink>
              <MenuLink to="/acquiredskills">{t('Suivi des enfants')}</MenuLink>
              <MenuSection title={t('Paramétrages suivi')} />
              <MenuLink to="/category">{t('Catégories')}</MenuLink>
              <MenuLink to="/activity">{t('Activités')}</MenuLink>
              <MenuLink to="/domains">{t('Domaines')}</MenuLink>
              <MenuLink to="/subdomains">{t('Sous-domaines')}</MenuLink>
              <MenuLink to="/progressionlist">{t('Progressions')}</MenuLink>
              <MenuLink to="/sorting">{t("Gestion ordre pour impression")}</MenuLink>
              <MenuSection title={t('Paramétrages inscription')} />
              <MenuLink to="/pricelist">
                {t('Liste des tarifications')}
              </MenuLink>
              <MenuLink to="/pricingsgridslist">
                {t('Grille tarifaire')}
              </MenuLink>

              <MenuLink to="/schoolingyearlist">
                {t('Liste des rentrées scolaires')}
              </MenuLink>
              <MenuLink to="/companylist">{t('Liste des sociétés')}</MenuLink>
            </IfAdmin>

            <IfEmployee user={user}>
              <MenuLink to="/nurseryviewer">
                {t('Calendrier garderie')}
              </MenuLink>
              <MenuLink to="/classpresence">{t('Présence en classe')}</MenuLink>
              <MenuLink to="/category">{t('Catégories')}</MenuLink>
              <MenuLink to="/activity">{t('Activités')}</MenuLink>
              <MenuLink to="/domains">{t('Domaines')}</MenuLink>
              <MenuLink to="/subdomains">{t('Sous-domaines')}</MenuLink>
              <MenuLink to="/progressionlist">{t('Progressions')}</MenuLink>
              <MenuLink to="/acquiredskills">{t('Suivi des enfants')}</MenuLink>
            </IfEmployee>

            <IfUser user={user}>
              <MenuLink to="/dashboard">{t('Tableau de bord')}</MenuLink>

              <MenuSection title={t('Scolarité')} />
              <MenuLink
                to="/schoolingyear"
                disabled={disable.includes('/schoolingyear')}
              >
                {t('Pré-inscrire mon enfant')}
              </MenuLink>
              <MenuLink
                to="/chooseregistration"
                disabled={disable.includes('/chooseregistration')}
              >
                {t('Voir mes pré-inscriptions')}
              </MenuLink>
              <MenuLink
                to="/billsview"
                disabled={disable.includes('/billsview')}
              >
                {t('Voir mes factures')}
              </MenuLink>
              <MenuLink
                to="/viewcertificate"
                disabled={disable.includes('/viewcertificate')}
              >
                {t('Certificat de scolarité')}
              </MenuLink>

              <MenuSection title={t('Garderie')} />
              <MenuLink to="/nursery" disabled={disable.includes('/nursery')}>
                {t('Réservation de garderie')}
              </MenuLink>
            </IfUser>
            <MenuSection title={t('Langues')} />
            <div className="flex flex-col align-start justify-start">
              <MenuLink
                onClick={() => {
                  changeLanguage('fr')
                  updateLanguage('fr')
                }}
              >
                <div className="flex align-center justify-start">
                  <div>
                    <FrenchFlag className="fill-current" />
                  </div>
                  <div className="pl-2 flex-1">{t('Français')}</div>
                </div>
              </MenuLink>
              <MenuLink
                onClick={() => {
                  changeLanguage('eng')
                  updateLanguage('eng')
                }}
              >
                <div className="flex align-center justify-start">
                  <div>
                    <UsFlag className="fill-current" />
                  </div>
                  <div className="pl-2 flex-1">{t('Anglais')}</div>
                </div>
              </MenuLink>
            </div>
            <MenuSection title={user.email} />
            <MenuLink href="#" onClick={logout}>
              {t('Déconnexion')}
            </MenuLink>
          </Dropdown>
        </div>
      </div>
    </nav>
  )
}

Navigation.defaultProps = {
  title: 'École Montessori',
  hasBack: false,
  isHome: false,
  disable: [],
}

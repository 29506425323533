import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import DatePicker from '../components/form/Date'
import { SCHOOLINGYEAR_STATUS } from '../lib/constants'
import Select from '../components/form/Select'
import StepButtons from './common/StepButtons'

export default function SetSchoolDate({
  onSubmit,
  goBack,
  date,
  pricingsGrids,
  pricingChoosen,
  id,
  childcareHours,
  addChildcareHours,
  loading,
}) {
  const form = useForm()
  const [pricingsGridName, setPricingsGridName] = useState([])
  const [defaultChidcareHours, setDefaultChidcareHours] = useState([])
  const {
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    getValues,
    reset,
    clearErrors,
  } = form
  const currentDate = new Date().getFullYear()

  const grilletarifaire = watch('grilletarifaire')

  const status = [
    {
      label: 'Publiée',
      value: SCHOOLINGYEAR_STATUS.publish,
    },
    { label: 'Brouillon', value: SCHOOLINGYEAR_STATUS.draft },
  ]

  const formatList = (list) => {
    return list.map((l) => {
      return {
        label: l.name,
        value: l.id,
      }
    })
  }

  useEffect(() => {
    const a = formatList(pricingsGrids)
    setPricingsGridName(a)
    if (pricingChoosen) {
      setValue('grilletarifaire', pricingChoosen.value)
    }
  }, [pricingsGrids, pricingChoosen, setValue])

  useEffect(() => {
    if (childcareHours && date) {
      const temp = date?.PricingGrid?.PricingGridChildcareHours?.map(
        (hours) => {
          return childcareHours.find((h) => h.value === hours.ChildcareHourId)
        }
      )
      if (temp && temp.length) {
        setValue(
          'childcarehours',
          temp && temp.filter((elt) => elt !== undefined)
        )
      }

      setValue('date', date.date)
      setValue('status', date.status)
    }
  }, [childcareHours, date, setValue])

  useEffect(() => {
    // creating new element by creatable
    const values = getValues('childcarehours')
    if (values && values.length > 0) {
      let newValue = values.filter((v) => v.hasOwnProperty('__isNew__'))
      if (newValue.length > 0) {
        newValue.forEach(async (elt) => {
          let hours = await addChildcareHours(elt)
          if (hours.error) {
            const updateValues = values.filter(
              (v) => !v.hasOwnProperty('__isNew__')
            )
            setValue('childcarehours', updateValues)
          }
          if (hours.results) {
            const updateValues = values.map((v) => {
              if (v.__isNew__) {
                delete v.__isNew__
                v.value = hours.results.id
              }
              return v
            })
            setValue('childcarehours', updateValues)
          }
        })
      }
    }
  }, [watch, addChildcareHours, getValues, setValue])

  useEffect(() => {
    if (loading && loading.refresh === true) {
      reset({
        date: '',
        childcarehours: [],
        grilletarifaire: null,
        status: null,
      })
    }
  }, [loading, reset])

  // setting childcarehours defaultValue
  useEffect(() => {
    if (
      pricingsGrids &&
      pricingsGrids.length > 0 &&
      childcareHours &&
      childcareHours.length > 0
    ) {
      let pricingGrid = pricingsGrids.find(
        (p) => p.id === getValues('grilletarifaire')
      )
      if (!pricingGrid) return
      let defaultVal = pricingGrid.PricingGridChildcareHours.map(
        (p) => p.ChildcareHourId
      )

      let temp = []
      defaultVal.forEach((d) => {
        let t = childcareHours.find((c) => c.value === d)
        if (t) {
          temp.push(t)
        }
      })
      setDefaultChidcareHours(temp)
      setValue('childcarehours', temp)
      clearErrors('childcarehours')
    }
  }, [
    pricingsGrids,
    grilletarifaire,
    childcareHours,
    getValues,
    setValue,
    clearErrors,
  ])

  return (
    <>
      <div className="flex items-center justify-center mt-16">
        <form
          className="form-container sm:w-full xl:w-1/2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="font-black mb-4 text-center">
            {`${
              !isNaN(id) ? 'Modifier' : 'Ajouter'
            } une date de rentrée scolaire`}
          </div>

          <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
            <div className="w-4/5">
              <Controller
                control={control}
                name="date"
                rules={{ validate: (value) => !!value || 'Champ obligatoire' }}
                render={({ onChange, value, name, defaultValue }) => {
                  return (
                    <>
                      <DatePicker
                        name={name}
                        label="Rentrée"
                        defaultValue={defaultValue}
                        form={form}
                        value={value}
                        error={errors.date && errors.date.message}
                        className="bg-gray-200 rounded-lg w-full"
                        options={{
                          max: String(currentDate + 10),
                          min: String(currentDate - 1),
                        }}
                      />
                    </>
                  )
                }}
              />
            </div>
          </div>

          <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
            <div className="w-4/5">
              <Controller
                control={control}
                name={`grilletarifaire`}
                rules={{
                  validate: (value) => {
                    return !!value || 'Champ obligatoire'
                  },
                }}
                render={({ onChange, value, name, defaultValue }) => {
                  return (
                    <>
                      <Select
                        key={`grilletarifaire-${value}`}
                        placeholder={'Grille tarifaire'}
                        name={name}
                        defaultValue={defaultValue}
                        className="w-full"
                        label="Grille tarifaire"
                        value={value}
                        values={pricingsGridName}
                        onChange={(e) => {
                          onChange(e && e.value)
                        }}
                      />
                      {errors && errors[name] && (
                        <div className="text-sm text-red-500">
                          {errors[name].message}
                        </div>
                      )}
                    </>
                  )
                }}
              />
            </div>
          </div>
          <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
            <div className="w-4/5">
              <Controller
                control={control}
                rules={{ required: 'Champ obligatoire' }}
                name={`status`}
                defaultValue={date && date.status}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      key={`grilletarifaire-${value}`}
                      placeholder={'Publiée / Brouillon'}
                      name={name}
                      className="w-full"
                      label="Statut"
                      value={value}
                      values={status}
                      onChange={(e) => {
                        onChange(e && e.value)
                      }}
                    />
                    {errors && errors[name] && (
                      <div className="text-sm text-red-500">
                        {errors[name].message}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          </div>
          <div className="flex w-full md:flex-row flex-col my-2 justify-evenly">
            <div className="w-4/5">
              <Controller
                control={control}
                name={`childcarehours`}
                rules={{
                  validate: (value) => {
                    return !!value && (value.length > 0 || 'Champ obligatoire')
                  },
                }}
                defaultValue={defaultChidcareHours}
                render={({ onChange, value, name }) => (
                  <>
                    <Select
                      key={`childcarehour-${JSON.stringify(
                        defaultChidcareHours
                      )}`}
                      placeholder={'8:00-8:30'}
                      name={name}
                      className="w-full"
                      label="Horaires garderie"
                      value={value}
                      isMulti
                      values={childcareHours}
                      creatable
                      filterOption={() => true}
                      onChange={(e) => {
                        onChange(e)
                      }}
                    />
                    {errors &&
                      errors.childcarehours &&
                      errors.childcarehours.message && (
                        <div className="text-sm text-red-500">
                          {errors.childcarehours.message}
                        </div>
                      )}
                  </>
                )}
              />
            </div>
          </div>
          <StepButtons
            goBack={goBack}
            loading={loading.isLoading}
            nextLabel={'Enregistrer'}
          />
        </form>
      </div>
    </>
  )
}

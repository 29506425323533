import React, { useMemo, useState, useCallback } from 'react'
import Table from 'components/common/table'
import useRegistration from 'hooks/useRegistration'
import { useHistory } from 'react-router-dom'
import { dateFmt } from 'components/common/table/formats'
import { DefaultColumnFilter } from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'

import {
  Actions,
  EditAction,
  RemoveAction,
} from 'components/common/table/actions'

import { useNotify } from 'stores/notify'

const initialPageSize = 10
const initialSortBy = [
  {
    id: 'id',
    desc: true,
  },
]

const MyModelsList = () => {
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const [forceRefresh] = useState(1)

  const { getCompanies, deleteCompany } = useRegistration()

  const onError = useCallback(
    (err) => {
      if (err && err.statusCode && err.statusCode === 401) {
        setErrorMessage(err.message)
        history.replace('/')
      } else {
        setErrorMessage('Une erreur est survenue')
      }
    },
    [history, setErrorMessage]
  )

  const fetchData = useApiFetch(getCompanies, onError)

  const [removed, setRemoved] = useState(null)

  const onRemove = useCallback(
    async (id) => {
      const req = await deleteCompany(id)
      if (req.error) {
        setErrorMessage(req.error.message)
        return
      }

      if (req.results && req.results.success) {
        setSuccessMessage(`Elément supprimé avec succès`)
        setRemoved(id)
      } else {
        setErrorMessage(`Impossible de supprimer: ${req.error.message}`)
      }
    },
    [deleteCompany, setErrorMessage, setSuccessMessage]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Date mise à jour',
        accessor: 'updatedAt',
        disableFilters: true,
        className: 'font-bold',
        Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: 'Nom',
        accessor: 'name', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Adresse',
        accessor: 'address', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Partenaire financier',
        accessor: 'financialPartner', // accessor is the "key" in the data
        disableFilters: true,
        // bold: true
        className: 'font-bold',
        Cell: ({ value }) => value && 'X',
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableFilters: true,
        Cell: (props) => (
          <Actions>
            <EditAction
              entity={props.value}
              message={'Voulez-vous modifier cette société '}
              to={`/setcompany/${props.value}`}
            />
            <RemoveAction
              onRemove={onRemove}
              {...props}
              title={`Êtes-vous sûr de vouloir supprimer la société ${props.row.original.name}&nbsp;?`}
            />
          </Actions>
        ),
      },
    ],
    [onRemove]
  )

  const addCompany = useCallback(() => {
    history.push('/setcompany/new')
  }, [history])

  return (
    <div className="flex justify-center w-full pt-12">
      <Table
        fetchData={fetchData}
        name="Liste des sociétés"
        initialPageSize={initialPageSize}
        columns={columns}
        rowRemoved={removed}
        initialSortBy={initialSortBy}
        addEntity={addCompany}
        forceRefresh={forceRefresh}
      />
    </div>
  )
}

export default MyModelsList
